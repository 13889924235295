(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.b3.a_ === region.co.a_)
	{
		return 'on line ' + region.b3.a_;
	}
	return 'on lines ' + region.b3.a_ + ' through ' + region.co.a_;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = elm$core$Set$toList(x);
		y = elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = elm$core$Dict$toList(x);
		y = elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = elm$core$Dict$toList(x);
		y = elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? elm$core$Basics$LT : n ? elm$core$Basics$GT : elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === elm$core$Basics$EQ ? 0 : ord === elm$core$Basics$LT ? -1 : 1;
	}));
});



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? elm$core$Maybe$Nothing
		: elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? elm$core$Maybe$Just(n) : elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? elm$core$Result$Ok(value)
		: (value instanceof String)
			? elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return elm$core$Result$Err(A2(elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return (elm$core$Result$isOk(result)) ? result : elm$core$Result$Err(A2(elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return (elm$core$Result$isOk(result)) ? result : elm$core$Result$Err(A2(elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!elm$core$Result$isOk(result))
					{
						return elm$core$Result$Err(A2(elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return elm$core$Result$Ok(elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if (elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return elm$core$Result$Err(elm$json$Json$Decode$OneOf(elm$core$List$reverse(errors)));

		case 1:
			return elm$core$Result$Err(A2(elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!elm$core$Result$isOk(result))
		{
			return elm$core$Result$Err(A2(elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList === 'function' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2(elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return elm$core$Result$Err(A2(elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dJ,
		impl.d8,
		impl.d6,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2(elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return elm$core$Maybe$Nothing;
	}
}



// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2(elm$json$Json$Decode$map, func, handler.a)
				:
			A3(elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		Q: func(record.Q),
		b4: record.b4,
		b_: record.b_
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.Q;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.b4;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.b_) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dJ,
		impl.d8,
		impl.d6,
		function(sendToApp, initialModel) {
			var view = impl.eb;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.dJ,
		impl.d8,
		impl.d6,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.a3 && impl.a3(sendToApp)
			var view = impl.eb;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.dr);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.d7) && (_VirtualDom_doc.title = title = doc.d7);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.dX;
	var onUrlRequest = impl.dY;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		a3: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.cZ === next.cZ
							&& curr.cA === next.cA
							&& curr.cV.a === next.cV.a
						)
							? elm$browser$Browser$Internal(next)
							: elm$browser$Browser$External(href)
					));
				}
			});
		},
		dJ: function(flags)
		{
			return A3(impl.dJ, flags, _Browser_getUrl(), key);
		},
		eb: impl.eb,
		d8: impl.d8,
		d6: impl.d6
	});
}

function _Browser_getUrl()
{
	return elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return elm$core$Result$isOk(result) ? elm$core$Maybe$Just(result.a) : elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { dF: 'hidden', ds: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { dF: 'mozHidden', ds: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { dF: 'msHidden', ds: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { dF: 'webkitHidden', ds: 'webkitvisibilitychange' }
		: { dF: 'hidden', ds: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail(elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		c5: _Browser_getScene(),
		df: {
			bH: _Browser_window.pageXOffset,
			ai: _Browser_window.pageYOffset,
			aP: _Browser_doc.documentElement.clientWidth,
			av: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		aP: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		av: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			c5: {
				aP: node.scrollWidth,
				av: node.scrollHeight
			},
			df: {
				bH: node.scrollLeft,
				ai: node.scrollTop,
				aP: node.clientWidth,
				av: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			c5: _Browser_getScene(),
			df: {
				bH: x,
				ai: y,
				aP: _Browser_doc.documentElement.clientWidth,
				av: _Browser_doc.documentElement.clientHeight
			},
			dA: {
				bH: x + rect.left,
				ai: y + rect.top,
				aP: rect.width,
				av: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}
var author$project$Main$LinkClicked = function (a) {
	return {$: 1, a: a};
};
var author$project$Main$UrlChanged = function (a) {
	return {$: 2, a: a};
};
var author$project$Main$Home = function (a) {
	return {$: 3, a: a};
};
var author$project$Main$NotFound = {$: 2};
var author$project$Main$ContentPage = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var author$project$Main$CoursePage = function (a) {
	return {$: 1, a: a};
};
var author$project$Main$Landing = {$: 0};
var elm$core$Basics$EQ = 1;
var elm$core$Basics$LT = 0;
var elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var elm$core$Array$foldr = F3(
	function (func, baseCase, _n0) {
		var tree = _n0.c;
		var tail = _n0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3(elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3(elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			elm$core$Elm$JsArray$foldr,
			helper,
			A3(elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var elm$core$List$cons = _List_cons;
var elm$core$Array$toList = function (array) {
	return A3(elm$core$Array$foldr, elm$core$List$cons, _List_Nil, array);
};
var elm$core$Basics$GT = 2;
var elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3(elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var elm$core$Dict$toList = function (dict) {
	return A3(
		elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var elm$core$Dict$keys = function (dict) {
	return A3(
		elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2(elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var elm$core$Set$toList = function (_n0) {
	var dict = _n0;
	return elm$core$Dict$keys(dict);
};
var elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var elm$core$Maybe$Nothing = {$: 1};
var elm$core$String$toInt = _String_toInt;
var elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var elm$core$Basics$identity = function (x) {
	return x;
};
var elm$url$Url$Parser$Parser = elm$core$Basics$identity;
var elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {X: frag, ad: params, U: unvisited, I: value, ah: visited};
	});
var elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_n0) {
			var visited = _n0.ah;
			var unvisited = _n0.U;
			var params = _n0.ad;
			var frag = _n0.X;
			var value = _n0.I;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _n2 = stringToSomething(next);
				if (!_n2.$) {
					var nextValue = _n2.a;
					return _List_fromArray(
						[
							A5(
							elm$url$Url$Parser$State,
							A2(elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var elm$url$Url$Parser$int = A2(elm$url$Url$Parser$custom, 'NUMBER', elm$core$String$toInt);
var elm$core$Basics$add = _Basics_add;
var elm$core$Basics$gt = _Utils_gt;
var elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var elm$core$List$reverse = function (list) {
	return A3(elm$core$List$foldl, elm$core$List$cons, _List_Nil, list);
};
var elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							elm$core$List$foldl,
							fn,
							acc,
							elm$core$List$reverse(r4)) : A4(elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4(elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var elm$url$Url$Parser$mapState = F2(
	function (func, _n0) {
		var visited = _n0.ah;
		var unvisited = _n0.U;
		var params = _n0.ad;
		var frag = _n0.X;
		var value = _n0.I;
		return A5(
			elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var elm$url$Url$Parser$map = F2(
	function (subValue, _n0) {
		var parseArg = _n0;
		return function (_n1) {
			var visited = _n1.ah;
			var unvisited = _n1.U;
			var params = _n1.ad;
			var frag = _n1.X;
			var value = _n1.I;
			return A2(
				elm$core$List$map,
				elm$url$Url$Parser$mapState(value),
				parseArg(
					A5(elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3(elm$core$List$foldr, elm$core$List$cons, ys, xs);
		}
	});
var elm$core$List$concat = function (lists) {
	return A3(elm$core$List$foldr, elm$core$List$append, _List_Nil, lists);
};
var elm$core$List$concatMap = F2(
	function (f, list) {
		return elm$core$List$concat(
			A2(elm$core$List$map, f, list));
	});
var elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			elm$core$List$concatMap,
			function (_n0) {
				var parser = _n0;
				return parser(state);
			},
			parsers);
	};
};
var elm$core$Basics$eq = _Utils_equal;
var elm$url$Url$Parser$s = function (str) {
	return function (_n0) {
		var visited = _n0.ah;
		var unvisited = _n0.U;
		var params = _n0.ad;
		var frag = _n0.X;
		var value = _n0.I;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					elm$url$Url$Parser$State,
					A2(elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var elm$url$Url$Parser$slash = F2(
	function (_n0, _n1) {
		var parseBefore = _n0;
		var parseAfter = _n1;
		return function (state) {
			return A2(
				elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var author$project$Main$routerParser = elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2(elm$url$Url$Parser$map, author$project$Main$Landing, elm$url$Url$Parser$top),
			A2(
			elm$url$Url$Parser$map,
			author$project$Main$CoursePage,
			A2(
				elm$url$Url$Parser$slash,
				elm$url$Url$Parser$s('course'),
				elm$url$Url$Parser$int)),
			A2(
			elm$url$Url$Parser$map,
			author$project$Main$ContentPage,
			A2(
				elm$url$Url$Parser$slash,
				elm$url$Url$Parser$s('course'),
				A2(elm$url$Url$Parser$slash, elm$url$Url$Parser$int, elm$url$Url$Parser$int)))
		]));
var author$project$Main$Content = function (a) {
	return {$: 1, a: a};
};
var author$project$Main$ContentMsg = function (a) {
	return {$: 4, a: a};
};
var elm$core$Basics$False = 1;
var elm$core$Basics$True = 0;
var elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var elm$core$Array$branchFactor = 32;
var elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var elm$core$Basics$ceiling = _Basics_ceiling;
var elm$core$Basics$fdiv = _Basics_fdiv;
var elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var elm$core$Basics$toFloat = _Basics_toFloat;
var elm$core$Array$shiftStep = elm$core$Basics$ceiling(
	A2(elm$core$Basics$logBase, 2, elm$core$Array$branchFactor));
var elm$core$Elm$JsArray$empty = _JsArray_empty;
var elm$core$Array$empty = A4(elm$core$Array$Array_elm_builtin, 0, elm$core$Array$shiftStep, elm$core$Elm$JsArray$empty, elm$core$Elm$JsArray$empty);
var elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _n0 = A2(elm$core$Elm$JsArray$initializeFromList, elm$core$Array$branchFactor, nodes);
			var node = _n0.a;
			var remainingNodes = _n0.b;
			var newAcc = A2(
				elm$core$List$cons,
				elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var elm$core$Tuple$first = function (_n0) {
	var x = _n0.a;
	return x;
};
var elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = elm$core$Basics$ceiling(nodeListSize / elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2(elm$core$Elm$JsArray$initializeFromList, elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2(elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var elm$core$Basics$floor = _Basics_floor;
var elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var elm$core$Basics$mul = _Basics_mul;
var elm$core$Basics$sub = _Basics_sub;
var elm$core$Elm$JsArray$length = _JsArray_length;
var elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.i) {
			return A4(
				elm$core$Array$Array_elm_builtin,
				elm$core$Elm$JsArray$length(builder.k),
				elm$core$Array$shiftStep,
				elm$core$Elm$JsArray$empty,
				builder.k);
		} else {
			var treeLen = builder.i * elm$core$Array$branchFactor;
			var depth = elm$core$Basics$floor(
				A2(elm$core$Basics$logBase, elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? elm$core$List$reverse(builder.l) : builder.l;
			var tree = A2(elm$core$Array$treeFromBuilder, correctNodeList, builder.i);
			return A4(
				elm$core$Array$Array_elm_builtin,
				elm$core$Elm$JsArray$length(builder.k) + treeLen,
				A2(elm$core$Basics$max, 5, depth * elm$core$Array$shiftStep),
				tree,
				builder.k);
		}
	});
var elm$core$Basics$idiv = _Basics_idiv;
var elm$core$Basics$lt = _Utils_lt;
var elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					elm$core$Array$builderToArray,
					false,
					{l: nodeList, i: (len / elm$core$Array$branchFactor) | 0, k: tail});
			} else {
				var leaf = elm$core$Array$Leaf(
					A3(elm$core$Elm$JsArray$initialize, elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2(elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var elm$core$Basics$le = _Utils_le;
var elm$core$Basics$remainderBy = _Basics_remainderBy;
var elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return elm$core$Array$empty;
		} else {
			var tailLen = len % elm$core$Array$branchFactor;
			var tail = A3(elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - elm$core$Array$branchFactor;
			return A5(elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var elm$core$Basics$and = _Basics_and;
var elm$core$Basics$append = _Utils_append;
var elm$core$Basics$or = _Basics_or;
var elm$core$Char$toCode = _Char_toCode;
var elm$core$Char$isLower = function (_char) {
	var code = elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var elm$core$Char$isUpper = function (_char) {
	var code = elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var elm$core$Char$isAlpha = function (_char) {
	return elm$core$Char$isLower(_char) || elm$core$Char$isUpper(_char);
};
var elm$core$Char$isDigit = function (_char) {
	var code = elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var elm$core$Char$isAlphaNum = function (_char) {
	return elm$core$Char$isLower(_char) || (elm$core$Char$isUpper(_char) || elm$core$Char$isDigit(_char));
};
var elm$core$List$length = function (xs) {
	return A3(
		elm$core$List$foldl,
		F2(
			function (_n0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var elm$core$List$map2 = _List_map2;
var elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2(elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var elm$core$List$range = F2(
	function (lo, hi) {
		return A3(elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			elm$core$List$map2,
			f,
			A2(
				elm$core$List$range,
				0,
				elm$core$List$length(xs) - 1),
			xs);
	});
var elm$core$String$all = _String_all;
var elm$core$String$fromInt = _String_fromNumber;
var elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var elm$core$String$uncons = _String_uncons;
var elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var elm$json$Json$Decode$indent = function (str) {
	return A2(
		elm$core$String$join,
		'\n    ',
		A2(elm$core$String$split, '\n', str));
};
var elm$json$Json$Encode$encode = _Json_encode;
var elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + (elm$core$String$fromInt(i + 1) + (') ' + elm$json$Json$Decode$indent(
			elm$json$Json$Decode$errorToString(error))));
	});
var elm$json$Json$Decode$errorToString = function (error) {
	return A2(elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _n1 = elm$core$String$uncons(f);
						if (_n1.$ === 1) {
							return false;
						} else {
							var _n2 = _n1.a;
							var _char = _n2.a;
							var rest = _n2.b;
							return elm$core$Char$isAlpha(_char) && A2(elm$core$String$all, elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2(elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + (elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2(elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									elm$core$String$join,
									'',
									elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										elm$core$String$join,
										'',
										elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + (elm$core$String$fromInt(
								elm$core$List$length(errors)) + ' ways:'));
							return A2(
								elm$core$String$join,
								'\n\n',
								A2(
									elm$core$List$cons,
									introduction,
									A2(elm$core$List$indexedMap, elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								elm$core$String$join,
								'',
								elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + (elm$json$Json$Decode$indent(
						A2(elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var elm$core$Platform$Cmd$map = _Platform_map;
var author$project$Main$stepContent = F2(
	function (model, _n0) {
		var items = _n0.a;
		var cmds = _n0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					C: author$project$Main$Content(items)
				}),
			A2(elm$core$Platform$Cmd$map, author$project$Main$ContentMsg, cmds));
	});
var author$project$Main$Course = function (a) {
	return {$: 0, a: a};
};
var author$project$Main$CourseMsg = function (a) {
	return {$: 3, a: a};
};
var author$project$Main$stepCourse = F2(
	function (model, _n0) {
		var items = _n0.a;
		var cmds = _n0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					C: author$project$Main$Course(items)
				}),
			A2(elm$core$Platform$Cmd$map, author$project$Main$CourseMsg, cmds));
	});
var author$project$Main$HomeMsg = function (a) {
	return {$: 5, a: a};
};
var author$project$Main$stepHome = F2(
	function (model, _n0) {
		var home = _n0.a;
		var cmds = _n0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					C: author$project$Main$Home(home)
				}),
			A2(elm$core$Platform$Cmd$map, author$project$Main$HomeMsg, cmds));
	});
var author$project$Config$Types$BasicIntervalConfig = F2(
	function (intervals, count) {
		return {aW: count, bP: intervals};
	});
var author$project$Config$Types$BasicIntervals = function (a) {
	return {$: 4, a: a};
};
var author$project$Config$Types$ContentInfo = F2(
	function (name, contentType) {
		return {du: contentType, dT: name};
	});
var author$project$Config$Types$CourseInfo = F3(
	function (title, description, content) {
		return {dt: content, dz: description, d7: title};
	});
var author$project$Config$Types$Exercise = function (a) {
	return {$: 1, a: a};
};
var author$project$Config$Types$KeyBasicIntervals = function (a) {
	return {$: 5, a: a};
};
var author$project$Config$Types$Video = function (a) {
	return {$: 0, a: a};
};
var author$project$Exercises$BasicIntervals$Eighth = 7;
var author$project$Exercises$BasicIntervals$Fifth = 4;
var author$project$Exercises$BasicIntervals$Fourth = 3;
var author$project$Exercises$BasicIntervals$Ninth = 8;
var author$project$Exercises$BasicIntervals$Same = 0;
var author$project$Exercises$BasicIntervals$Seventh = 6;
var author$project$Exercises$BasicIntervals$Sixth = 5;
var author$project$Exercises$BasicIntervals$Skip = 2;
var author$project$Exercises$BasicIntervals$Step = 1;
var author$project$Exercises$BasicIntervals$Tenth = 9;
var author$project$Config$IntervalsOne$courseIntervalsOne = A3(
	author$project$Config$Types$CourseInfo,
	'Basic Intervals',
	'Learn basic intervals on the staff and the keyboard.',
	_List_fromArray(
		[
			A2(
			author$project$Config$Types$ContentInfo,
			'Introduction to intervals',
			author$project$Config$Types$Video('379472697')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Seconds and Thirds on the staff',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$BasicIntervals(
					A2(
						author$project$Config$Types$BasicIntervalConfig,
						_List_fromArray(
							[0, 1, 2]),
						10)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Seconds and Thirds on the keyboard',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$KeyBasicIntervals(
					A2(
						author$project$Config$Types$BasicIntervalConfig,
						_List_fromArray(
							[1, 2]),
						10)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Fourths, Fifths, and Sixths on the staff',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$BasicIntervals(
					A2(
						author$project$Config$Types$BasicIntervalConfig,
						_List_fromArray(
							[3, 4, 5]),
						10)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Keyboard Basic Intervals Up to a 6th',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$KeyBasicIntervals(
					A2(
						author$project$Config$Types$BasicIntervalConfig,
						_List_fromArray(
							[3, 4, 5]),
						10)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Keyboard Basic Intervals Up to a 10th',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$KeyBasicIntervals(
					A2(
						author$project$Config$Types$BasicIntervalConfig,
						_List_fromArray(
							[6, 7, 8, 9]),
						10))))
		]));
var author$project$Config$Types$IntervalConfig = F4(
	function (intervals, qualities, clefs, count) {
		return {aV: clefs, aW: count, bP: intervals, bv: qualities};
	});
var author$project$Config$Types$Intervals = function (a) {
	return {$: 6, a: a};
};
var author$project$Config$Types$KeyIntervalConfig = F3(
	function (intervals, qualities, count) {
		return {aW: count, bP: intervals, bv: qualities};
	});
var author$project$Config$Types$KeyIntervals = function (a) {
	return {$: 7, a: a};
};
var author$project$Theory$RenderEngine$Alto = 2;
var author$project$Theory$RenderEngine$Bass = 1;
var author$project$Theory$RenderEngine$Treble = 0;
var author$project$Theory$TheoryEngine$Aug = 3;
var author$project$Theory$TheoryEngine$Dim = 0;
var author$project$Theory$TheoryEngine$Maj = 2;
var author$project$Theory$TheoryEngine$Min = 1;
var author$project$Theory$TheoryEngine$Perfect = 4;
var author$project$Config$IntervalsTwo$courseIntervalsTwo = A3(
	author$project$Config$Types$CourseInfo,
	'More Intervals',
	'Drill minor, major, perfect, & diminished intervals 2nds through 5ths.',
	_List_fromArray(
		[
			A2(
			author$project$Config$Types$ContentInfo,
			'Minor and Major 2nds',
			author$project$Config$Types$Video('380923833')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Minor and Major 2nd on the keyboard',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$KeyIntervals(
					A3(
						author$project$Config$Types$KeyIntervalConfig,
						_List_fromArray(
							[2]),
						_List_fromArray(
							[2, 1]),
						8)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Minor and Major 2nd on the staff',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Intervals(
					A4(
						author$project$Config$Types$IntervalConfig,
						_List_fromArray(
							[2]),
						_List_fromArray(
							[2, 1]),
						_List_fromArray(
							[0, 1]),
						10)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Minor and Major 3rds',
			author$project$Config$Types$Video('380926314')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Minor and Major 3rds on the keyboard',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$KeyIntervals(
					A3(
						author$project$Config$Types$KeyIntervalConfig,
						_List_fromArray(
							[3]),
						_List_fromArray(
							[2, 1]),
						8)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Minor and Major 3rds on the staff',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Intervals(
					A4(
						author$project$Config$Types$IntervalConfig,
						_List_fromArray(
							[3]),
						_List_fromArray(
							[2, 1]),
						_List_fromArray(
							[0, 1]),
						8)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'2nds and 3rds on the keyboard',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$KeyIntervals(
					A3(
						author$project$Config$Types$KeyIntervalConfig,
						_List_fromArray(
							[2, 3]),
						_List_fromArray(
							[2, 1]),
						8)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'2nds and 3rds on the staff',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Intervals(
					A4(
						author$project$Config$Types$IntervalConfig,
						_List_fromArray(
							[2, 3]),
						_List_fromArray(
							[2, 1]),
						_List_fromArray(
							[0, 1]),
						8)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'4ths & 5ths',
			author$project$Config$Types$Video('380942840')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Augmented Intervals',
			author$project$Config$Types$Video('380941854')),
			A2(
			author$project$Config$Types$ContentInfo,
			'4ths & 5ths on the staff',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Intervals(
					A4(
						author$project$Config$Types$IntervalConfig,
						_List_fromArray(
							[4, 5]),
						_List_fromArray(
							[4, 3, 0]),
						_List_fromArray(
							[0, 1]),
						10)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'4ths & 5ths on the keyboard',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$KeyIntervals(
					A3(
						author$project$Config$Types$KeyIntervalConfig,
						_List_fromArray(
							[4, 5]),
						_List_fromArray(
							[4, 3, 0]),
						10)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'2nds through 5ths',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$KeyIntervals(
					A3(
						author$project$Config$Types$KeyIntervalConfig,
						_List_fromArray(
							[2, 3, 4, 5]),
						_List_fromArray(
							[1, 2, 4, 3, 0]),
						10)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Bonus: 2nds through 5ths in the alto clef',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Intervals(
					A4(
						author$project$Config$Types$IntervalConfig,
						_List_fromArray(
							[2, 3, 4, 5]),
						_List_fromArray(
							[1, 2, 4, 0, 3]),
						_List_fromArray(
							[2]),
						10))))
		]));
var author$project$Config$Types$KeySignatureConfig = F2(
	function (clefs, keySignatures) {
		return {aV: clefs, dM: keySignatures};
	});
var author$project$Config$Types$KeySignatures = function (a) {
	return {$: 9, a: a};
};
var author$project$Theory$TheoryEngine$A = 5;
var author$project$Theory$TheoryEngine$B = 6;
var author$project$Theory$TheoryEngine$C = 0;
var author$project$Theory$TheoryEngine$D = 1;
var author$project$Theory$TheoryEngine$E = 2;
var author$project$Theory$TheoryEngine$F = 3;
var author$project$Theory$TheoryEngine$Flat = 1;
var author$project$Theory$TheoryEngine$G = 4;
var author$project$Theory$TheoryEngine$KeySignature = F3(
	function (note, accidental, quality) {
		return {dk: accidental, dU: note, d1: quality};
	});
var author$project$Theory$TheoryEngine$None = 3;
var author$project$Theory$TheoryEngine$Sharp = 4;
var author$project$Config$KeySignatures$courseKeySignatures = A3(
	author$project$Config$Types$CourseInfo,
	'Key Signatures',
	'Learn your major and minor key signatures.',
	_List_fromArray(
		[
			A2(
			author$project$Config$Types$ContentInfo,
			'Circle of 5ths',
			author$project$Config$Types$Video('383686306')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Major Sharp Key Signatures',
			author$project$Config$Types$Video('383687521')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Major Key Signatures - Sharps',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$KeySignatures(
					A2(
						author$project$Config$Types$KeySignatureConfig,
						_List_fromArray(
							[0, 1]),
						_List_fromArray(
							[
								A3(author$project$Theory$TheoryEngine$KeySignature, 4, 3, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 1, 3, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 5, 3, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 2, 3, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 6, 3, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 3, 4, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 0, 4, 2)
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Major Flat Key Signatures',
			author$project$Config$Types$Video('383687310')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Major Key Signatures - Flats',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$KeySignatures(
					A2(
						author$project$Config$Types$KeySignatureConfig,
						_List_fromArray(
							[0, 1]),
						_List_fromArray(
							[
								A3(author$project$Theory$TheoryEngine$KeySignature, 3, 3, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 6, 1, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 2, 1, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 5, 1, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 1, 1, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 4, 1, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 0, 1, 2)
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Minor Sharp Key Signatures',
			author$project$Config$Types$Video('383688423')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Minor Key Signatures - Sharps',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$KeySignatures(
					A2(
						author$project$Config$Types$KeySignatureConfig,
						_List_fromArray(
							[0, 1]),
						_List_fromArray(
							[
								A3(author$project$Theory$TheoryEngine$KeySignature, 5, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 2, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 6, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 3, 4, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 0, 4, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 4, 4, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 1, 4, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 5, 4, 1)
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Minor Flat Key Signatures',
			author$project$Config$Types$Video('383687686')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Minor Key Signatures - Flats',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$KeySignatures(
					A2(
						author$project$Config$Types$KeySignatureConfig,
						_List_fromArray(
							[0, 1]),
						_List_fromArray(
							[
								A3(author$project$Theory$TheoryEngine$KeySignature, 5, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 1, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 4, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 0, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 3, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 6, 1, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 2, 1, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 5, 1, 1)
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Major Key Signatures 2 sharps 2 flats',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$KeySignatures(
					A2(
						author$project$Config$Types$KeySignatureConfig,
						_List_fromArray(
							[0, 1]),
						_List_fromArray(
							[
								A3(author$project$Theory$TheoryEngine$KeySignature, 0, 3, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 4, 3, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 1, 3, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 3, 3, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 6, 1, 2)
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Major Key Signatures 4 sharps 4 flats',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$KeySignatures(
					A2(
						author$project$Config$Types$KeySignatureConfig,
						_List_fromArray(
							[0, 1]),
						_List_fromArray(
							[
								A3(author$project$Theory$TheoryEngine$KeySignature, 4, 3, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 1, 3, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 2, 3, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 3, 3, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 6, 1, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 2, 1, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 5, 1, 2)
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Minor Key Signatures 2 sharps 2 flats',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$KeySignatures(
					A2(
						author$project$Config$Types$KeySignatureConfig,
						_List_fromArray(
							[0, 1]),
						_List_fromArray(
							[
								A3(author$project$Theory$TheoryEngine$KeySignature, 5, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 2, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 6, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 1, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 4, 3, 1)
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Minor Key Signatures 4 sharps 4 flats',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$KeySignatures(
					A2(
						author$project$Config$Types$KeySignatureConfig,
						_List_fromArray(
							[0, 1]),
						_List_fromArray(
							[
								A3(author$project$Theory$TheoryEngine$KeySignature, 5, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 2, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 6, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 1, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 4, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 0, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 3, 3, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 3, 4, 1),
								A3(author$project$Theory$TheoryEngine$KeySignature, 0, 4, 1)
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Major Key Signatures 7 sharps 7 flats',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$KeySignatures(
					A2(
						author$project$Config$Types$KeySignatureConfig,
						_List_fromArray(
							[0, 1]),
						_List_fromArray(
							[
								A3(author$project$Theory$TheoryEngine$KeySignature, 2, 3, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 6, 3, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 3, 4, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 0, 4, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 2, 1, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 5, 1, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 1, 1, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 4, 1, 2),
								A3(author$project$Theory$TheoryEngine$KeySignature, 0, 1, 2)
							])))))
		]));
var author$project$Config$Types$KeyNotes = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var author$project$Config$Types$NoteClefConfig = F2(
	function (clef, notes) {
		return {bK: clef, cP: notes};
	});
var author$project$Config$Types$NoteConfig = function (clefs) {
	return {aV: clefs};
};
var author$project$Config$Types$Notes = function (a) {
	return {$: 0, a: a};
};
var author$project$Config$Types$Staff = 0;
var author$project$Config$Types$Text = 1;
var author$project$Theory$TheoryEngine$Note = F3(
	function (name, octave, accidental) {
		return {dk: accidental, dT: name, dW: octave};
	});
var author$project$Config$NoteNames$courseNoteNames = A3(
	author$project$Config$Types$CourseInfo,
	'Note Names',
	'Learn to identify notes on the treble and bass clefs as well as the keyboard.',
	_List_fromArray(
		[
			A2(
			author$project$Config$Types$ContentInfo,
			'Notes on the staff',
			author$project$Config$Types$Video('379470889')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Treble Notes C, D, E',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Notes(
					author$project$Config$Types$NoteConfig(
						_List_fromArray(
							[
								A2(
								author$project$Config$Types$NoteClefConfig,
								0,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 1, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 2, 4, 3)
									]))
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Notes on the keyboard',
			author$project$Config$Types$Video('379471378')),
			A2(
			author$project$Config$Types$ContentInfo,
			'C, D, E on the keyboard',
			author$project$Config$Types$Exercise(
				A2(
					author$project$Config$Types$KeyNotes,
					1,
					author$project$Config$Types$NoteConfig(
						_List_fromArray(
							[
								A2(
								author$project$Config$Types$NoteClefConfig,
								0,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 1, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 2, 4, 3)
									]))
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Notes on the staff & keyboard',
			author$project$Config$Types$Video('379472214')),
			A2(
			author$project$Config$Types$ContentInfo,
			'C, D, E staff to keyboard',
			author$project$Config$Types$Exercise(
				A2(
					author$project$Config$Types$KeyNotes,
					0,
					author$project$Config$Types$NoteConfig(
						_List_fromArray(
							[
								A2(
								author$project$Config$Types$NoteClefConfig,
								0,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 1, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 2, 4, 3)
									]))
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Bass Notes C, B, A',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Notes(
					author$project$Config$Types$NoteConfig(
						_List_fromArray(
							[
								A2(
								author$project$Config$Types$NoteClefConfig,
								1,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 6, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 5, 3, 3)
									]))
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Treble (middle C to G) and Bass (middle C to F) Clef notes',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Notes(
					author$project$Config$Types$NoteConfig(
						_List_fromArray(
							[
								A2(
								author$project$Config$Types$NoteClefConfig,
								0,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 1, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 2, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 3, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 4, 4, 3)
									])),
								A2(
								author$project$Config$Types$NoteClefConfig,
								1,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 6, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 5, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 4, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 3, 3, 3)
									]))
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Treble (middle C to G) and Bass (middle C to F) Clef notes on the staff',
			author$project$Config$Types$Exercise(
				A2(
					author$project$Config$Types$KeyNotes,
					0,
					author$project$Config$Types$NoteConfig(
						_List_fromArray(
							[
								A2(
								author$project$Config$Types$NoteClefConfig,
								0,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 1, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 2, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 3, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 4, 4, 3)
									])),
								A2(
								author$project$Config$Types$NoteClefConfig,
								1,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 6, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 5, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 4, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 3, 3, 3)
									]))
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Treble (B3 to C5) and Bass (C3 to D4) Clef notes',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Notes(
					author$project$Config$Types$NoteConfig(
						_List_fromArray(
							[
								A2(
								author$project$Config$Types$NoteClefConfig,
								0,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 6, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 1, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 2, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 3, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 4, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 5, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 6, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 0, 5, 3)
									])),
								A2(
								author$project$Config$Types$NoteClefConfig,
								1,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 0, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 1, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 2, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 3, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 4, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 5, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 6, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 1, 4, 3)
									]))
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Treble (B3 to C5) and Bass (C3 to D4) Clef notes on the staff',
			author$project$Config$Types$Exercise(
				A2(
					author$project$Config$Types$KeyNotes,
					0,
					author$project$Config$Types$NoteConfig(
						_List_fromArray(
							[
								A2(
								author$project$Config$Types$NoteClefConfig,
								0,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 6, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 1, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 2, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 3, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 4, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 5, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 6, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 0, 5, 3)
									])),
								A2(
								author$project$Config$Types$NoteClefConfig,
								1,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 0, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 1, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 2, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 3, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 4, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 5, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 6, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 1, 4, 3)
									]))
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Alto and Tenor Clefs',
			author$project$Config$Types$Video('379473812')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Bonus: Alto Clef Notes',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Notes(
					author$project$Config$Types$NoteConfig(
						_List_fromArray(
							[
								A2(
								author$project$Config$Types$NoteClefConfig,
								2,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 1, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 2, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 3, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 4, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 5, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 6, 3, 3),
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 1, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 2, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 3, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 4, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 5, 4, 3),
										A3(author$project$Theory$TheoryEngine$Note, 6, 4, 3)
									]))
							])))))
		]));
var author$project$Theory$TheoryEngine$DoubleFlat = 0;
var author$project$Theory$TheoryEngine$DoubleSharp = 5;
var author$project$Config$NoteNamesTwo$courseNoteNamesTwo = A3(
	author$project$Config$Types$CourseInfo,
	'Harder Note Names',
	'Learn to identify sharp and flat notes. Also learn double sharps and flats.',
	_List_fromArray(
		[
			A2(
			author$project$Config$Types$ContentInfo,
			'Sharps and flats',
			author$project$Config$Types$Video('379529495')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Sharps and flats on keyboard',
			author$project$Config$Types$Exercise(
				A2(
					author$project$Config$Types$KeyNotes,
					1,
					author$project$Config$Types$NoteConfig(
						_List_fromArray(
							[
								A2(
								author$project$Config$Types$NoteClefConfig,
								0,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 1, 4, 1),
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 4),
										A3(author$project$Theory$TheoryEngine$Note, 1, 4, 4),
										A3(author$project$Theory$TheoryEngine$Note, 2, 4, 1),
										A3(author$project$Theory$TheoryEngine$Note, 3, 4, 4),
										A3(author$project$Theory$TheoryEngine$Note, 4, 4, 1),
										A3(author$project$Theory$TheoryEngine$Note, 4, 4, 4),
										A3(author$project$Theory$TheoryEngine$Note, 5, 4, 1)
									])),
								A2(
								author$project$Config$Types$NoteClefConfig,
								1,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 6, 3, 1),
										A3(author$project$Theory$TheoryEngine$Note, 5, 3, 4),
										A3(author$project$Theory$TheoryEngine$Note, 5, 3, 1),
										A3(author$project$Theory$TheoryEngine$Note, 4, 3, 4),
										A3(author$project$Theory$TheoryEngine$Note, 4, 3, 1),
										A3(author$project$Theory$TheoryEngine$Note, 3, 3, 4),
										A3(author$project$Theory$TheoryEngine$Note, 2, 3, 1),
										A3(author$project$Theory$TheoryEngine$Note, 1, 3, 4)
									]))
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Sharps and flats on keyboard from staff',
			author$project$Config$Types$Exercise(
				A2(
					author$project$Config$Types$KeyNotes,
					0,
					author$project$Config$Types$NoteConfig(
						_List_fromArray(
							[
								A2(
								author$project$Config$Types$NoteClefConfig,
								0,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 1, 4, 1),
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 4),
										A3(author$project$Theory$TheoryEngine$Note, 1, 4, 4),
										A3(author$project$Theory$TheoryEngine$Note, 2, 4, 1),
										A3(author$project$Theory$TheoryEngine$Note, 3, 4, 4),
										A3(author$project$Theory$TheoryEngine$Note, 4, 4, 1),
										A3(author$project$Theory$TheoryEngine$Note, 4, 4, 4),
										A3(author$project$Theory$TheoryEngine$Note, 5, 4, 1)
									])),
								A2(
								author$project$Config$Types$NoteClefConfig,
								1,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 6, 3, 1),
										A3(author$project$Theory$TheoryEngine$Note, 5, 3, 4),
										A3(author$project$Theory$TheoryEngine$Note, 5, 3, 1),
										A3(author$project$Theory$TheoryEngine$Note, 4, 3, 4),
										A3(author$project$Theory$TheoryEngine$Note, 4, 3, 1),
										A3(author$project$Theory$TheoryEngine$Note, 3, 3, 4),
										A3(author$project$Theory$TheoryEngine$Note, 2, 3, 1),
										A3(author$project$Theory$TheoryEngine$Note, 1, 3, 4)
									]))
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Double Sharps and flats',
			author$project$Config$Types$Video('379528961')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Double sharps and flats on keyboard from note name',
			author$project$Config$Types$Exercise(
				A2(
					author$project$Config$Types$KeyNotes,
					1,
					author$project$Config$Types$NoteConfig(
						_List_fromArray(
							[
								A2(
								author$project$Config$Types$NoteClefConfig,
								0,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 1),
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 5),
										A3(author$project$Theory$TheoryEngine$Note, 1, 4, 0),
										A3(author$project$Theory$TheoryEngine$Note, 1, 4, 5),
										A3(author$project$Theory$TheoryEngine$Note, 2, 4, 4),
										A3(author$project$Theory$TheoryEngine$Note, 2, 4, 5),
										A3(author$project$Theory$TheoryEngine$Note, 2, 4, 0),
										A3(author$project$Theory$TheoryEngine$Note, 3, 4, 1),
										A3(author$project$Theory$TheoryEngine$Note, 3, 4, 5),
										A3(author$project$Theory$TheoryEngine$Note, 3, 4, 0),
										A3(author$project$Theory$TheoryEngine$Note, 4, 4, 1),
										A3(author$project$Theory$TheoryEngine$Note, 4, 4, 0),
										A3(author$project$Theory$TheoryEngine$Note, 5, 4, 5),
										A3(author$project$Theory$TheoryEngine$Note, 6, 4, 4)
									]))
							]))))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Double sharps and flats on keyboard from staff',
			author$project$Config$Types$Exercise(
				A2(
					author$project$Config$Types$KeyNotes,
					0,
					author$project$Config$Types$NoteConfig(
						_List_fromArray(
							[
								A2(
								author$project$Config$Types$NoteClefConfig,
								0,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 1),
										A3(author$project$Theory$TheoryEngine$Note, 0, 4, 5),
										A3(author$project$Theory$TheoryEngine$Note, 1, 4, 0),
										A3(author$project$Theory$TheoryEngine$Note, 2, 4, 4),
										A3(author$project$Theory$TheoryEngine$Note, 3, 4, 1),
										A3(author$project$Theory$TheoryEngine$Note, 3, 4, 5)
									])),
								A2(
								author$project$Config$Types$NoteClefConfig,
								1,
								_List_fromArray(
									[
										A3(author$project$Theory$TheoryEngine$Note, 5, 3, 5),
										A3(author$project$Theory$TheoryEngine$Note, 6, 3, 4),
										A3(author$project$Theory$TheoryEngine$Note, 3, 3, 1),
										A3(author$project$Theory$TheoryEngine$Note, 2, 3, 4),
										A3(author$project$Theory$TheoryEngine$Note, 2, 3, 5),
										A3(author$project$Theory$TheoryEngine$Note, 1, 3, 5)
									]))
							])))))
		]));
var author$project$Config$Types$SeventhConfig = F4(
	function (inversions, qualities, clefs, count) {
		return {aV: clefs, aW: count, cG: inversions, bv: qualities};
	});
var author$project$Config$Types$Sevenths = function (a) {
	return {$: 3, a: a};
};
var author$project$Theory$TheoryEngine$Dominant = 2;
var author$project$Theory$TheoryEngine$First = 1;
var author$project$Theory$TheoryEngine$FullDim = 0;
var author$project$Theory$TheoryEngine$HalfDim = 1;
var author$project$Theory$TheoryEngine$MajMaj = 4;
var author$project$Theory$TheoryEngine$MinMin = 5;
var author$project$Theory$TheoryEngine$Root = 0;
var author$project$Theory$TheoryEngine$Second = 2;
var author$project$Theory$TheoryEngine$Third = 3;
var author$project$Config$SeventhChords$courseSeventhChords = A3(
	author$project$Config$Types$CourseInfo,
	'Seventh Chords',
	'Learn the different types of 7th chords and their inversions.',
	_List_fromArray(
		[
			A2(
			author$project$Config$Types$ContentInfo,
			'The Major Scale',
			author$project$Config$Types$Video('382313582')),
			A2(
			author$project$Config$Types$ContentInfo,
			'The Minor Scale',
			author$project$Config$Types$Video('382316132')),
			A2(
			author$project$Config$Types$ContentInfo,
			'6th & 7th Intervals',
			author$project$Config$Types$Video('382317654')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Minor and Major 6ths & 7ths on the keyboard',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$KeyIntervals(
					A3(
						author$project$Config$Types$KeyIntervalConfig,
						_List_fromArray(
							[6, 7]),
						_List_fromArray(
							[2, 1]),
						8)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Minor and Major 6ths & 7ths on the staff',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Intervals(
					A4(
						author$project$Config$Types$IntervalConfig,
						_List_fromArray(
							[6, 7]),
						_List_fromArray(
							[2, 1]),
						_List_fromArray(
							[0, 1]),
						8)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'7th Chords - Part 1',
			author$project$Config$Types$Video('382320578')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Seventh Chords Maj, Dom, Min',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Sevenths(
					A4(
						author$project$Config$Types$SeventhConfig,
						_List_fromArray(
							[0]),
						_List_fromArray(
							[4, 2, 5]),
						_List_fromArray(
							[0, 1]),
						10)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'7th Chords - Part 2',
			author$project$Config$Types$Video('382321530')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Seventh Chords Half and Full Dim',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Sevenths(
					A4(
						author$project$Config$Types$SeventhConfig,
						_List_fromArray(
							[0]),
						_List_fromArray(
							[1, 0]),
						_List_fromArray(
							[0, 1]),
						10)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Dom/Maj Seventh Chords in inversion',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Sevenths(
					A4(
						author$project$Config$Types$SeventhConfig,
						_List_fromArray(
							[0, 1, 2, 3]),
						_List_fromArray(
							[2, 4]),
						_List_fromArray(
							[0, 1]),
						10)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'All Seventh Chords in inversion',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Sevenths(
					A4(
						author$project$Config$Types$SeventhConfig,
						_List_fromArray(
							[0, 1, 2, 3]),
						_List_fromArray(
							[4, 2, 5, 1, 0]),
						_List_fromArray(
							[0, 1]),
						10))))
		]));
var author$project$Config$Types$TriadConfig = F4(
	function (inversions, qualities, clefs, count) {
		return {aV: clefs, aW: count, cG: inversions, bv: qualities};
	});
var author$project$Config$Types$Triads = function (a) {
	return {$: 2, a: a};
};
var author$project$Theory$RenderEngine$Tenor = 3;
var author$project$Config$Triads$courseTriads = A3(
	author$project$Config$Types$CourseInfo,
	'Major & Minor Triads',
	'Drill major & minor triads in root position and in inversion.',
	_List_fromArray(
		[
			A2(
			author$project$Config$Types$ContentInfo,
			'5 Finger Patterns',
			author$project$Config$Types$Video('382228530')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Triads & Inversions',
			author$project$Config$Types$Video('382229961')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Major and Minor Triads on the Staff',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Triads(
					A4(
						author$project$Config$Types$TriadConfig,
						_List_fromArray(
							[0]),
						_List_fromArray(
							[2, 1]),
						_List_fromArray(
							[0, 1]),
						10)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Triad Inversions - Part 2',
			author$project$Config$Types$Video('382312813')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Major Triads in inversion',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Triads(
					A4(
						author$project$Config$Types$TriadConfig,
						_List_fromArray(
							[0, 1, 2]),
						_List_fromArray(
							[2]),
						_List_fromArray(
							[0, 1]),
						10)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Major & Minor Triads in inversion',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Triads(
					A4(
						author$project$Config$Types$TriadConfig,
						_List_fromArray(
							[0, 1, 2]),
						_List_fromArray(
							[2, 1]),
						_List_fromArray(
							[0, 1]),
						10)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Bonus: Triads in Alto and Tenor Clefs',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Triads(
					A4(
						author$project$Config$Types$TriadConfig,
						_List_fromArray(
							[0, 1, 2]),
						_List_fromArray(
							[2, 1]),
						_List_fromArray(
							[2, 3]),
						10)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Augmented & Diminished Triads',
			author$project$Config$Types$Video('382311020')),
			A2(
			author$project$Config$Types$ContentInfo,
			'Major, Minor, Aug, & Dim Triads',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Triads(
					A4(
						author$project$Config$Types$TriadConfig,
						_List_fromArray(
							[0]),
						_List_fromArray(
							[2, 1, 3, 0]),
						_List_fromArray(
							[0, 1]),
						10)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'Aug & Dim Triads in Inversion',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Triads(
					A4(
						author$project$Config$Types$TriadConfig,
						_List_fromArray(
							[0, 1, 2]),
						_List_fromArray(
							[3, 0]),
						_List_fromArray(
							[0, 1]),
						10)))),
			A2(
			author$project$Config$Types$ContentInfo,
			'All Triads in Inversion',
			author$project$Config$Types$Exercise(
				author$project$Config$Types$Triads(
					A4(
						author$project$Config$Types$TriadConfig,
						_List_fromArray(
							[0, 1, 2]),
						_List_fromArray(
							[2, 1, 3, 0]),
						_List_fromArray(
							[0, 1]),
						10))))
		]));
var author$project$Config$Course$allCourses = function (paid) {
	return paid ? _List_fromArray(
		[author$project$Config$NoteNames$courseNoteNames, author$project$Config$IntervalsOne$courseIntervalsOne, author$project$Config$NoteNamesTwo$courseNoteNamesTwo, author$project$Config$IntervalsTwo$courseIntervalsTwo, author$project$Config$Triads$courseTriads, author$project$Config$KeySignatures$courseKeySignatures, author$project$Config$SeventhChords$courseSeventhChords]) : _List_fromArray(
		[author$project$Config$NoteNames$courseNoteNames, author$project$Config$IntervalsOne$courseIntervalsOne]);
};
var author$project$Config$Types$NotFound = {$: 2};
var author$project$Config$Types$ClefNote = F2(
	function (clef, note) {
		return {bK: clef, dU: note};
	});
var author$project$Config$Types$noteClefConfigToList = function (config) {
	return A2(
		elm$core$List$map,
		function (x) {
			return A2(author$project$Config$Types$ClefNote, config.bK, x);
		},
		config.cP);
};
var author$project$Exercises$BasicIntervals$Down = 1;
var author$project$Exercises$BasicIntervals$Model = function (intervalPool) {
	return function (root) {
		return function (interval) {
			return function (directionPool) {
				return function (direction) {
					return function (selectedInterval) {
						return function (showAnswer) {
							return function (count) {
								return function (history) {
									return function (previous) {
										return {aW: count, bM: direction, cm: directionPool, aw: history, z: interval, ax: intervalPool, b$: previous, a2: root, aE: selectedInterval, T: showAnswer};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var author$project$Exercises$BasicIntervals$Up = 0;
var elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return elm$core$Maybe$Just(x);
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var author$project$Exercises$BasicIntervals$init = F2(
	function (intervals, count) {
		var startInterval = A2(
			elm$core$Maybe$withDefault,
			2,
			elm$core$List$head(intervals));
		return author$project$Exercises$BasicIntervals$Model(intervals)(elm$core$Maybe$Nothing)(startInterval)(
			_List_fromArray(
				[0, 1]))(0)(elm$core$Maybe$Nothing)(false)(count)(_List_Nil)(elm$core$Maybe$Nothing);
	});
var author$project$Exercises$BasicIntervals$NewInterval = function (a) {
	return {$: 1, a: a};
};
var elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _n0 = A2(elm$core$Elm$JsArray$initializeFromList, elm$core$Array$branchFactor, list);
			var jsArray = _n0.a;
			var remainingItems = _n0.b;
			if (_Utils_cmp(
				elm$core$Elm$JsArray$length(jsArray),
				elm$core$Array$branchFactor) < 0) {
				return A2(
					elm$core$Array$builderToArray,
					true,
					{l: nodeList, i: nodeListSize, k: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					elm$core$List$cons,
					elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return elm$core$Array$empty;
	} else {
		return A3(elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var elm$random$Random$Generate = elm$core$Basics$identity;
var elm$core$Task$andThen = _Scheduler_andThen;
var elm$core$Task$succeed = _Scheduler_succeed;
var elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var elm$random$Random$Seed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$random$Random$next = function (_n0) {
	var state0 = _n0.a;
	var incr = _n0.b;
	return A2(elm$random$Random$Seed, ((state0 * 1664525) + incr) >>> 0, incr);
};
var elm$random$Random$initialSeed = function (x) {
	var _n0 = elm$random$Random$next(
		A2(elm$random$Random$Seed, 0, 1013904223));
	var state1 = _n0.a;
	var incr = _n0.b;
	var state2 = (state1 + x) >>> 0;
	return elm$random$Random$next(
		A2(elm$random$Random$Seed, state2, incr));
};
var elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$time$Time$customZone = elm$time$Time$Zone;
var elm$time$Time$Posix = elm$core$Basics$identity;
var elm$time$Time$millisToPosix = elm$core$Basics$identity;
var elm$time$Time$now = _Time_now(elm$time$Time$millisToPosix);
var elm$time$Time$posixToMillis = function (_n0) {
	var millis = _n0;
	return millis;
};
var elm$random$Random$init = A2(
	elm$core$Task$andThen,
	function (time) {
		return elm$core$Task$succeed(
			elm$random$Random$initialSeed(
				elm$time$Time$posixToMillis(time)));
	},
	elm$time$Time$now);
var elm$core$Platform$sendToApp = _Platform_sendToApp;
var elm$random$Random$step = F2(
	function (_n0, seed) {
		var generator = _n0;
		return generator(seed);
	});
var elm$random$Random$onEffects = F3(
	function (router, commands, seed) {
		if (!commands.b) {
			return elm$core$Task$succeed(seed);
		} else {
			var generator = commands.a;
			var rest = commands.b;
			var _n1 = A2(elm$random$Random$step, generator, seed);
			var value = _n1.a;
			var newSeed = _n1.b;
			return A2(
				elm$core$Task$andThen,
				function (_n2) {
					return A3(elm$random$Random$onEffects, router, rest, newSeed);
				},
				A2(elm$core$Platform$sendToApp, router, value));
		}
	});
var elm$random$Random$onSelfMsg = F3(
	function (_n0, _n1, seed) {
		return elm$core$Task$succeed(seed);
	});
var elm$random$Random$Generator = elm$core$Basics$identity;
var elm$random$Random$map = F2(
	function (func, _n0) {
		var genA = _n0;
		return function (seed0) {
			var _n1 = genA(seed0);
			var a = _n1.a;
			var seed1 = _n1.b;
			return _Utils_Tuple2(
				func(a),
				seed1);
		};
	});
var elm$random$Random$cmdMap = F2(
	function (func, _n0) {
		var generator = _n0;
		return A2(elm$random$Random$map, func, generator);
	});
_Platform_effectManagers['Random'] = _Platform_createManager(elm$random$Random$init, elm$random$Random$onEffects, elm$random$Random$onSelfMsg, elm$random$Random$cmdMap);
var elm$random$Random$command = _Platform_leaf('Random');
var elm$random$Random$generate = F2(
	function (tagger, generator) {
		return elm$random$Random$command(
			A2(elm$random$Random$map, tagger, generator));
	});
var elm$core$Array$bitMask = 4294967295 >>> (32 - elm$core$Array$shiftStep);
var elm$core$Bitwise$and = _Bitwise_and;
var elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = elm$core$Array$bitMask & (index >>> shift);
			var _n0 = A2(elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_n0.$) {
				var subTree = _n0.a;
				var $temp$shift = shift - elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _n0.a;
				return A2(elm$core$Elm$JsArray$unsafeGet, elm$core$Array$bitMask & index, values);
			}
		}
	});
var elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var elm$core$Basics$ge = _Utils_ge;
var elm$core$Array$get = F2(
	function (index, _n0) {
		var len = _n0.a;
		var startShift = _n0.b;
		var tree = _n0.c;
		var tail = _n0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			elm$core$Array$tailIndex(len)) > -1) ? elm$core$Maybe$Just(
			A2(elm$core$Elm$JsArray$unsafeGet, elm$core$Array$bitMask & index, tail)) : elm$core$Maybe$Just(
			A3(elm$core$Array$getHelp, startShift, index, tree)));
	});
var elm$core$Array$length = function (_n0) {
	var len = _n0.a;
	return len;
};
var elm$core$Basics$negate = function (n) {
	return -n;
};
var elm$core$Bitwise$xor = _Bitwise_xor;
var elm$random$Random$peel = function (_n0) {
	var state = _n0.a;
	var word = (state ^ (state >>> ((state >>> 28) + 4))) * 277803737;
	return ((word >>> 22) ^ word) >>> 0;
};
var elm$random$Random$int = F2(
	function (a, b) {
		return function (seed0) {
			var _n0 = (_Utils_cmp(a, b) < 0) ? _Utils_Tuple2(a, b) : _Utils_Tuple2(b, a);
			var lo = _n0.a;
			var hi = _n0.b;
			var range = (hi - lo) + 1;
			if (!((range - 1) & range)) {
				return _Utils_Tuple2(
					(((range - 1) & elm$random$Random$peel(seed0)) >>> 0) + lo,
					elm$random$Random$next(seed0));
			} else {
				var threshhold = (((-range) >>> 0) % range) >>> 0;
				var accountForBias = function (seed) {
					accountForBias:
					while (true) {
						var x = elm$random$Random$peel(seed);
						var seedN = elm$random$Random$next(seed);
						if (_Utils_cmp(x, threshhold) < 0) {
							var $temp$seed = seedN;
							seed = $temp$seed;
							continue accountForBias;
						} else {
							return _Utils_Tuple2((x % range) + lo, seedN);
						}
					}
				};
				return accountForBias(seed0);
			}
		};
	});
var elm_community$random_extra$Random$Array$sample = function (arr) {
	var gen = A2(
		elm$random$Random$int,
		0,
		elm$core$Array$length(arr) - 1);
	return A2(
		elm$random$Random$map,
		function (index) {
			return A2(elm$core$Array$get, index, arr);
		},
		gen);
};
var author$project$Exercises$BasicIntervals$initCmd = function (intervalPool) {
	return A2(
		elm$random$Random$generate,
		author$project$Exercises$BasicIntervals$NewInterval,
		elm_community$random_extra$Random$Array$sample(
			elm$core$Array$fromList(intervalPool)));
};
var author$project$Exercises$Intervals$Model = function (root) {
	return function (clef) {
		return function (interval) {
			return function (intervalQuality) {
				return function (clefPool) {
					return function (intervalPool) {
						return function (qualityPool) {
							return function (selectedInterval) {
								return function (selectedQuality) {
									return function (showAnswer) {
										return function (count) {
											return function (history) {
												return {bK: clef, aU: clefPool, aW: count, aw: history, z: interval, ax: intervalPool, Z: intervalQuality, bw: qualityPool, a2: root, aE: selectedInterval, aF: selectedQuality, T: showAnswer};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var author$project$Exercises$Intervals$init = F4(
	function (intervals, qualities, clefs, count) {
		return author$project$Exercises$Intervals$Model(elm$core$Maybe$Nothing)(elm$core$Maybe$Nothing)(elm$core$Maybe$Nothing)(elm$core$Maybe$Nothing)(clefs)(intervals)(qualities)(elm$core$Maybe$Nothing)(elm$core$Maybe$Nothing)(false)(count)(_List_Nil);
	});
var author$project$Exercises$Intervals$NewClef = function (a) {
	return {$: 2, a: a};
};
var author$project$Exercises$Intervals$initCmd = function (clefs) {
	return A2(
		elm$random$Random$generate,
		author$project$Exercises$Intervals$NewClef,
		elm_community$random_extra$Random$Array$sample(
			elm$core$Array$fromList(clefs)));
};
var author$project$Exercises$KeyBasicIntervals$Model = F7(
	function (interval, direction, intervalPool, count, answered, keyModel, showAnswer) {
		return {aS: answered, aW: count, bM: direction, z: interval, ax: intervalPool, O: keyModel, T: showAnswer};
	});
var author$project$Theory$Keyboard$None = {$: 12};
var author$project$Theory$Keyboard$Model = F4(
	function (keysPressed, givenKeys, answer, allowPressed) {
		return {bI: allowPressed, $7: answer, Y: givenKeys, cJ: keysPressed};
	});
var author$project$Theory$Keyboard$initKeyboardKeys = F3(
	function (given, answer, allowed) {
		return A4(author$project$Theory$Keyboard$Model, _List_Nil, given, answer, allowed);
	});
var author$project$Exercises$KeyBasicIntervals$init = F2(
	function (intervals, count) {
		var startKey = author$project$Theory$Keyboard$None;
		var startInterval = 1;
		var startDirection = 0;
		var intervalKey = author$project$Theory$Keyboard$None;
		return A7(
			author$project$Exercises$KeyBasicIntervals$Model,
			startInterval,
			startDirection,
			intervals,
			count,
			0,
			A3(
				author$project$Theory$Keyboard$initKeyboardKeys,
				_List_fromArray(
					[startKey]),
				_List_fromArray(
					[intervalKey]),
				1),
			false);
	});
var author$project$Exercises$KeyBasicIntervals$NewInterval = function (a) {
	return {$: 4, a: a};
};
var author$project$Exercises$KeyBasicIntervals$initCmd = function (intervals) {
	return A2(
		elm$random$Random$generate,
		author$project$Exercises$KeyBasicIntervals$NewInterval,
		elm_community$random_extra$Random$Array$sample(
			elm$core$Array$fromList(intervals)));
};
var author$project$Exercises$KeyIntervals$Model = function (root) {
	return function (interval) {
		return function (direction) {
			return function (intervalQuality) {
				return function (intervalPool) {
					return function (qualityPool) {
						return function (keyModel) {
							return function (showAnswer) {
								return function (count) {
									return function (history) {
										return {aW: count, bM: direction, aw: history, z: interval, ax: intervalPool, Z: intervalQuality, O: keyModel, bw: qualityPool, a2: root, T: showAnswer};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var author$project$Exercises$KeyIntervals$init = F3(
	function (intervals, qualities, count) {
		return author$project$Exercises$KeyIntervals$Model(elm$core$Maybe$Nothing)(elm$core$Maybe$Nothing)(elm$core$Maybe$Nothing)(elm$core$Maybe$Nothing)(intervals)(qualities)(
			A3(author$project$Theory$Keyboard$initKeyboardKeys, _List_Nil, _List_Nil, 1))(false)(count)(_List_Nil);
	});
var author$project$Exercises$KeyIntervals$NewInterval = function (a) {
	return {$: 1, a: a};
};
var author$project$Exercises$KeyIntervals$initCmd = function (intervals) {
	return A2(
		elm$random$Random$generate,
		author$project$Exercises$KeyIntervals$NewInterval,
		elm_community$random_extra$Random$Array$sample(
			elm$core$Array$fromList(intervals)));
};
var author$project$Exercises$KeyNotes$Model = F7(
	function (currentNote, notePool, deck, keyModel, showAnswer, answered, display) {
		return {aS: answered, F: currentNote, ap: deck, bg: display, O: keyModel, bo: notePool, T: showAnswer};
	});
var author$project$Theory$Keyboard$A = function (a) {
	return {$: 9, a: a};
};
var author$project$Theory$Keyboard$Ais = function (a) {
	return {$: 10, a: a};
};
var author$project$Theory$Keyboard$B = function (a) {
	return {$: 11, a: a};
};
var author$project$Theory$Keyboard$C = function (a) {
	return {$: 0, a: a};
};
var author$project$Theory$Keyboard$Cis = function (a) {
	return {$: 1, a: a};
};
var author$project$Theory$Keyboard$D = function (a) {
	return {$: 2, a: a};
};
var author$project$Theory$Keyboard$Dis = function (a) {
	return {$: 3, a: a};
};
var author$project$Theory$Keyboard$E = function (a) {
	return {$: 4, a: a};
};
var author$project$Theory$Keyboard$F = function (a) {
	return {$: 5, a: a};
};
var author$project$Theory$Keyboard$Fis = function (a) {
	return {$: 6, a: a};
};
var author$project$Theory$Keyboard$G = function (a) {
	return {$: 7, a: a};
};
var author$project$Theory$Keyboard$Gis = function (a) {
	return {$: 8, a: a};
};
var author$project$Theory$Keyboard$noteToKeyboardKey = function (note) {
	var _n0 = note.dT;
	switch (_n0) {
		case 0:
			var _n1 = note.dk;
			switch (_n1) {
				case 0:
					return author$project$Theory$Keyboard$Ais(note.dW - 1);
				case 1:
					return author$project$Theory$Keyboard$B(note.dW - 1);
				case 3:
					return author$project$Theory$Keyboard$C(note.dW);
				case 2:
					return author$project$Theory$Keyboard$C(note.dW);
				case 4:
					return author$project$Theory$Keyboard$Cis(note.dW);
				default:
					return author$project$Theory$Keyboard$D(note.dW);
			}
		case 1:
			var _n2 = note.dk;
			switch (_n2) {
				case 0:
					return author$project$Theory$Keyboard$C(note.dW);
				case 1:
					return author$project$Theory$Keyboard$Cis(note.dW);
				case 3:
					return author$project$Theory$Keyboard$D(note.dW);
				case 2:
					return author$project$Theory$Keyboard$D(note.dW);
				case 4:
					return author$project$Theory$Keyboard$Dis(note.dW);
				default:
					return author$project$Theory$Keyboard$E(note.dW);
			}
		case 2:
			var _n3 = note.dk;
			switch (_n3) {
				case 0:
					return author$project$Theory$Keyboard$D(note.dW);
				case 1:
					return author$project$Theory$Keyboard$Dis(note.dW);
				case 3:
					return author$project$Theory$Keyboard$E(note.dW);
				case 2:
					return author$project$Theory$Keyboard$E(note.dW);
				case 4:
					return author$project$Theory$Keyboard$F(note.dW);
				default:
					return author$project$Theory$Keyboard$Fis(note.dW);
			}
		case 3:
			var _n4 = note.dk;
			switch (_n4) {
				case 0:
					return author$project$Theory$Keyboard$Dis(note.dW);
				case 1:
					return author$project$Theory$Keyboard$E(note.dW);
				case 3:
					return author$project$Theory$Keyboard$F(note.dW);
				case 2:
					return author$project$Theory$Keyboard$F(note.dW);
				case 4:
					return author$project$Theory$Keyboard$Fis(note.dW);
				default:
					return author$project$Theory$Keyboard$G(note.dW);
			}
		case 4:
			var _n5 = note.dk;
			switch (_n5) {
				case 0:
					return author$project$Theory$Keyboard$F(note.dW);
				case 1:
					return author$project$Theory$Keyboard$Fis(note.dW);
				case 3:
					return author$project$Theory$Keyboard$G(note.dW);
				case 2:
					return author$project$Theory$Keyboard$G(note.dW);
				case 4:
					return author$project$Theory$Keyboard$Gis(note.dW);
				default:
					return author$project$Theory$Keyboard$A(note.dW);
			}
		case 5:
			var _n6 = note.dk;
			switch (_n6) {
				case 0:
					return author$project$Theory$Keyboard$G(note.dW);
				case 1:
					return author$project$Theory$Keyboard$Gis(note.dW);
				case 3:
					return author$project$Theory$Keyboard$A(note.dW);
				case 2:
					return author$project$Theory$Keyboard$A(note.dW);
				case 4:
					return author$project$Theory$Keyboard$Ais(note.dW);
				default:
					return author$project$Theory$Keyboard$B(note.dW);
			}
		case 6:
			var _n7 = note.dk;
			switch (_n7) {
				case 0:
					return author$project$Theory$Keyboard$A(note.dW);
				case 1:
					return author$project$Theory$Keyboard$Ais(note.dW);
				case 3:
					return author$project$Theory$Keyboard$B(note.dW);
				case 2:
					return author$project$Theory$Keyboard$B(note.dW);
				case 4:
					return author$project$Theory$Keyboard$C(note.dW + 1);
				default:
					return author$project$Theory$Keyboard$Cis(note.dW + 1);
			}
		default:
			return author$project$Theory$Keyboard$None;
	}
};
var author$project$Exercises$KeyNotes$scaleKey = function (note) {
	var octave = note.dW;
	var letter = note.dT;
	var acc = note.dk;
	var genKey = function (x) {
		return author$project$Theory$Keyboard$noteToKeyboardKey(
			A3(author$project$Theory$TheoryEngine$Note, letter, x, acc));
	};
	return A2(
		elm$core$List$map,
		genKey,
		A2(elm$core$List$range, 0, 4));
};
var author$project$Exercises$KeyNotes$initKeys = function (note) {
	return A3(
		author$project$Theory$Keyboard$initKeyboardKeys,
		_List_Nil,
		author$project$Exercises$KeyNotes$scaleKey(note),
		1);
};
var author$project$Exercises$KeyNotes$initModel = function (notes) {
	if (notes.b) {
		var x = notes.a;
		var xs = notes.b;
		return A6(
			author$project$Exercises$KeyNotes$Model,
			x,
			xs,
			notes,
			author$project$Exercises$KeyNotes$initKeys(x.dU),
			false,
			0);
	} else {
		return A6(
			author$project$Exercises$KeyNotes$Model,
			A2(
				author$project$Config$Types$ClefNote,
				0,
				A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3)),
			_List_Nil,
			notes,
			A3(author$project$Theory$Keyboard$initKeyboardKeys, _List_Nil, _List_Nil, 1),
			false,
			0);
	}
};
var author$project$Exercises$KeyNotes$NewDeck = function (a) {
	return {$: 4, a: a};
};
var elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var elm$random$Random$andThen = F2(
	function (callback, _n0) {
		var genA = _n0;
		return function (seed) {
			var _n1 = genA(seed);
			var result = _n1.a;
			var newSeed = _n1.b;
			var _n2 = callback(result);
			var genB = _n2;
			return genB(newSeed);
		};
	});
var elm$random$Random$constant = function (value) {
	return function (seed) {
		return _Utils_Tuple2(value, seed);
	};
};
var elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2(elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var elm$core$List$takeTailRec = F2(
	function (n, list) {
		return elm$core$List$reverse(
			A3(elm$core$List$takeReverse, n, list, _List_Nil));
	});
var elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _n0 = _Utils_Tuple2(n, list);
			_n0$1:
			while (true) {
				_n0$5:
				while (true) {
					if (!_n0.b.b) {
						return list;
					} else {
						if (_n0.b.b.b) {
							switch (_n0.a) {
								case 1:
									break _n0$1;
								case 2:
									var _n2 = _n0.b;
									var x = _n2.a;
									var _n3 = _n2.b;
									var y = _n3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_n0.b.b.b.b) {
										var _n4 = _n0.b;
										var x = _n4.a;
										var _n5 = _n4.b;
										var y = _n5.a;
										var _n6 = _n5.b;
										var z = _n6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _n0$5;
									}
								default:
									if (_n0.b.b.b.b && _n0.b.b.b.b.b) {
										var _n7 = _n0.b;
										var x = _n7.a;
										var _n8 = _n7.b;
										var y = _n8.a;
										var _n9 = _n8.b;
										var z = _n9.a;
										var _n10 = _n9.b;
										var w = _n10.a;
										var tl = _n10.b;
										return (ctr > 1000) ? A2(
											elm$core$List$cons,
											x,
											A2(
												elm$core$List$cons,
												y,
												A2(
													elm$core$List$cons,
													z,
													A2(
														elm$core$List$cons,
														w,
														A2(elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											elm$core$List$cons,
											x,
											A2(
												elm$core$List$cons,
												y,
												A2(
													elm$core$List$cons,
													z,
													A2(
														elm$core$List$cons,
														w,
														A3(elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _n0$5;
									}
							}
						} else {
							if (_n0.a === 1) {
								break _n0$1;
							} else {
								break _n0$5;
							}
						}
					}
				}
				return list;
			}
			var _n1 = _n0.b;
			var x = _n1.a;
			return _List_fromArray(
				[x]);
		}
	});
var elm$core$List$take = F2(
	function (n, list) {
		return A3(elm$core$List$takeFast, 0, n, list);
	});
var elm_community$random_extra$Random$List$get = F2(
	function (index, list) {
		return elm$core$List$head(
			A2(elm$core$List$drop, index, list));
	});
var elm_community$random_extra$Random$List$choose = function (list) {
	if (elm$core$List$isEmpty(list)) {
		return elm$random$Random$constant(
			_Utils_Tuple2(elm$core$Maybe$Nothing, list));
	} else {
		var lastIndex = elm$core$List$length(list) - 1;
		var gen = A2(elm$random$Random$int, 0, lastIndex);
		var front = function (i) {
			return A2(elm$core$List$take, i, list);
		};
		var back = function (i) {
			return A2(elm$core$List$drop, i + 1, list);
		};
		return A2(
			elm$random$Random$map,
			function (index) {
				return _Utils_Tuple2(
					A2(elm_community$random_extra$Random$List$get, index, list),
					A2(
						elm$core$List$append,
						front(index),
						back(index)));
			},
			gen);
	}
};
var elm_community$random_extra$Random$List$shuffle = function (list) {
	if (elm$core$List$isEmpty(list)) {
		return elm$random$Random$constant(list);
	} else {
		var helper = function (_n0) {
			var done = _n0.a;
			var remaining = _n0.b;
			return A2(
				elm$random$Random$andThen,
				function (_n1) {
					var m_val = _n1.a;
					var shorter = _n1.b;
					if (m_val.$ === 1) {
						return elm$random$Random$constant(
							_Utils_Tuple2(done, shorter));
					} else {
						var val = m_val.a;
						return helper(
							_Utils_Tuple2(
								A2(elm$core$List$cons, val, done),
								shorter));
					}
				},
				elm_community$random_extra$Random$List$choose(remaining));
		};
		return A2(
			elm$random$Random$map,
			elm$core$Tuple$first,
			helper(
				_Utils_Tuple2(_List_Nil, list)));
	}
};
var author$project$Exercises$KeyNotes$shuffle = function (notes) {
	return A2(
		elm$random$Random$generate,
		author$project$Exercises$KeyNotes$NewDeck,
		elm_community$random_extra$Random$List$shuffle(notes));
};
var author$project$Exercises$KeySignatures$Model = F9(
	function (clef, keySignature, clefPool, keyPool, keyDeck, selectedNote, selectedAccidental, showAnswer, answered) {
		return {aS: answered, bK: clef, aU: clefPool, bl: keyDeck, bm: keyPool, n: keySignature, r: selectedAccidental, x: selectedNote, T: showAnswer};
	});
var author$project$Exercises$KeySignatures$init = F2(
	function (clefs, keys) {
		if (keys.b) {
			var x = keys.a;
			var xs = keys.b;
			return A9(author$project$Exercises$KeySignatures$Model, elm$core$Maybe$Nothing, x, clefs, keys, xs, elm$core$Maybe$Nothing, 3, false, 0);
		} else {
			return A9(
				author$project$Exercises$KeySignatures$Model,
				elm$core$Maybe$Nothing,
				A3(author$project$Theory$TheoryEngine$KeySignature, 0, 3, 2),
				clefs,
				keys,
				_List_Nil,
				elm$core$Maybe$Nothing,
				3,
				false,
				0);
		}
	});
var author$project$Exercises$KeySignatures$NewDeck = function (a) {
	return {$: 0, a: a};
};
var author$project$Exercises$KeySignatures$shuffle = function (keys) {
	return A2(
		elm$random$Random$generate,
		author$project$Exercises$KeySignatures$NewDeck,
		elm_community$random_extra$Random$List$shuffle(keys));
};
var author$project$Exercises$Notes$Model = F6(
	function (currentNote, notePool, deck, selectedNote, showAnswer, answered) {
		return {aS: answered, F: currentNote, ap: deck, bo: notePool, x: selectedNote, T: showAnswer};
	});
var author$project$Exercises$Notes$initModel = function (notes) {
	if (notes.b) {
		var x = notes.a;
		var xs = notes.b;
		return A6(author$project$Exercises$Notes$Model, x, xs, notes, '', false, 0);
	} else {
		return A6(
			author$project$Exercises$Notes$Model,
			A2(
				author$project$Config$Types$ClefNote,
				0,
				A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3)),
			_List_Nil,
			notes,
			'',
			false,
			0);
	}
};
var author$project$Exercises$Notes$NewDeck = function (a) {
	return {$: 4, a: a};
};
var author$project$Exercises$Notes$shuffle = function (notes) {
	return A2(
		elm$random$Random$generate,
		author$project$Exercises$Notes$NewDeck,
		elm_community$random_extra$Random$List$shuffle(notes));
};
var author$project$Exercises$Sevenths$Model = function (root) {
	return function (clef) {
		return function (inversion) {
			return function (quality) {
				return function (clefPool) {
					return function (inversionPool) {
						return function (qualityPool) {
							return function (selectedRoot) {
								return function (selectedQuality) {
									return function (showAnswer) {
										return function (count) {
											return function (history) {
												return {bK: clef, aU: clefPool, aW: count, aw: history, _: inversion, bQ: inversionPool, d1: quality, bw: qualityPool, a2: root, aF: selectedQuality, aG: selectedRoot, T: showAnswer};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var author$project$Exercises$Sevenths$init = F4(
	function (inversions, qualities, clefs, count) {
		return author$project$Exercises$Sevenths$Model(elm$core$Maybe$Nothing)(elm$core$Maybe$Nothing)(elm$core$Maybe$Nothing)(elm$core$Maybe$Nothing)(clefs)(inversions)(qualities)(elm$core$Maybe$Nothing)(elm$core$Maybe$Nothing)(false)(count)(_List_Nil);
	});
var author$project$Exercises$Sevenths$NewClef = function (a) {
	return {$: 2, a: a};
};
var author$project$Exercises$Sevenths$initCmd = function (clefs) {
	return A2(
		elm$random$Random$generate,
		author$project$Exercises$Sevenths$NewClef,
		elm_community$random_extra$Random$Array$sample(
			elm$core$Array$fromList(clefs)));
};
var author$project$Exercises$Triads$Model = function (root) {
	return function (clef) {
		return function (inversion) {
			return function (quality) {
				return function (clefPool) {
					return function (inversionPool) {
						return function (qualityPool) {
							return function (selectedRoot) {
								return function (selectedQuality) {
									return function (showAnswer) {
										return function (count) {
											return function (history) {
												return {bK: clef, aU: clefPool, aW: count, aw: history, _: inversion, bQ: inversionPool, d1: quality, bw: qualityPool, a2: root, aF: selectedQuality, aG: selectedRoot, T: showAnswer};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var author$project$Exercises$Triads$init = F4(
	function (inversions, qualities, clefs, count) {
		return author$project$Exercises$Triads$Model(elm$core$Maybe$Nothing)(elm$core$Maybe$Nothing)(elm$core$Maybe$Nothing)(elm$core$Maybe$Nothing)(clefs)(inversions)(qualities)(elm$core$Maybe$Nothing)(elm$core$Maybe$Nothing)(false)(count)(_List_Nil);
	});
var author$project$Exercises$Triads$NewClef = function (a) {
	return {$: 2, a: a};
};
var author$project$Exercises$Triads$initCmd = function (clefs) {
	return A2(
		elm$random$Random$generate,
		author$project$Exercises$Triads$NewClef,
		elm_community$random_extra$Random$Array$sample(
			elm$core$Array$fromList(clefs)));
};
var author$project$Page$Content$BasicIntervalModel = function (a) {
	return {$: 2, a: a};
};
var author$project$Page$Content$BasicIntervalsMsg = function (a) {
	return {$: 3, a: a};
};
var author$project$Page$Content$IntervalModel = function (a) {
	return {$: 4, a: a};
};
var author$project$Page$Content$IntervalsMsg = function (a) {
	return {$: 5, a: a};
};
var author$project$Page$Content$KeyBasicIntervalModel = function (a) {
	return {$: 3, a: a};
};
var author$project$Page$Content$KeyBasicIntervalsMsg = function (a) {
	return {$: 4, a: a};
};
var author$project$Page$Content$KeyIntervalModel = function (a) {
	return {$: 5, a: a};
};
var author$project$Page$Content$KeyIntervalsMsg = function (a) {
	return {$: 6, a: a};
};
var author$project$Page$Content$KeyNoteModel = function (a) {
	return {$: 1, a: a};
};
var author$project$Page$Content$KeyNoteMsg = function (a) {
	return {$: 2, a: a};
};
var author$project$Page$Content$KeySigModel = function (a) {
	return {$: 8, a: a};
};
var author$project$Page$Content$KeySigMsg = function (a) {
	return {$: 9, a: a};
};
var author$project$Page$Content$Model = F6(
	function (courseNum, courseInfo, contentNum, contentInfo, contentModel, showList) {
		return {ck: contentInfo, h: contentModel, ao: contentNum, aX: courseInfo, be: courseNum, bB: showList};
	});
var author$project$Page$Content$NotFound = {$: 10};
var author$project$Page$Content$NoteModel = function (a) {
	return {$: 0, a: a};
};
var author$project$Page$Content$NoteMsg = function (a) {
	return {$: 1, a: a};
};
var author$project$Page$Content$SeventhModel = function (a) {
	return {$: 7, a: a};
};
var author$project$Page$Content$SeventhsMsg = function (a) {
	return {$: 8, a: a};
};
var author$project$Page$Content$TriadModel = function (a) {
	return {$: 6, a: a};
};
var author$project$Page$Content$TriadsMsg = function (a) {
	return {$: 7, a: a};
};
var author$project$Page$Content$VideoModel = function (a) {
	return {$: 9, a: a};
};
var elm$core$Platform$Cmd$batch = _Platform_batch;
var elm$core$Platform$Cmd$none = elm$core$Platform$Cmd$batch(_List_Nil);
var author$project$Page$Content$initModel = F3(
	function (hasPaid, courseNum, contentNum) {
		var courses = author$project$Config$Course$allCourses(hasPaid);
		var courseInfo = A2(
			elm$core$Maybe$withDefault,
			A3(author$project$Config$Types$CourseInfo, 'No Course Found', '', _List_Nil),
			A2(
				elm$core$Array$get,
				courseNum,
				elm$core$Array$fromList(courses)));
		var contentInfo = A2(
			elm$core$Maybe$withDefault,
			A2(author$project$Config$Types$ContentInfo, 'No Content Found', author$project$Config$Types$NotFound),
			A2(
				elm$core$Array$get,
				contentNum,
				elm$core$Array$fromList(courseInfo.dt)));
		var initModelWrapper = function (x) {
			return A6(author$project$Page$Content$Model, courseNum, courseInfo, contentNum, contentInfo, x, false);
		};
		var _n0 = contentInfo.du;
		switch (_n0.$) {
			case 2:
				return _Utils_Tuple2(
					initModelWrapper(author$project$Page$Content$NotFound),
					elm$core$Platform$Cmd$none);
			case 0:
				var code = _n0.a;
				return _Utils_Tuple2(
					initModelWrapper(
						author$project$Page$Content$VideoModel(code)),
					elm$core$Platform$Cmd$none);
			default:
				var exercise = _n0.a;
				switch (exercise.$) {
					case 0:
						var noteConfig = exercise.a;
						var notes = elm$core$List$concat(
							A2(elm$core$List$map, author$project$Config$Types$noteClefConfigToList, noteConfig.aV));
						return _Utils_Tuple2(
							initModelWrapper(
								author$project$Page$Content$NoteModel(
									author$project$Exercises$Notes$initModel(notes))),
							A2(
								elm$core$Platform$Cmd$map,
								author$project$Page$Content$NoteMsg,
								author$project$Exercises$Notes$shuffle(notes)));
					case 1:
						var display = exercise.a;
						var noteConfig = exercise.b;
						var notes = elm$core$List$concat(
							A2(elm$core$List$map, author$project$Config$Types$noteClefConfigToList, noteConfig.aV));
						return _Utils_Tuple2(
							initModelWrapper(
								author$project$Page$Content$KeyNoteModel(
									A2(author$project$Exercises$KeyNotes$initModel, notes, display))),
							A2(
								elm$core$Platform$Cmd$map,
								author$project$Page$Content$KeyNoteMsg,
								author$project$Exercises$KeyNotes$shuffle(notes)));
					case 4:
						var intervalConfig = exercise.a;
						return _Utils_Tuple2(
							initModelWrapper(
								author$project$Page$Content$BasicIntervalModel(
									A2(author$project$Exercises$BasicIntervals$init, intervalConfig.bP, intervalConfig.aW))),
							A2(
								elm$core$Platform$Cmd$map,
								author$project$Page$Content$BasicIntervalsMsg,
								author$project$Exercises$BasicIntervals$initCmd(intervalConfig.bP)));
					case 5:
						var intervalConfig = exercise.a;
						return _Utils_Tuple2(
							initModelWrapper(
								author$project$Page$Content$KeyBasicIntervalModel(
									A2(author$project$Exercises$KeyBasicIntervals$init, intervalConfig.bP, intervalConfig.aW))),
							A2(
								elm$core$Platform$Cmd$map,
								author$project$Page$Content$KeyBasicIntervalsMsg,
								author$project$Exercises$KeyBasicIntervals$initCmd(intervalConfig.bP)));
					case 6:
						var intervalConfig = exercise.a;
						return _Utils_Tuple2(
							initModelWrapper(
								author$project$Page$Content$IntervalModel(
									A4(author$project$Exercises$Intervals$init, intervalConfig.bP, intervalConfig.bv, intervalConfig.aV, intervalConfig.aW))),
							A2(
								elm$core$Platform$Cmd$map,
								author$project$Page$Content$IntervalsMsg,
								author$project$Exercises$Intervals$initCmd(intervalConfig.aV)));
					case 7:
						var intervalConfig = exercise.a;
						return _Utils_Tuple2(
							initModelWrapper(
								author$project$Page$Content$KeyIntervalModel(
									A3(author$project$Exercises$KeyIntervals$init, intervalConfig.bP, intervalConfig.bv, intervalConfig.aW))),
							A2(
								elm$core$Platform$Cmd$map,
								author$project$Page$Content$KeyIntervalsMsg,
								author$project$Exercises$KeyIntervals$initCmd(intervalConfig.bP)));
					case 2:
						var triadConfig = exercise.a;
						return _Utils_Tuple2(
							initModelWrapper(
								author$project$Page$Content$TriadModel(
									A4(author$project$Exercises$Triads$init, triadConfig.cG, triadConfig.bv, triadConfig.aV, triadConfig.aW))),
							A2(
								elm$core$Platform$Cmd$map,
								author$project$Page$Content$TriadsMsg,
								author$project$Exercises$Triads$initCmd(triadConfig.aV)));
					case 3:
						var seventhConfig = exercise.a;
						return _Utils_Tuple2(
							initModelWrapper(
								author$project$Page$Content$SeventhModel(
									A4(author$project$Exercises$Sevenths$init, seventhConfig.cG, seventhConfig.bv, seventhConfig.aV, seventhConfig.aW))),
							A2(
								elm$core$Platform$Cmd$map,
								author$project$Page$Content$SeventhsMsg,
								author$project$Exercises$Sevenths$initCmd(seventhConfig.aV)));
					case 9:
						var keyConfig = exercise.a;
						return _Utils_Tuple2(
							initModelWrapper(
								author$project$Page$Content$KeySigModel(
									A2(author$project$Exercises$KeySignatures$init, keyConfig.aV, keyConfig.dM))),
							A2(
								elm$core$Platform$Cmd$map,
								author$project$Page$Content$KeySigMsg,
								author$project$Exercises$KeySignatures$shuffle(keyConfig.dM)));
					default:
						return _Utils_Tuple2(
							initModelWrapper(
								author$project$Page$Content$VideoModel('')),
							elm$core$Platform$Cmd$none);
				}
		}
	});
var author$project$Page$Content$init = F3(
	function (hasPaid, courseNum, contentNum) {
		return A3(author$project$Page$Content$initModel, hasPaid, courseNum, contentNum);
	});
var author$project$Page$Course$Model = function (num) {
	return {bX: num};
};
var author$project$Page$Course$init = function (num) {
	return _Utils_Tuple2(
		author$project$Page$Course$Model(num),
		elm$core$Platform$Cmd$none);
};
var author$project$Page$Home$Model = {};
var author$project$Page$Home$init = _Utils_Tuple2(author$project$Page$Home$Model, elm$core$Platform$Cmd$none);
var elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _n1 = state.U;
			if (!_n1.b) {
				return elm$core$Maybe$Just(state.I);
			} else {
				if ((_n1.a === '') && (!_n1.b.b)) {
					return elm$core$Maybe$Just(state.I);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				elm$core$List$cons,
				segment,
				elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var elm$url$Url$Parser$preparePath = function (path) {
	var _n0 = A2(elm$core$String$split, '/', path);
	if (_n0.b && (_n0.a === '')) {
		var segments = _n0.b;
		return elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _n0;
		return elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var elm$core$Dict$empty = elm$core$Dict$RBEmpty_elm_builtin;
var elm$core$Basics$compare = _Utils_compare;
var elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _n1 = A2(elm$core$Basics$compare, targetKey, key);
				switch (_n1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var elm$core$Dict$Black = 1;
var elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var elm$core$Dict$Red = 0;
var elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _n1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _n3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5(elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _n5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _n6 = left.d;
				var _n7 = _n6.a;
				var llK = _n6.b;
				var llV = _n6.c;
				var llLeft = _n6.d;
				var llRight = _n6.e;
				var lRight = left.e;
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5(elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5(elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5(elm$core$Dict$RBNode_elm_builtin, 0, key, value, elm$core$Dict$RBEmpty_elm_builtin, elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _n1 = A2(elm$core$Basics$compare, key, nKey);
			switch (_n1) {
				case 0:
					return A5(
						elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3(elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5(elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3(elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _n0 = A3(elm$core$Dict$insertHelp, key, value, dict);
		if ((_n0.$ === -1) && (!_n0.a)) {
			var _n1 = _n0.a;
			var k = _n0.b;
			var v = _n0.c;
			var l = _n0.d;
			var r = _n0.e;
			return A5(elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _n0;
			return x;
		}
	});
var elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _n1 = dict.d;
			var lClr = _n1.a;
			var lK = _n1.b;
			var lV = _n1.c;
			var lLeft = _n1.d;
			var lRight = _n1.e;
			var _n2 = dict.e;
			var rClr = _n2.a;
			var rK = _n2.b;
			var rV = _n2.c;
			var rLeft = _n2.d;
			var _n3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _n2.e;
			return A5(
				elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5(elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _n4 = dict.d;
			var lClr = _n4.a;
			var lK = _n4.b;
			var lV = _n4.c;
			var lLeft = _n4.d;
			var lRight = _n4.e;
			var _n5 = dict.e;
			var rClr = _n5.a;
			var rK = _n5.b;
			var rV = _n5.c;
			var rLeft = _n5.d;
			var rRight = _n5.e;
			if (clr === 1) {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _n1 = dict.d;
			var lClr = _n1.a;
			var lK = _n1.b;
			var lV = _n1.c;
			var _n2 = _n1.d;
			var _n3 = _n2.a;
			var llK = _n2.b;
			var llV = _n2.c;
			var llLeft = _n2.d;
			var llRight = _n2.e;
			var lRight = _n1.e;
			var _n4 = dict.e;
			var rClr = _n4.a;
			var rK = _n4.b;
			var rV = _n4.c;
			var rLeft = _n4.d;
			var rRight = _n4.e;
			return A5(
				elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5(elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _n5 = dict.d;
			var lClr = _n5.a;
			var lK = _n5.b;
			var lV = _n5.c;
			var lLeft = _n5.d;
			var lRight = _n5.e;
			var _n6 = dict.e;
			var rClr = _n6.a;
			var rK = _n6.b;
			var rV = _n6.c;
			var rLeft = _n6.d;
			var rRight = _n6.e;
			if (clr === 1) {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _n1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5(elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_n2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _n3 = right.a;
							var _n4 = right.d;
							var _n5 = _n4.a;
							return elm$core$Dict$moveRedRight(dict);
						} else {
							break _n2$2;
						}
					} else {
						var _n6 = right.a;
						var _n7 = right.d;
						return elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _n2$2;
				}
			}
			return dict;
		}
	});
var elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _n3 = lLeft.a;
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					elm$core$Dict$removeMin(left),
					right);
			} else {
				var _n4 = elm$core$Dict$moveRedLeft(dict);
				if (_n4.$ === -1) {
					var nColor = _n4.a;
					var nKey = _n4.b;
					var nValue = _n4.c;
					var nLeft = _n4.d;
					var nRight = _n4.e;
					return A5(
						elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _n4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _n6 = lLeft.a;
						return A5(
							elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2(elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _n7 = elm$core$Dict$moveRedLeft(dict);
						if (_n7.$ === -1) {
							var nColor = _n7.a;
							var nKey = _n7.b;
							var nValue = _n7.c;
							var nLeft = _n7.d;
							var nRight = _n7.e;
							return A5(
								elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2(elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2(elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7(elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _n1 = elm$core$Dict$getMin(right);
				if (_n1.$ === -1) {
					var minKey = _n1.b;
					var minValue = _n1.c;
					return A5(
						elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						elm$core$Dict$removeMin(right));
				} else {
					return elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2(elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var elm$core$Dict$remove = F2(
	function (key, dict) {
		var _n0 = A2(elm$core$Dict$removeHelp, key, dict);
		if ((_n0.$ === -1) && (!_n0.a)) {
			var _n1 = _n0.a;
			var k = _n0.b;
			var v = _n0.c;
			var l = _n0.d;
			var r = _n0.e;
			return A5(elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _n0;
			return x;
		}
	});
var elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _n0 = alter(
			A2(elm$core$Dict$get, targetKey, dictionary));
		if (!_n0.$) {
			var value = _n0.a;
			return A3(elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2(elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var elm$url$Url$percentDecode = _Url_percentDecode;
var elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return elm$core$Maybe$Just(
				A2(elm$core$List$cons, value, list));
		}
	});
var elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _n0 = A2(elm$core$String$split, '=', segment);
		if ((_n0.b && _n0.b.b) && (!_n0.b.b.b)) {
			var rawKey = _n0.a;
			var _n1 = _n0.b;
			var rawValue = _n1.a;
			var _n2 = elm$url$Url$percentDecode(rawKey);
			if (_n2.$ === 1) {
				return dict;
			} else {
				var key = _n2.a;
				var _n3 = elm$url$Url$percentDecode(rawValue);
				if (_n3.$ === 1) {
					return dict;
				} else {
					var value = _n3.a;
					return A3(
						elm$core$Dict$update,
						key,
						elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			elm$core$List$foldr,
			elm$url$Url$Parser$addParam,
			elm$core$Dict$empty,
			A2(elm$core$String$split, '&', qry));
	}
};
var elm$url$Url$Parser$parse = F2(
	function (_n0, url) {
		var parser = _n0;
		return elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					elm$url$Url$Parser$State,
					_List_Nil,
					elm$url$Url$Parser$preparePath(url.cS),
					elm$url$Url$Parser$prepareQuery(url.c_),
					url.cu,
					elm$core$Basics$identity)));
	});
var author$project$Main$stepUrl = F2(
	function (url, model) {
		var _n0 = A2(elm$url$Url$Parser$parse, author$project$Main$routerParser, url);
		if (!_n0.$) {
			var answer = _n0.a;
			switch (answer.$) {
				case 0:
					return A2(author$project$Main$stepHome, model, author$project$Page$Home$init);
				case 1:
					var num = answer.a;
					return A2(
						author$project$Main$stepCourse,
						model,
						author$project$Page$Course$init(num));
				default:
					var course = answer.a;
					var content = answer.b;
					return A2(
						author$project$Main$stepContent,
						model,
						A3(author$project$Page$Content$init, model.A, course, content));
			}
		} else {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{C: author$project$Main$NotFound}),
				elm$core$Platform$Cmd$none);
		}
	});
var author$project$Main$init = F3(
	function (_n0, url, key) {
		return A2(
			author$project$Main$stepUrl,
			url,
			{
				A: true,
				bR: key,
				C: author$project$Main$Home(author$project$Page$Home$Model),
				aN: elm$core$Maybe$Nothing
			});
	});
var elm$core$Platform$Sub$batch = _Platform_batch;
var elm$core$Platform$Sub$none = elm$core$Platform$Sub$batch(_List_Nil);
var author$project$Main$subscriptions = function (_n0) {
	return elm$core$Platform$Sub$none;
};
var author$project$Exercises$BasicIntervals$NewDirection = function (a) {
	return {$: 2, a: a};
};
var author$project$Exercises$BasicIntervals$NewNote = function (a) {
	return {$: 3, a: a};
};
var author$project$Exercises$BasicIntervals$basicIntervalToInt = function (interval) {
	switch (interval) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 4:
			return 4;
		case 5:
			return 5;
		case 6:
			return 6;
		case 7:
			return 7;
		case 8:
			return 8;
		default:
			return 9;
	}
};
var author$project$Exercises$BasicIntervals$getNoteRange = F2(
	function (interval, direction) {
		var n = author$project$Exercises$BasicIntervals$basicIntervalToInt(interval);
		if (!direction) {
			return _Utils_Tuple2((-8) + n, 8);
		} else {
			return _Utils_Tuple2(-8, 8 - n);
		}
	});
var elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var author$project$Exercises$BasicIntervals$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							b$: elm$core$Maybe$Just(
								_Utils_Tuple2(model.z, model.a2))
						}),
					author$project$Exercises$BasicIntervals$initCmd(model.ax));
			case 1:
				var mInterval = msg.a;
				if (!mInterval.$) {
					var interval = mInterval.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{z: interval}),
						A2(
							elm$random$Random$generate,
							author$project$Exercises$BasicIntervals$NewDirection,
							elm_community$random_extra$Random$Array$sample(
								elm$core$Array$fromList(model.cm))));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 2:
				var mDirection = msg.a;
				if (!mDirection.$) {
					var direction = mDirection.a;
					var _n3 = A2(author$project$Exercises$BasicIntervals$getNoteRange, model.z, direction);
					var start = _n3.a;
					var end = _n3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bM: direction}),
						A2(
							elm$random$Random$generate,
							author$project$Exercises$BasicIntervals$NewNote,
							A2(elm$random$Random$int, start, end)));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 3:
				var index = msg.a;
				return (A2(
					elm$core$List$member,
					_Utils_Tuple2(
						model.z,
						elm$core$Maybe$Just(index)),
					model.aw) || _Utils_eq(
					elm$core$Maybe$Just(
						_Utils_Tuple2(
							model.z,
							elm$core$Maybe$Just(index))),
					model.b$)) ? _Utils_Tuple2(
					model,
					author$project$Exercises$BasicIntervals$initCmd(model.ax)) : _Utils_Tuple2(
					_Utils_update(
						model,
						{
							a2: elm$core$Maybe$Just(index),
							aE: elm$core$Maybe$Nothing,
							T: false
						}),
					elm$core$Platform$Cmd$none);
			case 4:
				var result = function () {
					var _n4 = model.aE;
					if (!_n4.$) {
						var i = _n4.a;
						return _Utils_eq(model.z, i);
					} else {
						return false;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aw: result ? _Utils_ap(
								_List_fromArray(
									[
										_Utils_Tuple2(model.z, model.a2)
									]),
								model.aw) : model.aw,
							T: true
						}),
					elm$core$Platform$Cmd$none);
			case 5:
				return _Utils_Tuple2(
					A2(author$project$Exercises$BasicIntervals$init, model.ax, model.aW),
					author$project$Exercises$BasicIntervals$initCmd(model.ax));
			default:
				var i = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aE: i}),
					elm$core$Platform$Cmd$none);
		}
	});
var author$project$Exercises$Intervals$HistoryItem = F4(
	function (note, clef, interval, quality) {
		return {bK: clef, z: interval, dU: note, d1: quality};
	});
var author$project$Exercises$Intervals$NewInterval = function (a) {
	return {$: 3, a: a};
};
var author$project$Exercises$Intervals$NewNote = function (a) {
	return {$: 0, a: a};
};
var author$project$Exercises$Intervals$NewQuality = function (a) {
	return {$: 4, a: a};
};
var elm$core$Basics$modBy = _Basics_modBy;
var author$project$Exercises$Intervals$intervalQualities = function (num) {
	var interval = A2(elm$core$Basics$modBy, 7, num - 1);
	switch (interval) {
		case 0:
			return _List_fromArray(
				[4]);
		case 1:
			return _List_fromArray(
				[1, 2, 3]);
		case 2:
			return _List_fromArray(
				[1, 2]);
		case 3:
			return _List_fromArray(
				[4, 3]);
		case 4:
			return _List_fromArray(
				[0, 4, 3]);
		case 5:
			return _List_fromArray(
				[1, 2, 3]);
		case 6:
			return _List_fromArray(
				[0, 1, 2]);
		default:
			return _List_Nil;
	}
};
var author$project$Theory$TheoryEngine$accidentalToInt = function (accidental) {
	switch (accidental) {
		case 0:
			return -2;
		case 1:
			return -1;
		case 2:
			return 0;
		case 3:
			return 0;
		case 4:
			return 1;
		default:
			return 2;
	}
};
var author$project$Theory$TheoryEngine$noteNameToInt = function (name) {
	switch (name) {
		case 0:
			return 0;
		case 1:
			return 2;
		case 2:
			return 4;
		case 3:
			return 5;
		case 4:
			return 7;
		case 5:
			return 9;
		case 6:
			return 11;
		default:
			return -1;
	}
};
var author$project$Theory$TheoryEngine$noteToInt = function (note) {
	var nameInt = author$project$Theory$TheoryEngine$noteNameToInt(note.dT);
	var accidentalInt = author$project$Theory$TheoryEngine$accidentalToInt(note.dk);
	return (nameInt + (12 * note.dW)) + accidentalInt;
};
var author$project$Theory$TheoryEngine$compareNotes = F2(
	function (note1, note2) {
		var n2 = author$project$Theory$TheoryEngine$noteToInt(note2);
		var n1 = author$project$Theory$TheoryEngine$noteToInt(note1);
		return A2(elm$core$Basics$compare, n1, n2);
	});
var author$project$Theory$TheoryEngine$notesGTE = F2(
	function (note1, note2) {
		return (A2(author$project$Theory$TheoryEngine$compareNotes, note1, note2) === 2) || (A2(author$project$Theory$TheoryEngine$compareNotes, note1, note2) === 1);
	});
var author$project$Theory$TheoryEngine$notesLTE = F2(
	function (note1, note2) {
		return (!A2(author$project$Theory$TheoryEngine$compareNotes, note1, note2)) || (A2(author$project$Theory$TheoryEngine$compareNotes, note1, note2) === 1);
	});
var elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2(elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var author$project$Theory$TheoryEngine$noteRange = F2(
	function (start, end) {
		var octaveRange = A2(elm$core$List$range, start.dW, end.dW);
		var octaveList = function (n) {
			return _List_fromArray(
				[
					A3(author$project$Theory$TheoryEngine$Note, 0, n, 3),
					A3(author$project$Theory$TheoryEngine$Note, 0, n, 4),
					A3(author$project$Theory$TheoryEngine$Note, 1, n, 1),
					A3(author$project$Theory$TheoryEngine$Note, 1, n, 3),
					A3(author$project$Theory$TheoryEngine$Note, 1, n, 4),
					A3(author$project$Theory$TheoryEngine$Note, 2, n, 1),
					A3(author$project$Theory$TheoryEngine$Note, 2, n, 3),
					A3(author$project$Theory$TheoryEngine$Note, 3, n, 3),
					A3(author$project$Theory$TheoryEngine$Note, 3, n, 4),
					A3(author$project$Theory$TheoryEngine$Note, 4, n, 1),
					A3(author$project$Theory$TheoryEngine$Note, 4, n, 3),
					A3(author$project$Theory$TheoryEngine$Note, 4, n, 4),
					A3(author$project$Theory$TheoryEngine$Note, 5, n, 1),
					A3(author$project$Theory$TheoryEngine$Note, 5, n, 3),
					A3(author$project$Theory$TheoryEngine$Note, 5, n, 4),
					A3(author$project$Theory$TheoryEngine$Note, 6, n, 1),
					A3(author$project$Theory$TheoryEngine$Note, 6, n, 3)
				]);
		};
		var fullList = elm$core$List$concat(
			A2(elm$core$List$map, octaveList, octaveRange));
		var filteredList = A2(
			elm$core$List$filter,
			function (x) {
				return A2(author$project$Theory$TheoryEngine$notesGTE, x, start) && A2(author$project$Theory$TheoryEngine$notesLTE, x, end);
			},
			fullList);
		return filteredList;
	});
var author$project$Theory$RenderEngine$clefRangeList = function (clef) {
	switch (clef) {
		case 0:
			return A2(
				author$project$Theory$TheoryEngine$noteRange,
				A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3),
				A3(author$project$Theory$TheoryEngine$Note, 5, 5, 3));
		case 1:
			return A2(
				author$project$Theory$TheoryEngine$noteRange,
				A3(author$project$Theory$TheoryEngine$Note, 2, 2, 3),
				A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3));
		case 2:
			return A2(
				author$project$Theory$TheoryEngine$noteRange,
				A3(author$project$Theory$TheoryEngine$Note, 1, 3, 3),
				A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3));
		default:
			return A2(
				author$project$Theory$TheoryEngine$noteRange,
				A3(author$project$Theory$TheoryEngine$Note, 6, 2, 3),
				A3(author$project$Theory$TheoryEngine$Note, 5, 4, 3));
	}
};
var author$project$Theory$TheoryEngine$Down = 1;
var author$project$Theory$TheoryEngine$IntervalName = F2(
	function (quality, interval) {
		return {z: interval, d1: quality};
	});
var author$project$Theory$TheoryEngine$notesGT = F2(
	function (note1, note2) {
		return A2(author$project$Theory$TheoryEngine$compareNotes, note1, note2) === 2;
	});
var author$project$Theory$TheoryEngine$notesMax = F2(
	function (note1, note2) {
		return A2(author$project$Theory$TheoryEngine$notesGT, note1, note2) ? note1 : note2;
	});
var author$project$Theory$TheoryEngine$notesMaximum = function (notes) {
	if (notes.b) {
		var x = notes.a;
		var xs = notes.b;
		return elm$core$Maybe$Just(
			A3(elm$core$List$foldl, author$project$Theory$TheoryEngine$notesMax, x, xs));
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var author$project$Theory$TheoryEngine$invertIntervalNumber = function (interval) {
	var num = (interval > 7) ? (interval % 7) : interval;
	var newNum = (num < 0) ? (num * (-1)) : num;
	return 9 - newNum;
};
var author$project$Theory$TheoryEngine$invertIntervalQuality = function (quality) {
	switch (quality) {
		case 0:
			return 3;
		case 1:
			return 2;
		case 2:
			return 1;
		case 3:
			return 0;
		default:
			return 4;
	}
};
var author$project$Theory$TheoryEngine$invertInterval = function (interval) {
	return A2(
		author$project$Theory$TheoryEngine$IntervalName,
		author$project$Theory$TheoryEngine$invertIntervalQuality(interval.d1),
		author$project$Theory$TheoryEngine$invertIntervalNumber(interval.z));
};
var author$project$Theory$TheoryEngine$InvalidNote = 7;
var elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var author$project$Theory$TheoryEngine$baseInterval = function (interval) {
	var i = elm$core$Basics$abs(interval);
	switch (i) {
		case 1:
			return 0;
		case 2:
			return 2;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 7;
		case 6:
			return 9;
		case 7:
			return 11;
		case 8:
			return 12;
		default:
			return author$project$Theory$TheoryEngine$baseInterval(8) + author$project$Theory$TheoryEngine$baseInterval(interval - 7);
	}
};
var author$project$Theory$TheoryEngine$getHalfStepsFromInterval = function (interval) {
	var normalized = interval.z % 7;
	var isPerfect = (normalized === 4) || ((normalized === 5) || (normalized === 1));
	if (isPerfect) {
		var _n0 = interval.d1;
		switch (_n0) {
			case 0:
				return author$project$Theory$TheoryEngine$baseInterval(interval.z) - 1;
			case 1:
				return author$project$Theory$TheoryEngine$baseInterval(interval.z);
			case 2:
				return author$project$Theory$TheoryEngine$baseInterval(interval.z);
			case 4:
				return author$project$Theory$TheoryEngine$baseInterval(interval.z);
			default:
				return author$project$Theory$TheoryEngine$baseInterval(interval.z) + 1;
		}
	} else {
		var _n1 = interval.d1;
		switch (_n1) {
			case 0:
				return author$project$Theory$TheoryEngine$baseInterval(interval.z) - 2;
			case 1:
				return author$project$Theory$TheoryEngine$baseInterval(interval.z) - 1;
			case 2:
				return author$project$Theory$TheoryEngine$baseInterval(interval.z);
			case 4:
				return author$project$Theory$TheoryEngine$baseInterval(interval.z);
			default:
				return author$project$Theory$TheoryEngine$baseInterval(interval.z) + 1;
		}
	}
};
var author$project$Theory$TheoryEngine$noteNameToStep = function (note) {
	switch (note) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 4:
			return 4;
		case 5:
			return 5;
		case 6:
			return 6;
		default:
			return -1;
	}
};
var author$project$Theory$TheoryEngine$Interval = F2(
	function (number, halfSteps) {
		return {cy: halfSteps, dV: number};
	});
var author$project$Theory$TheoryEngine$noteNameToHalfStep = function (note) {
	switch (note) {
		case 0:
			return 0;
		case 1:
			return 2;
		case 2:
			return 4;
		case 3:
			return 5;
		case 4:
			return 7;
		case 5:
			return 9;
		case 6:
			return 11;
		default:
			return -1;
	}
};
var author$project$Theory$TheoryEngine$adjustHalfStepAccidental = F2(
	function (note, accidental) {
		switch (accidental) {
			case 0:
				return author$project$Theory$TheoryEngine$noteNameToHalfStep(note) - 2;
			case 1:
				return author$project$Theory$TheoryEngine$noteNameToHalfStep(note) - 1;
			case 2:
				return author$project$Theory$TheoryEngine$noteNameToHalfStep(note);
			case 3:
				return author$project$Theory$TheoryEngine$noteNameToHalfStep(note);
			case 4:
				return author$project$Theory$TheoryEngine$noteNameToHalfStep(note) + 1;
			default:
				return author$project$Theory$TheoryEngine$noteNameToHalfStep(note) + 2;
		}
	});
var author$project$Theory$TheoryEngine$notesToInterval = F2(
	function (n1, n2) {
		var offset = n2.dW - n1.dW;
		var stepOffset = offset * 7;
		var stepDistance = elm$core$Basics$abs(
			stepOffset + (author$project$Theory$TheoryEngine$noteNameToStep(n2.dT) - author$project$Theory$TheoryEngine$noteNameToStep(n1.dT))) + 1;
		var halfStepOffset = offset * 12;
		var halfStepDistance = elm$core$Basics$abs(
			halfStepOffset + (A2(author$project$Theory$TheoryEngine$adjustHalfStepAccidental, n2.dT, n2.dk) - A2(author$project$Theory$TheoryEngine$adjustHalfStepAccidental, n1.dT, n1.dk)));
		return A2(author$project$Theory$TheoryEngine$Interval, stepDistance, halfStepDistance);
	});
var author$project$Theory$TheoryEngine$stepToNoteName = function (num) {
	switch (num) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 4:
			return 4;
		case 5:
			return 5;
		case 6:
			return 6;
		default:
			return 7;
	}
};
var author$project$Theory$TheoryEngine$transposeNoteUp = F2(
	function (interval, note) {
		var newNoteNum = author$project$Theory$TheoryEngine$noteNameToStep(note.dT) + (interval.z - 1);
		var octaveOffset = (newNoteNum / 7) | 0;
		var newNote = newNoteNum % 7;
		var newNoteName = author$project$Theory$TheoryEngine$stepToNoteName(newNote);
		var noteGuess = A3(author$project$Theory$TheoryEngine$Note, newNoteName, note.dW + octaveOffset, 3);
		var intervalGuess = A2(author$project$Theory$TheoryEngine$notesToInterval, note, noteGuess);
		var halfSteps = author$project$Theory$TheoryEngine$getHalfStepsFromInterval(interval);
		var noteGuessDiff = halfSteps - intervalGuess.cy;
		return _Utils_eq(noteGuessDiff, -2) ? _Utils_update(
			noteGuess,
			{dk: 0}) : (_Utils_eq(noteGuessDiff, -1) ? _Utils_update(
			noteGuess,
			{dk: 1}) : ((!noteGuessDiff) ? noteGuess : ((noteGuessDiff === 1) ? _Utils_update(
			noteGuess,
			{dk: 4}) : ((noteGuessDiff === 2) ? _Utils_update(
			noteGuess,
			{dk: 5}) : A3(author$project$Theory$TheoryEngine$Note, 7, 1, 3)))));
	});
var author$project$Theory$TheoryEngine$transposeNote = F3(
	function (interval, direction, note) {
		if (!direction) {
			return A2(author$project$Theory$TheoryEngine$transposeNoteUp, interval, note);
		} else {
			var octaveAdjust = (interval.z > 7) ? (((interval.z - 1) / 7) | 0) : 0;
			var newNote = A2(
				author$project$Theory$TheoryEngine$transposeNoteUp,
				author$project$Theory$TheoryEngine$invertInterval(interval),
				note);
			return _Utils_update(
				newNote,
				{dW: (newNote.dW - 1) - octaveAdjust});
		}
	});
var author$project$Exercises$Intervals$possibleNotes = F3(
	function (mClef, mInterval, quality) {
		var noteList = function () {
			if (!mClef.$) {
				var clef = mClef.a;
				return author$project$Theory$RenderEngine$clefRangeList(clef);
			} else {
				return _List_Nil;
			}
		}();
		var mTopNote = author$project$Theory$TheoryEngine$notesMaximum(noteList);
		if (!mInterval.$) {
			var interval = mInterval.a;
			if (!mTopNote.$) {
				var topNote = mTopNote.a;
				var cutOff = A3(
					author$project$Theory$TheoryEngine$transposeNote,
					A2(author$project$Theory$TheoryEngine$IntervalName, quality, interval),
					1,
					topNote);
				return A2(
					elm$core$List$filter,
					function (x) {
						return A2(author$project$Theory$TheoryEngine$notesGTE, cutOff, x);
					},
					noteList);
			} else {
				return _List_Nil;
			}
		} else {
			return _List_Nil;
		}
	});
var author$project$Exercises$Intervals$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aE: elm$core$Maybe$Nothing, aF: elm$core$Maybe$Nothing, T: false}),
					author$project$Exercises$Intervals$initCmd(model.aU));
			case 2:
				var mClef = msg.a;
				if (!mClef.$) {
					var clef = mClef.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bK: elm$core$Maybe$Just(clef)
							}),
						A2(
							elm$random$Random$generate,
							author$project$Exercises$Intervals$NewInterval,
							elm_community$random_extra$Random$Array$sample(
								elm$core$Array$fromList(model.ax))));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 3:
				var mInterval = msg.a;
				if (!mInterval.$) {
					var interval = mInterval.a;
					var qualities = A2(
						elm$core$List$filter,
						function (x) {
							return A2(elm$core$List$member, x, model.bw);
						},
						author$project$Exercises$Intervals$intervalQualities(interval));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								z: elm$core$Maybe$Just(interval)
							}),
						A2(
							elm$random$Random$generate,
							author$project$Exercises$Intervals$NewQuality,
							elm_community$random_extra$Random$Array$sample(
								elm$core$Array$fromList(qualities))));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 4:
				var mQuality = msg.a;
				if (!mQuality.$) {
					var quality = mQuality.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								Z: elm$core$Maybe$Just(quality)
							}),
						A2(
							elm$random$Random$generate,
							author$project$Exercises$Intervals$NewNote,
							elm_community$random_extra$Random$Array$sample(
								elm$core$Array$fromList(
									A3(author$project$Exercises$Intervals$possibleNotes, model.bK, model.z, quality)))));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 0:
				var mNote = msg.a;
				if (!mNote.$) {
					var note = mNote.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								a2: elm$core$Maybe$Just(note)
							}),
						A2(
							elm$core$List$member,
							A4(author$project$Exercises$Intervals$HistoryItem, model.a2, model.bK, model.z, model.Z),
							model.aw) ? author$project$Exercises$Intervals$initCmd(model.aU) : elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 6:
				var mQuality = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aF: mQuality}),
					elm$core$Platform$Cmd$none);
			case 5:
				var mInterval = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aE: mInterval}),
					elm$core$Platform$Cmd$none);
			case 7:
				return _Utils_Tuple2(
					A4(author$project$Exercises$Intervals$init, model.ax, model.bw, model.aU, model.aW),
					author$project$Exercises$Intervals$initCmd(model.aU));
			default:
				var resultQuality = function () {
					var _n6 = model.aF;
					if (!_n6.$) {
						var i = _n6.a;
						return _Utils_eq(
							model.Z,
							elm$core$Maybe$Just(i));
					} else {
						return false;
					}
				}();
				var resultInterval = function () {
					var _n5 = model.aE;
					if (!_n5.$) {
						var i = _n5.a;
						return _Utils_eq(
							model.z,
							elm$core$Maybe$Just(i));
					} else {
						return false;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aw: (resultInterval && resultQuality) ? _Utils_ap(
								_List_fromArray(
									[
										A4(author$project$Exercises$Intervals$HistoryItem, model.a2, model.bK, model.z, model.Z)
									]),
								model.aw) : model.aw,
							T: true
						}),
					elm$core$Platform$Cmd$none);
		}
	});
var author$project$Exercises$KeyBasicIntervals$KeyMsg = function (a) {
	return {$: 0, a: a};
};
var author$project$Exercises$KeyBasicIntervals$NewDirection = function (a) {
	return {$: 2, a: a};
};
var author$project$Exercises$KeyBasicIntervals$NewKey = function (a) {
	return {$: 1, a: a};
};
var author$project$Exercises$KeyBasicIntervals$keyboardKeyFromInt = function (num) {
	var mod7 = A2(elm$core$Basics$modBy, 7, num);
	var div7 = (num / 7) | 0;
	switch (mod7) {
		case 0:
			return elm$core$Maybe$Just(
				author$project$Theory$Keyboard$C(div7));
		case 1:
			return elm$core$Maybe$Just(
				author$project$Theory$Keyboard$D(div7));
		case 2:
			return elm$core$Maybe$Just(
				author$project$Theory$Keyboard$E(div7));
		case 3:
			return elm$core$Maybe$Just(
				author$project$Theory$Keyboard$F(div7));
		case 4:
			return elm$core$Maybe$Just(
				author$project$Theory$Keyboard$G(div7));
		case 5:
			return elm$core$Maybe$Just(
				author$project$Theory$Keyboard$A(div7));
		case 6:
			return elm$core$Maybe$Just(
				author$project$Theory$Keyboard$B(div7));
		default:
			return elm$core$Maybe$Nothing;
	}
};
var author$project$Exercises$KeyBasicIntervals$keyboardKeyToInt = function (key) {
	switch (key.$) {
		case 0:
			var num = key.a;
			return elm$core$Maybe$Just(0 + (num * 7));
		case 2:
			var num = key.a;
			return elm$core$Maybe$Just(1 + (num * 7));
		case 4:
			var num = key.a;
			return elm$core$Maybe$Just(2 + (num * 7));
		case 5:
			var num = key.a;
			return elm$core$Maybe$Just(3 + (num * 7));
		case 7:
			var num = key.a;
			return elm$core$Maybe$Just(4 + (num * 7));
		case 9:
			var num = key.a;
			return elm$core$Maybe$Just(5 + (num * 7));
		case 11:
			var num = key.a;
			return elm$core$Maybe$Just(6 + (num * 7));
		default:
			return elm$core$Maybe$Nothing;
	}
};
var author$project$Exercises$KeyBasicIntervals$keyInterval = F3(
	function (key, interval, direction) {
		var keyNum = A2(
			elm$core$Maybe$withDefault,
			-1,
			author$project$Exercises$KeyBasicIntervals$keyboardKeyToInt(key));
		var intervalNum = author$project$Exercises$BasicIntervals$basicIntervalToInt(interval);
		var newKeyNum = function () {
			if (!direction) {
				return keyNum + intervalNum;
			} else {
				return keyNum - intervalNum;
			}
		}();
		return A2(
			elm$core$Maybe$withDefault,
			author$project$Theory$Keyboard$None,
			author$project$Exercises$KeyBasicIntervals$keyboardKeyFromInt(newKeyNum));
	});
var author$project$Exercises$KeyBasicIntervals$possibleKeys = _List_fromArray(
	[
		author$project$Theory$Keyboard$C(1),
		author$project$Theory$Keyboard$D(1),
		author$project$Theory$Keyboard$E(1),
		author$project$Theory$Keyboard$F(1),
		author$project$Theory$Keyboard$G(1),
		author$project$Theory$Keyboard$A(1),
		author$project$Theory$Keyboard$B(1),
		author$project$Theory$Keyboard$C(2),
		author$project$Theory$Keyboard$D(2),
		author$project$Theory$Keyboard$E(2),
		author$project$Theory$Keyboard$F(2),
		author$project$Theory$Keyboard$G(2),
		author$project$Theory$Keyboard$A(2),
		author$project$Theory$Keyboard$B(2)
	]);
var elm$core$Basics$neq = _Utils_notEqual;
var elm$core$Basics$not = _Basics_not;
var author$project$Theory$Keyboard$toggleKey = F2(
	function (key, model) {
		var hasKey = A2(elm$core$List$member, key, model.cJ);
		return ((model.bI === 1) && (!A2(elm$core$List$member, key, model.Y))) ? _List_fromArray(
			[key]) : (hasKey ? A2(
			elm$core$List$filter,
			function (x) {
				return !_Utils_eq(x, key);
			},
			model.cJ) : (((_Utils_cmp(
			elm$core$List$length(model.cJ),
			model.bI) < 0) && (!A2(elm$core$List$member, key, model.Y))) ? A2(elm$core$List$cons, key, model.cJ) : model.cJ));
	});
var author$project$Theory$Keyboard$update = F2(
	function (msg, model) {
		if (!msg.$) {
			var keyboardKey = msg.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						cJ: A2(author$project$Theory$Keyboard$toggleKey, keyboardKey, model)
					}),
				elm$core$Platform$Cmd$none);
		} else {
			return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
		}
	});
var author$project$Exercises$KeyBasicIntervals$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 6:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aS: 0, T: false}),
					A2(
						elm$random$Random$generate,
						author$project$Exercises$KeyBasicIntervals$NewInterval,
						elm_community$random_extra$Random$Array$sample(
							elm$core$Array$fromList(model.ax))));
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{T: false}),
					A2(
						elm$random$Random$generate,
						author$project$Exercises$KeyBasicIntervals$NewInterval,
						elm_community$random_extra$Random$Array$sample(
							elm$core$Array$fromList(model.ax))));
			case 4:
				var maybeInterval = msg.a;
				if (!maybeInterval.$) {
					var interval = maybeInterval.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{z: interval}),
						A2(
							elm$random$Random$generate,
							author$project$Exercises$KeyBasicIntervals$NewDirection,
							elm_community$random_extra$Random$Array$sample(
								elm$core$Array$fromList(
									_List_fromArray(
										[0, 1])))));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 2:
				var maybeDirection = msg.a;
				var direction = A2(elm$core$Maybe$withDefault, 0, maybeDirection);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bM: direction}),
					A2(
						elm$random$Random$generate,
						author$project$Exercises$KeyBasicIntervals$NewKey,
						elm_community$random_extra$Random$Array$sample(
							elm$core$Array$fromList(author$project$Exercises$KeyBasicIntervals$possibleKeys))));
			case 1:
				var maybeKey = msg.a;
				if (!maybeKey.$) {
					var key = maybeKey.a;
					var newKey = A3(author$project$Exercises$KeyBasicIntervals$keyInterval, key, model.z, model.bM);
					var newKeyModel = A3(
						author$project$Theory$Keyboard$initKeyboardKeys,
						_List_fromArray(
							[key]),
						_List_fromArray(
							[newKey]),
						1);
					var outOfRange = !A2(elm$core$List$member, newKey, author$project$Exercises$KeyBasicIntervals$possibleKeys);
					return outOfRange ? _Utils_Tuple2(
						model,
						A2(
							elm$random$Random$generate,
							author$project$Exercises$KeyBasicIntervals$NewKey,
							elm_community$random_extra$Random$Array$sample(
								elm$core$Array$fromList(author$project$Exercises$KeyBasicIntervals$possibleKeys)))) : _Utils_Tuple2(
						_Utils_update(
							model,
							{O: newKeyModel}),
						elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 5:
				var result = _Utils_eq(model.O.$7, model.O.cJ) ? 1 : 0;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aS: model.aS + result, T: true}),
					elm$core$Platform$Cmd$none);
			default:
				var n = msg.a;
				var _n3 = A2(author$project$Theory$Keyboard$update, n, model.O);
				var m = _n3.a;
				var c = _n3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{O: m}),
					A2(elm$core$Platform$Cmd$map, author$project$Exercises$KeyBasicIntervals$KeyMsg, c));
		}
	});
var author$project$Exercises$KeyIntervals$HistoryItem = F3(
	function (note, interval, quality) {
		return {z: interval, dU: note, d1: quality};
	});
var author$project$Exercises$KeyIntervals$KeyMsg = function (a) {
	return {$: 5, a: a};
};
var author$project$Exercises$KeyIntervals$NewDirection = function (a) {
	return {$: 3, a: a};
};
var author$project$Exercises$KeyIntervals$NewNote = function (a) {
	return {$: 4, a: a};
};
var author$project$Exercises$KeyIntervals$NewQuality = function (a) {
	return {$: 2, a: a};
};
var author$project$Exercises$KeyIntervals$intervalQualities = function (num) {
	var interval = A2(elm$core$Basics$modBy, 7, num - 1);
	switch (interval) {
		case 0:
			return _List_fromArray(
				[4]);
		case 1:
			return _List_fromArray(
				[1, 2, 3]);
		case 2:
			return _List_fromArray(
				[1, 2]);
		case 3:
			return _List_fromArray(
				[4, 3]);
		case 4:
			return _List_fromArray(
				[0, 4, 3]);
		case 5:
			return _List_fromArray(
				[1, 2, 3]);
		case 6:
			return _List_fromArray(
				[0, 1, 2]);
		default:
			return _List_Nil;
	}
};
var author$project$Exercises$KeyIntervals$noteList = _List_fromArray(
	[
		A3(author$project$Theory$TheoryEngine$Note, 0, 1, 3),
		A3(author$project$Theory$TheoryEngine$Note, 0, 1, 4),
		A3(author$project$Theory$TheoryEngine$Note, 1, 1, 1),
		A3(author$project$Theory$TheoryEngine$Note, 1, 1, 3),
		A3(author$project$Theory$TheoryEngine$Note, 1, 1, 4),
		A3(author$project$Theory$TheoryEngine$Note, 2, 1, 1),
		A3(author$project$Theory$TheoryEngine$Note, 2, 1, 3),
		A3(author$project$Theory$TheoryEngine$Note, 3, 1, 3),
		A3(author$project$Theory$TheoryEngine$Note, 3, 1, 4),
		A3(author$project$Theory$TheoryEngine$Note, 4, 1, 1),
		A3(author$project$Theory$TheoryEngine$Note, 4, 1, 3),
		A3(author$project$Theory$TheoryEngine$Note, 4, 1, 4),
		A3(author$project$Theory$TheoryEngine$Note, 5, 1, 1),
		A3(author$project$Theory$TheoryEngine$Note, 5, 1, 3),
		A3(author$project$Theory$TheoryEngine$Note, 5, 1, 4),
		A3(author$project$Theory$TheoryEngine$Note, 6, 1, 1),
		A3(author$project$Theory$TheoryEngine$Note, 6, 1, 3),
		A3(author$project$Theory$TheoryEngine$Note, 0, 2, 3),
		A3(author$project$Theory$TheoryEngine$Note, 0, 2, 4),
		A3(author$project$Theory$TheoryEngine$Note, 1, 2, 1),
		A3(author$project$Theory$TheoryEngine$Note, 1, 2, 3),
		A3(author$project$Theory$TheoryEngine$Note, 1, 2, 4),
		A3(author$project$Theory$TheoryEngine$Note, 2, 2, 1),
		A3(author$project$Theory$TheoryEngine$Note, 2, 2, 3),
		A3(author$project$Theory$TheoryEngine$Note, 3, 2, 3),
		A3(author$project$Theory$TheoryEngine$Note, 3, 2, 4),
		A3(author$project$Theory$TheoryEngine$Note, 4, 2, 1),
		A3(author$project$Theory$TheoryEngine$Note, 4, 2, 3),
		A3(author$project$Theory$TheoryEngine$Note, 4, 2, 4),
		A3(author$project$Theory$TheoryEngine$Note, 5, 2, 1),
		A3(author$project$Theory$TheoryEngine$Note, 5, 2, 3),
		A3(author$project$Theory$TheoryEngine$Note, 5, 2, 4),
		A3(author$project$Theory$TheoryEngine$Note, 6, 2, 1),
		A3(author$project$Theory$TheoryEngine$Note, 6, 2, 3),
		A3(author$project$Theory$TheoryEngine$Note, 0, 3, 3)
	]);
var author$project$Theory$TheoryEngine$Up = 0;
var author$project$Exercises$KeyIntervals$possibleNotes = F3(
	function (mInterval, mQuality, direction) {
		if (!mInterval.$) {
			var interval = mInterval.a;
			if (!mQuality.$) {
				var quality = mQuality.a;
				if (!direction) {
					var cutOff = A3(
						author$project$Theory$TheoryEngine$transposeNote,
						A2(author$project$Theory$TheoryEngine$IntervalName, quality, interval),
						1,
						A3(author$project$Theory$TheoryEngine$Note, 0, 3, 3));
					return A2(
						elm$core$List$filter,
						function (x) {
							return A2(author$project$Theory$TheoryEngine$notesGTE, cutOff, x);
						},
						author$project$Exercises$KeyIntervals$noteList);
				} else {
					var cutOff = A3(
						author$project$Theory$TheoryEngine$transposeNote,
						A2(author$project$Theory$TheoryEngine$IntervalName, quality, interval),
						0,
						A3(author$project$Theory$TheoryEngine$Note, 0, 1, 3));
					return A2(
						elm$core$List$filter,
						function (x) {
							return A2(author$project$Theory$TheoryEngine$notesLTE, cutOff, x);
						},
						author$project$Exercises$KeyIntervals$noteList);
				}
			} else {
				return _List_Nil;
			}
		} else {
			return _List_Nil;
		}
	});
var author$project$Exercises$KeyIntervals$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{T: false}),
					author$project$Exercises$KeyIntervals$initCmd(model.ax));
			case 1:
				var mInterval = msg.a;
				if (!mInterval.$) {
					var interval = mInterval.a;
					var qualities = A2(
						elm$core$List$filter,
						function (x) {
							return A2(elm$core$List$member, x, model.bw);
						},
						author$project$Exercises$KeyIntervals$intervalQualities(interval));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								z: elm$core$Maybe$Just(interval)
							}),
						A2(
							elm$random$Random$generate,
							author$project$Exercises$KeyIntervals$NewQuality,
							elm_community$random_extra$Random$Array$sample(
								elm$core$Array$fromList(qualities))));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 2:
				var mQuality = msg.a;
				if (!mQuality.$) {
					var quality = mQuality.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								Z: elm$core$Maybe$Just(quality)
							}),
						A2(
							elm$random$Random$generate,
							author$project$Exercises$KeyIntervals$NewDirection,
							elm_community$random_extra$Random$Array$sample(
								elm$core$Array$fromList(
									_List_fromArray(
										[0, 1])))));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 3:
				var mDirection = msg.a;
				if (!mDirection.$) {
					var direction = mDirection.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bM: elm$core$Maybe$Just(direction)
							}),
						A2(
							elm$random$Random$generate,
							author$project$Exercises$KeyIntervals$NewNote,
							elm_community$random_extra$Random$Array$sample(
								elm$core$Array$fromList(
									A3(author$project$Exercises$KeyIntervals$possibleNotes, model.z, model.Z, direction)))));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 4:
				var mNote = msg.a;
				if (!mNote.$) {
					var note = mNote.a;
					var intervalNote = function () {
						var _n5 = model.Z;
						if (!_n5.$) {
							var quality = _n5.a;
							var _n6 = model.z;
							if (!_n6.$) {
								var interval = _n6.a;
								var _n7 = model.bM;
								if (!_n7.$) {
									var direction = _n7.a;
									return A3(
										author$project$Theory$TheoryEngine$transposeNote,
										A2(author$project$Theory$TheoryEngine$IntervalName, quality, interval),
										direction,
										note);
								} else {
									return note;
								}
							} else {
								return note;
							}
						} else {
							return note;
						}
					}();
					var given = author$project$Theory$Keyboard$noteToKeyboardKey(note);
					var answer = author$project$Theory$Keyboard$noteToKeyboardKey(intervalNote);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								O: A3(
									author$project$Theory$Keyboard$initKeyboardKeys,
									_List_fromArray(
										[given]),
									_List_fromArray(
										[answer]),
									1),
								a2: elm$core$Maybe$Just(note)
							}),
						elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 5:
				var n = msg.a;
				var _n8 = A2(author$project$Theory$Keyboard$update, n, model.O);
				var m = _n8.a;
				var c = _n8.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{O: m}),
					A2(elm$core$Platform$Cmd$map, author$project$Exercises$KeyIntervals$KeyMsg, c));
			case 6:
				return _Utils_Tuple2(
					A3(author$project$Exercises$KeyIntervals$init, model.ax, model.bw, model.aW),
					author$project$Exercises$KeyIntervals$initCmd(model.ax));
			default:
				var result = _Utils_eq(model.O.cJ, model.O.$7);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aw: result ? _Utils_ap(
								_List_fromArray(
									[
										A3(author$project$Exercises$KeyIntervals$HistoryItem, model.a2, model.z, model.Z)
									]),
								model.aw) : model.aw,
							T: true
						}),
					elm$core$Platform$Cmd$none);
		}
	});
var author$project$Exercises$KeyNotes$KeyMsg = function (a) {
	return {$: 2, a: a};
};
var author$project$Exercises$KeyNotes$union = F2(
	function (list1, list2) {
		union:
		while (true) {
			if (list1.b) {
				var x = list1.a;
				var xs = list1.b;
				if (A2(elm$core$List$member, x, list2)) {
					var $temp$list1 = xs,
						$temp$list2 = list2;
					list1 = $temp$list1;
					list2 = $temp$list2;
					continue union;
				} else {
					var $temp$list1 = xs,
						$temp$list2 = _Utils_ap(
						_List_fromArray(
							[x]),
						list2);
					list1 = $temp$list1;
					list2 = $temp$list2;
					continue union;
				}
			} else {
				return list2;
			}
		}
	});
var author$project$Exercises$KeyNotes$checkKeys = F2(
	function (input, answer) {
		return _Utils_eq(
			A2(author$project$Exercises$KeyNotes$union, input, answer),
			answer);
	});
var author$project$Exercises$KeyNotes$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 4:
				var deck = msg.a;
				if (deck.b) {
					var x = deck.a;
					var xs = deck.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								F: x,
								O: author$project$Exercises$KeyNotes$initKeys(x.dU),
								bo: xs
							}),
						elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 0:
				var result = A2(author$project$Exercises$KeyNotes$checkKeys, model.O.cJ, model.O.$7);
				var _n2 = model.bo;
				if (_n2.b) {
					var x = _n2.a;
					var xs = _n2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								F: x,
								O: author$project$Exercises$KeyNotes$initKeys(x.dU),
								bo: _Utils_ap(
									xs,
									(!result) ? _List_fromArray(
										[model.F]) : _List_Nil),
								T: false
							}),
						elm$core$Platform$Cmd$none);
				} else {
					return result ? _Utils_Tuple2(
						A2(author$project$Exercises$KeyNotes$initModel, model.ap, model.bg),
						author$project$Exercises$KeyNotes$shuffle(model.ap)) : _Utils_Tuple2(
						_Utils_update(
							model,
							{
								O: author$project$Exercises$KeyNotes$initKeys(model.F.dU),
								T: false
							}),
						elm$core$Platform$Cmd$none);
				}
			case 1:
				var result = A2(author$project$Exercises$KeyNotes$checkKeys, model.O.cJ, model.O.$7);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aS: model.aS + (result ? 1 : 0),
							T: true
						}),
					elm$core$Platform$Cmd$none);
			case 2:
				var n = msg.a;
				var _n3 = A2(author$project$Theory$Keyboard$update, n, model.O);
				var m = _n3.a;
				var c = _n3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{O: m}),
					A2(elm$core$Platform$Cmd$map, author$project$Exercises$KeyNotes$KeyMsg, c));
			default:
				return _Utils_Tuple2(
					A2(author$project$Exercises$KeyNotes$initModel, model.ap, model.bg),
					author$project$Exercises$KeyNotes$shuffle(model.ap));
		}
	});
var author$project$Exercises$KeySignatures$NewClef = function (a) {
	return {$: 1, a: a};
};
var author$project$Exercises$KeySignatures$initUpdate = function (model) {
	return _Utils_Tuple2(
		A2(author$project$Exercises$KeySignatures$init, model.aU, model.bm),
		author$project$Exercises$KeySignatures$shuffle(model.bm));
};
var author$project$Exercises$KeySignatures$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var keys = msg.a;
				if (keys.b) {
					var x = keys.a;
					var xs = keys.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bl: xs, n: x}),
						A2(
							elm$random$Random$generate,
							author$project$Exercises$KeySignatures$NewClef,
							elm_community$random_extra$Random$Array$sample(
								elm$core$Array$fromList(model.aU))));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 1:
				var mClef = msg.a;
				if (!mClef.$) {
					var clef = mClef.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bK: elm$core$Maybe$Just(clef)
							}),
						elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 2:
				var result = _Utils_eq(
					elm$core$Maybe$Just(model.n.dU),
					model.x) && _Utils_eq(model.n.dk, model.r);
				var _n3 = model.bl;
				if (_n3.b) {
					var x = _n3.a;
					var xs = _n3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bl: _Utils_ap(
									xs,
									(!result) ? _List_fromArray(
										[model.n]) : _List_Nil),
								n: x,
								r: 3,
								x: elm$core$Maybe$Nothing,
								T: false
							}),
						A2(
							elm$random$Random$generate,
							author$project$Exercises$KeySignatures$NewClef,
							elm_community$random_extra$Random$Array$sample(
								elm$core$Array$fromList(model.aU))));
				} else {
					return result ? author$project$Exercises$KeySignatures$initUpdate(model) : _Utils_Tuple2(
						_Utils_update(
							model,
							{r: 3, x: elm$core$Maybe$Nothing, T: false}),
						elm$core$Platform$Cmd$none);
				}
			case 6:
				var result = _Utils_eq(
					elm$core$Maybe$Just(model.n.dU),
					model.x) && _Utils_eq(model.n.dk, model.r);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aS: model.aS + (result ? 1 : 0),
							T: true
						}),
					elm$core$Platform$Cmd$none);
			case 3:
				var note = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{x: note}),
					elm$core$Platform$Cmd$none);
			case 4:
				var accidental = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{r: accidental}),
					elm$core$Platform$Cmd$none);
			default:
				return author$project$Exercises$KeySignatures$initUpdate(model);
		}
	});
var author$project$Theory$TheoryEngine$noteNameToString = function (note) {
	switch (note) {
		case 0:
			return 'C';
		case 1:
			return 'D';
		case 2:
			return 'E';
		case 3:
			return 'F';
		case 4:
			return 'G';
		case 5:
			return 'A';
		case 6:
			return 'B';
		default:
			return 'invalid';
	}
};
var author$project$Exercises$Notes$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 4:
				var deck = msg.a;
				if (deck.b) {
					var x = deck.a;
					var xs = deck.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{F: x, bo: xs}),
						elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 0:
				var result = _Utils_eq(
					model.x,
					author$project$Theory$TheoryEngine$noteNameToString(model.F.dU.dT));
				var _n2 = model.bo;
				if (_n2.b) {
					var x = _n2.a;
					var xs = _n2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								F: x,
								bo: _Utils_ap(
									xs,
									(!result) ? _List_fromArray(
										[model.F]) : _List_Nil),
								x: '',
								T: false
							}),
						elm$core$Platform$Cmd$none);
				} else {
					return result ? _Utils_Tuple2(
						author$project$Exercises$Notes$initModel(model.ap),
						author$project$Exercises$Notes$shuffle(model.ap)) : _Utils_Tuple2(
						_Utils_update(
							model,
							{x: '', T: false}),
						elm$core$Platform$Cmd$none);
				}
			case 1:
				var result = _Utils_eq(
					model.x,
					author$project$Theory$TheoryEngine$noteNameToString(model.F.dU.dT));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aS: model.aS + (result ? 1 : 0),
							T: true
						}),
					elm$core$Platform$Cmd$none);
			case 2:
				var note = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{x: note}),
					elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					author$project$Exercises$Notes$initModel(model.ap),
					author$project$Exercises$Notes$shuffle(model.ap));
		}
	});
var author$project$Exercises$Sevenths$HistoryItem = F4(
	function (clef, root, inversion, quality) {
		return {bK: clef, _: inversion, d1: quality, a2: root};
	});
var author$project$Exercises$Sevenths$NewInversion = function (a) {
	return {$: 3, a: a};
};
var author$project$Exercises$Sevenths$NewNote = function (a) {
	return {$: 1, a: a};
};
var author$project$Exercises$Sevenths$NewQuality = function (a) {
	return {$: 4, a: a};
};
var author$project$Theory$TheoryEngine$notesLT = F2(
	function (note1, note2) {
		return !A2(author$project$Theory$TheoryEngine$compareNotes, note1, note2);
	});
var author$project$Theory$TheoryEngine$notesMin = F2(
	function (note1, note2) {
		return A2(author$project$Theory$TheoryEngine$notesLT, note1, note2) ? note1 : note2;
	});
var author$project$Theory$TheoryEngine$notesMinimum = function (notes) {
	if (notes.b) {
		var x = notes.a;
		var xs = notes.b;
		return elm$core$Maybe$Just(
			A3(elm$core$List$foldl, author$project$Theory$TheoryEngine$notesMin, x, xs));
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var author$project$Exercises$Sevenths$possibleNotes = F2(
	function (mClef, inversion) {
		var quality = 2;
		var noteList = function () {
			if (!mClef.$) {
				var clef = mClef.a;
				return author$project$Theory$RenderEngine$clefRangeList(clef);
			} else {
				return _List_Nil;
			}
		}();
		var mTopNote = author$project$Theory$TheoryEngine$notesMaximum(noteList);
		var mBottomNote = author$project$Theory$TheoryEngine$notesMinimum(noteList);
		var intervalUp = function () {
			switch (inversion) {
				case 0:
					return 7;
				case 1:
					return 1;
				case 2:
					return 3;
				default:
					return 5;
			}
		}();
		var intervalDown = function () {
			switch (inversion) {
				case 0:
					return 1;
				case 1:
					return 6;
				case 2:
					return 4;
				default:
					return 2;
			}
		}();
		if (mTopNote.$ === 1) {
			return _List_Nil;
		} else {
			var topNote = mTopNote.a;
			if (mBottomNote.$ === 1) {
				return _List_Nil;
			} else {
				var bottomNote = mBottomNote.a;
				var cutOffUp = A3(
					author$project$Theory$TheoryEngine$transposeNote,
					A2(author$project$Theory$TheoryEngine$IntervalName, quality, intervalUp),
					1,
					topNote);
				var cutOffDown = A3(
					author$project$Theory$TheoryEngine$transposeNote,
					A2(author$project$Theory$TheoryEngine$IntervalName, quality, intervalDown),
					0,
					bottomNote);
				return A2(
					elm$core$List$filter,
					function (x) {
						return A2(author$project$Theory$TheoryEngine$notesGTE, cutOffUp, x) && A2(author$project$Theory$TheoryEngine$notesLTE, cutOffDown, x);
					},
					noteList);
			}
		}
	});
var author$project$Theory$TheoryEngine$MinMaj = 3;
var author$project$Exercises$Sevenths$triadQualities = function (note) {
	var _n0 = note.dT;
	switch (_n0) {
		case 0:
			var _n1 = note.dk;
			switch (_n1) {
				case 4:
					return _List_fromArray(
						[4, 5, 3, 2, 0, 1]);
				case 1:
					return _List_fromArray(
						[4]);
				case 3:
					return _List_fromArray(
						[4, 5, 3, 2, 0, 1]);
				case 2:
					return _List_fromArray(
						[4, 5, 3, 2, 0, 1]);
				case 5:
					return _List_Nil;
				default:
					return _List_Nil;
			}
		case 1:
			var _n2 = note.dk;
			switch (_n2) {
				case 4:
					return _List_fromArray(
						[5, 3, 1]);
				case 1:
					return _List_fromArray(
						[4, 5, 3, 2, 0]);
				case 3:
					return _List_fromArray(
						[4, 5, 3, 2, 0, 1]);
				case 2:
					return _List_fromArray(
						[4, 5, 3, 2, 0, 1]);
				case 5:
					return _List_Nil;
				default:
					return _List_Nil;
			}
		case 2:
			var _n3 = note.dk;
			switch (_n3) {
				case 4:
					return _List_fromArray(
						[1]);
				case 1:
					return _List_fromArray(
						[4, 5, 3, 2, 0]);
				case 3:
					return _List_fromArray(
						[4, 5, 3, 2, 0, 1]);
				case 2:
					return _List_fromArray(
						[4, 5, 3, 2, 0, 1]);
				case 5:
					return _List_Nil;
				default:
					return _List_Nil;
			}
		case 3:
			var _n4 = note.dk;
			switch (_n4) {
				case 4:
					return _List_fromArray(
						[4, 5, 3, 2, 0, 1]);
				case 1:
					return _List_Nil;
				case 3:
					return _List_fromArray(
						[4, 5, 3, 2, 0, 1]);
				case 2:
					return _List_fromArray(
						[4, 5, 3, 2, 0, 1]);
				case 5:
					return _List_Nil;
				default:
					return _List_Nil;
			}
		case 4:
			var _n5 = note.dk;
			switch (_n5) {
				case 4:
					return _List_fromArray(
						[5, 3, 1, 2]);
				case 1:
					return _List_fromArray(
						[4, 0, 2]);
				case 3:
					return _List_fromArray(
						[4, 5, 3, 2, 0, 1]);
				case 2:
					return _List_fromArray(
						[4, 5, 3, 2, 0, 1]);
				case 5:
					return _List_Nil;
				default:
					return _List_Nil;
			}
		case 5:
			var _n6 = note.dk;
			switch (_n6) {
				case 4:
					return _List_fromArray(
						[5, 3, 1]);
				case 1:
					return _List_fromArray(
						[4, 0, 2]);
				case 3:
					return _List_fromArray(
						[4, 5, 3, 2, 0, 1]);
				case 2:
					return _List_fromArray(
						[4, 5, 3, 2, 0, 1]);
				case 5:
					return _List_Nil;
				default:
					return _List_Nil;
			}
		case 6:
			var _n7 = note.dk;
			switch (_n7) {
				case 4:
					return _List_fromArray(
						[1]);
				case 1:
					return _List_fromArray(
						[4, 5, 3, 2, 0, 1]);
				case 3:
					return _List_fromArray(
						[4, 5, 3, 2, 0, 1]);
				case 2:
					return _List_fromArray(
						[4, 5, 3, 2, 0, 1]);
				case 5:
					return _List_Nil;
				default:
					return _List_Nil;
			}
		default:
			return _List_Nil;
	}
};
var author$project$Exercises$Sevenths$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aF: elm$core$Maybe$Nothing, aG: elm$core$Maybe$Nothing, T: false}),
					author$project$Exercises$Sevenths$initCmd(model.aU));
			case 2:
				var mClef = msg.a;
				if (!mClef.$) {
					var clef = mClef.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bK: elm$core$Maybe$Just(clef)
							}),
						A2(
							elm$random$Random$generate,
							author$project$Exercises$Sevenths$NewInversion,
							elm_community$random_extra$Random$Array$sample(
								elm$core$Array$fromList(model.bQ))));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 3:
				var mInversion = msg.a;
				if (!mInversion.$) {
					var inversion = mInversion.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								_: elm$core$Maybe$Just(inversion)
							}),
						A2(
							elm$random$Random$generate,
							author$project$Exercises$Sevenths$NewNote,
							elm_community$random_extra$Random$Array$sample(
								elm$core$Array$fromList(
									A2(author$project$Exercises$Sevenths$possibleNotes, model.bK, inversion)))));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 1:
				var mNote = msg.a;
				if (!mNote.$) {
					var note = mNote.a;
					var qualities = A2(
						elm$core$List$filter,
						function (x) {
							return A2(elm$core$List$member, x, model.bw);
						},
						author$project$Exercises$Sevenths$triadQualities(note));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								a2: elm$core$Maybe$Just(note)
							}),
						(elm$core$List$length(qualities) >= 1) ? A2(
							elm$random$Random$generate,
							author$project$Exercises$Sevenths$NewQuality,
							elm_community$random_extra$Random$Array$sample(
								elm$core$Array$fromList(qualities))) : author$project$Exercises$Sevenths$initCmd(model.aU));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 4:
				var mQuality = msg.a;
				if (!mQuality.$) {
					var quality = mQuality.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d1: elm$core$Maybe$Just(quality)
							}),
						A2(
							elm$core$List$member,
							A4(
								author$project$Exercises$Sevenths$HistoryItem,
								model.bK,
								model.a2,
								model._,
								elm$core$Maybe$Just(quality)),
							model.aw) ? author$project$Exercises$Sevenths$initCmd(model.aU) : elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 6:
				var mQuality = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aF: mQuality}),
					elm$core$Platform$Cmd$none);
			case 5:
				var mNote = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aG: mNote}),
					elm$core$Platform$Cmd$none);
			case 7:
				return _Utils_Tuple2(
					A4(author$project$Exercises$Sevenths$init, model.bQ, model.bw, model.aU, model.aW),
					author$project$Exercises$Sevenths$initCmd(model.aU));
			default:
				var resultQuality = function () {
					var _n6 = model.aF;
					if (!_n6.$) {
						var i = _n6.a;
						return _Utils_eq(
							model.d1,
							elm$core$Maybe$Just(i));
					} else {
						return false;
					}
				}();
				var resultInterval = function () {
					var _n5 = model.aG;
					if (!_n5.$) {
						var i = _n5.a;
						return _Utils_eq(
							model.a2,
							elm$core$Maybe$Just(i));
					} else {
						return false;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aw: (resultInterval && resultQuality) ? _Utils_ap(
								_List_fromArray(
									[
										A4(author$project$Exercises$Sevenths$HistoryItem, model.bK, model.a2, model._, model.d1)
									]),
								model.aw) : model.aw,
							T: true
						}),
					elm$core$Platform$Cmd$none);
		}
	});
var author$project$Exercises$Triads$HistoryItem = F4(
	function (clef, root, inversion, quality) {
		return {bK: clef, _: inversion, d1: quality, a2: root};
	});
var author$project$Exercises$Triads$NewInversion = function (a) {
	return {$: 3, a: a};
};
var author$project$Exercises$Triads$NewNote = function (a) {
	return {$: 1, a: a};
};
var author$project$Exercises$Triads$NewQuality = function (a) {
	return {$: 4, a: a};
};
var author$project$Exercises$Triads$possibleNotes = F2(
	function (mClef, inversion) {
		var quality = 2;
		var noteList = function () {
			if (!mClef.$) {
				var clef = mClef.a;
				return author$project$Theory$RenderEngine$clefRangeList(clef);
			} else {
				return _List_Nil;
			}
		}();
		var mTopNote = author$project$Theory$TheoryEngine$notesMaximum(noteList);
		var mBottomNote = author$project$Theory$TheoryEngine$notesMinimum(noteList);
		var intervalUp = function () {
			switch (inversion) {
				case 0:
					return 5;
				case 1:
					return 1;
				case 2:
					return 3;
				default:
					return 1;
			}
		}();
		var intervalDown = function () {
			switch (inversion) {
				case 0:
					return 1;
				case 1:
					return 6;
				case 2:
					return 4;
				default:
					return 1;
			}
		}();
		if (mTopNote.$ === 1) {
			return _List_Nil;
		} else {
			var topNote = mTopNote.a;
			if (mBottomNote.$ === 1) {
				return _List_Nil;
			} else {
				var bottomNote = mBottomNote.a;
				var cutOffUp = A3(
					author$project$Theory$TheoryEngine$transposeNote,
					A2(author$project$Theory$TheoryEngine$IntervalName, quality, intervalUp),
					1,
					topNote);
				var cutOffDown = A3(
					author$project$Theory$TheoryEngine$transposeNote,
					A2(author$project$Theory$TheoryEngine$IntervalName, quality, intervalDown),
					0,
					bottomNote);
				return A2(
					elm$core$List$filter,
					function (x) {
						return A2(author$project$Theory$TheoryEngine$notesGTE, cutOffUp, x) && A2(author$project$Theory$TheoryEngine$notesLTE, cutOffDown, x);
					},
					noteList);
			}
		}
	});
var author$project$Exercises$Triads$triadQualities = function (note) {
	var _n0 = note.dT;
	switch (_n0) {
		case 0:
			var _n1 = note.dk;
			switch (_n1) {
				case 4:
					return _List_fromArray(
						[2, 1, 3, 0]);
				case 1:
					return _List_fromArray(
						[2]);
				case 3:
					return _List_fromArray(
						[2, 1, 3, 0]);
				case 2:
					return _List_fromArray(
						[2, 1, 3, 0]);
				case 5:
					return _List_Nil;
				default:
					return _List_Nil;
			}
		case 1:
			var _n2 = note.dk;
			switch (_n2) {
				case 4:
					return _List_fromArray(
						[1, 0]);
				case 1:
					return _List_fromArray(
						[2, 1, 3]);
				case 3:
					return _List_fromArray(
						[2, 1, 3, 0]);
				case 2:
					return _List_fromArray(
						[2, 1, 3, 0]);
				case 5:
					return _List_Nil;
				default:
					return _List_Nil;
			}
		case 2:
			var _n3 = note.dk;
			switch (_n3) {
				case 4:
					return _List_fromArray(
						[0]);
				case 1:
					return _List_fromArray(
						[2, 1, 3]);
				case 3:
					return _List_fromArray(
						[2, 1, 3, 0]);
				case 2:
					return _List_fromArray(
						[2, 1, 3, 0]);
				case 5:
					return _List_Nil;
				default:
					return _List_Nil;
			}
		case 3:
			var _n4 = note.dk;
			switch (_n4) {
				case 4:
					return _List_fromArray(
						[2, 1, 0, 3]);
				case 1:
					return _List_Nil;
				case 3:
					return _List_fromArray(
						[2, 1, 3, 0]);
				case 2:
					return _List_fromArray(
						[2, 1, 3, 0]);
				case 5:
					return _List_Nil;
				default:
					return _List_Nil;
			}
		case 4:
			var _n5 = note.dk;
			switch (_n5) {
				case 4:
					return _List_fromArray(
						[1, 0]);
				case 1:
					return _List_fromArray(
						[2, 3]);
				case 3:
					return _List_fromArray(
						[2, 1, 3, 0]);
				case 2:
					return _List_fromArray(
						[2, 1, 3, 0]);
				case 5:
					return _List_Nil;
				default:
					return _List_Nil;
			}
		case 5:
			var _n6 = note.dk;
			switch (_n6) {
				case 4:
					return _List_fromArray(
						[1, 0]);
				case 1:
					return _List_fromArray(
						[2, 3]);
				case 3:
					return _List_fromArray(
						[2, 1, 3, 0]);
				case 2:
					return _List_fromArray(
						[2, 1, 3, 0]);
				case 5:
					return _List_Nil;
				default:
					return _List_Nil;
			}
		case 6:
			var _n7 = note.dk;
			switch (_n7) {
				case 4:
					return _List_fromArray(
						[0]);
				case 1:
					return _List_fromArray(
						[2, 1, 3, 0]);
				case 3:
					return _List_fromArray(
						[2, 1, 3, 0]);
				case 2:
					return _List_fromArray(
						[2, 1, 3, 0]);
				case 5:
					return _List_Nil;
				default:
					return _List_Nil;
			}
		default:
			return _List_Nil;
	}
};
var author$project$Exercises$Triads$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aF: elm$core$Maybe$Nothing, aG: elm$core$Maybe$Nothing, T: false}),
					author$project$Exercises$Triads$initCmd(model.aU));
			case 2:
				var mClef = msg.a;
				if (!mClef.$) {
					var clef = mClef.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bK: elm$core$Maybe$Just(clef)
							}),
						A2(
							elm$random$Random$generate,
							author$project$Exercises$Triads$NewInversion,
							elm_community$random_extra$Random$Array$sample(
								elm$core$Array$fromList(model.bQ))));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 3:
				var mInversion = msg.a;
				if (!mInversion.$) {
					var inversion = mInversion.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								_: elm$core$Maybe$Just(inversion)
							}),
						A2(
							elm$random$Random$generate,
							author$project$Exercises$Triads$NewNote,
							elm_community$random_extra$Random$Array$sample(
								elm$core$Array$fromList(
									A2(author$project$Exercises$Triads$possibleNotes, model.bK, inversion)))));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 1:
				var mNote = msg.a;
				if (!mNote.$) {
					var note = mNote.a;
					var qualities = A2(
						elm$core$List$filter,
						function (x) {
							return A2(elm$core$List$member, x, model.bw);
						},
						author$project$Exercises$Triads$triadQualities(note));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								a2: elm$core$Maybe$Just(note)
							}),
						(elm$core$List$length(qualities) >= 1) ? A2(
							elm$random$Random$generate,
							author$project$Exercises$Triads$NewQuality,
							elm_community$random_extra$Random$Array$sample(
								elm$core$Array$fromList(qualities))) : author$project$Exercises$Triads$initCmd(model.aU));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 4:
				var mQuality = msg.a;
				if (!mQuality.$) {
					var quality = mQuality.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								d1: elm$core$Maybe$Just(quality)
							}),
						A2(
							elm$core$List$member,
							A4(
								author$project$Exercises$Triads$HistoryItem,
								model.bK,
								model.a2,
								model._,
								elm$core$Maybe$Just(quality)),
							model.aw) ? author$project$Exercises$Triads$initCmd(model.aU) : elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 6:
				var mQuality = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aF: mQuality}),
					elm$core$Platform$Cmd$none);
			case 5:
				var mNote = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aG: mNote}),
					elm$core$Platform$Cmd$none);
			case 7:
				return _Utils_Tuple2(
					A4(author$project$Exercises$Triads$init, model.bQ, model.bw, model.aU, model.aW),
					author$project$Exercises$Triads$initCmd(model.aU));
			default:
				var resultQuality = function () {
					var _n6 = model.aF;
					if (!_n6.$) {
						var i = _n6.a;
						return _Utils_eq(
							model.d1,
							elm$core$Maybe$Just(i));
					} else {
						return false;
					}
				}();
				var resultInterval = function () {
					var _n5 = model.aG;
					if (!_n5.$) {
						var i = _n5.a;
						return _Utils_eq(
							model.a2,
							elm$core$Maybe$Just(i));
					} else {
						return false;
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aw: (resultInterval && resultQuality) ? _Utils_ap(
								_List_fromArray(
									[
										A4(author$project$Exercises$Triads$HistoryItem, model.bK, model.a2, model._, model.d1)
									]),
								model.aw) : model.aw,
							T: true
						}),
					elm$core$Platform$Cmd$none);
		}
	});
var author$project$Page$Content$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bB: !model.bB}),
					elm$core$Platform$Cmd$none);
			case 1:
				var n = msg.a;
				var _n1 = model.h;
				if (!_n1.$) {
					var noteModel = _n1.a;
					var _n2 = A2(author$project$Exercises$Notes$update, n, noteModel);
					var m = _n2.a;
					var c = _n2.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h: author$project$Page$Content$NoteModel(m)
							}),
						A2(elm$core$Platform$Cmd$map, author$project$Page$Content$NoteMsg, c));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 2:
				var n = msg.a;
				var _n3 = model.h;
				if (_n3.$ === 1) {
					var noteModel = _n3.a;
					var _n4 = A2(author$project$Exercises$KeyNotes$update, n, noteModel);
					var m = _n4.a;
					var c = _n4.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h: author$project$Page$Content$KeyNoteModel(m)
							}),
						A2(elm$core$Platform$Cmd$map, author$project$Page$Content$KeyNoteMsg, c));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 3:
				var n = msg.a;
				var _n5 = model.h;
				if (_n5.$ === 2) {
					var biModel = _n5.a;
					var _n6 = A2(author$project$Exercises$BasicIntervals$update, n, biModel);
					var m = _n6.a;
					var c = _n6.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h: author$project$Page$Content$BasicIntervalModel(m)
							}),
						A2(elm$core$Platform$Cmd$map, author$project$Page$Content$BasicIntervalsMsg, c));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 4:
				var n = msg.a;
				var _n7 = model.h;
				if (_n7.$ === 3) {
					var kbiModel = _n7.a;
					var _n8 = A2(author$project$Exercises$KeyBasicIntervals$update, n, kbiModel);
					var m = _n8.a;
					var c = _n8.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h: author$project$Page$Content$KeyBasicIntervalModel(m)
							}),
						A2(elm$core$Platform$Cmd$map, author$project$Page$Content$KeyBasicIntervalsMsg, c));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 5:
				var n = msg.a;
				var _n9 = model.h;
				if (_n9.$ === 4) {
					var iModel = _n9.a;
					var _n10 = A2(author$project$Exercises$Intervals$update, n, iModel);
					var m = _n10.a;
					var c = _n10.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h: author$project$Page$Content$IntervalModel(m)
							}),
						A2(elm$core$Platform$Cmd$map, author$project$Page$Content$IntervalsMsg, c));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 6:
				var n = msg.a;
				var _n11 = model.h;
				if (_n11.$ === 5) {
					var iModel = _n11.a;
					var _n12 = A2(author$project$Exercises$KeyIntervals$update, n, iModel);
					var m = _n12.a;
					var c = _n12.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h: author$project$Page$Content$KeyIntervalModel(m)
							}),
						A2(elm$core$Platform$Cmd$map, author$project$Page$Content$KeyIntervalsMsg, c));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 7:
				var n = msg.a;
				var _n13 = model.h;
				if (_n13.$ === 6) {
					var iModel = _n13.a;
					var _n14 = A2(author$project$Exercises$Triads$update, n, iModel);
					var m = _n14.a;
					var c = _n14.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h: author$project$Page$Content$TriadModel(m)
							}),
						A2(elm$core$Platform$Cmd$map, author$project$Page$Content$TriadsMsg, c));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 8:
				var n = msg.a;
				var _n15 = model.h;
				if (_n15.$ === 7) {
					var iModel = _n15.a;
					var _n16 = A2(author$project$Exercises$Sevenths$update, n, iModel);
					var m = _n16.a;
					var c = _n16.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h: author$project$Page$Content$SeventhModel(m)
							}),
						A2(elm$core$Platform$Cmd$map, author$project$Page$Content$SeventhsMsg, c));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			default:
				var n = msg.a;
				var _n17 = model.h;
				if (_n17.$ === 8) {
					var iModel = _n17.a;
					var _n18 = A2(author$project$Exercises$KeySignatures$update, n, iModel);
					var m = _n18.a;
					var c = _n18.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								h: author$project$Page$Content$KeySigModel(m)
							}),
						A2(elm$core$Platform$Cmd$map, author$project$Page$Content$KeySigMsg, c));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
		}
	});
var author$project$Page$Course$update = F2(
	function (msg, model) {
		return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
	});
var author$project$Page$Home$update = F2(
	function (msg, model) {
		return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
	});
var elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var elm$browser$Browser$Dom$NotFound = elm$core$Basics$identity;
var elm$core$Basics$never = function (_n0) {
	never:
	while (true) {
		var nvr = _n0;
		var $temp$_n0 = nvr;
		_n0 = $temp$_n0;
		continue never;
	}
};
var elm$core$Task$Perform = elm$core$Basics$identity;
var elm$core$Task$init = elm$core$Task$succeed(0);
var elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			elm$core$Task$andThen,
			function (a) {
				return elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			elm$core$Task$andThen,
			function (a) {
				return A2(
					elm$core$Task$andThen,
					function (b) {
						return elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var elm$core$Task$sequence = function (tasks) {
	return A3(
		elm$core$List$foldr,
		elm$core$Task$map2(elm$core$List$cons),
		elm$core$Task$succeed(_List_Nil),
		tasks);
};
var elm$core$Task$spawnCmd = F2(
	function (router, _n0) {
		var task = _n0;
		return _Scheduler_spawn(
			A2(
				elm$core$Task$andThen,
				elm$core$Platform$sendToApp(router),
				task));
	});
var elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			elm$core$Task$map,
			function (_n0) {
				return 0;
			},
			elm$core$Task$sequence(
				A2(
					elm$core$List$map,
					elm$core$Task$spawnCmd(router),
					commands)));
	});
var elm$core$Task$onSelfMsg = F3(
	function (_n0, _n1, _n2) {
		return elm$core$Task$succeed(0);
	});
var elm$core$Task$cmdMap = F2(
	function (tagger, _n0) {
		var task = _n0;
		return A2(elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager(elm$core$Task$init, elm$core$Task$onEffects, elm$core$Task$onSelfMsg, elm$core$Task$cmdMap);
var elm$core$Task$command = _Platform_leaf('Task');
var elm$core$Task$perform = F2(
	function (toMessage, task) {
		return elm$core$Task$command(
			A2(elm$core$Task$map, toMessage, task));
	});
var elm$json$Json$Decode$map = _Json_map1;
var elm$json$Json$Decode$map2 = _Json_map2;
var elm$json$Json$Decode$succeed = _Json_succeed;
var elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var elm$core$String$length = _String_length;
var elm$core$String$slice = _String_slice;
var elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			elm$core$String$slice,
			n,
			elm$core$String$length(string),
			string);
	});
var elm$core$String$startsWith = _String_startsWith;
var elm$url$Url$Http = 0;
var elm$url$Url$Https = 1;
var elm$core$String$indexes = _String_indexes;
var elm$core$String$isEmpty = function (string) {
	return string === '';
};
var elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(elm$core$String$slice, 0, n, string);
	});
var elm$core$String$contains = _String_contains;
var elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {cu: fragment, cA: host, cS: path, cV: port_, cZ: protocol, c_: query};
	});
var elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if (elm$core$String$isEmpty(str) || A2(elm$core$String$contains, '@', str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, ':', str);
			if (!_n0.b) {
				return elm$core$Maybe$Just(
					A6(elm$url$Url$Url, protocol, str, elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_n0.b.b) {
					var i = _n0.a;
					var _n1 = elm$core$String$toInt(
						A2(elm$core$String$dropLeft, i + 1, str));
					if (_n1.$ === 1) {
						return elm$core$Maybe$Nothing;
					} else {
						var port_ = _n1;
						return elm$core$Maybe$Just(
							A6(
								elm$url$Url$Url,
								protocol,
								A2(elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return elm$core$Maybe$Nothing;
				}
			}
		}
	});
var elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if (elm$core$String$isEmpty(str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, '/', str);
			if (!_n0.b) {
				return A5(elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _n0.a;
				return A5(
					elm$url$Url$chompBeforePath,
					protocol,
					A2(elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2(elm$core$String$left, i, str));
			}
		}
	});
var elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if (elm$core$String$isEmpty(str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, '?', str);
			if (!_n0.b) {
				return A4(elm$url$Url$chompBeforeQuery, protocol, elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _n0.a;
				return A4(
					elm$url$Url$chompBeforeQuery,
					protocol,
					elm$core$Maybe$Just(
						A2(elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2(elm$core$String$left, i, str));
			}
		}
	});
var elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if (elm$core$String$isEmpty(str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, '#', str);
			if (!_n0.b) {
				return A3(elm$url$Url$chompBeforeFragment, protocol, elm$core$Maybe$Nothing, str);
			} else {
				var i = _n0.a;
				return A3(
					elm$url$Url$chompBeforeFragment,
					protocol,
					elm$core$Maybe$Just(
						A2(elm$core$String$dropLeft, i + 1, str)),
					A2(elm$core$String$left, i, str));
			}
		}
	});
var elm$url$Url$fromString = function (str) {
	return A2(elm$core$String$startsWith, 'http://', str) ? A2(
		elm$url$Url$chompAfterProtocol,
		0,
		A2(elm$core$String$dropLeft, 7, str)) : (A2(elm$core$String$startsWith, 'https://', str) ? A2(
		elm$url$Url$chompAfterProtocol,
		1,
		A2(elm$core$String$dropLeft, 8, str)) : elm$core$Maybe$Nothing);
};
var elm$browser$Browser$Navigation$load = _Browser_load;
var elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + elm$core$String$fromInt(port_));
		}
	});
var elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var elm$url$Url$toString = function (url) {
	var http = function () {
		var _n0 = url.cZ;
		if (!_n0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		elm$url$Url$addPrefixed,
		'#',
		url.cu,
		A3(
			elm$url$Url$addPrefixed,
			'?',
			url.c_,
			_Utils_ap(
				A2(
					elm$url$Url$addPort,
					url.cV,
					_Utils_ap(http, url.cA)),
				url.cS)));
};
var author$project$Main$update = F2(
	function (message, model) {
		switch (message.$) {
			case 0:
				return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
			case 1:
				var urlRequest = message.a;
				if (!urlRequest.$) {
					var url = urlRequest.a;
					return _Utils_Tuple2(
						model,
						A2(
							elm$browser$Browser$Navigation$pushUrl,
							model.bR,
							elm$url$Url$toString(url)));
				} else {
					var href = urlRequest.a;
					return _Utils_Tuple2(
						model,
						elm$browser$Browser$Navigation$load(href));
				}
			case 2:
				var url = message.a;
				return A2(author$project$Main$stepUrl, url, model);
			case 3:
				var msg = message.a;
				var _n2 = model.C;
				if (!_n2.$) {
					var course = _n2.a;
					return A2(
						author$project$Main$stepCourse,
						model,
						A2(author$project$Page$Course$update, msg, course));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 4:
				var msg = message.a;
				var _n3 = model.C;
				if (_n3.$ === 1) {
					var content = _n3.a;
					return A2(
						author$project$Main$stepContent,
						model,
						A2(author$project$Page$Content$update, msg, content));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			default:
				var msg = message.a;
				var _n4 = model.C;
				if (_n4.$ === 3) {
					var home = _n4.a;
					return A2(
						author$project$Main$stepHome,
						model,
						A2(author$project$Page$Home$update, msg, home));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
		}
	});
var author$project$Icons$FeatherIcons$Icon = elm$core$Basics$identity;
var author$project$Icons$FeatherIcons$defaultAttributes = function (name) {
	return {
		bd: elm$core$Maybe$Just('feather feather-' + name),
		bC: 24,
		a4: '',
		bD: 2,
		bG: '0 0 24 24'
	};
};
var author$project$Icons$FeatherIcons$makeBuilder = F2(
	function (name, src) {
		return {
			p: author$project$Icons$FeatherIcons$defaultAttributes(name),
			t: src
		};
	});
var rtfeldman$elm_css$VirtualDom$Styled$NodeNS = F4(
	function (a, b, c, d) {
		return {$: 1, a: a, b: b, c: c, d: d};
	});
var rtfeldman$elm_css$VirtualDom$Styled$nodeNS = rtfeldman$elm_css$VirtualDom$Styled$NodeNS;
var rtfeldman$elm_css$Svg$Styled$node = rtfeldman$elm_css$VirtualDom$Styled$nodeNS('http://www.w3.org/2000/svg');
var rtfeldman$elm_css$Svg$Styled$path = rtfeldman$elm_css$Svg$Styled$node('path');
var rtfeldman$elm_css$Svg$Styled$polyline = rtfeldman$elm_css$Svg$Styled$node('polyline');
var elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var rtfeldman$elm_css$VirtualDom$Styled$Attribute = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var rtfeldman$elm_css$VirtualDom$Styled$attribute = F2(
	function (key, value) {
		return A3(
			rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2(elm$virtual_dom$VirtualDom$attribute, key, value),
			_List_Nil,
			'');
	});
var rtfeldman$elm_css$Svg$Styled$Attributes$d = rtfeldman$elm_css$VirtualDom$Styled$attribute('d');
var rtfeldman$elm_css$Svg$Styled$Attributes$points = rtfeldman$elm_css$VirtualDom$Styled$attribute('points');
var author$project$Icons$FeatherIcons$home = A2(
	author$project$Icons$FeatherIcons$makeBuilder,
	'home',
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$d('M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$polyline,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$points('9 22 9 12 15 12 15 22')
				]),
			_List_Nil)
		]));
var elm$core$String$fromFloat = _String_fromNumber;
var elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var rtfeldman$elm_css$VirtualDom$Styled$KeyedNode = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var rtfeldman$elm_css$VirtualDom$Styled$KeyedNodeNS = F4(
	function (a, b, c, d) {
		return {$: 3, a: a, b: b, c: c, d: d};
	});
var rtfeldman$elm_css$VirtualDom$Styled$Node = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var rtfeldman$elm_css$VirtualDom$Styled$Unstyled = function (a) {
	return {$: 4, a: a};
};
var elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var rtfeldman$elm_css$VirtualDom$Styled$mapAttribute = F2(
	function (transform, _n0) {
		var prop = _n0.a;
		var styles = _n0.b;
		var classname = _n0.c;
		return A3(
			rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2(elm$virtual_dom$VirtualDom$mapAttribute, transform, prop),
			styles,
			classname);
	});
var rtfeldman$elm_css$VirtualDom$Styled$map = F2(
	function (transform, vdomNode) {
		switch (vdomNode.$) {
			case 0:
				var elemType = vdomNode.a;
				var properties = vdomNode.b;
				var children = vdomNode.c;
				return A3(
					rtfeldman$elm_css$VirtualDom$Styled$Node,
					elemType,
					A2(
						elm$core$List$map,
						rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						elm$core$List$map,
						rtfeldman$elm_css$VirtualDom$Styled$map(transform),
						children));
			case 1:
				var ns = vdomNode.a;
				var elemType = vdomNode.b;
				var properties = vdomNode.c;
				var children = vdomNode.d;
				return A4(
					rtfeldman$elm_css$VirtualDom$Styled$NodeNS,
					ns,
					elemType,
					A2(
						elm$core$List$map,
						rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						elm$core$List$map,
						rtfeldman$elm_css$VirtualDom$Styled$map(transform),
						children));
			case 2:
				var elemType = vdomNode.a;
				var properties = vdomNode.b;
				var children = vdomNode.c;
				return A3(
					rtfeldman$elm_css$VirtualDom$Styled$KeyedNode,
					elemType,
					A2(
						elm$core$List$map,
						rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						elm$core$List$map,
						function (_n1) {
							var key = _n1.a;
							var child = _n1.b;
							return _Utils_Tuple2(
								key,
								A2(rtfeldman$elm_css$VirtualDom$Styled$map, transform, child));
						},
						children));
			case 3:
				var ns = vdomNode.a;
				var elemType = vdomNode.b;
				var properties = vdomNode.c;
				var children = vdomNode.d;
				return A4(
					rtfeldman$elm_css$VirtualDom$Styled$KeyedNodeNS,
					ns,
					elemType,
					A2(
						elm$core$List$map,
						rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						elm$core$List$map,
						function (_n2) {
							var key = _n2.a;
							var child = _n2.b;
							return _Utils_Tuple2(
								key,
								A2(rtfeldman$elm_css$VirtualDom$Styled$map, transform, child));
						},
						children));
			default:
				var vdom = vdomNode.a;
				return rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
					A2(elm$virtual_dom$VirtualDom$map, transform, vdom));
		}
	});
var rtfeldman$elm_css$Svg$Styled$map = rtfeldman$elm_css$VirtualDom$Styled$map;
var rtfeldman$elm_css$Svg$Styled$svg = rtfeldman$elm_css$Svg$Styled$node('svg');
var rtfeldman$elm_css$Svg$Styled$Attributes$class = rtfeldman$elm_css$VirtualDom$Styled$attribute('class');
var rtfeldman$elm_css$Svg$Styled$Attributes$fill = rtfeldman$elm_css$VirtualDom$Styled$attribute('fill');
var rtfeldman$elm_css$Svg$Styled$Attributes$height = rtfeldman$elm_css$VirtualDom$Styled$attribute('height');
var rtfeldman$elm_css$Svg$Styled$Attributes$stroke = rtfeldman$elm_css$VirtualDom$Styled$attribute('stroke');
var rtfeldman$elm_css$Svg$Styled$Attributes$strokeLinecap = rtfeldman$elm_css$VirtualDom$Styled$attribute('stroke-linecap');
var rtfeldman$elm_css$Svg$Styled$Attributes$strokeLinejoin = rtfeldman$elm_css$VirtualDom$Styled$attribute('stroke-linejoin');
var rtfeldman$elm_css$Svg$Styled$Attributes$strokeWidth = rtfeldman$elm_css$VirtualDom$Styled$attribute('stroke-width');
var rtfeldman$elm_css$Svg$Styled$Attributes$viewBox = rtfeldman$elm_css$VirtualDom$Styled$attribute('viewBox');
var rtfeldman$elm_css$Svg$Styled$Attributes$width = rtfeldman$elm_css$VirtualDom$Styled$attribute('width');
var author$project$Icons$FeatherIcons$toHtml = F2(
	function (attributes, _n0) {
		var src = _n0.t;
		var attrs = _n0.p;
		var strSize = elm$core$String$fromFloat(attrs.bC);
		var baseAttributes = _List_fromArray(
			[
				rtfeldman$elm_css$Svg$Styled$Attributes$fill('none'),
				rtfeldman$elm_css$Svg$Styled$Attributes$height(
				_Utils_ap(strSize, attrs.a4)),
				rtfeldman$elm_css$Svg$Styled$Attributes$width(
				_Utils_ap(strSize, attrs.a4)),
				rtfeldman$elm_css$Svg$Styled$Attributes$stroke('currentColor'),
				rtfeldman$elm_css$Svg$Styled$Attributes$strokeLinecap('round'),
				rtfeldman$elm_css$Svg$Styled$Attributes$strokeLinejoin('round'),
				rtfeldman$elm_css$Svg$Styled$Attributes$strokeWidth(
				elm$core$String$fromFloat(attrs.bD)),
				rtfeldman$elm_css$Svg$Styled$Attributes$viewBox(attrs.bG)
			]);
		var combinedAttributes = _Utils_ap(
			function () {
				var _n1 = attrs.bd;
				if (!_n1.$) {
					var c = _n1.a;
					return A2(
						elm$core$List$cons,
						rtfeldman$elm_css$Svg$Styled$Attributes$class(c),
						baseAttributes);
				} else {
					return baseAttributes;
				}
			}(),
			attributes);
		return A2(
			rtfeldman$elm_css$Svg$Styled$svg,
			combinedAttributes,
			A2(
				elm$core$List$map,
				rtfeldman$elm_css$Svg$Styled$map(elm$core$Basics$never),
				src));
	});
var author$project$Icons$FeatherIcons$withSize = F2(
	function (size, _n0) {
		var attrs = _n0.p;
		var src = _n0.t;
		return {
			p: _Utils_update(
				attrs,
				{bC: size}),
			t: src
		};
	});
var author$project$Icons$FeatherIcons$withSizeUnit = F2(
	function (sizeUnit, _n0) {
		var attrs = _n0.p;
		var src = _n0.t;
		return {
			p: _Utils_update(
				attrs,
				{a4: sizeUnit}),
			t: src
		};
	});
var rtfeldman$elm_css$Svg$Styled$line = rtfeldman$elm_css$Svg$Styled$node('line');
var rtfeldman$elm_css$Svg$Styled$Attributes$x1 = rtfeldman$elm_css$VirtualDom$Styled$attribute('x1');
var rtfeldman$elm_css$Svg$Styled$Attributes$x2 = rtfeldman$elm_css$VirtualDom$Styled$attribute('x2');
var rtfeldman$elm_css$Svg$Styled$Attributes$y1 = rtfeldman$elm_css$VirtualDom$Styled$attribute('y1');
var rtfeldman$elm_css$Svg$Styled$Attributes$y2 = rtfeldman$elm_css$VirtualDom$Styled$attribute('y2');
var author$project$Icons$FeatherIcons$x = A2(
	author$project$Icons$FeatherIcons$makeBuilder,
	'x',
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$line,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$x1('18'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y1('6'),
					rtfeldman$elm_css$Svg$Styled$Attributes$x2('6'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y2('18')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$line,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$x1('6'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y1('6'),
					rtfeldman$elm_css$Svg$Styled$Attributes$x2('18'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y2('18')
				]),
			_List_Nil)
		]));
var author$project$Page$Content$ToggleList = {$: 10};
var author$project$Exercises$BasicIntervals$CheckInterval = {$: 4};
var author$project$Exercises$BasicIntervals$GetRandomInterval = {$: 0};
var author$project$Exercises$BasicIntervals$Reset = {$: 5};
var author$project$Exercises$BasicIntervals$basicIntervalToString = function (interval) {
	switch (interval) {
		case 1:
			return '2nd';
		case 2:
			return '3rd';
		case 0:
			return 'Same';
		case 3:
			return '4th';
		case 4:
			return '5th';
		case 5:
			return '6th';
		case 6:
			return '7th';
		case 7:
			return 'Octave';
		case 8:
			return '9th';
		default:
			return '10th';
	}
};
var author$project$Exercises$BasicIntervals$SelectInterval = function (a) {
	return {$: 6, a: a};
};
var rtfeldman$elm_css$VirtualDom$Styled$node = rtfeldman$elm_css$VirtualDom$Styled$Node;
var rtfeldman$elm_css$Html$Styled$node = rtfeldman$elm_css$VirtualDom$Styled$node;
var rtfeldman$elm_css$Html$Styled$span = rtfeldman$elm_css$Html$Styled$node('span');
var rtfeldman$elm_css$Html$Styled$sup = rtfeldman$elm_css$Html$Styled$node('sup');
var elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var rtfeldman$elm_css$VirtualDom$Styled$text = function (str) {
	return rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
		elm$virtual_dom$VirtualDom$text(str));
};
var rtfeldman$elm_css$Svg$Styled$text = rtfeldman$elm_css$VirtualDom$Styled$text;
var author$project$Exercises$BasicIntervals$basicIntervalToLabel = function (interval) {
	switch (interval) {
		case 1:
			return A2(
				rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$text(
						author$project$Exercises$BasicIntervals$basicIntervalToString(interval))
					]));
		case 2:
			return A2(
				rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$text(
						author$project$Exercises$BasicIntervals$basicIntervalToString(interval))
					]));
		case 0:
			return A2(
				rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$text(
						author$project$Exercises$BasicIntervals$basicIntervalToString(interval))
					]));
		case 3:
			return A2(
				rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$text('4'),
						A2(
						rtfeldman$elm_css$Html$Styled$sup,
						_List_Nil,
						_List_fromArray(
							[
								rtfeldman$elm_css$Svg$Styled$text('th')
							]))
					]));
		case 4:
			return A2(
				rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$text('5'),
						A2(
						rtfeldman$elm_css$Html$Styled$sup,
						_List_Nil,
						_List_fromArray(
							[
								rtfeldman$elm_css$Svg$Styled$text('th')
							]))
					]));
		case 5:
			return A2(
				rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$text('6'),
						A2(
						rtfeldman$elm_css$Html$Styled$sup,
						_List_Nil,
						_List_fromArray(
							[
								rtfeldman$elm_css$Svg$Styled$text('th')
							]))
					]));
		case 6:
			return A2(
				rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$text('7'),
						A2(
						rtfeldman$elm_css$Html$Styled$sup,
						_List_Nil,
						_List_fromArray(
							[
								rtfeldman$elm_css$Svg$Styled$text('th')
							]))
					]));
		case 7:
			return A2(
				rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$text(
						author$project$Exercises$BasicIntervals$basicIntervalToString(interval))
					]));
		case 8:
			return A2(
				rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$text('9'),
						A2(
						rtfeldman$elm_css$Html$Styled$sup,
						_List_Nil,
						_List_fromArray(
							[
								rtfeldman$elm_css$Svg$Styled$text('th')
							]))
					]));
		default:
			return A2(
				rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$text('10'),
						A2(
						rtfeldman$elm_css$Html$Styled$sup,
						_List_Nil,
						_List_fromArray(
							[
								rtfeldman$elm_css$Svg$Styled$text('th')
							]))
					]));
	}
};
var author$project$UI$Elements$Default = 0;
var author$project$UI$Elements$Right = 3;
var author$project$UI$Elements$Selected = 1;
var author$project$UI$Elements$Wrong = 2;
var author$project$UI$Branding$colorStr = {dh: 'D10050', di: '10849e', dj: 'D9C716', dq: '72B0D9', bL: '333', dw: 'AAA', cw: '00D00E', cx: 'EEE', cL: '7E83DF', cM: 'FFF', bT: 'D3EAFA', dO: 'B3FCB8', bU: 'FFECD5', dP: 'FCE9B5', dQ: 'DDD', d_: 'FFC67E', b0: '333', b1: 'AF296B', d2: 'FF0700', b2: '777', ed: 'FFDD7E'};
var elm$core$String$foldr = _String_foldr;
var elm$core$String$toList = function (string) {
	return A3(elm$core$String$foldr, elm$core$List$cons, _List_Nil, string);
};
var elm$core$String$cons = _String_cons;
var rtfeldman$elm_css$Css$withPrecedingHash = function (str) {
	return A2(elm$core$String$startsWith, '#', str) ? str : A2(elm$core$String$cons, '#', str);
};
var rtfeldman$elm_css$Css$Structure$Compatible = 0;
var rtfeldman$elm_css$Css$erroneousHex = function (str) {
	return {
		aR: 1,
		dq: 0,
		am: 0,
		cw: 0,
		d2: 0,
		I: rtfeldman$elm_css$Css$withPrecedingHash(str)
	};
};
var elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var elm$core$String$fromList = _String_fromList;
var elm$core$String$toLower = _String_toLower;
var elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return elm$core$Maybe$Just(xs);
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return elm$core$Result$Err(e);
		}
	});
var elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return elm$core$Result$Err(
				f(e));
		}
	});
var elm$core$Basics$pow = _Basics_pow;
var elm$core$String$fromChar = function (_char) {
	return A2(elm$core$String$cons, _char, '');
};
var rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2(elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2(elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2(elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2(elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2(elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2(elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2(elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2(elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2(elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2(elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2(elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2(elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2(elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2(elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2(elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return elm$core$Result$Err(
							elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var rtfeldman$elm_hex$Hex$fromString = function (str) {
	if (elm$core$String$isEmpty(str)) {
		return elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2(elm$core$String$startsWith, '-', str)) {
				var list = A2(
					elm$core$Maybe$withDefault,
					_List_Nil,
					elm$core$List$tail(
						elm$core$String$toList(str)));
				return A2(
					elm$core$Result$map,
					elm$core$Basics$negate,
					A3(
						rtfeldman$elm_hex$Hex$fromStringHelp,
						elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					rtfeldman$elm_hex$Hex$fromStringHelp,
					elm$core$String$length(str) - 1,
					elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2(elm$core$Result$mapError, formatError, result);
	}
};
var rtfeldman$elm_css$Css$validHex = F5(
	function (str, _n0, _n1, _n2, _n3) {
		var r1 = _n0.a;
		var r2 = _n0.b;
		var g1 = _n1.a;
		var g2 = _n1.b;
		var b1 = _n2.a;
		var b2 = _n2.b;
		var a1 = _n3.a;
		var a2 = _n3.b;
		var toResult = A2(
			elm$core$Basics$composeR,
			elm$core$String$fromList,
			A2(elm$core$Basics$composeR, elm$core$String$toLower, rtfeldman$elm_hex$Hex$fromString));
		var results = _Utils_Tuple2(
			_Utils_Tuple2(
				toResult(
					_List_fromArray(
						[r1, r2])),
				toResult(
					_List_fromArray(
						[g1, g2]))),
			_Utils_Tuple2(
				toResult(
					_List_fromArray(
						[b1, b2])),
				toResult(
					_List_fromArray(
						[a1, a2]))));
		if ((((!results.a.a.$) && (!results.a.b.$)) && (!results.b.a.$)) && (!results.b.b.$)) {
			var _n5 = results.a;
			var red = _n5.a.a;
			var green = _n5.b.a;
			var _n6 = results.b;
			var blue = _n6.a.a;
			var alpha = _n6.b.a;
			return {
				aR: alpha / 255,
				dq: blue,
				am: 0,
				cw: green,
				d2: red,
				I: rtfeldman$elm_css$Css$withPrecedingHash(str)
			};
		} else {
			return rtfeldman$elm_css$Css$erroneousHex(str);
		}
	});
var rtfeldman$elm_css$Css$hex = function (str) {
	var withoutHash = A2(elm$core$String$startsWith, '#', str) ? A2(elm$core$String$dropLeft, 1, str) : str;
	var _n0 = elm$core$String$toList(withoutHash);
	_n0$4:
	while (true) {
		if ((_n0.b && _n0.b.b) && _n0.b.b.b) {
			if (!_n0.b.b.b.b) {
				var r = _n0.a;
				var _n1 = _n0.b;
				var g = _n1.a;
				var _n2 = _n1.b;
				var b = _n2.a;
				return A5(
					rtfeldman$elm_css$Css$validHex,
					str,
					_Utils_Tuple2(r, r),
					_Utils_Tuple2(g, g),
					_Utils_Tuple2(b, b),
					_Utils_Tuple2('f', 'f'));
			} else {
				if (!_n0.b.b.b.b.b) {
					var r = _n0.a;
					var _n3 = _n0.b;
					var g = _n3.a;
					var _n4 = _n3.b;
					var b = _n4.a;
					var _n5 = _n4.b;
					var a = _n5.a;
					return A5(
						rtfeldman$elm_css$Css$validHex,
						str,
						_Utils_Tuple2(r, r),
						_Utils_Tuple2(g, g),
						_Utils_Tuple2(b, b),
						_Utils_Tuple2(a, a));
				} else {
					if (_n0.b.b.b.b.b.b) {
						if (!_n0.b.b.b.b.b.b.b) {
							var r1 = _n0.a;
							var _n6 = _n0.b;
							var r2 = _n6.a;
							var _n7 = _n6.b;
							var g1 = _n7.a;
							var _n8 = _n7.b;
							var g2 = _n8.a;
							var _n9 = _n8.b;
							var b1 = _n9.a;
							var _n10 = _n9.b;
							var b2 = _n10.a;
							return A5(
								rtfeldman$elm_css$Css$validHex,
								str,
								_Utils_Tuple2(r1, r2),
								_Utils_Tuple2(g1, g2),
								_Utils_Tuple2(b1, b2),
								_Utils_Tuple2('f', 'f'));
						} else {
							if (_n0.b.b.b.b.b.b.b.b && (!_n0.b.b.b.b.b.b.b.b.b)) {
								var r1 = _n0.a;
								var _n11 = _n0.b;
								var r2 = _n11.a;
								var _n12 = _n11.b;
								var g1 = _n12.a;
								var _n13 = _n12.b;
								var g2 = _n13.a;
								var _n14 = _n13.b;
								var b1 = _n14.a;
								var _n15 = _n14.b;
								var b2 = _n15.a;
								var _n16 = _n15.b;
								var a1 = _n16.a;
								var _n17 = _n16.b;
								var a2 = _n17.a;
								return A5(
									rtfeldman$elm_css$Css$validHex,
									str,
									_Utils_Tuple2(r1, r2),
									_Utils_Tuple2(g1, g2),
									_Utils_Tuple2(b1, b2),
									_Utils_Tuple2(a1, a2));
							} else {
								break _n0$4;
							}
						}
					} else {
						break _n0$4;
					}
				}
			}
		} else {
			break _n0$4;
		}
	}
	return rtfeldman$elm_css$Css$erroneousHex(str);
};
var author$project$UI$Branding$colors = {
	dh: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.dh),
	di: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.di),
	dj: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.dj),
	dq: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.dq),
	bL: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.bL),
	dw: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.dw),
	cw: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.cw),
	cx: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.cx),
	cL: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.cL),
	cM: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.cM),
	bT: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.bT),
	dO: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.dO),
	bU: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.bU),
	dP: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.dP),
	dQ: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.dQ),
	d_: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.d_),
	b0: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.b0),
	b1: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.b1),
	d2: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.d2),
	b2: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.b2),
	ed: rtfeldman$elm_css$Css$hex(author$project$UI$Branding$colorStr.ed)
};
var rtfeldman$elm_css$Css$Preprocess$ExtendSelector = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var rtfeldman$elm_css$Css$Structure$PseudoClassSelector = function (a) {
	return {$: 2, a: a};
};
var rtfeldman$elm_css$Css$pseudoClass = function (_class) {
	return rtfeldman$elm_css$Css$Preprocess$ExtendSelector(
		rtfeldman$elm_css$Css$Structure$PseudoClassSelector(_class));
};
var rtfeldman$elm_css$Css$active = rtfeldman$elm_css$Css$pseudoClass('active');
var rtfeldman$elm_css$Css$Preprocess$AppendProperty = function (a) {
	return {$: 0, a: a};
};
var rtfeldman$elm_css$Css$property = F2(
	function (key, value) {
		return rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var rtfeldman$elm_css$Css$backgroundColor = function (c) {
	return A2(rtfeldman$elm_css$Css$property, 'background-color', c.I);
};
var rtfeldman$elm_css$Css$prop1 = F2(
	function (key, arg) {
		return A2(rtfeldman$elm_css$Css$property, key, arg.I);
	});
var rtfeldman$elm_css$Css$border = rtfeldman$elm_css$Css$prop1('border');
var rtfeldman$elm_css$Css$borderRadius = rtfeldman$elm_css$Css$prop1('border-radius');
var rtfeldman$elm_css$Css$bottom = rtfeldman$elm_css$Css$prop1('bottom');
var rtfeldman$elm_css$Css$color = function (c) {
	return A2(rtfeldman$elm_css$Css$property, 'color', c.I);
};
var rtfeldman$elm_css$Css$cursor = rtfeldman$elm_css$Css$prop1('cursor');
var rtfeldman$elm_css$Css$disabled = rtfeldman$elm_css$Css$pseudoClass('disabled');
var rtfeldman$elm_css$Css$display = rtfeldman$elm_css$Css$prop1('display');
var rtfeldman$elm_css$Css$EmUnits = 0;
var rtfeldman$elm_css$Css$Internal$lengthConverter = F3(
	function (units, unitLabel, numericValue) {
		return {
			b6: 0,
			cg: 0,
			ar: 0,
			q: 0,
			aZ: 0,
			ay: 0,
			P: 0,
			az: 0,
			aA: 0,
			aa: 0,
			ab: 0,
			G: 0,
			S: numericValue,
			aK: 0,
			aM: unitLabel,
			a9: units,
			I: _Utils_ap(
				elm$core$String$fromFloat(numericValue),
				unitLabel)
		};
	});
var rtfeldman$elm_css$Css$em = A2(rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'em');
var rtfeldman$elm_css$Css$fontSize = rtfeldman$elm_css$Css$prop1('font-size');
var rtfeldman$elm_css$Css$fontWeight = function (_n0) {
	var value = _n0.I;
	return A2(rtfeldman$elm_css$Css$property, 'font-weight', value);
};
var rtfeldman$elm_css$Css$height = rtfeldman$elm_css$Css$prop1('height');
var rtfeldman$elm_css$Css$hover = rtfeldman$elm_css$Css$pseudoClass('hover');
var rtfeldman$elm_css$Css$inlineBlock = {bg: 0, I: 'inline-block'};
var rtfeldman$elm_css$Css$UnitlessInteger = 0;
var rtfeldman$elm_css$Css$int = function (val) {
	return {
		N: 0,
		bk: 0,
		ab: 0,
		G: 0,
		dV: 0,
		bp: 0,
		S: val,
		aM: '',
		a9: 0,
		I: elm$core$String$fromInt(val)
	};
};
var rtfeldman$elm_css$Css$margin = rtfeldman$elm_css$Css$prop1('margin');
var rtfeldman$elm_css$Css$marginRight = rtfeldman$elm_css$Css$prop1('margin-right');
var rtfeldman$elm_css$Css$marginTop = rtfeldman$elm_css$Css$prop1('margin-top');
var rtfeldman$elm_css$Css$minWidth = rtfeldman$elm_css$Css$prop1('min-width');
var rtfeldman$elm_css$Css$none = {aj: 0, cd: 0, u: 0, a: 0, bg: 0, dG: 0, cD: 0, bS: 0, aA: 0, aa: 0, G: 0, c: 0, b: 0, bW: 0, bs: 0, d0: 0, D: 0, bx: 0, d4: 0, aJ: 0, ag: 0, y: 0, g: 0, d9: 0, I: 'none'};
var rtfeldman$elm_css$Css$prop2 = F3(
	function (key, argA, argB) {
		return A2(
			rtfeldman$elm_css$Css$property,
			key,
			A2(
				elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.I, argB.I])));
	});
var rtfeldman$elm_css$Css$padding2 = rtfeldman$elm_css$Css$prop2('padding');
var rtfeldman$elm_css$Css$pointer = {a: 0, I: 'pointer'};
var rtfeldman$elm_css$Css$PxUnits = 0;
var rtfeldman$elm_css$Css$px = A2(rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'px');
var rtfeldman$elm_css$Css$cssFunction = F2(
	function (funcName, args) {
		return funcName + ('(' + (A2(elm$core$String$join, ', ', args) + ')'));
	});
var rtfeldman$elm_css$Css$scale = function (x) {
	return {
		g: 0,
		I: A2(
			rtfeldman$elm_css$Css$cssFunction,
			'scale',
			_List_fromArray(
				[
					elm$core$String$fromFloat(x)
				]))
	};
};
var elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var rtfeldman$elm_css$Css$valuesOrNone = function (list) {
	return elm$core$List$isEmpty(list) ? {I: 'none'} : {
		I: A2(
			elm$core$String$join,
			' ',
			A2(
				elm$core$List$map,
				function ($) {
					return $.I;
				},
				list))
	};
};
var rtfeldman$elm_css$Css$transforms = A2(
	elm$core$Basics$composeL,
	rtfeldman$elm_css$Css$prop1('transform'),
	rtfeldman$elm_css$Css$valuesOrNone);
var rtfeldman$elm_css$Css$transform = function (only) {
	return rtfeldman$elm_css$Css$transforms(
		_List_fromArray(
			[only]));
};
var rtfeldman$elm_css$Css$Internal$property = F2(
	function (key, value) {
		return rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var rtfeldman$elm_css$Css$Preprocess$ApplyStyles = function (a) {
	return {$: 6, a: a};
};
var rtfeldman$elm_css$Css$Internal$getOverloadedProperty = F3(
	function (functionName, desiredKey, style) {
		getOverloadedProperty:
		while (true) {
			switch (style.$) {
				case 0:
					var str = style.a;
					var key = A2(
						elm$core$Maybe$withDefault,
						'',
						elm$core$List$head(
							A2(elm$core$String$split, ':', str)));
					return A2(rtfeldman$elm_css$Css$Internal$property, desiredKey, key);
				case 1:
					var selector = style.a;
					return A2(rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-selector'));
				case 2:
					var combinator = style.a;
					return A2(rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-combinator'));
				case 3:
					var pseudoElement = style.a;
					return A2(rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-pseudo-element setter'));
				case 4:
					return A2(rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-media-query'));
				case 5:
					return A2(rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-keyframes'));
				default:
					if (!style.a.b) {
						return A2(rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-empty-Style'));
					} else {
						if (!style.a.b.b) {
							var _n1 = style.a;
							var only = _n1.a;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = only;
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						} else {
							var _n2 = style.a;
							var first = _n2.a;
							var rest = _n2.b;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = rtfeldman$elm_css$Css$Preprocess$ApplyStyles(rest);
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						}
					}
			}
		}
	});
var rtfeldman$elm_css$Css$Internal$IncompatibleUnits = 0;
var rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty = A3(rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '', 0);
var rtfeldman$elm_css$Css$verticalAlign = function (fn) {
	return A3(
		rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'verticalAlign',
		'vertical-align',
		fn(rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var rtfeldman$elm_css$Html$Styled$button = rtfeldman$elm_css$Html$Styled$node('button');
var elm$json$Json$Encode$string = _Json_wrap;
var elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var Skinney$murmur3$Murmur3$HashData = F4(
	function (shift, seed, hash, charsProcessed) {
		return {al: charsProcessed, au: hash, ae: seed, aH: shift};
	});
var Skinney$murmur3$Murmur3$c1 = 3432918353;
var Skinney$murmur3$Murmur3$c2 = 461845907;
var Skinney$murmur3$Murmur3$multiplyBy = F2(
	function (b, a) {
		return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
	});
var elm$core$Bitwise$or = _Bitwise_or;
var Skinney$murmur3$Murmur3$rotlBy = F2(
	function (b, a) {
		return (a << b) | (a >>> (32 - b));
	});
var Skinney$murmur3$Murmur3$finalize = function (data) {
	var acc = data.au ? (data.ae ^ A2(
		Skinney$murmur3$Murmur3$multiplyBy,
		Skinney$murmur3$Murmur3$c2,
		A2(
			Skinney$murmur3$Murmur3$rotlBy,
			15,
			A2(Skinney$murmur3$Murmur3$multiplyBy, Skinney$murmur3$Murmur3$c1, data.au)))) : data.ae;
	var h0 = acc ^ data.al;
	var h1 = A2(Skinney$murmur3$Murmur3$multiplyBy, 2246822507, h0 ^ (h0 >>> 16));
	var h2 = A2(Skinney$murmur3$Murmur3$multiplyBy, 3266489909, h1 ^ (h1 >>> 13));
	return (h2 ^ (h2 >>> 16)) >>> 0;
};
var Skinney$murmur3$Murmur3$mix = F2(
	function (h1, k1) {
		return A2(
			Skinney$murmur3$Murmur3$multiplyBy,
			5,
			A2(
				Skinney$murmur3$Murmur3$rotlBy,
				13,
				h1 ^ A2(
					Skinney$murmur3$Murmur3$multiplyBy,
					Skinney$murmur3$Murmur3$c2,
					A2(
						Skinney$murmur3$Murmur3$rotlBy,
						15,
						A2(Skinney$murmur3$Murmur3$multiplyBy, Skinney$murmur3$Murmur3$c1, k1))))) + 3864292196;
	});
var Skinney$murmur3$Murmur3$hashFold = F2(
	function (c, data) {
		var res = data.au | ((255 & elm$core$Char$toCode(c)) << data.aH);
		var _n0 = data.aH;
		if (_n0 === 24) {
			return {
				al: data.al + 1,
				au: 0,
				ae: A2(Skinney$murmur3$Murmur3$mix, data.ae, res),
				aH: 0
			};
		} else {
			return {al: data.al + 1, au: res, ae: data.ae, aH: data.aH + 8};
		}
	});
var elm$core$String$foldl = _String_foldl;
var Skinney$murmur3$Murmur3$hashString = F2(
	function (seed, str) {
		return Skinney$murmur3$Murmur3$finalize(
			A3(
				elm$core$String$foldl,
				Skinney$murmur3$Murmur3$hashFold,
				A4(Skinney$murmur3$Murmur3$HashData, 0, seed, 0, 0),
				str));
	});
var elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var rtfeldman$elm_css$Css$Preprocess$stylesheet = function (snippets) {
	return {ci: elm$core$Maybe$Nothing, cB: _List_Nil, cO: _List_Nil, c7: snippets};
};
var rtfeldman$elm_css$Css$Preprocess$unwrapSnippet = function (_n0) {
	var declarations = _n0;
	return declarations;
};
var elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return elm$core$Maybe$Just(
				f(value));
		} else {
			return elm$core$Maybe$Nothing;
		}
	});
var rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors = function (declarations) {
	collectSelectors:
	while (true) {
		if (!declarations.b) {
			return _List_Nil;
		} else {
			if (!declarations.a.$) {
				var _n1 = declarations.a.a;
				var firstSelector = _n1.a;
				var otherSelectors = _n1.b;
				var rest = declarations.b;
				return _Utils_ap(
					A2(elm$core$List$cons, firstSelector, otherSelectors),
					rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(rest));
			} else {
				var rest = declarations.b;
				var $temp$declarations = rest;
				declarations = $temp$declarations;
				continue collectSelectors;
			}
		}
	}
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$last = function (list) {
	last:
	while (true) {
		if (!list.b) {
			return elm$core$Maybe$Nothing;
		} else {
			if (!list.b.b) {
				var singleton = list.a;
				return elm$core$Maybe$Just(singleton);
			} else {
				var rest = list.b;
				var $temp$list = rest;
				list = $temp$list;
				continue last;
			}
		}
	}
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration = function (declarations) {
	lastDeclaration:
	while (true) {
		if (!declarations.b) {
			return elm$core$Maybe$Nothing;
		} else {
			if (!declarations.b.b) {
				var x = declarations.a;
				return elm$core$Maybe$Just(
					_List_fromArray(
						[x]));
			} else {
				var xs = declarations.b;
				var $temp$declarations = xs;
				declarations = $temp$declarations;
				continue lastDeclaration;
			}
		}
	}
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf = function (maybes) {
	oneOf:
	while (true) {
		if (!maybes.b) {
			return elm$core$Maybe$Nothing;
		} else {
			var maybe = maybes.a;
			var rest = maybes.b;
			if (maybe.$ === 1) {
				var $temp$maybes = rest;
				maybes = $temp$maybes;
				continue oneOf;
			} else {
				return maybe;
			}
		}
	}
};
var rtfeldman$elm_css$Css$Structure$FontFeatureValues = function (a) {
	return {$: 9, a: a};
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues = function (tuples) {
	var expandTuples = function (tuplesToExpand) {
		if (!tuplesToExpand.b) {
			return _List_Nil;
		} else {
			var properties = tuplesToExpand.a;
			var rest = tuplesToExpand.b;
			return A2(
				elm$core$List$cons,
				properties,
				expandTuples(rest));
		}
	};
	var newTuples = expandTuples(tuples);
	return _List_fromArray(
		[
			rtfeldman$elm_css$Css$Structure$FontFeatureValues(newTuples)
		]);
};
var rtfeldman$elm_css$Css$Structure$DocumentRule = F5(
	function (a, b, c, d, e) {
		return {$: 3, a: a, b: b, c: c, d: d, e: e};
	});
var rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule = F5(
	function (str1, str2, str3, str4, declaration) {
		if (!declaration.$) {
			var structureStyleBlock = declaration.a;
			return A5(rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
		} else {
			return declaration;
		}
	});
var rtfeldman$elm_css$Css$Structure$MediaRule = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var rtfeldman$elm_css$Css$Structure$SupportsRule = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule = F2(
	function (mediaQueries, declaration) {
		switch (declaration.$) {
			case 0:
				var structureStyleBlock = declaration.a;
				return A2(
					rtfeldman$elm_css$Css$Structure$MediaRule,
					mediaQueries,
					_List_fromArray(
						[structureStyleBlock]));
			case 1:
				var newMediaQueries = declaration.a;
				var structureStyleBlocks = declaration.b;
				return A2(
					rtfeldman$elm_css$Css$Structure$MediaRule,
					_Utils_ap(mediaQueries, newMediaQueries),
					structureStyleBlocks);
			case 2:
				var str = declaration.a;
				var declarations = declaration.b;
				return A2(
					rtfeldman$elm_css$Css$Structure$SupportsRule,
					str,
					A2(
						elm$core$List$map,
						rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
						declarations));
			case 3:
				var str1 = declaration.a;
				var str2 = declaration.b;
				var str3 = declaration.c;
				var str4 = declaration.d;
				var structureStyleBlock = declaration.e;
				return A5(rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
			case 4:
				return declaration;
			case 5:
				return declaration;
			case 6:
				return declaration;
			case 7:
				return declaration;
			case 8:
				return declaration;
			default:
				return declaration;
		}
	});
var rtfeldman$elm_css$Css$Structure$CounterStyle = function (a) {
	return {$: 8, a: a};
};
var rtfeldman$elm_css$Css$Structure$FontFace = function (a) {
	return {$: 5, a: a};
};
var rtfeldman$elm_css$Css$Structure$Keyframes = function (a) {
	return {$: 6, a: a};
};
var rtfeldman$elm_css$Css$Structure$PageRule = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var rtfeldman$elm_css$Css$Structure$Selector = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var rtfeldman$elm_css$Css$Structure$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var rtfeldman$elm_css$Css$Structure$Viewport = function (a) {
	return {$: 7, a: a};
};
var rtfeldman$elm_css$Css$Structure$mapLast = F2(
	function (update, list) {
		if (!list.b) {
			return list;
		} else {
			if (!list.b.b) {
				var only = list.a;
				return _List_fromArray(
					[
						update(only)
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					elm$core$List$cons,
					first,
					A2(rtfeldman$elm_css$Css$Structure$mapLast, update, rest));
			}
		}
	});
var rtfeldman$elm_css$Css$Structure$withPropertyAppended = F2(
	function (property, _n0) {
		var firstSelector = _n0.a;
		var otherSelectors = _n0.b;
		var properties = _n0.c;
		return A3(
			rtfeldman$elm_css$Css$Structure$StyleBlock,
			firstSelector,
			otherSelectors,
			_Utils_ap(
				properties,
				_List_fromArray(
					[property])));
	});
var rtfeldman$elm_css$Css$Structure$appendProperty = F2(
	function (property, declarations) {
		if (!declarations.b) {
			return declarations;
		} else {
			if (!declarations.b.b) {
				switch (declarations.a.$) {
					case 0:
						var styleBlock = declarations.a.a;
						return _List_fromArray(
							[
								rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
								A2(rtfeldman$elm_css$Css$Structure$withPropertyAppended, property, styleBlock))
							]);
					case 1:
						var _n1 = declarations.a;
						var mediaQueries = _n1.a;
						var styleBlocks = _n1.b;
						return _List_fromArray(
							[
								A2(
								rtfeldman$elm_css$Css$Structure$MediaRule,
								mediaQueries,
								A2(
									rtfeldman$elm_css$Css$Structure$mapLast,
									rtfeldman$elm_css$Css$Structure$withPropertyAppended(property),
									styleBlocks))
							]);
					default:
						return declarations;
				}
			} else {
				var first = declarations.a;
				var rest = declarations.b;
				return A2(
					elm$core$List$cons,
					first,
					A2(rtfeldman$elm_css$Css$Structure$appendProperty, property, rest));
			}
		}
	});
var rtfeldman$elm_css$Css$Structure$appendToLastSelector = F2(
	function (f, styleBlock) {
		if (!styleBlock.b.b) {
			var only = styleBlock.a;
			var properties = styleBlock.c;
			return _List_fromArray(
				[
					A3(rtfeldman$elm_css$Css$Structure$StyleBlock, only, _List_Nil, properties),
					A3(
					rtfeldman$elm_css$Css$Structure$StyleBlock,
					f(only),
					_List_Nil,
					_List_Nil)
				]);
		} else {
			var first = styleBlock.a;
			var rest = styleBlock.b;
			var properties = styleBlock.c;
			var newRest = A2(elm$core$List$map, f, rest);
			var newFirst = f(first);
			return _List_fromArray(
				[
					A3(rtfeldman$elm_css$Css$Structure$StyleBlock, first, rest, properties),
					A3(rtfeldman$elm_css$Css$Structure$StyleBlock, newFirst, newRest, _List_Nil)
				]);
		}
	});
var rtfeldman$elm_css$Css$Structure$applyPseudoElement = F2(
	function (pseudo, _n0) {
		var sequence = _n0.a;
		var selectors = _n0.b;
		return A3(
			rtfeldman$elm_css$Css$Structure$Selector,
			sequence,
			selectors,
			elm$core$Maybe$Just(pseudo));
	});
var rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector = F2(
	function (pseudo, styleBlock) {
		return A2(
			rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			rtfeldman$elm_css$Css$Structure$applyPseudoElement(pseudo),
			styleBlock);
	});
var rtfeldman$elm_css$Css$Structure$CustomSelector = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var rtfeldman$elm_css$Css$Structure$TypeSelectorSequence = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence = function (a) {
	return {$: 1, a: a};
};
var rtfeldman$elm_css$Css$Structure$appendRepeatable = F2(
	function (selector, sequence) {
		switch (sequence.$) {
			case 0:
				var typeSelector = sequence.a;
				var list = sequence.b;
				return A2(
					rtfeldman$elm_css$Css$Structure$TypeSelectorSequence,
					typeSelector,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			case 1:
				var list = sequence.a;
				return rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			default:
				var str = sequence.a;
				var list = sequence.b;
				return A2(
					rtfeldman$elm_css$Css$Structure$CustomSelector,
					str,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
		}
	});
var rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator = F2(
	function (selector, list) {
		if (!list.b) {
			return _List_Nil;
		} else {
			if (!list.b.b) {
				var _n1 = list.a;
				var combinator = _n1.a;
				var sequence = _n1.b;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						combinator,
						A2(rtfeldman$elm_css$Css$Structure$appendRepeatable, selector, sequence))
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					elm$core$List$cons,
					first,
					A2(rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, selector, rest));
			}
		}
	});
var rtfeldman$elm_css$Css$Structure$appendRepeatableSelector = F2(
	function (repeatableSimpleSelector, selector) {
		if (!selector.b.b) {
			var sequence = selector.a;
			var pseudoElement = selector.c;
			return A3(
				rtfeldman$elm_css$Css$Structure$Selector,
				A2(rtfeldman$elm_css$Css$Structure$appendRepeatable, repeatableSimpleSelector, sequence),
				_List_Nil,
				pseudoElement);
		} else {
			var firstSelector = selector.a;
			var tuples = selector.b;
			var pseudoElement = selector.c;
			return A3(
				rtfeldman$elm_css$Css$Structure$Selector,
				firstSelector,
				A2(rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, repeatableSimpleSelector, tuples),
				pseudoElement);
		}
	});
var rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector = F2(
	function (selector, styleBlock) {
		return A2(
			rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			rtfeldman$elm_css$Css$Structure$appendRepeatableSelector(selector),
			styleBlock);
	});
var rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock = F2(
	function (update, declarations) {
		_n0$12:
		while (true) {
			if (!declarations.b) {
				return declarations;
			} else {
				if (!declarations.b.b) {
					switch (declarations.a.$) {
						case 0:
							var styleBlock = declarations.a.a;
							return A2(
								elm$core$List$map,
								rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration,
								update(styleBlock));
						case 1:
							if (declarations.a.b.b) {
								if (!declarations.a.b.b.b) {
									var _n1 = declarations.a;
									var mediaQueries = _n1.a;
									var _n2 = _n1.b;
									var styleBlock = _n2.a;
									return _List_fromArray(
										[
											A2(
											rtfeldman$elm_css$Css$Structure$MediaRule,
											mediaQueries,
											update(styleBlock))
										]);
								} else {
									var _n3 = declarations.a;
									var mediaQueries = _n3.a;
									var _n4 = _n3.b;
									var first = _n4.a;
									var rest = _n4.b;
									var _n5 = A2(
										rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock,
										update,
										_List_fromArray(
											[
												A2(rtfeldman$elm_css$Css$Structure$MediaRule, mediaQueries, rest)
											]));
									if ((_n5.b && (_n5.a.$ === 1)) && (!_n5.b.b)) {
										var _n6 = _n5.a;
										var newMediaQueries = _n6.a;
										var newStyleBlocks = _n6.b;
										return _List_fromArray(
											[
												A2(
												rtfeldman$elm_css$Css$Structure$MediaRule,
												newMediaQueries,
												A2(elm$core$List$cons, first, newStyleBlocks))
											]);
									} else {
										var newDeclarations = _n5;
										return newDeclarations;
									}
								}
							} else {
								break _n0$12;
							}
						case 2:
							var _n7 = declarations.a;
							var str = _n7.a;
							var nestedDeclarations = _n7.b;
							return _List_fromArray(
								[
									A2(
									rtfeldman$elm_css$Css$Structure$SupportsRule,
									str,
									A2(rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, nestedDeclarations))
								]);
						case 3:
							var _n8 = declarations.a;
							var str1 = _n8.a;
							var str2 = _n8.b;
							var str3 = _n8.c;
							var str4 = _n8.d;
							var styleBlock = _n8.e;
							return A2(
								elm$core$List$map,
								A4(rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4),
								update(styleBlock));
						case 4:
							var _n9 = declarations.a;
							return declarations;
						case 5:
							return declarations;
						case 6:
							return declarations;
						case 7:
							return declarations;
						case 8:
							return declarations;
						default:
							return declarations;
					}
				} else {
					break _n0$12;
				}
			}
		}
		var first = declarations.a;
		var rest = declarations.b;
		return A2(
			elm$core$List$cons,
			first,
			A2(rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, rest));
	});
var rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule = F2(
	function (mediaQueries, declaration) {
		if (!declaration.$) {
			var styleBlock = declaration.a;
			return A2(
				rtfeldman$elm_css$Css$Structure$MediaRule,
				mediaQueries,
				_List_fromArray(
					[styleBlock]));
		} else {
			return declaration;
		}
	});
var rtfeldman$elm_css$Hash$murmurSeed = 15739;
var rtfeldman$elm_hex$Hex$unsafeToDigit = function (num) {
	unsafeToDigit:
	while (true) {
		switch (num) {
			case 0:
				return '0';
			case 1:
				return '1';
			case 2:
				return '2';
			case 3:
				return '3';
			case 4:
				return '4';
			case 5:
				return '5';
			case 6:
				return '6';
			case 7:
				return '7';
			case 8:
				return '8';
			case 9:
				return '9';
			case 10:
				return 'a';
			case 11:
				return 'b';
			case 12:
				return 'c';
			case 13:
				return 'd';
			case 14:
				return 'e';
			case 15:
				return 'f';
			default:
				var $temp$num = num;
				num = $temp$num;
				continue unsafeToDigit;
		}
	}
};
var rtfeldman$elm_hex$Hex$unsafePositiveToDigits = F2(
	function (digits, num) {
		unsafePositiveToDigits:
		while (true) {
			if (num < 16) {
				return A2(
					elm$core$List$cons,
					rtfeldman$elm_hex$Hex$unsafeToDigit(num),
					digits);
			} else {
				var $temp$digits = A2(
					elm$core$List$cons,
					rtfeldman$elm_hex$Hex$unsafeToDigit(
						A2(elm$core$Basics$modBy, 16, num)),
					digits),
					$temp$num = (num / 16) | 0;
				digits = $temp$digits;
				num = $temp$num;
				continue unsafePositiveToDigits;
			}
		}
	});
var rtfeldman$elm_hex$Hex$toString = function (num) {
	return elm$core$String$fromList(
		(num < 0) ? A2(
			elm$core$List$cons,
			'-',
			A2(rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, -num)) : A2(rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, num));
};
var rtfeldman$elm_css$Hash$fromString = function (str) {
	return A2(
		elm$core$String$cons,
		'_',
		rtfeldman$elm_hex$Hex$toString(
			A2(Skinney$murmur3$Murmur3$hashString, rtfeldman$elm_css$Hash$murmurSeed, str)));
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast = F4(
	function (nestedStyles, rest, f, declarations) {
		var withoutParent = function (decls) {
			return A2(
				elm$core$Maybe$withDefault,
				_List_Nil,
				elm$core$List$tail(decls));
		};
		var nextResult = A2(
			rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
			rest,
			A2(
				elm$core$Maybe$withDefault,
				_List_Nil,
				rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		var newDeclarations = function () {
			var _n14 = _Utils_Tuple2(
				elm$core$List$head(nextResult),
				rtfeldman$elm_css$Css$Preprocess$Resolve$last(declarations));
			if ((!_n14.a.$) && (!_n14.b.$)) {
				var nextResultParent = _n14.a.a;
				var originalParent = _n14.b.a;
				return _Utils_ap(
					A2(
						elm$core$List$take,
						elm$core$List$length(declarations) - 1,
						declarations),
					_List_fromArray(
						[
							(!_Utils_eq(originalParent, nextResultParent)) ? nextResultParent : originalParent
						]));
			} else {
				return declarations;
			}
		}();
		var insertStylesToNestedDecl = function (lastDecl) {
			return elm$core$List$concat(
				A2(
					rtfeldman$elm_css$Css$Structure$mapLast,
					rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles(nestedStyles),
					A2(
						elm$core$List$map,
						elm$core$List$singleton,
						A2(rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, f, lastDecl))));
		};
		var initialResult = A2(
			elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				elm$core$Maybe$map,
				insertStylesToNestedDecl,
				rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		return _Utils_ap(
			newDeclarations,
			_Utils_ap(
				withoutParent(initialResult),
				withoutParent(nextResult)));
	});
var rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles = F2(
	function (styles, declarations) {
		if (!styles.b) {
			return declarations;
		} else {
			switch (styles.a.$) {
				case 0:
					var property = styles.a.a;
					var rest = styles.b;
					return A2(
						rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2(rtfeldman$elm_css$Css$Structure$appendProperty, property, declarations));
				case 1:
					var _n4 = styles.a;
					var selector = _n4.a;
					var nestedStyles = _n4.b;
					var rest = styles.b;
					return A4(
						rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector(selector),
						declarations);
				case 2:
					var _n5 = styles.a;
					var selectorCombinator = _n5.a;
					var snippets = _n5.b;
					var rest = styles.b;
					var chain = F2(
						function (_n9, _n10) {
							var originalSequence = _n9.a;
							var originalTuples = _n9.b;
							var originalPseudoElement = _n9.c;
							var newSequence = _n10.a;
							var newTuples = _n10.b;
							var newPseudoElement = _n10.c;
							return A3(
								rtfeldman$elm_css$Css$Structure$Selector,
								originalSequence,
								_Utils_ap(
									originalTuples,
									A2(
										elm$core$List$cons,
										_Utils_Tuple2(selectorCombinator, newSequence),
										newTuples)),
								rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf(
									_List_fromArray(
										[newPseudoElement, originalPseudoElement])));
						});
					var expandDeclaration = function (declaration) {
						switch (declaration.$) {
							case 0:
								var _n7 = declaration.a;
								var firstSelector = _n7.a;
								var otherSelectors = _n7.b;
								var nestedStyles = _n7.c;
								var newSelectors = A2(
									elm$core$List$concatMap,
									function (originalSelector) {
										return A2(
											elm$core$List$map,
											chain(originalSelector),
											A2(elm$core$List$cons, firstSelector, otherSelectors));
									},
									rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations));
								var newDeclarations = function () {
									if (!newSelectors.b) {
										return _List_Nil;
									} else {
										var first = newSelectors.a;
										var remainder = newSelectors.b;
										return _List_fromArray(
											[
												rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
												A3(rtfeldman$elm_css$Css$Structure$StyleBlock, first, remainder, _List_Nil))
											]);
									}
								}();
								return A2(rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, nestedStyles, newDeclarations);
							case 1:
								var mediaQueries = declaration.a;
								var styleBlocks = declaration.b;
								return A2(rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
							case 2:
								var str = declaration.a;
								var otherSnippets = declaration.b;
								return A2(rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, otherSnippets);
							case 3:
								var str1 = declaration.a;
								var str2 = declaration.b;
								var str3 = declaration.c;
								var str4 = declaration.d;
								var styleBlock = declaration.e;
								return A2(
									elm$core$List$map,
									A4(rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
									rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
							case 4:
								var str = declaration.a;
								var properties = declaration.b;
								return _List_fromArray(
									[
										A2(rtfeldman$elm_css$Css$Structure$PageRule, str, properties)
									]);
							case 5:
								var properties = declaration.a;
								return _List_fromArray(
									[
										rtfeldman$elm_css$Css$Structure$FontFace(properties)
									]);
							case 6:
								var properties = declaration.a;
								return _List_fromArray(
									[
										rtfeldman$elm_css$Css$Structure$Viewport(properties)
									]);
							case 7:
								var properties = declaration.a;
								return _List_fromArray(
									[
										rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
									]);
							default:
								var tuples = declaration.a;
								return rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
						}
					};
					return elm$core$List$concat(
						_Utils_ap(
							_List_fromArray(
								[
									A2(rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations)
								]),
							A2(
								elm$core$List$map,
								expandDeclaration,
								A2(elm$core$List$concatMap, rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets))));
				case 3:
					var _n11 = styles.a;
					var pseudoElement = _n11.a;
					var nestedStyles = _n11.b;
					var rest = styles.b;
					return A4(
						rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector(pseudoElement),
						declarations);
				case 5:
					var str = styles.a.a;
					var rest = styles.b;
					var name = rtfeldman$elm_css$Hash$fromString(str);
					var newProperty = 'animation-name:' + name;
					var newDeclarations = A2(
						rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2(rtfeldman$elm_css$Css$Structure$appendProperty, newProperty, declarations));
					return A2(
						elm$core$List$append,
						newDeclarations,
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$Structure$Keyframes(
								{dx: str, dT: name})
							]));
				case 4:
					var _n12 = styles.a;
					var mediaQueries = _n12.a;
					var nestedStyles = _n12.b;
					var rest = styles.b;
					var extraDeclarations = function () {
						var _n13 = rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations);
						if (!_n13.b) {
							return _List_Nil;
						} else {
							var firstSelector = _n13.a;
							var otherSelectors = _n13.b;
							return A2(
								elm$core$List$map,
								rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule(mediaQueries),
								A2(
									rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
									nestedStyles,
									elm$core$List$singleton(
										rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
											A3(rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil)))));
						}
					}();
					return _Utils_ap(
						A2(rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations),
						extraDeclarations);
				default:
					var otherStyles = styles.a.a;
					var rest = styles.b;
					return A2(
						rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						_Utils_ap(otherStyles, rest),
						declarations);
			}
		}
	});
var rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock = function (_n2) {
	var firstSelector = _n2.a;
	var otherSelectors = _n2.b;
	var styles = _n2.c;
	return A2(
		rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
		styles,
		_List_fromArray(
			[
				rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
				A3(rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil))
			]));
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$extract = function (snippetDeclarations) {
	if (!snippetDeclarations.b) {
		return _List_Nil;
	} else {
		var first = snippetDeclarations.a;
		var rest = snippetDeclarations.b;
		return _Utils_ap(
			rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations(first),
			rtfeldman$elm_css$Css$Preprocess$Resolve$extract(rest));
	}
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule = F2(
	function (mediaQueries, styleBlocks) {
		var handleStyleBlock = function (styleBlock) {
			return A2(
				elm$core$List$map,
				rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
				rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		};
		return A2(elm$core$List$concatMap, handleStyleBlock, styleBlocks);
	});
var rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule = F2(
	function (str, snippets) {
		var declarations = rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
			A2(elm$core$List$concatMap, rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
		return _List_fromArray(
			[
				A2(rtfeldman$elm_css$Css$Structure$SupportsRule, str, declarations)
			]);
	});
var rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations = function (snippetDeclaration) {
	switch (snippetDeclaration.$) {
		case 0:
			var styleBlock = snippetDeclaration.a;
			return rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock);
		case 1:
			var mediaQueries = snippetDeclaration.a;
			var styleBlocks = snippetDeclaration.b;
			return A2(rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
		case 2:
			var str = snippetDeclaration.a;
			var snippets = snippetDeclaration.b;
			return A2(rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, snippets);
		case 3:
			var str1 = snippetDeclaration.a;
			var str2 = snippetDeclaration.b;
			var str3 = snippetDeclaration.c;
			var str4 = snippetDeclaration.d;
			var styleBlock = snippetDeclaration.e;
			return A2(
				elm$core$List$map,
				A4(rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
				rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		case 4:
			var str = snippetDeclaration.a;
			var properties = snippetDeclaration.b;
			return _List_fromArray(
				[
					A2(rtfeldman$elm_css$Css$Structure$PageRule, str, properties)
				]);
		case 5:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					rtfeldman$elm_css$Css$Structure$FontFace(properties)
				]);
		case 6:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					rtfeldman$elm_css$Css$Structure$Viewport(properties)
				]);
		case 7:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
				]);
		default:
			var tuples = snippetDeclaration.a;
			return rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
	}
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure = function (_n0) {
	var charset = _n0.ci;
	var imports = _n0.cB;
	var namespaces = _n0.cO;
	var snippets = _n0.c7;
	var declarations = rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
		A2(elm$core$List$concatMap, rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
	return {ci: charset, dy: declarations, cB: imports, cO: namespaces};
};
var elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			elm$core$List$any,
			A2(elm$core$Basics$composeL, elm$core$Basics$not, isOkay),
			list);
	});
var rtfeldman$elm_css$Css$Structure$compactHelp = F2(
	function (declaration, _n0) {
		var keyframesByName = _n0.a;
		var declarations = _n0.b;
		switch (declaration.$) {
			case 0:
				var _n2 = declaration.a;
				var properties = _n2.c;
				return elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2(elm$core$List$cons, declaration, declarations));
			case 1:
				var styleBlocks = declaration.b;
				return A2(
					elm$core$List$all,
					function (_n3) {
						var properties = _n3.c;
						return elm$core$List$isEmpty(properties);
					},
					styleBlocks) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2(elm$core$List$cons, declaration, declarations));
			case 2:
				var otherDeclarations = declaration.b;
				return elm$core$List$isEmpty(otherDeclarations) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2(elm$core$List$cons, declaration, declarations));
			case 3:
				return _Utils_Tuple2(
					keyframesByName,
					A2(elm$core$List$cons, declaration, declarations));
			case 4:
				var properties = declaration.b;
				return elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2(elm$core$List$cons, declaration, declarations));
			case 5:
				var properties = declaration.a;
				return elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2(elm$core$List$cons, declaration, declarations));
			case 6:
				var record = declaration.a;
				return elm$core$String$isEmpty(record.dx) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					A3(elm$core$Dict$insert, record.dT, record.dx, keyframesByName),
					declarations);
			case 7:
				var properties = declaration.a;
				return elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2(elm$core$List$cons, declaration, declarations));
			case 8:
				var properties = declaration.a;
				return elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2(elm$core$List$cons, declaration, declarations));
			default:
				var tuples = declaration.a;
				return A2(
					elm$core$List$all,
					function (_n4) {
						var properties = _n4.b;
						return elm$core$List$isEmpty(properties);
					},
					tuples) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2(elm$core$List$cons, declaration, declarations));
		}
	});
var rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations = F2(
	function (keyframesByName, compactedDeclarations) {
		return A2(
			elm$core$List$append,
			A2(
				elm$core$List$map,
				function (_n0) {
					var name = _n0.a;
					var decl = _n0.b;
					return rtfeldman$elm_css$Css$Structure$Keyframes(
						{dx: decl, dT: name});
				},
				elm$core$Dict$toList(keyframesByName)),
			compactedDeclarations);
	});
var rtfeldman$elm_css$Css$Structure$compactStylesheet = function (_n0) {
	var charset = _n0.ci;
	var imports = _n0.cB;
	var namespaces = _n0.cO;
	var declarations = _n0.dy;
	var _n1 = A3(
		elm$core$List$foldr,
		rtfeldman$elm_css$Css$Structure$compactHelp,
		_Utils_Tuple2(elm$core$Dict$empty, _List_Nil),
		declarations);
	var keyframesByName = _n1.a;
	var compactedDeclarations = _n1.b;
	var finalDeclarations = A2(rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations, keyframesByName, compactedDeclarations);
	return {ci: charset, dy: finalDeclarations, cB: imports, cO: namespaces};
};
var rtfeldman$elm_css$Css$Structure$Output$charsetToString = function (charset) {
	return A2(
		elm$core$Maybe$withDefault,
		'',
		A2(
			elm$core$Maybe$map,
			function (str) {
				return '@charset \"' + (str + '\"');
			},
			charset));
};
var rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString = function (expression) {
	return '(' + (expression.cr + (A2(
		elm$core$Maybe$withDefault,
		'',
		A2(
			elm$core$Maybe$map,
			elm$core$Basics$append(': '),
			expression.I)) + ')'));
};
var rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString = function (mediaType) {
	switch (mediaType) {
		case 0:
			return 'print';
		case 1:
			return 'screen';
		default:
			return 'speech';
	}
};
var rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString = function (mediaQuery) {
	var prefixWith = F3(
		function (str, mediaType, expressions) {
			return str + (' ' + A2(
				elm$core$String$join,
				' and ',
				A2(
					elm$core$List$cons,
					rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString(mediaType),
					A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, expressions))));
		});
	switch (mediaQuery.$) {
		case 0:
			var expressions = mediaQuery.a;
			return A2(
				elm$core$String$join,
				' and ',
				A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, expressions));
		case 1:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'only', mediaType, expressions);
		case 2:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'not', mediaType, expressions);
		default:
			var str = mediaQuery.a;
			return str;
	}
};
var rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString = F2(
	function (name, mediaQuery) {
		return '@import \"' + (name + (rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString(mediaQuery) + '\"'));
	});
var rtfeldman$elm_css$Css$Structure$Output$importToString = function (_n0) {
	var name = _n0.a;
	var mediaQueries = _n0.b;
	return A2(
		elm$core$String$join,
		'\n',
		A2(
			elm$core$List$map,
			rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString(name),
			mediaQueries));
};
var rtfeldman$elm_css$Css$Structure$Output$namespaceToString = function (_n0) {
	var prefix = _n0.a;
	var str = _n0.b;
	return '@namespace ' + (prefix + ('\"' + (str + '\"')));
};
var rtfeldman$elm_css$Css$Structure$Output$spaceIndent = '    ';
var rtfeldman$elm_css$Css$Structure$Output$indent = function (str) {
	return _Utils_ap(rtfeldman$elm_css$Css$Structure$Output$spaceIndent, str);
};
var rtfeldman$elm_css$Css$Structure$Output$noIndent = '';
var rtfeldman$elm_css$Css$Structure$Output$emitProperty = function (str) {
	return str + ';';
};
var rtfeldman$elm_css$Css$Structure$Output$emitProperties = function (properties) {
	return A2(
		elm$core$String$join,
		'\n',
		A2(
			elm$core$List$map,
			A2(elm$core$Basics$composeL, rtfeldman$elm_css$Css$Structure$Output$indent, rtfeldman$elm_css$Css$Structure$Output$emitProperty),
			properties));
};
var elm$core$String$append = _String_append;
var rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString = function (_n0) {
	var str = _n0;
	return '::' + str;
};
var rtfeldman$elm_css$Css$Structure$Output$combinatorToString = function (combinator) {
	switch (combinator) {
		case 0:
			return '+';
		case 1:
			return '~';
		case 2:
			return '>';
		default:
			return '';
	}
};
var rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString = function (repeatableSimpleSelector) {
	switch (repeatableSimpleSelector.$) {
		case 0:
			var str = repeatableSimpleSelector.a;
			return '.' + str;
		case 1:
			var str = repeatableSimpleSelector.a;
			return '#' + str;
		case 2:
			var str = repeatableSimpleSelector.a;
			return ':' + str;
		default:
			var str = repeatableSimpleSelector.a;
			return '[' + (str + ']');
	}
};
var rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString = function (simpleSelectorSequence) {
	switch (simpleSelectorSequence.$) {
		case 0:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return A2(
				elm$core$String$join,
				'',
				A2(
					elm$core$List$cons,
					str,
					A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors)));
		case 1:
			var repeatableSimpleSelectors = simpleSelectorSequence.a;
			return elm$core$List$isEmpty(repeatableSimpleSelectors) ? '*' : A2(
				elm$core$String$join,
				'',
				A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors));
		default:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return A2(
				elm$core$String$join,
				'',
				A2(
					elm$core$List$cons,
					str,
					A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors)));
	}
};
var rtfeldman$elm_css$Css$Structure$Output$selectorChainToString = function (_n0) {
	var combinator = _n0.a;
	var sequence = _n0.b;
	return A2(
		elm$core$String$join,
		' ',
		_List_fromArray(
			[
				rtfeldman$elm_css$Css$Structure$Output$combinatorToString(combinator),
				rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(sequence)
			]));
};
var rtfeldman$elm_css$Css$Structure$Output$selectorToString = function (_n0) {
	var simpleSelectorSequence = _n0.a;
	var chain = _n0.b;
	var pseudoElement = _n0.c;
	var segments = A2(
		elm$core$List$cons,
		rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(simpleSelectorSequence),
		A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$selectorChainToString, chain));
	var pseudoElementsString = A2(
		elm$core$String$join,
		'',
		_List_fromArray(
			[
				A2(
				elm$core$Maybe$withDefault,
				'',
				A2(elm$core$Maybe$map, rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString, pseudoElement))
			]));
	return A2(
		elm$core$String$append,
		A2(
			elm$core$String$join,
			' ',
			A2(
				elm$core$List$filter,
				A2(elm$core$Basics$composeL, elm$core$Basics$not, elm$core$String$isEmpty),
				segments)),
		pseudoElementsString);
};
var rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock = F2(
	function (indentLevel, _n0) {
		var firstSelector = _n0.a;
		var otherSelectors = _n0.b;
		var properties = _n0.c;
		var selectorStr = A2(
			elm$core$String$join,
			', ',
			A2(
				elm$core$List$map,
				rtfeldman$elm_css$Css$Structure$Output$selectorToString,
				A2(elm$core$List$cons, firstSelector, otherSelectors)));
		return A2(
			elm$core$String$join,
			'',
			_List_fromArray(
				[
					selectorStr,
					' {\n',
					indentLevel,
					rtfeldman$elm_css$Css$Structure$Output$emitProperties(properties),
					'\n',
					indentLevel,
					'}'
				]));
	});
var rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration = function (decl) {
	switch (decl.$) {
		case 0:
			var styleBlock = decl.a;
			return A2(rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock, rtfeldman$elm_css$Css$Structure$Output$noIndent, styleBlock);
		case 1:
			var mediaQueries = decl.a;
			var styleBlocks = decl.b;
			var query = A2(
				elm$core$String$join,
				',\n',
				A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString, mediaQueries));
			var blocks = A2(
				elm$core$String$join,
				'\n\n',
				A2(
					elm$core$List$map,
					A2(
						elm$core$Basics$composeL,
						rtfeldman$elm_css$Css$Structure$Output$indent,
						rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock(rtfeldman$elm_css$Css$Structure$Output$spaceIndent)),
					styleBlocks));
			return '@media ' + (query + (' {\n' + (blocks + '\n}')));
		case 2:
			return 'TODO';
		case 3:
			return 'TODO';
		case 4:
			return 'TODO';
		case 5:
			return 'TODO';
		case 6:
			var name = decl.a.dT;
			var declaration = decl.a.dx;
			return '@keyframes ' + (name + (' {\n' + (declaration + '\n}')));
		case 7:
			return 'TODO';
		case 8:
			return 'TODO';
		default:
			return 'TODO';
	}
};
var rtfeldman$elm_css$Css$Structure$Output$prettyPrint = function (_n0) {
	var charset = _n0.ci;
	var imports = _n0.cB;
	var namespaces = _n0.cO;
	var declarations = _n0.dy;
	return A2(
		elm$core$String$join,
		'\n\n',
		A2(
			elm$core$List$filter,
			A2(elm$core$Basics$composeL, elm$core$Basics$not, elm$core$String$isEmpty),
			_List_fromArray(
				[
					rtfeldman$elm_css$Css$Structure$Output$charsetToString(charset),
					A2(
					elm$core$String$join,
					'\n',
					A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$importToString, imports)),
					A2(
					elm$core$String$join,
					'\n',
					A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$namespaceToString, namespaces)),
					A2(
					elm$core$String$join,
					'\n\n',
					A2(elm$core$List$map, rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration, declarations))
				])));
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$compileHelp = function (sheet) {
	return rtfeldman$elm_css$Css$Structure$Output$prettyPrint(
		rtfeldman$elm_css$Css$Structure$compactStylesheet(
			rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure(sheet)));
};
var rtfeldman$elm_css$Css$Preprocess$Resolve$compile = function (styles) {
	return A2(
		elm$core$String$join,
		'\n\n',
		A2(elm$core$List$map, rtfeldman$elm_css$Css$Preprocess$Resolve$compileHelp, styles));
};
var rtfeldman$elm_css$Css$Preprocess$Snippet = elm$core$Basics$identity;
var rtfeldman$elm_css$Css$Preprocess$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var rtfeldman$elm_css$VirtualDom$Styled$makeSnippet = F2(
	function (styles, sequence) {
		var selector = A3(rtfeldman$elm_css$Css$Structure$Selector, sequence, _List_Nil, elm$core$Maybe$Nothing);
		return _List_fromArray(
			[
				rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration(
				A3(rtfeldman$elm_css$Css$Preprocess$StyleBlock, selector, _List_Nil, styles))
			]);
	});
var rtfeldman$elm_css$VirtualDom$Styled$murmurSeed = 15739;
var rtfeldman$elm_css$VirtualDom$Styled$getClassname = function (styles) {
	return elm$core$List$isEmpty(styles) ? 'unstyled' : A2(
		elm$core$String$cons,
		'_',
		rtfeldman$elm_hex$Hex$toString(
			A2(
				Skinney$murmur3$Murmur3$hashString,
				rtfeldman$elm_css$VirtualDom$Styled$murmurSeed,
				rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
					elm$core$List$singleton(
						rtfeldman$elm_css$Css$Preprocess$stylesheet(
							elm$core$List$singleton(
								A2(
									rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
									styles,
									rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(_List_Nil)))))))));
};
var rtfeldman$elm_css$Html$Styled$Internal$css = function (styles) {
	var classname = rtfeldman$elm_css$VirtualDom$Styled$getClassname(styles);
	var classProperty = A2(
		elm$virtual_dom$VirtualDom$property,
		'className',
		elm$json$Json$Encode$string(classname));
	return A3(rtfeldman$elm_css$VirtualDom$Styled$Attribute, classProperty, styles, classname);
};
var rtfeldman$elm_css$Html$Styled$styled = F4(
	function (fn, styles, attrs, children) {
		return A2(
			fn,
			A2(
				elm$core$List$cons,
				rtfeldman$elm_css$Html$Styled$Internal$css(styles),
				attrs),
			children);
	});
var author$project$UI$Elements$noteBtn = function (state) {
	var _n0 = function () {
		switch (state) {
			case 0:
				return _Utils_Tuple3(author$project$UI$Branding$colors.cx, author$project$UI$Branding$colors.dQ, author$project$UI$Branding$colors.bL);
			case 1:
				return _Utils_Tuple3(author$project$UI$Branding$colors.dw, author$project$UI$Branding$colors.dw, author$project$UI$Branding$colors.bL);
			case 2:
				return _Utils_Tuple3(author$project$UI$Branding$colors.d2, author$project$UI$Branding$colors.d2, author$project$UI$Branding$colors.bL);
			default:
				return _Utils_Tuple3(author$project$UI$Branding$colors.cw, author$project$UI$Branding$colors.cw, author$project$UI$Branding$colors.bL);
		}
	}();
	var bgColor = _n0.a;
	var hoverColor = _n0.b;
	var color = _n0.c;
	return A2(
		rtfeldman$elm_css$Html$Styled$styled,
		rtfeldman$elm_css$Html$Styled$button,
		_List_fromArray(
			[
				rtfeldman$elm_css$Css$margin(
				rtfeldman$elm_css$Css$em(0)),
				rtfeldman$elm_css$Css$verticalAlign(rtfeldman$elm_css$Css$bottom),
				A2(
				rtfeldman$elm_css$Css$padding2,
				rtfeldman$elm_css$Css$px(0),
				rtfeldman$elm_css$Css$em(0.8)),
				rtfeldman$elm_css$Css$height(
				rtfeldman$elm_css$Css$em(3)),
				rtfeldman$elm_css$Css$minWidth(
				rtfeldman$elm_css$Css$em(3)),
				rtfeldman$elm_css$Css$display(rtfeldman$elm_css$Css$inlineBlock),
				rtfeldman$elm_css$Css$marginRight(
				rtfeldman$elm_css$Css$px(4)),
				rtfeldman$elm_css$Css$marginTop(
				rtfeldman$elm_css$Css$px(4)),
				rtfeldman$elm_css$Css$borderRadius(
				rtfeldman$elm_css$Css$px(4)),
				rtfeldman$elm_css$Css$fontWeight(
				rtfeldman$elm_css$Css$int(800)),
				rtfeldman$elm_css$Css$fontSize(
				rtfeldman$elm_css$Css$em(1.2)),
				rtfeldman$elm_css$Css$border(
				rtfeldman$elm_css$Css$px(0)),
				rtfeldman$elm_css$Css$cursor(rtfeldman$elm_css$Css$pointer),
				rtfeldman$elm_css$Css$backgroundColor(bgColor),
				rtfeldman$elm_css$Css$color(color),
				A2(rtfeldman$elm_css$Css$property, 'transition', '0.2s'),
				rtfeldman$elm_css$Css$active(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$transform(
						rtfeldman$elm_css$Css$scale(0.9))
					])),
				rtfeldman$elm_css$Css$disabled(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$transform(rtfeldman$elm_css$Css$none)
					])),
				rtfeldman$elm_css$Css$hover(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$backgroundColor(hoverColor)
					]))
			]));
};
var elm$json$Json$Encode$bool = _Json_wrap;
var rtfeldman$elm_css$VirtualDom$Styled$property = F2(
	function (key, value) {
		return A3(
			rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2(elm$virtual_dom$VirtualDom$property, key, value),
			_List_Nil,
			'');
	});
var rtfeldman$elm_css$Html$Styled$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			rtfeldman$elm_css$VirtualDom$Styled$property,
			key,
			elm$json$Json$Encode$bool(bool));
	});
var rtfeldman$elm_css$Html$Styled$Attributes$disabled = rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('disabled');
var elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var rtfeldman$elm_css$VirtualDom$Styled$on = F2(
	function (eventName, handler) {
		return A3(
			rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2(elm$virtual_dom$VirtualDom$on, eventName, handler),
			_List_Nil,
			'');
	});
var rtfeldman$elm_css$Html$Styled$Events$on = F2(
	function (event, decoder) {
		return A2(
			rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var rtfeldman$elm_css$Html$Styled$Events$onClick = function (msg) {
	return A2(
		rtfeldman$elm_css$Html$Styled$Events$on,
		'click',
		elm$json$Json$Decode$succeed(msg));
};
var author$project$Exercises$BasicIntervals$btnWrapper = F3(
	function (model, done, interval) {
		var userInput = model.aE;
		var mi = model.z;
		if (!userInput.$) {
			var si = userInput.a;
			return A3(
				author$project$UI$Elements$noteBtn,
				(model.T && _Utils_eq(interval, mi)) ? 3 : ((_Utils_eq(interval, si) && (model.T && (!_Utils_eq(interval, mi)))) ? 2 : (_Utils_eq(interval, si) ? 1 : 0)),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(
						author$project$Exercises$BasicIntervals$SelectInterval(
							elm$core$Maybe$Just(interval))),
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.T || done)
					]),
				_List_fromArray(
					[
						author$project$Exercises$BasicIntervals$basicIntervalToLabel(interval)
					]));
		} else {
			return A3(
				author$project$UI$Elements$noteBtn,
				0,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(
						author$project$Exercises$BasicIntervals$SelectInterval(
							elm$core$Maybe$Just(interval))),
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.T || done)
					]),
				_List_fromArray(
					[
						author$project$Exercises$BasicIntervals$basicIntervalToLabel(interval)
					]));
		}
	});
var author$project$Exercises$BasicIntervals$directionToInt = function (direction) {
	if (!direction) {
		return 1;
	} else {
		return -1;
	}
};
var author$project$Exercises$BasicIntervals$config = {am: '#222', dH: 'rgba(0,0,0,0.3)'};
var rtfeldman$elm_css$Svg$Styled$Attributes$transform = rtfeldman$elm_css$VirtualDom$Styled$attribute('transform');
var author$project$Exercises$BasicIntervals$legerLine = function (offset) {
	return A2(
		rtfeldman$elm_css$Svg$Styled$line,
		_List_fromArray(
			[
				rtfeldman$elm_css$Svg$Styled$Attributes$x1('5.5'),
				rtfeldman$elm_css$Svg$Styled$Attributes$x2('7.8'),
				rtfeldman$elm_css$Svg$Styled$Attributes$y1('0'),
				rtfeldman$elm_css$Svg$Styled$Attributes$y2('0'),
				rtfeldman$elm_css$Svg$Styled$Attributes$strokeWidth('0.1'),
				rtfeldman$elm_css$Svg$Styled$Attributes$stroke(author$project$Exercises$BasicIntervals$config.am),
				rtfeldman$elm_css$Svg$Styled$Attributes$transform(
				'translate(8,' + (elm$core$String$fromInt(offset) + ')'))
			]),
		_List_Nil);
};
var elm$core$Basics$truncate = _Basics_truncate;
var author$project$Exercises$BasicIntervals$legerLines = function (offset) {
	var lines = offset | 0;
	return (lines > 2) ? A2(
		elm$core$List$drop,
		1,
		A2(
			elm$core$List$map,
			author$project$Exercises$BasicIntervals$legerLine,
			A2(elm$core$List$range, 2, lines))) : ((_Utils_cmp(lines, -2) < 0) ? A2(
		elm$core$List$drop,
		1,
		elm$core$List$reverse(
			A2(
				elm$core$List$map,
				author$project$Exercises$BasicIntervals$legerLine,
				A2(elm$core$List$range, lines, -2)))) : _List_Nil);
};
var author$project$Exercises$BasicIntervals$noteHead = A2(
	rtfeldman$elm_css$Svg$Styled$path,
	_List_fromArray(
		[
			rtfeldman$elm_css$Svg$Styled$Attributes$d('M220 138c56 0 109 -29 109 -91c0 -72 -56 -121 -103 -149c-36 -21 -76 -36 -117 -36c-56 0 -109 29 -109 91c0 72 56 121 103 149c36 21 76 36 117 36z'),
			rtfeldman$elm_css$Svg$Styled$Attributes$fill(author$project$Exercises$BasicIntervals$config.am),
			rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(0,3) scale(0.004, -0.004)')
		]),
	_List_Nil);
var rtfeldman$elm_css$Svg$Styled$rect = rtfeldman$elm_css$Svg$Styled$node('rect');
var author$project$Exercises$BasicIntervals$noteStem = function (stemDirection) {
	if (!stemDirection) {
		return A2(
			rtfeldman$elm_css$Svg$Styled$rect,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$fill(author$project$Exercises$BasicIntervals$config.am),
					rtfeldman$elm_css$Svg$Styled$Attributes$height('3.3'),
					rtfeldman$elm_css$Svg$Styled$Attributes$width('0.13'),
					rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(1.18,-0.5)')
				]),
			_List_Nil);
	} else {
		return A2(
			rtfeldman$elm_css$Svg$Styled$rect,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$fill(author$project$Exercises$BasicIntervals$config.am),
					rtfeldman$elm_css$Svg$Styled$Attributes$height('3.3'),
					rtfeldman$elm_css$Svg$Styled$Attributes$width('0.13'),
					rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(0,3.2)')
				]),
			_List_Nil);
	}
};
var rtfeldman$elm_css$Svg$Styled$g = rtfeldman$elm_css$Svg$Styled$node('g');
var rtfeldman$elm_css$Svg$Styled$Attributes$id = rtfeldman$elm_css$VirtualDom$Styled$attribute('id');
var author$project$Exercises$BasicIntervals$drawNote = F3(
	function (offsetX, direction, offsetY) {
		return A2(
			rtfeldman$elm_css$Svg$Styled$g,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$transform(
					'translate(' + (elm$core$String$fromInt(offsetX) + ',0)'))
				]),
			_List_fromArray(
				[
					A2(
					rtfeldman$elm_css$Svg$Styled$g,
					_List_fromArray(
						[
							rtfeldman$elm_css$Svg$Styled$Attributes$id('note'),
							rtfeldman$elm_css$Svg$Styled$Attributes$transform(
							'translate(14,' + (elm$core$String$fromFloat(2 + offsetY) + ')'))
						]),
					_List_fromArray(
						[
							author$project$Exercises$BasicIntervals$noteHead,
							author$project$Exercises$BasicIntervals$noteStem(direction)
						])),
					A2(
					rtfeldman$elm_css$Svg$Styled$g,
					_List_fromArray(
						[
							rtfeldman$elm_css$Svg$Styled$Attributes$id('legerLines'),
							rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(0,5)')
						]),
					author$project$Exercises$BasicIntervals$legerLines(offsetY))
				]));
	});
var author$project$Exercises$BasicIntervals$drawInterval = F3(
	function (root, interval, direction) {
		var rootOffset = root / 2;
		var otherOffset = (root - (author$project$Exercises$BasicIntervals$basicIntervalToInt(interval) * author$project$Exercises$BasicIntervals$directionToInt(direction))) / 2;
		var stemDirection = function () {
			var _n0 = (_Utils_cmp(rootOffset, otherOffset) < 0) ? _Utils_Tuple2(rootOffset, otherOffset) : _Utils_Tuple2(otherOffset, rootOffset);
			var higher = _n0.a;
			var lower = _n0.b;
			return (lower > 2) ? 0 : ((_Utils_cmp(higher, -2) < 0) ? 1 : 1);
		}();
		return A2(
			rtfeldman$elm_css$Svg$Styled$g,
			_List_Nil,
			_List_fromArray(
				[
					A3(author$project$Exercises$BasicIntervals$drawNote, -2, stemDirection, rootOffset),
					A3(author$project$Exercises$BasicIntervals$drawNote, 1, stemDirection, otherOffset)
				]));
	});
var author$project$Exercises$BasicIntervals$staffLine = function (offset) {
	return A2(
		rtfeldman$elm_css$Svg$Styled$line,
		_List_fromArray(
			[
				rtfeldman$elm_css$Svg$Styled$Attributes$id('line1'),
				rtfeldman$elm_css$Svg$Styled$Attributes$x1('0'),
				rtfeldman$elm_css$Svg$Styled$Attributes$x2('12'),
				rtfeldman$elm_css$Svg$Styled$Attributes$y1('0'),
				rtfeldman$elm_css$Svg$Styled$Attributes$y2('0'),
				rtfeldman$elm_css$Svg$Styled$Attributes$strokeWidth('0.1'),
				rtfeldman$elm_css$Svg$Styled$Attributes$stroke(author$project$Exercises$BasicIntervals$config.am),
				rtfeldman$elm_css$Svg$Styled$Attributes$transform(
				'translate(8,' + (elm$core$String$fromInt(offset) + ')'))
			]),
		_List_Nil);
};
var author$project$UI$Elements$Check = 0;
var author$project$UI$Elements$Next = 1;
var author$project$UI$Elements$Reset = 2;
var rtfeldman$elm_css$Html$Styled$h2 = rtfeldman$elm_css$Html$Styled$node('h2');
var rtfeldman$elm_css$Html$Styled$Attributes$css = rtfeldman$elm_css$Html$Styled$Internal$css;
var author$project$UI$Elements$description = function (desc) {
	return A2(
		rtfeldman$elm_css$Html$Styled$h2,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$fontWeight(
						rtfeldman$elm_css$Css$int(600))
					]))
			]),
		desc);
};
var author$project$Icons$FeatherIcons$rotateCw = A2(
	author$project$Icons$FeatherIcons$makeBuilder,
	'rotate-cw',
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$polyline,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$points('23 4 23 10 17 10')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$d('M20.49 15a9 9 0 1 1-2.12-9.36L23 10')
				]),
			_List_Nil)
		]));
var author$project$Icons$FeatherIcons$withClass = F2(
	function (_class, _n0) {
		var attrs = _n0.p;
		var src = _n0.t;
		return {
			p: _Utils_update(
				attrs,
				{
					bd: elm$core$Maybe$Just(_class)
				}),
			t: src
		};
	});
var rtfeldman$elm_css$Css$prop3 = F4(
	function (key, argA, argB, argC) {
		return A2(
			rtfeldman$elm_css$Css$property,
			key,
			A2(
				elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.I, argB.I, argC.I])));
	});
var rtfeldman$elm_css$Css$border3 = rtfeldman$elm_css$Css$prop3('border');
var rtfeldman$elm_css$Css$boxShadow = rtfeldman$elm_css$Css$prop1('box-shadow');
var rtfeldman$elm_css$Css$prop5 = F6(
	function (key, argA, argB, argC, argD, argE) {
		return A2(
			rtfeldman$elm_css$Css$property,
			key,
			A2(
				elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.I, argB.I, argC.I, argD.I, argE.I])));
	});
var rtfeldman$elm_css$Css$boxShadow5 = rtfeldman$elm_css$Css$prop5('box-shadow');
var rtfeldman$elm_css$Css$default = {a: 0, I: 'default'};
var rtfeldman$elm_css$Css$inset = {u: 0, I: 'inset'};
var rtfeldman$elm_css$Css$RemUnits = 0;
var rtfeldman$elm_css$Css$rem = A2(rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'rem');
var rtfeldman$elm_css$Css$rgba = F4(
	function (r, g, b, alpha) {
		return {
			aR: alpha,
			dq: b,
			am: 0,
			cw: g,
			d2: r,
			I: A2(
				rtfeldman$elm_css$Css$cssFunction,
				'rgba',
				_Utils_ap(
					A2(
						elm$core$List$map,
						elm$core$String$fromInt,
						_List_fromArray(
							[r, g, b])),
					_List_fromArray(
						[
							elm$core$String$fromFloat(alpha)
						])))
		};
	});
var rtfeldman$elm_css$Css$solid = {u: 0, af: 0, I: 'solid'};
var rtfeldman$elm_css$Css$textTransform = rtfeldman$elm_css$Css$prop1('text-transform');
var rtfeldman$elm_css$Css$uppercase = {ag: 0, I: 'uppercase'};
var rtfeldman$elm_css$Css$width = rtfeldman$elm_css$Css$prop1('width');
var author$project$UI$Elements$btn = A2(
	rtfeldman$elm_css$Html$Styled$styled,
	rtfeldman$elm_css$Html$Styled$button,
	_List_fromArray(
		[
			rtfeldman$elm_css$Css$margin(
			rtfeldman$elm_css$Css$em(1)),
			rtfeldman$elm_css$Css$width(
			rtfeldman$elm_css$Css$em(12)),
			rtfeldman$elm_css$Css$height(
			rtfeldman$elm_css$Css$em(3)),
			rtfeldman$elm_css$Css$border(
			rtfeldman$elm_css$Css$px(0)),
			rtfeldman$elm_css$Css$borderRadius(
			rtfeldman$elm_css$Css$px(4)),
			rtfeldman$elm_css$Css$cursor(rtfeldman$elm_css$Css$pointer),
			rtfeldman$elm_css$Css$backgroundColor(author$project$UI$Branding$colors.dq),
			A5(
			rtfeldman$elm_css$Css$boxShadow5,
			rtfeldman$elm_css$Css$inset,
			rtfeldman$elm_css$Css$em(0),
			rtfeldman$elm_css$Css$rem(-0.3),
			rtfeldman$elm_css$Css$em(0),
			A4(rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0.1)),
			rtfeldman$elm_css$Css$color(author$project$UI$Branding$colors.cM),
			A2(rtfeldman$elm_css$Css$property, 'transition', '0.2s'),
			rtfeldman$elm_css$Css$fontWeight(
			rtfeldman$elm_css$Css$int(800)),
			rtfeldman$elm_css$Css$fontSize(
			rtfeldman$elm_css$Css$em(1.2)),
			rtfeldman$elm_css$Css$textTransform(rtfeldman$elm_css$Css$uppercase),
			rtfeldman$elm_css$Css$active(
			_List_fromArray(
				[
					rtfeldman$elm_css$Css$transform(
					rtfeldman$elm_css$Css$scale(0.9))
				])),
			rtfeldman$elm_css$Css$disabled(
			_List_fromArray(
				[
					rtfeldman$elm_css$Css$cursor(rtfeldman$elm_css$Css$default),
					rtfeldman$elm_css$Css$backgroundColor(
					rtfeldman$elm_css$Css$hex('FFF')),
					rtfeldman$elm_css$Css$color(
					rtfeldman$elm_css$Css$hex('777')),
					A3(
					rtfeldman$elm_css$Css$border3,
					rtfeldman$elm_css$Css$px(1),
					rtfeldman$elm_css$Css$solid,
					rtfeldman$elm_css$Css$hex('CCC')),
					rtfeldman$elm_css$Css$boxShadow(rtfeldman$elm_css$Css$none),
					rtfeldman$elm_css$Css$color(
					rtfeldman$elm_css$Css$hex('CCC')),
					rtfeldman$elm_css$Css$active(
					_List_fromArray(
						[
							rtfeldman$elm_css$Css$transform(rtfeldman$elm_css$Css$none)
						]))
				]))
		]));
var rtfeldman$elm_css$Html$Styled$text = rtfeldman$elm_css$VirtualDom$Styled$text;
var author$project$UI$Elements$exerciseBtn = F2(
	function (state, attributes) {
		switch (state) {
			case 0:
				return A2(
					author$project$UI$Elements$btn,
					attributes,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$text('Check')
						]));
			case 1:
				return A2(
					author$project$UI$Elements$btn,
					attributes,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$text('Next')
						]));
			default:
				return A2(
					author$project$UI$Elements$btn,
					attributes,
					_List_fromArray(
						[
							A2(
							author$project$Icons$FeatherIcons$toHtml,
							_List_Nil,
							A2(
								author$project$Icons$FeatherIcons$withSizeUnit,
								'em',
								A2(
									author$project$Icons$FeatherIcons$withSize,
									1.5,
									A2(author$project$Icons$FeatherIcons$withClass, 'light', author$project$Icons$FeatherIcons$rotateCw))))
						]));
		}
	});
var author$project$UI$Elements$exerciseWidth = rtfeldman$elm_css$Css$em(40);
var rtfeldman$elm_css$Css$absolute = {a1: 0, I: 'absolute'};
var rtfeldman$elm_css$Css$auto = {dm: 0, a: 0, ar: 0, bk: 0, dL: 0, ay: 0, P: 0, G: 0, aB: 0, D: 0, bE: 0, aL: 0, y: 0, I: 'auto'};
var rtfeldman$elm_css$Css$center = rtfeldman$elm_css$Css$prop1('center');
var rtfeldman$elm_css$Css$left = rtfeldman$elm_css$Css$prop1('left');
var rtfeldman$elm_css$Css$margin2 = rtfeldman$elm_css$Css$prop2('margin');
var rtfeldman$elm_css$Css$maxWidth = rtfeldman$elm_css$Css$prop1('max-width');
var rtfeldman$elm_css$Css$prop4 = F5(
	function (key, argA, argB, argC, argD) {
		return A2(
			rtfeldman$elm_css$Css$property,
			key,
			A2(
				elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.I, argB.I, argC.I, argD.I])));
	});
var rtfeldman$elm_css$Css$padding4 = rtfeldman$elm_css$Css$prop4('padding');
var rtfeldman$elm_css$Css$PercentageUnits = 0;
var rtfeldman$elm_css$Css$pct = A2(rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '%');
var rtfeldman$elm_css$Css$position = rtfeldman$elm_css$Css$prop1('position');
var rtfeldman$elm_css$Css$relative = {a1: 0, I: 'relative'};
var rtfeldman$elm_css$Css$textAlign = function (fn) {
	return A3(
		rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'textAlign',
		'text-align',
		fn(rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var rtfeldman$elm_css$Css$top = rtfeldman$elm_css$Css$prop1('top');
var rtfeldman$elm_css$Html$Styled$div = rtfeldman$elm_css$Html$Styled$node('div');
var author$project$UI$Elements$exerciseFrame = F2(
	function (progress, body) {
		var progressBarHeight = 20;
		return A2(
			rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							rtfeldman$elm_css$Css$maxWidth(author$project$UI$Elements$exerciseWidth),
							rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$auto)
						]))
				]),
			_List_fromArray(
				[
					A2(
					rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center),
									rtfeldman$elm_css$Css$backgroundColor(
									rtfeldman$elm_css$Css$hex('FFF')),
									rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$relative),
									A2(
									rtfeldman$elm_css$Css$margin2,
									rtfeldman$elm_css$Css$px(0),
									rtfeldman$elm_css$Css$em(1)),
									rtfeldman$elm_css$Css$marginTop(
									rtfeldman$elm_css$Css$em(1))
								]))
						]),
					_List_fromArray(
						[
							A2(
							rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$relative)
										]))
								]),
							_List_fromArray(
								[
									A2(
									rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$absolute),
													rtfeldman$elm_css$Css$top(
													rtfeldman$elm_css$Css$px(0)),
													rtfeldman$elm_css$Css$left(
													rtfeldman$elm_css$Css$px(0)),
													rtfeldman$elm_css$Css$height(
													rtfeldman$elm_css$Css$px(progressBarHeight)),
													rtfeldman$elm_css$Css$backgroundColor(author$project$UI$Branding$colors.cw),
													rtfeldman$elm_css$Css$width(
													rtfeldman$elm_css$Css$pct(progress)),
													A2(rtfeldman$elm_css$Css$property, 'transition', '0.3s'),
													rtfeldman$elm_css$Css$borderRadius(
													rtfeldman$elm_css$Css$px(progressBarHeight / 2))
												]))
										]),
									_List_Nil),
									A2(
									rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$absolute),
													rtfeldman$elm_css$Css$top(
													rtfeldman$elm_css$Css$px(0)),
													rtfeldman$elm_css$Css$left(
													rtfeldman$elm_css$Css$px(0)),
													rtfeldman$elm_css$Css$height(
													rtfeldman$elm_css$Css$px(progressBarHeight)),
													rtfeldman$elm_css$Css$backgroundColor(
													A4(rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0.1)),
													rtfeldman$elm_css$Css$width(
													rtfeldman$elm_css$Css$pct(100)),
													rtfeldman$elm_css$Css$borderRadius(
													rtfeldman$elm_css$Css$px(progressBarHeight / 2))
												]))
										]),
									_List_Nil)
								])),
							A2(
							rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											A4(
											rtfeldman$elm_css$Css$padding4,
											rtfeldman$elm_css$Css$em(1),
											rtfeldman$elm_css$Css$em(1),
											rtfeldman$elm_css$Css$em(0),
											rtfeldman$elm_css$Css$em(1))
										]))
								]),
							body)
						]))
				]));
	});
var rtfeldman$elm_css$Svg$Styled$Attributes$style = rtfeldman$elm_css$VirtualDom$Styled$attribute('style');
var author$project$Exercises$BasicIntervals$view = function (model) {
	var progress = 100 * (elm$core$List$length(model.aw) / model.aW);
	var intervalStr = author$project$Exercises$BasicIntervals$basicIntervalToString(model.z);
	var done = progress >= 100;
	return A2(
		author$project$UI$Elements$exerciseFrame,
		progress,
		_List_fromArray(
			[
				author$project$UI$Elements$description(
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$text('Identify the interval')
					])),
				A2(
				rtfeldman$elm_css$Svg$Styled$svg,
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$Attributes$width('90mm'),
						rtfeldman$elm_css$Svg$Styled$Attributes$viewBox('8 0 12 10'),
						rtfeldman$elm_css$Svg$Styled$Attributes$style('font-family: sans-serif; font-size: 11px; max-width: 100%;')
					]),
				_List_fromArray(
					[
						A2(
						rtfeldman$elm_css$Svg$Styled$g,
						_List_fromArray(
							[
								rtfeldman$elm_css$Svg$Styled$Attributes$id('svgBasicIntervals')
							]),
						_List_fromArray(
							[
								A2(
								rtfeldman$elm_css$Svg$Styled$g,
								_List_fromArray(
									[
										rtfeldman$elm_css$Svg$Styled$Attributes$id('lines'),
										rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(0,2)')
									]),
								A2(
									elm$core$List$map,
									author$project$Exercises$BasicIntervals$staffLine,
									A2(elm$core$List$range, 1, 5))),
								function () {
								var _n0 = model.a2;
								if (!_n0.$) {
									var root = _n0.a;
									return A3(author$project$Exercises$BasicIntervals$drawInterval, root, model.z, model.bM);
								} else {
									return A2(rtfeldman$elm_css$Svg$Styled$g, _List_Nil, _List_Nil);
								}
							}()
							]))
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				A2(
					elm$core$List$map,
					function (x) {
						return A3(author$project$Exercises$BasicIntervals$btnWrapper, model, done, x);
					},
					model.ax)),
				done ? A2(
				author$project$UI$Elements$exerciseBtn,
				2,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$BasicIntervals$Reset)
					])) : (model.T ? A2(
				author$project$UI$Elements$exerciseBtn,
				1,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$BasicIntervals$GetRandomInterval)
					])) : A2(
				author$project$UI$Elements$exerciseBtn,
				0,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(
						_Utils_eq(model.aE, elm$core$Maybe$Nothing)),
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$BasicIntervals$CheckInterval)
					])))
			]));
};
var author$project$Exercises$Intervals$CheckInterval = {$: 8};
var author$project$Exercises$Intervals$GetRandomClef = {$: 1};
var author$project$Exercises$Intervals$Reset = {$: 7};
var author$project$Exercises$Intervals$SelectInterval = function (a) {
	return {$: 5, a: a};
};
var author$project$Exercises$Intervals$intervalToLabel = function (mNum) {
	if (!mNum.$) {
		var num = mNum.a;
		switch (num) {
			case 1:
				return rtfeldman$elm_css$Svg$Styled$text('Unison');
			case 2:
				return A2(
					rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							rtfeldman$elm_css$Svg$Styled$text('2'),
							A2(
							rtfeldman$elm_css$Html$Styled$sup,
							_List_Nil,
							_List_fromArray(
								[
									rtfeldman$elm_css$Svg$Styled$text('nd')
								]))
						]));
			case 3:
				return A2(
					rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							rtfeldman$elm_css$Svg$Styled$text('3'),
							A2(
							rtfeldman$elm_css$Html$Styled$sup,
							_List_Nil,
							_List_fromArray(
								[
									rtfeldman$elm_css$Svg$Styled$text('rd')
								]))
						]));
			case 4:
				return A2(
					rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							rtfeldman$elm_css$Svg$Styled$text('4'),
							A2(
							rtfeldman$elm_css$Html$Styled$sup,
							_List_Nil,
							_List_fromArray(
								[
									rtfeldman$elm_css$Svg$Styled$text('th')
								]))
						]));
			case 5:
				return A2(
					rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							rtfeldman$elm_css$Svg$Styled$text('5'),
							A2(
							rtfeldman$elm_css$Html$Styled$sup,
							_List_Nil,
							_List_fromArray(
								[
									rtfeldman$elm_css$Svg$Styled$text('th')
								]))
						]));
			case 6:
				return A2(
					rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							rtfeldman$elm_css$Svg$Styled$text('6'),
							A2(
							rtfeldman$elm_css$Html$Styled$sup,
							_List_Nil,
							_List_fromArray(
								[
									rtfeldman$elm_css$Svg$Styled$text('th')
								]))
						]));
			case 7:
				return A2(
					rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							rtfeldman$elm_css$Svg$Styled$text('7'),
							A2(
							rtfeldman$elm_css$Html$Styled$sup,
							_List_Nil,
							_List_fromArray(
								[
									rtfeldman$elm_css$Svg$Styled$text('th')
								]))
						]));
			case 8:
				return rtfeldman$elm_css$Svg$Styled$text('Octave');
			case 9:
				return A2(
					rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							rtfeldman$elm_css$Svg$Styled$text('9'),
							A2(
							rtfeldman$elm_css$Html$Styled$sup,
							_List_Nil,
							_List_fromArray(
								[
									rtfeldman$elm_css$Svg$Styled$text('th')
								]))
						]));
			case 10:
				return A2(
					rtfeldman$elm_css$Html$Styled$span,
					_List_Nil,
					_List_fromArray(
						[
							rtfeldman$elm_css$Svg$Styled$text('10'),
							A2(
							rtfeldman$elm_css$Html$Styled$sup,
							_List_Nil,
							_List_fromArray(
								[
									rtfeldman$elm_css$Svg$Styled$text('th')
								]))
						]));
			default:
				var n = num;
				return rtfeldman$elm_css$Svg$Styled$text(
					elm$core$String$fromInt(n));
		}
	} else {
		return rtfeldman$elm_css$Svg$Styled$text('');
	}
};
var author$project$Exercises$Intervals$intervalBtn = F3(
	function (model, done, interval) {
		var userInterval = model.aE;
		var mi = model.z;
		if (!userInterval.$) {
			var si = userInterval.a;
			return A3(
				author$project$UI$Elements$noteBtn,
				(model.T && _Utils_eq(interval, mi)) ? 3 : ((_Utils_eq(
					interval,
					elm$core$Maybe$Just(si)) && (model.T && (!_Utils_eq(interval, mi)))) ? 2 : (_Utils_eq(
					interval,
					elm$core$Maybe$Just(si)) ? 1 : 0)),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(
						author$project$Exercises$Intervals$SelectInterval(interval)),
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.T || done)
					]),
				_List_fromArray(
					[
						author$project$Exercises$Intervals$intervalToLabel(interval)
					]));
		} else {
			return A3(
				author$project$UI$Elements$noteBtn,
				0,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(
						author$project$Exercises$Intervals$SelectInterval(interval)),
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.T || done)
					]),
				_List_fromArray(
					[
						author$project$Exercises$Intervals$intervalToLabel(interval)
					]));
		}
	});
var author$project$Theory$TheoryEngine$getInterval = F3(
	function (interval, direction, root) {
		if (!direction) {
			return _List_fromArray(
				[
					root,
					A3(author$project$Theory$TheoryEngine$transposeNote, interval, direction, root)
				]);
		} else {
			return _List_fromArray(
				[
					A3(author$project$Theory$TheoryEngine$transposeNote, interval, direction, root),
					root
				]);
		}
	});
var author$project$Exercises$Intervals$modelToNotes = function (model) {
	var _n0 = model.a2;
	if (!_n0.$) {
		var note = _n0.a;
		var _n1 = model.z;
		if (!_n1.$) {
			var interval = _n1.a;
			var _n2 = model.Z;
			if (!_n2.$) {
				var quality = _n2.a;
				return A3(
					author$project$Theory$TheoryEngine$getInterval,
					A2(author$project$Theory$TheoryEngine$IntervalName, quality, interval),
					0,
					note);
			} else {
				return _List_Nil;
			}
		} else {
			return _List_Nil;
		}
	} else {
		return _List_Nil;
	}
};
var author$project$Exercises$Intervals$SelectQuality = function (a) {
	return {$: 6, a: a};
};
var author$project$Theory$TheoryEngine$qualityToString = function (quality) {
	switch (quality) {
		case 0:
			return 'Diminished';
		case 1:
			return 'Minor';
		case 2:
			return 'Major';
		case 3:
			return 'Augmented';
		default:
			return 'Perfect';
	}
};
var author$project$Exercises$Intervals$qualityBtn = F3(
	function (model, done, quality) {
		var userQuality = model.aF;
		var toString = function (x) {
			if (!x.$) {
				var q = x.a;
				return rtfeldman$elm_css$Svg$Styled$text(
					author$project$Theory$TheoryEngine$qualityToString(q));
			} else {
				return rtfeldman$elm_css$Svg$Styled$text('');
			}
		};
		var mq = model.Z;
		if (!userQuality.$) {
			var si = userQuality.a;
			return A3(
				author$project$UI$Elements$noteBtn,
				(model.T && _Utils_eq(quality, mq)) ? 3 : ((_Utils_eq(
					quality,
					elm$core$Maybe$Just(si)) && (model.T && (!_Utils_eq(quality, mq)))) ? 2 : (_Utils_eq(
					quality,
					elm$core$Maybe$Just(si)) ? 1 : 0)),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(
						author$project$Exercises$Intervals$SelectQuality(quality)),
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.T || done)
					]),
				_List_fromArray(
					[
						toString(quality)
					]));
		} else {
			return A3(
				author$project$UI$Elements$noteBtn,
				0,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(
						author$project$Exercises$Intervals$SelectQuality(quality)),
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.T || done)
					]),
				_List_fromArray(
					[
						toString(quality)
					]));
		}
	});
var author$project$Theory$RenderEngine$config = {am: '#222', dH: 'rgba(0,0,0,0.3)'};
var author$project$Theory$RenderEngine$staffLine = function (offset) {
	return A2(
		rtfeldman$elm_css$Svg$Styled$line,
		_List_fromArray(
			[
				rtfeldman$elm_css$Svg$Styled$Attributes$x1('0'),
				rtfeldman$elm_css$Svg$Styled$Attributes$x2('14'),
				rtfeldman$elm_css$Svg$Styled$Attributes$y1('0'),
				rtfeldman$elm_css$Svg$Styled$Attributes$y2('0'),
				rtfeldman$elm_css$Svg$Styled$Attributes$strokeWidth('0.1'),
				rtfeldman$elm_css$Svg$Styled$Attributes$stroke(author$project$Theory$RenderEngine$config.am),
				rtfeldman$elm_css$Svg$Styled$Attributes$transform(
				'translate(8,' + (elm$core$String$fromInt(offset) + ')'))
			]),
		_List_Nil);
};
var author$project$Theory$RenderEngine$renderLines = A2(
	rtfeldman$elm_css$Svg$Styled$svg,
	_List_fromArray(
		[
			rtfeldman$elm_css$Svg$Styled$Attributes$width('90mm'),
			rtfeldman$elm_css$Svg$Styled$Attributes$viewBox('8 0 14 10'),
			rtfeldman$elm_css$Svg$Styled$Attributes$style('font-family: sans-serif; font-size: 11px; max-width: 100%;')
		]),
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$g,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					rtfeldman$elm_css$Svg$Styled$g,
					_List_fromArray(
						[
							rtfeldman$elm_css$Svg$Styled$Attributes$id('lines'),
							rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(0,2)')
						]),
					A2(
						elm$core$List$map,
						author$project$Theory$RenderEngine$staffLine,
						A2(elm$core$List$range, 1, 5)))
				]))
		]));
var author$project$Theory$RenderEngine$NoteCoordinate = F2(
	function (y, bumpNoteHead) {
		return {cf: bumpNoteHead, ai: y};
	});
var author$project$Theory$RenderEngine$PartialAccidentalCoordinate = F2(
	function (y, accidental) {
		return {dk: accidental, ai: y};
	});
var author$project$Theory$RenderEngine$doubleFlat = rtfeldman$elm_css$Svg$Styled$Attributes$d('M190 41l-1 -66c0 -4 -1 -7 -1 -11c0 -22 1 -44 4 -66c44 37 87 82 87 139c0 33 -11 67 -40 67c-30 0 -48 -30 -49 -63zM147 -138l-2 106c-27 -34 -66 -62 -98 -93c-13 -13 -19 -38 -39 -38c-13 0 -23 11 -23 25l-12 595c8 5 18 8 27 8s19 -3 27 -8l-7 -345 c17 21 43 34 70 34c20 0 38 -8 52 -21l-7 332c8 5 18 8 27 8s19 -3 27 -8l-6 -345c24 21 55 34 87 34c53 0 92 -47 92 -101c0 -80 -86 -118 -147 -170c-15 -13 -23 -38 -44 -38c-13 0 -24 11 -24 25zM17 -26v-10c0 -23 1 -45 4 -68c39 38 71 86 71 141c0 32 -6 67 -33 67 c-28 0 -39 -32 -40 -63z');
var author$project$Theory$RenderEngine$doubleSharp = rtfeldman$elm_css$Svg$Styled$Attributes$d('M152 0c23 -23 56 -28 89 -28c6 0 10 -4 10 -10l9 -87c1 -5 -4 -10 -9 -10h-1l-87 9c-5 1 -10 5 -10 10c0 33 -5 66 -28 89c-23 -23 -28 -56 -28 -89c0 -5 -4 -9 -9 -10l-88 -9h-1c-5 0 -10 5 -9 10l9 87c0 6 4 10 10 10c33 0 66 5 89 28c-23 23 -56 28 -89 28 c-6 0 -10 4 -10 10l-9 87c-1 5 4 10 9 10h1l88 -9c5 -1 9 -5 9 -10c0 -33 5 -66 28 -89c23 23 28 56 28 89c0 5 5 9 10 10l87 9h1c5 0 10 -5 9 -10l-9 -87c0 -6 -4 -10 -10 -10c-33 0 -66 -5 -89 -28z');
var author$project$Theory$RenderEngine$flat = rtfeldman$elm_css$Svg$Styled$Attributes$d('M27 41l-1 -66v-11c0 -22 1 -44 4 -66c45 38 93 80 93 139c0 33 -14 67 -43 67c-31 0 -52 -30 -53 -63zM-15 -138l-12 595c8 5 18 8 27 8s19 -3 27 -8l-7 -345c25 21 58 34 91 34c52 0 89 -48 89 -102c0 -80 -86 -117 -147 -169c-15 -13 -24 -38 -45 -38 c-13 0 -23 11 -23 25z');
var author$project$Theory$RenderEngine$natural = rtfeldman$elm_css$Svg$Styled$Attributes$d('M-8 375c8 4 17 7 26 7s16 -3 24 -7l-2 -183l106 20h3c10 0 18 -7 18 -17l7 -570c-8 -4 -16 -7 -25 -7s-17 3 -25 7l3 183l-106 -20h-3c-10 0 -18 7 -18 17zM131 112l-92 -17l-3 -207l92 17z');
var author$project$Theory$RenderEngine$sharp = rtfeldman$elm_css$Svg$Styled$Attributes$d('M216 -312c0 -10 -8 -19 -18 -19s-19 9 -19 19v145l-83 -31v-158c0 -10 -9 -19 -19 -19s-18 9 -18 19v145l-32 -12c-2 -1 -5 -1 -7 -1c-11 0 -20 9 -20 20v60c0 8 5 16 13 19l46 16v160l-32 -11c-2 -1 -5 -1 -7 -1c-11 0 -20 9 -20 20v60c0 8 5 15 13 18l46 17v158 c0 10 8 19 18 19s19 -9 19 -19v-145l83 31v158c0 10 9 19 19 19s18 -9 18 -19v-145l32 12c2 1 5 1 7 1c11 0 20 -9 20 -20v-60c0 -8 -5 -16 -13 -19l-46 -16v-160l32 11c2 1 5 1 7 1c11 0 20 -9 20 -20v-60c0 -8 -5 -15 -13 -18l-46 -17v-158zM96 65v-160l83 30v160z');
var author$project$Theory$RenderEngine$drawAccidental = F3(
	function (offsetY, accidental, offset) {
		var y = elm$core$String$fromFloat(5 + offsetY);
		var aOffset = elm$core$String$fromFloat(5 + offset);
		var draw = function (f) {
			return A2(
				rtfeldman$elm_css$Svg$Styled$path,
				_List_fromArray(
					[
						f,
						rtfeldman$elm_css$Svg$Styled$Attributes$fill(author$project$Theory$RenderEngine$config.am),
						rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(' + (aOffset + (',' + (y + ') scale(0.0040, -0.0040)'))))
					]),
				_List_Nil);
		};
		switch (accidental) {
			case 2:
				return draw(author$project$Theory$RenderEngine$natural);
			case 3:
				return A2(rtfeldman$elm_css$Svg$Styled$path, _List_Nil, _List_Nil);
			case 1:
				return draw(author$project$Theory$RenderEngine$flat);
			case 4:
				return draw(author$project$Theory$RenderEngine$sharp);
			case 0:
				return draw(author$project$Theory$RenderEngine$doubleFlat);
			default:
				return draw(author$project$Theory$RenderEngine$doubleSharp);
		}
	});
var author$project$Theory$RenderEngine$cClef = function (offset) {
	return A2(
		rtfeldman$elm_css$Svg$Styled$path,
		_List_fromArray(
			[
				rtfeldman$elm_css$Svg$Styled$Attributes$d('M318 0c0 -33 7 -73 45 -73c29 0 57 31 88 31c123 0 229 -89 229 -208c0 -169 -93 -250 -265 -250c-83 0 -152 61 -152 142c0 42 34 77 76 77s76 -35 76 -77c0 -39 -49 -37 -49 -76c0 -23 24 -38 49 -38c116 0 140 90 140 222c0 106 -12 180 -104 180 c-72 0 -119 -71 -119 -149c0 -9 -7 -14 -14 -14s-13 5 -13 14c0 76 -31 147 -84 200v-471c0 -6 -4 -10 -10 -10h-21c-6 0 -10 4 -10 10v980c0 6 4 10 10 10h21c6 0 10 -4 10 -10v-471c53 53 84 124 84 200c0 9 6 14 13 14s14 -5 14 -14c0 -78 47 -149 119 -149 c92 0 104 74 104 180c0 132 -24 222 -140 222c-25 0 -49 -15 -49 -38c0 -39 49 -37 49 -76c0 -42 -34 -77 -76 -77s-76 35 -76 77c0 81 69 142 152 142c172 0 265 -81 265 -250c0 -119 -106 -208 -229 -208c-31 0 -59 31 -88 31c-38 0 -45 -40 -45 -73zM129 -500h-119 c-6 0 -10 4 -10 10v980c0 6 4 10 10 10h119c6 0 10 -4 10 -10v-980c0 -6 -4 -10 -10 -10z'),
				rtfeldman$elm_css$Svg$Styled$Attributes$fill(author$project$Theory$RenderEngine$config.am),
				rtfeldman$elm_css$Svg$Styled$Attributes$transform(
				'translate(8.8,' + (elm$core$String$fromInt(offset) + ') scale(0.004, -0.004)'))
			]),
		_List_Nil);
};
var author$project$Theory$RenderEngine$altoClef = author$project$Theory$RenderEngine$cClef(3);
var author$project$Theory$RenderEngine$bassClef = A2(
	rtfeldman$elm_css$Svg$Styled$path,
	_List_fromArray(
		[
			rtfeldman$elm_css$Svg$Styled$Attributes$d('M557 -125c0 28 23 51 51 51s51 -23 51 -51s-23 -51 -51 -51s-51 23 -51 51zM557 125c0 28 23 51 51 51s51 -23 51 -51s-23 -51 -51 -51s-51 23 -51 51zM232 263c172 0 293 -88 293 -251c0 -263 -263 -414 -516 -521c-3 -3 -6 -4 -9 -4c-7 0 -13 6 -13 13c0 3 1 6 4 9 c202 118 412 265 412 493c0 120 -63 235 -171 235c-74 0 -129 -54 -154 -126c11 5 22 8 34 8c55 0 100 -45 100 -100c0 -58 -44 -106 -100 -106c-60 0 -112 47 -112 106c0 133 102 244 232 244z'),
			rtfeldman$elm_css$Svg$Styled$Attributes$fill(author$project$Theory$RenderEngine$config.am),
			rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(8.8,2) scale(0.004, -0.004)')
		]),
	_List_Nil);
var author$project$Theory$RenderEngine$tenorClef = author$project$Theory$RenderEngine$cClef(2);
var author$project$Theory$RenderEngine$trebleClef = A2(
	rtfeldman$elm_css$Svg$Styled$path,
	_List_fromArray(
		[
			rtfeldman$elm_css$Svg$Styled$Attributes$d('M376 262c4 0 9 1 13 1c155 0 256 -128 256 -261c0 -76 -33 -154 -107 -210c-22 -17 -47 -28 -73 -36c3 -35 5 -70 5 -105c0 -19 -1 -39 -2 -58c-7 -120 -90 -228 -208 -228c-108 0 -195 88 -195 197c0 58 53 103 112 103c54 0 95 -47 95 -103c0 -52 -43 -95 -95 -95\nc-11 0 -21 2 -31 6c26 -39 68 -65 117 -65c96 0 157 92 163 191c1 18 2 37 2 55c0 31 -1 61 -4 92c-29 -5 -58 -8 -89 -8c-188 0 -333 172 -333 374c0 177 131 306 248 441c-19 62 -37 125 -45 190c-6 52 -7 104 -7 156c0 115 55 224 149 292c3 2 7 3 10 3c4 0 7 0 10 -3\nc71 -84 133 -245 133 -358c0 -143 -86 -255 -180 -364c21 -68 39 -138 56 -207zM461 -203c68 24 113 95 113 164c0 90 -66 179 -173 190c24 -116 46 -231 60 -354zM74 28c0 -135 129 -247 264 -247c28 0 55 2 82 6c-14 127 -37 245 -63 364c-79 -8 -124 -61 -124 -119\nc0 -44 25 -91 81 -123c5 -5 7 -10 7 -15c0 -11 -10 -22 -22 -22c-3 0 -6 1 -9 2c-80 43 -117 115 -117 185c0 88 58 174 160 197c-14 58 -29 117 -46 175c-107 -121 -213 -243 -213 -403zM408 1045c-99 -48 -162 -149 -162 -259c0 -74 18 -133 36 -194\nc80 97 146 198 146 324c0 55 -4 79 -20 129z'),
			rtfeldman$elm_css$Svg$Styled$Attributes$fill(author$project$Theory$RenderEngine$config.am),
			rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(8.8,4) scale(0.004, -0.004)')
		]),
	_List_Nil);
var author$project$Theory$RenderEngine$drawClef = function (clef) {
	switch (clef) {
		case 0:
			return author$project$Theory$RenderEngine$trebleClef;
		case 1:
			return author$project$Theory$RenderEngine$bassClef;
		case 2:
			return author$project$Theory$RenderEngine$altoClef;
		default:
			return author$project$Theory$RenderEngine$tenorClef;
	}
};
var author$project$Theory$RenderEngine$noteHead = A2(
	rtfeldman$elm_css$Svg$Styled$path,
	_List_fromArray(
		[
			rtfeldman$elm_css$Svg$Styled$Attributes$d('M213 112c-50 0 -69 -43 -69 -88c0 -77 57 -136 134 -136c50 0 69 43 69 88c0 77 -57 136 -134 136zM491 0c0 -43 -34 -75 -72 -96c-53 -29 -114 -40 -174 -40s-120 11 -173 40c-38 21 -72 53 -72 96s34 75 72 96c53 29 113 40 173 40s121 -11 174 -40 c38 -21 72 -53 72 -96z'),
			rtfeldman$elm_css$Svg$Styled$Attributes$fill(author$project$Theory$RenderEngine$config.am),
			rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(0,3) scale(0.004, -0.004)')
		]),
	_List_Nil);
var author$project$Theory$RenderEngine$drawNote = function (offset) {
	return A2(
		rtfeldman$elm_css$Svg$Styled$g,
		_List_fromArray(
			[
				rtfeldman$elm_css$Svg$Styled$Attributes$transform(
				'translate(14,' + (elm$core$String$fromFloat(2 + offset) + ')'))
			]),
		_List_fromArray(
			[author$project$Theory$RenderEngine$noteHead]));
};
var author$project$Theory$RenderEngine$legerLine = function (offset) {
	return A2(
		rtfeldman$elm_css$Svg$Styled$line,
		_List_fromArray(
			[
				rtfeldman$elm_css$Svg$Styled$Attributes$x1('5.5'),
				rtfeldman$elm_css$Svg$Styled$Attributes$x2('8.45'),
				rtfeldman$elm_css$Svg$Styled$Attributes$y1('0'),
				rtfeldman$elm_css$Svg$Styled$Attributes$y2('0'),
				rtfeldman$elm_css$Svg$Styled$Attributes$strokeWidth('0.1'),
				rtfeldman$elm_css$Svg$Styled$Attributes$stroke(author$project$Theory$RenderEngine$config.am),
				rtfeldman$elm_css$Svg$Styled$Attributes$transform(
				'translate(8,' + (elm$core$String$fromInt(offset) + ')'))
			]),
		_List_Nil);
};
var author$project$Theory$RenderEngine$legerLines = function (offset) {
	var lines = offset | 0;
	return (lines > 2) ? A2(
		elm$core$List$drop,
		1,
		A2(
			elm$core$List$map,
			author$project$Theory$RenderEngine$legerLine,
			A2(elm$core$List$range, 2, lines))) : ((_Utils_cmp(lines, -2) < 0) ? A2(
		elm$core$List$drop,
		1,
		elm$core$List$reverse(
			A2(
				elm$core$List$map,
				author$project$Theory$RenderEngine$legerLine,
				A2(elm$core$List$range, lines, -2)))) : _List_Nil);
};
var author$project$Theory$RenderEngine$drawNoteAndLegerLine = function (pos) {
	return A2(
		rtfeldman$elm_css$Svg$Styled$g,
		_List_fromArray(
			[
				rtfeldman$elm_css$Svg$Styled$Attributes$transform(
				pos.cf ? 'translate(1.7,0)' : 'translate(0,0)')
			]),
		_List_fromArray(
			[
				author$project$Theory$RenderEngine$drawNote(pos.ai),
				A2(
				rtfeldman$elm_css$Svg$Styled$g,
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(0,5)')
					]),
				author$project$Theory$RenderEngine$legerLines(pos.ai))
			]));
};
var author$project$Theory$RenderEngine$clefReferenceNote = function (clef) {
	switch (clef) {
		case 0:
			return A3(author$project$Theory$TheoryEngine$Note, 6, 4, 3);
		case 1:
			return A3(author$project$Theory$TheoryEngine$Note, 1, 3, 3);
		case 2:
			return A3(author$project$Theory$TheoryEngine$Note, 0, 4, 3);
		default:
			return A3(author$project$Theory$TheoryEngine$Note, 5, 3, 3);
	}
};
var author$project$Theory$RenderEngine$noteToInt = function (note) {
	var octave = note.dW;
	var offset = octave * 7;
	var noteName = note.dT;
	switch (noteName) {
		case 0:
			return 0 + offset;
		case 1:
			return 1 + offset;
		case 2:
			return 2 + offset;
		case 3:
			return 3 + offset;
		case 4:
			return 4 + offset;
		case 5:
			return 5 + offset;
		case 6:
			return 6 + offset;
		default:
			return -1;
	}
};
var author$project$Theory$RenderEngine$noteDistance = F2(
	function (note1, note2) {
		return author$project$Theory$RenderEngine$noteToInt(note1) - author$project$Theory$RenderEngine$noteToInt(note2);
	});
var author$project$Theory$RenderEngine$noteOffset = F2(
	function (clef, note) {
		return A2(
			author$project$Theory$RenderEngine$noteDistance,
			author$project$Theory$RenderEngine$clefReferenceNote(clef),
			note) / 2;
	});
var author$project$Theory$RenderEngine$SM = F2(
	function (note, offsets) {
		return {dU: note, bq: offsets};
	});
var author$project$Theory$RenderEngine$computeOffsetsX = F2(
	function (state, notes) {
		computeOffsetsX:
		while (true) {
			if (!notes.b) {
				return state.bq;
			} else {
				var x = notes.a;
				var xs = notes.b;
				var diff = x - state.dU;
				var offset = A2(
					elm$core$Maybe$withDefault,
					false,
					elm$core$List$head(
						elm$core$List$reverse(state.bq))) ? false : ((diff <= 1) ? true : false);
				var $temp$state = A2(
					author$project$Theory$RenderEngine$SM,
					x,
					A2(
						elm$core$List$append,
						state.bq,
						_List_fromArray(
							[offset]))),
					$temp$notes = xs;
				state = $temp$state;
				notes = $temp$notes;
				continue computeOffsetsX;
			}
		}
	});
var author$project$Theory$RenderEngine$noteOffsetsX = function (notes) {
	if (!notes.b) {
		return _List_Nil;
	} else {
		var x = notes.a;
		var xs = notes.b;
		return A2(
			author$project$Theory$RenderEngine$computeOffsetsX,
			A2(
				author$project$Theory$RenderEngine$SM,
				author$project$Theory$RenderEngine$noteToInt(x),
				_List_fromArray(
					[false])),
			A2(elm$core$List$map, author$project$Theory$RenderEngine$noteToInt, xs));
	}
};
var author$project$Theory$RenderEngine$noteComparison = F2(
	function (noteA, noteB) {
		var b = author$project$Theory$RenderEngine$noteToInt(noteB);
		var a = author$project$Theory$RenderEngine$noteToInt(noteA);
		return A2(elm$core$Basics$compare, a, b);
	});
var elm$core$List$sortWith = _List_sortWith;
var author$project$Theory$RenderEngine$sortNotes = function (notes) {
	return A2(elm$core$List$sortWith, author$project$Theory$RenderEngine$noteComparison, notes);
};
var author$project$Theory$RenderEngine$AccidentalCoordinate = F3(
	function (y, offset, accidental) {
		return {dk: accidental, cR: offset, ai: y};
	});
var author$project$Theory$RenderEngine$accidentalOffset = function (accidental) {
	switch (accidental) {
		case 2:
			return 1.1;
		case 3:
			return 0;
		case 1:
			return 1.0;
		case 4:
			return 1.1;
		case 0:
			return 1.6;
		default:
			return 1.1;
	}
};
var author$project$Theory$RenderEngine$computeAccidentalOffsets = F3(
	function (accidentals, result, offset) {
		computeAccidentalOffsets:
		while (true) {
			if (!accidentals.b) {
				return result;
			} else {
				var x = accidentals.a;
				var xs = accidentals.b;
				var newOffset = offset - author$project$Theory$RenderEngine$accidentalOffset(x.dk);
				var $temp$accidentals = elm$core$List$reverse(xs),
					$temp$result = A2(
					elm$core$List$cons,
					A3(author$project$Theory$RenderEngine$AccidentalCoordinate, x.ai, newOffset, x.dk),
					result),
					$temp$offset = newOffset;
				accidentals = $temp$accidentals;
				result = $temp$result;
				offset = $temp$offset;
				continue computeAccidentalOffsets;
			}
		}
	});
var author$project$Theory$RenderEngine$stackAccidentals = function (accidentals) {
	var filteredAccidentals = elm$core$List$reverse(
		A2(
			elm$core$List$filter,
			function (x) {
				return x.dk !== 3;
			},
			accidentals));
	return A3(author$project$Theory$RenderEngine$computeAccidentalOffsets, filteredAccidentals, _List_Nil, 8.7);
};
var author$project$Theory$RenderEngine$drawNotesOnStaff = F2(
	function (notes, clef) {
		var sortedNotes = author$project$Theory$RenderEngine$sortNotes(notes);
		var posY = A2(
			elm$core$List$map,
			author$project$Theory$RenderEngine$noteOffset(clef),
			sortedNotes);
		var noteHeadOffsets = author$project$Theory$RenderEngine$noteOffsetsX(sortedNotes);
		var noteHeadCoordinates = A3(
			elm$core$List$map2,
			F2(
				function (x, y) {
					return A2(author$project$Theory$RenderEngine$NoteCoordinate, x, y);
				}),
			posY,
			noteHeadOffsets);
		var accidentalCoordinates = author$project$Theory$RenderEngine$stackAccidentals(
			A3(
				elm$core$List$map2,
				F2(
					function (x, y) {
						return A2(author$project$Theory$RenderEngine$PartialAccidentalCoordinate, y, x.dk);
					}),
				sortedNotes,
				posY));
		return _List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Svg$Styled$g,
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$Attributes$id('lines'),
						rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(0,2)')
					]),
				A2(
					elm$core$List$map,
					author$project$Theory$RenderEngine$staffLine,
					A2(elm$core$List$range, 1, 5))),
				A2(
				rtfeldman$elm_css$Svg$Styled$g,
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$Attributes$id('clef'),
						rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(0,2)')
					]),
				_List_fromArray(
					[
						author$project$Theory$RenderEngine$drawClef(clef)
					])),
				A2(
				rtfeldman$elm_css$Svg$Styled$g,
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$Attributes$id('notes'),
						rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(3,0)')
					]),
				_List_fromArray(
					[
						A2(
						rtfeldman$elm_css$Svg$Styled$g,
						_List_fromArray(
							[
								rtfeldman$elm_css$Svg$Styled$Attributes$id('noteHeads')
							]),
						A2(elm$core$List$map, author$project$Theory$RenderEngine$drawNoteAndLegerLine, noteHeadCoordinates)),
						A2(
						rtfeldman$elm_css$Svg$Styled$g,
						_List_fromArray(
							[
								rtfeldman$elm_css$Svg$Styled$Attributes$id('accidentals')
							]),
						A2(
							elm$core$List$map,
							function (x) {
								return A3(author$project$Theory$RenderEngine$drawAccidental, x.ai, x.dk, x.cR);
							},
							accidentalCoordinates))
					]))
			]);
	});
var author$project$Theory$RenderEngine$renderNotes = F2(
	function (notes, clef) {
		return A2(
			rtfeldman$elm_css$Svg$Styled$svg,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$width('90mm'),
					rtfeldman$elm_css$Svg$Styled$Attributes$viewBox('8 0 14 10'),
					rtfeldman$elm_css$Svg$Styled$Attributes$style('font-family: sans-serif; font-size: 11px; max-width: 100%;')
				]),
			_List_fromArray(
				[
					A2(
					rtfeldman$elm_css$Svg$Styled$g,
					_List_Nil,
					A2(author$project$Theory$RenderEngine$drawNotesOnStaff, notes, clef))
				]));
	});
var rtfeldman$elm_css$Css$marginLeft = rtfeldman$elm_css$Css$prop1('margin-left');
var author$project$Exercises$Intervals$view = function (model) {
	var progress = 100 * (elm$core$List$length(model.aw) / model.aW);
	var done = progress >= 100;
	return A2(
		author$project$UI$Elements$exerciseFrame,
		progress,
		_List_fromArray(
			[
				author$project$UI$Elements$description(
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$text('Select the interval shown on the staff')
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$marginRight(rtfeldman$elm_css$Css$auto),
								rtfeldman$elm_css$Css$marginLeft(rtfeldman$elm_css$Css$auto),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$em(45)),
								rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center)
							]))
					]),
				function () {
					var _n0 = model.bK;
					if (!_n0.$) {
						var clef = _n0.a;
						return _List_fromArray(
							[
								A2(
								author$project$Theory$RenderEngine$renderNotes,
								author$project$Exercises$Intervals$modelToNotes(model),
								clef)
							]);
					} else {
						return _List_fromArray(
							[author$project$Theory$RenderEngine$renderLines]);
					}
				}()),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				A2(
					elm$core$List$map,
					function (x) {
						return A3(
							author$project$Exercises$Intervals$qualityBtn,
							model,
							done,
							elm$core$Maybe$Just(x));
					},
					model.bw)),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$em(1))
							]))
					]),
				A2(
					elm$core$List$map,
					function (x) {
						return A3(
							author$project$Exercises$Intervals$intervalBtn,
							model,
							done,
							elm$core$Maybe$Just(x));
					},
					model.ax)),
				done ? A2(
				author$project$UI$Elements$exerciseBtn,
				2,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$Intervals$Reset)
					])) : (model.T ? A2(
				author$project$UI$Elements$exerciseBtn,
				1,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$Intervals$GetRandomClef)
					])) : A2(
				author$project$UI$Elements$exerciseBtn,
				0,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(
						_Utils_eq(model.aE, elm$core$Maybe$Nothing) || _Utils_eq(model.aF, elm$core$Maybe$Nothing)),
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$Intervals$CheckInterval)
					])))
			]));
};
var author$project$Exercises$BasicIntervals$directionToString = function (direction) {
	if (!direction) {
		return 'Up';
	} else {
		return 'Down';
	}
};
var author$project$Exercises$KeyBasicIntervals$CheckAnswer = {$: 5};
var author$project$Exercises$KeyBasicIntervals$GetRandomInterval = {$: 3};
var author$project$Exercises$KeyBasicIntervals$Reset = {$: 6};
var author$project$Theory$Keyboard$ClickKey = function (a) {
	return {$: 0, a: a};
};
var author$project$Theory$Keyboard$NoOp = {$: 1};
var author$project$Theory$Keyboard$isMember = F2(
	function (list, i) {
		return A2(elm$core$List$member, i, list);
	});
var author$project$Theory$Keyboard$offsetToKeyboardKey = function (offset) {
	switch (offset) {
		case 'm0.75932':
			return author$project$Theory$Keyboard$C(1);
		case 'm40.656':
			return author$project$Theory$Keyboard$D(1);
		case 'm80.543':
			return author$project$Theory$Keyboard$E(1);
		case 'm120.45':
			return author$project$Theory$Keyboard$F(1);
		case 'm160.34':
			return author$project$Theory$Keyboard$G(1);
		case 'm200.23':
			return author$project$Theory$Keyboard$A(1);
		case 'm240.15':
			return author$project$Theory$Keyboard$B(1);
		case 'm280.05':
			return author$project$Theory$Keyboard$C(2);
		case 'm319.95':
			return author$project$Theory$Keyboard$D(2);
		case 'm359.84':
			return author$project$Theory$Keyboard$E(2);
		case 'm399.73':
			return author$project$Theory$Keyboard$F(2);
		case 'm439.63':
			return author$project$Theory$Keyboard$G(2);
		case 'm479.52':
			return author$project$Theory$Keyboard$A(2);
		case 'm519.44':
			return author$project$Theory$Keyboard$B(2);
		case 'm559.34':
			return author$project$Theory$Keyboard$C(3);
		case '29.434':
			return author$project$Theory$Keyboard$Cis(1);
		case '69.321':
			return author$project$Theory$Keyboard$Dis(1);
		case '149.12':
			return author$project$Theory$Keyboard$Fis(1);
		case '189.01':
			return author$project$Theory$Keyboard$Gis(1);
		case '228.93':
			return author$project$Theory$Keyboard$Ais(1);
		case '308.73':
			return author$project$Theory$Keyboard$Cis(2);
		case '348.62':
			return author$project$Theory$Keyboard$Dis(2);
		case '428.41':
			return author$project$Theory$Keyboard$Fis(2);
		case '468.29':
			return author$project$Theory$Keyboard$Gis(2);
		case '508.22':
			return author$project$Theory$Keyboard$Ais(2);
		default:
			return author$project$Theory$Keyboard$None;
	}
};
var author$project$Theory$Keyboard$fillKey = F5(
	function (model, offset, color, isAnswer, showAnswer) {
		return A2(
			author$project$Theory$Keyboard$isMember,
			model.Y,
			author$project$Theory$Keyboard$offsetToKeyboardKey(offset)) ? rtfeldman$elm_css$Svg$Styled$Attributes$fill('#' + author$project$UI$Branding$colorStr.dq) : ((isAnswer && A2(
			author$project$Theory$Keyboard$isMember,
			model.$7,
			author$project$Theory$Keyboard$offsetToKeyboardKey(offset))) ? rtfeldman$elm_css$Svg$Styled$Attributes$fill('#' + author$project$UI$Branding$colorStr.cw) : (((!isAnswer) && (showAnswer && (A2(
			author$project$Theory$Keyboard$isMember,
			model.cJ,
			author$project$Theory$Keyboard$offsetToKeyboardKey(offset)) && (!A2(
			author$project$Theory$Keyboard$isMember,
			model.$7,
			author$project$Theory$Keyboard$offsetToKeyboardKey(offset)))))) ? rtfeldman$elm_css$Svg$Styled$Attributes$fill('#' + author$project$UI$Branding$colorStr.d2) : (((!isAnswer) && (showAnswer && (A2(
			author$project$Theory$Keyboard$isMember,
			model.cJ,
			author$project$Theory$Keyboard$offsetToKeyboardKey(offset)) && A2(
			author$project$Theory$Keyboard$isMember,
			model.$7,
			author$project$Theory$Keyboard$offsetToKeyboardKey(offset))))) ? rtfeldman$elm_css$Svg$Styled$Attributes$fill('#' + author$project$UI$Branding$colorStr.cw) : (((!isAnswer) && A2(
			author$project$Theory$Keyboard$isMember,
			model.cJ,
			author$project$Theory$Keyboard$offsetToKeyboardKey(offset))) ? rtfeldman$elm_css$Svg$Styled$Attributes$fill('#' + author$project$UI$Branding$colorStr.dw) : rtfeldman$elm_css$Svg$Styled$Attributes$fill(color)))));
	});
var rtfeldman$elm_css$Svg$Styled$Internal$css = function (styles) {
	var classname = rtfeldman$elm_css$VirtualDom$Styled$getClassname(styles);
	var classAttribute = A2(elm$virtual_dom$VirtualDom$attribute, 'class', classname);
	return A3(rtfeldman$elm_css$VirtualDom$Styled$Attribute, classAttribute, styles, classname);
};
var rtfeldman$elm_css$Svg$Styled$Attributes$css = rtfeldman$elm_css$Svg$Styled$Internal$css;
var author$project$Theory$Keyboard$keyHover = F4(
	function (model, isAnswer, showAnswer, offset) {
		return rtfeldman$elm_css$Svg$Styled$Attributes$css(
			(isAnswer || showAnswer) ? _List_Nil : (A2(
				elm$core$List$member,
				author$project$Theory$Keyboard$offsetToKeyboardKey(offset),
				model.Y) ? _List_Nil : ((_Utils_eq(
				elm$core$List$length(model.$7),
				elm$core$List$length(model.cJ)) && (!A2(
				elm$core$List$member,
				author$project$Theory$Keyboard$offsetToKeyboardKey(offset),
				model.cJ))) ? _List_Nil : _List_fromArray(
				[
					rtfeldman$elm_css$Css$hover(
					_List_fromArray(
						[
							rtfeldman$elm_css$Css$cursor(rtfeldman$elm_css$Css$pointer)
						]))
				]))));
	});
var rtfeldman$elm_css$Svg$Styled$Attributes$x = rtfeldman$elm_css$VirtualDom$Styled$attribute('x');
var rtfeldman$elm_css$Svg$Styled$Attributes$y = rtfeldman$elm_css$VirtualDom$Styled$attribute('y');
var rtfeldman$elm_css$Svg$Styled$Events$onClick = function (msg) {
	return A2(
		rtfeldman$elm_css$Html$Styled$Events$on,
		'click',
		elm$json$Json$Decode$succeed(msg));
};
var author$project$Theory$Keyboard$blackKey = F4(
	function (model, isAnswer, showAnswer, offset) {
		return A2(
			rtfeldman$elm_css$Svg$Styled$rect,
			_List_fromArray(
				[
					A5(author$project$Theory$Keyboard$fillKey, model, offset, '#333', isAnswer, showAnswer),
					showAnswer ? rtfeldman$elm_css$Svg$Styled$Events$onClick(author$project$Theory$Keyboard$NoOp) : rtfeldman$elm_css$Svg$Styled$Events$onClick(
					author$project$Theory$Keyboard$ClickKey(
						author$project$Theory$Keyboard$offsetToKeyboardKey(offset))),
					A4(author$project$Theory$Keyboard$keyHover, model, isAnswer, showAnswer, offset),
					rtfeldman$elm_css$Svg$Styled$Attributes$height('97.287'),
					rtfeldman$elm_css$Svg$Styled$Attributes$width('22.448'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y('890.47'),
					rtfeldman$elm_css$Svg$Styled$Attributes$x(offset)
				]),
			_List_Nil);
	});
var author$project$Theory$Keyboard$blackKeyOffsets = _List_fromArray(
	['29.434', '69.321', '149.12', '189.01', '228.93', '308.73', '348.62', '428.41', '468.29', '508.22']);
var author$project$Theory$Keyboard$whiteKey = F4(
	function (model, isAnswer, showAnswer, offset) {
		return A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					A5(author$project$Theory$Keyboard$fillKey, model, offset, 'white', isAnswer, showAnswer),
					showAnswer ? rtfeldman$elm_css$Svg$Styled$Events$onClick(author$project$Theory$Keyboard$NoOp) : rtfeldman$elm_css$Svg$Styled$Events$onClick(
					author$project$Theory$Keyboard$ClickKey(
						author$project$Theory$Keyboard$offsetToKeyboardKey(offset))),
					A4(author$project$Theory$Keyboard$keyHover, model, isAnswer, showAnswer, offset),
					rtfeldman$elm_css$Svg$Styled$Attributes$d(offset + ' 890.52v157.05 0.1366l0.006 0.01a4.0199 4.0199 0 0 0 3.8737 3.8737l0.006 0.01h0.13348 15.931 15.931 0.13349l0.006-0.01a4.0199 4.0199 0 0 0 3.8737 -3.8737l0.006-0.01v-0.1366-157.05h-19.95-19.95z')
				]),
			_List_Nil);
	});
var author$project$Theory$Keyboard$whiteKeyOffsets = _List_fromArray(
	['m0.75932', 'm40.656', 'm80.543', 'm120.45', 'm160.34', 'm200.23', 'm240.15', 'm280.05', 'm319.95', 'm359.84', 'm399.73', 'm439.63', 'm479.52', 'm559.34', 'm519.44']);
var rtfeldman$elm_css$Svg$Styled$Attributes$version = rtfeldman$elm_css$VirtualDom$Styled$attribute('version');
var author$project$Theory$Keyboard$keyboard = F3(
	function (model, isAnswer, showAnswer) {
		return A2(
			rtfeldman$elm_css$Svg$Styled$svg,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$height('100%'),
					rtfeldman$elm_css$Svg$Styled$Attributes$width('100%'),
					rtfeldman$elm_css$Svg$Styled$Attributes$version('1.1'),
					rtfeldman$elm_css$Svg$Styled$Attributes$viewBox('0 0 599.99993 162.60325')
				]),
			_List_fromArray(
				[
					A2(
					rtfeldman$elm_css$Svg$Styled$g,
					_List_fromArray(
						[
							rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(-.000032047 -889.76)'),
							rtfeldman$elm_css$Svg$Styled$Attributes$stroke('#333')
						]),
					_List_fromArray(
						[
							A2(
							rtfeldman$elm_css$Svg$Styled$g,
							_List_fromArray(
								[
									rtfeldman$elm_css$Svg$Styled$Attributes$strokeWidth('1.5186'),
									rtfeldman$elm_css$Svg$Styled$Attributes$fill('#fff')
								]),
							A2(
								elm$core$List$map,
								A3(author$project$Theory$Keyboard$whiteKey, model, isAnswer, showAnswer),
								author$project$Theory$Keyboard$whiteKeyOffsets)),
							A2(
							rtfeldman$elm_css$Svg$Styled$g,
							_List_fromArray(
								[
									rtfeldman$elm_css$Svg$Styled$Attributes$strokeWidth('1.3238'),
									rtfeldman$elm_css$Svg$Styled$Attributes$fill('#333')
								]),
							A2(
								elm$core$List$map,
								A3(author$project$Theory$Keyboard$blackKey, model, isAnswer, showAnswer),
								author$project$Theory$Keyboard$blackKeyOffsets))
						]))
				]));
	});
var author$project$Theory$Keyboard$keyboardKeyToInt = function (key) {
	var offset = F2(
		function (x, y) {
			return ((x - 1) * 12) + y;
		});
	switch (key.$) {
		case 0:
			var octave = key.a;
			return A2(offset, octave, 0);
		case 1:
			var octave = key.a;
			return A2(offset, octave, 1);
		case 2:
			var octave = key.a;
			return A2(offset, octave, 2);
		case 3:
			var octave = key.a;
			return A2(offset, octave, 3);
		case 4:
			var octave = key.a;
			return A2(offset, octave, 4);
		case 5:
			var octave = key.a;
			return A2(offset, octave, 5);
		case 6:
			var octave = key.a;
			return A2(offset, octave, 6);
		case 7:
			var octave = key.a;
			return A2(offset, octave, 7);
		case 8:
			var octave = key.a;
			return A2(offset, octave, 8);
		case 9:
			var octave = key.a;
			return A2(offset, octave, 9);
		case 10:
			var octave = key.a;
			return A2(offset, octave, 10);
		case 11:
			var octave = key.a;
			return A2(offset, octave, 11);
		default:
			return -1;
	}
};
var elm$core$Set$Set_elm_builtin = elm$core$Basics$identity;
var elm$core$Set$empty = elm$core$Dict$empty;
var elm$core$Set$insert = F2(
	function (key, _n0) {
		var dict = _n0;
		return A3(elm$core$Dict$insert, key, 0, dict);
	});
var elm$core$Set$fromList = function (list) {
	return A3(elm$core$List$foldl, elm$core$Set$insert, elm$core$Set$empty, list);
};
var author$project$Theory$Keyboard$view = F3(
	function (model, showAnswer, showAnswerKeyboard) {
		return A2(
			rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							rtfeldman$elm_css$Css$marginRight(rtfeldman$elm_css$Css$auto),
							rtfeldman$elm_css$Css$marginLeft(rtfeldman$elm_css$Css$auto),
							rtfeldman$elm_css$Css$maxWidth(
							rtfeldman$elm_css$Css$em(45)),
							rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center)
						]))
				]),
			_List_fromArray(
				[
					A3(author$project$Theory$Keyboard$keyboard, model, false, showAnswer),
					(showAnswer && showAnswerKeyboard) ? ((!_Utils_eq(
					elm$core$Set$fromList(
						A2(elm$core$List$map, author$project$Theory$Keyboard$keyboardKeyToInt, model.$7)),
					elm$core$Set$fromList(
						A2(elm$core$List$map, author$project$Theory$Keyboard$keyboardKeyToInt, model.cJ)))) ? A3(author$project$Theory$Keyboard$keyboard, model, true, showAnswer) : A2(rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)) : A2(rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil)
				]));
	});
var rtfeldman$elm_css$Css$middle = rtfeldman$elm_css$Css$prop1('middle');
var rtfeldman$elm_css$Html$Styled$p = rtfeldman$elm_css$Html$Styled$node('p');
var author$project$UI$Elements$label = function (labels) {
	return A2(
		rtfeldman$elm_css$Html$Styled$p,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$fontWeight(
						rtfeldman$elm_css$Css$int(800)),
						rtfeldman$elm_css$Css$fontSize(
						rtfeldman$elm_css$Css$em(1.5)),
						rtfeldman$elm_css$Css$height(
						rtfeldman$elm_css$Css$em(1.3)),
						rtfeldman$elm_css$Css$verticalAlign(rtfeldman$elm_css$Css$middle)
					]))
			]),
		labels);
};
var rtfeldman$elm_css$Css$bold = {N: 0, I: 'bold'};
var rtfeldman$elm_css$Css$fontStyle = rtfeldman$elm_css$Css$prop1('font-style');
var rtfeldman$elm_css$Css$italic = {as: 0, I: 'italic'};
var rtfeldman$elm_css$Html$Styled$map = rtfeldman$elm_css$VirtualDom$Styled$map;
var author$project$Exercises$KeyBasicIntervals$view = function (model) {
	var progress = 100 * (model.aS / model.aW);
	var done = progress >= 100;
	return A2(
		author$project$UI$Elements$exerciseFrame,
		progress,
		_List_fromArray(
			[
				author$project$UI$Elements$description(
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('Find the interval from the given note on the'),
						A2(
						rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$fontWeight(rtfeldman$elm_css$Css$bold),
										rtfeldman$elm_css$Css$fontStyle(rtfeldman$elm_css$Css$italic)
									]))
							]),
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$text(' white keys')
							]))
					])),
				author$project$UI$Elements$label(
				_List_fromArray(
					[
						author$project$Exercises$BasicIntervals$basicIntervalToLabel(model.z),
						rtfeldman$elm_css$Html$Styled$text(
						' ' + author$project$Exercises$BasicIntervals$directionToString(model.bM))
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$map,
				author$project$Exercises$KeyBasicIntervals$KeyMsg,
				A3(author$project$Theory$Keyboard$view, model.O, model.T, true)),
				done ? A2(
				author$project$UI$Elements$exerciseBtn,
				2,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$KeyBasicIntervals$Reset)
					])) : ((!model.T) ? A2(
				author$project$UI$Elements$exerciseBtn,
				0,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$KeyBasicIntervals$CheckAnswer),
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(
						!elm$core$List$length(model.O.cJ))
					])) : A2(
				author$project$UI$Elements$exerciseBtn,
				1,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$KeyBasicIntervals$GetRandomInterval)
					])))
			]));
};
var author$project$Exercises$KeyIntervals$CheckInterval = {$: 7};
var author$project$Exercises$KeyIntervals$GetRandomInterval = {$: 0};
var author$project$Exercises$KeyIntervals$Reset = {$: 6};
var author$project$Theory$TheoryEngine$directionToString = function (direction) {
	if (!direction) {
		return 'Up';
	} else {
		return 'Down';
	}
};
var elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			elm$core$String$slice,
			-n,
			elm$core$String$length(string),
			string);
	});
var author$project$Theory$TheoryEngine$intervalToLabel = function (interval) {
	switch (interval) {
		case 1:
			return A2(
				rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('Unison')
					]));
		case 8:
			return A2(
				rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('Octave')
					]));
		default:
			var str = elm$core$String$fromInt(interval);
			var isRD = A2(elm$core$String$right, 1, str) === '3';
			var isND = A2(elm$core$String$right, 1, str) === '2';
			return A2(
				rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text(
						elm$core$String$fromInt(interval)),
						A2(
						rtfeldman$elm_css$Html$Styled$sup,
						_List_Nil,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$text(
								isRD ? 'rd' : (isND ? 'nd' : 'th'))
							]))
					]));
	}
};
var author$project$Exercises$KeyIntervals$view = function (model) {
	var qualityLabel = function () {
		var _n2 = model.Z;
		if (!_n2.$) {
			var quality = _n2.a;
			return author$project$Theory$TheoryEngine$qualityToString(quality);
		} else {
			return '';
		}
	}();
	var progress = 100 * (elm$core$List$length(model.aw) / model.aW);
	var intervalLabel = function () {
		var _n1 = model.z;
		if (!_n1.$) {
			var interval = _n1.a;
			return author$project$Theory$TheoryEngine$intervalToLabel(interval);
		} else {
			return A2(rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil);
		}
	}();
	var done = progress >= 100;
	var directionLabel = function () {
		var _n0 = model.bM;
		if (!_n0.$) {
			var direction = _n0.a;
			return author$project$Theory$TheoryEngine$directionToString(direction);
		} else {
			return '';
		}
	}();
	return A2(
		author$project$UI$Elements$exerciseFrame,
		progress,
		_List_fromArray(
			[
				author$project$UI$Elements$description(
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$text('Find the interval from the given note')
					])),
				author$project$UI$Elements$label(
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$text(qualityLabel),
						rtfeldman$elm_css$Svg$Styled$text(' '),
						intervalLabel,
						rtfeldman$elm_css$Svg$Styled$text(' '),
						rtfeldman$elm_css$Svg$Styled$text(directionLabel)
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$map,
				author$project$Exercises$KeyIntervals$KeyMsg,
				A3(author$project$Theory$Keyboard$view, model.O, model.T, true)),
				done ? A2(
				author$project$UI$Elements$exerciseBtn,
				2,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$KeyIntervals$Reset)
					])) : (model.T ? A2(
				author$project$UI$Elements$exerciseBtn,
				1,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$KeyIntervals$GetRandomInterval)
					])) : A2(
				author$project$UI$Elements$exerciseBtn,
				0,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(
						!elm$core$List$length(model.O.cJ)),
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$KeyIntervals$CheckInterval)
					])))
			]));
};
var author$project$Exercises$KeyNotes$CheckNote = {$: 1};
var author$project$Exercises$KeyNotes$NextNote = {$: 0};
var author$project$Exercises$KeyNotes$Reset = {$: 3};
var author$project$Icons$Glyphs$color = 'inherit';
var author$project$Icons$Glyphs$doubleFlatD = rtfeldman$elm_css$Svg$Styled$Attributes$d('M190 41l-1 -66c0 -4 -1 -7 -1 -11c0 -22 1 -44 4 -66c44 37 87 82 87 139c0 33 -11 67 -40 67c-30 0 -48 -30 -49 -63zM147 -138l-2 106c-27 -34 -66 -62 -98 -93c-13 -13 -19 -38 -39 -38c-13 0 -23 11 -23 25l-12 595c8 5 18 8 27 8s19 -3 27 -8l-7 -345 c17 21 43 34 70 34c20 0 38 -8 52 -21l-7 332c8 5 18 8 27 8s19 -3 27 -8l-6 -345c24 21 55 34 87 34c53 0 92 -47 92 -101c0 -80 -86 -118 -147 -170c-15 -13 -23 -38 -44 -38c-13 0 -24 11 -24 25zM17 -26v-10c0 -23 1 -45 4 -68c39 38 71 86 71 141c0 32 -6 67 -33 67 c-28 0 -39 -32 -40 -63z');
var author$project$Icons$Glyphs$doubleFlat = A2(
	rtfeldman$elm_css$Svg$Styled$svg,
	_List_fromArray(
		[
			rtfeldman$elm_css$Svg$Styled$Attributes$width('18px'),
			rtfeldman$elm_css$Svg$Styled$Attributes$viewBox('8 0 18 26')
		]),
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					author$project$Icons$Glyphs$doubleFlatD,
					rtfeldman$elm_css$Svg$Styled$Attributes$fill(author$project$Icons$Glyphs$color),
					rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(10,19) scale(0.040, -0.040)')
				]),
			_List_Nil)
		]));
var author$project$Icons$Glyphs$doubleSharpD = rtfeldman$elm_css$Svg$Styled$Attributes$d('M152 0c23 -23 56 -28 89 -28c6 0 10 -4 10 -10l9 -87c1 -5 -4 -10 -9 -10h-1l-87 9c-5 1 -10 5 -10 10c0 33 -5 66 -28 89c-23 -23 -28 -56 -28 -89c0 -5 -4 -9 -9 -10l-88 -9h-1c-5 0 -10 5 -9 10l9 87c0 6 4 10 10 10c33 0 66 5 89 28c-23 23 -56 28 -89 28 c-6 0 -10 4 -10 10l-9 87c-1 5 4 10 9 10h1l88 -9c5 -1 9 -5 9 -10c0 -33 5 -66 28 -89c23 23 28 56 28 89c0 5 5 9 10 10l87 9h1c5 0 10 -5 9 -10l-9 -87c0 -6 -4 -10 -10 -10c-33 0 -66 -5 -89 -28z');
var author$project$Icons$Glyphs$doubleSharp = A2(
	rtfeldman$elm_css$Svg$Styled$svg,
	_List_fromArray(
		[
			rtfeldman$elm_css$Svg$Styled$Attributes$width('14px'),
			rtfeldman$elm_css$Svg$Styled$Attributes$viewBox('8 0 12 12')
		]),
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					author$project$Icons$Glyphs$doubleSharpD,
					rtfeldman$elm_css$Svg$Styled$Attributes$fill(author$project$Icons$Glyphs$color),
					rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(9,6) scale(0.040, -0.040)')
				]),
			_List_Nil)
		]));
var author$project$Icons$Glyphs$flatD = rtfeldman$elm_css$Svg$Styled$Attributes$d('M27 41l-1 -66v-11c0 -22 1 -44 4 -66c45 38 93 80 93 139c0 33 -14 67 -43 67c-31 0 -52 -30 -53 -63zM-15 -138l-12 595c8 5 18 8 27 8s19 -3 27 -8l-7 -345c25 21 58 34 91 34c52 0 89 -48 89 -102c0 -80 -86 -117 -147 -169c-15 -13 -24 -38 -45 -38 c-13 0 -23 11 -23 25z');
var author$project$Icons$Glyphs$flat = A2(
	rtfeldman$elm_css$Svg$Styled$svg,
	_List_fromArray(
		[
			rtfeldman$elm_css$Svg$Styled$Attributes$width('12px'),
			rtfeldman$elm_css$Svg$Styled$Attributes$viewBox('8 0 12 26')
		]),
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					author$project$Icons$Glyphs$flatD,
					rtfeldman$elm_css$Svg$Styled$Attributes$fill(author$project$Icons$Glyphs$color),
					rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(10,19) scale(0.040, -0.040)')
				]),
			_List_Nil)
		]));
var author$project$Icons$Glyphs$naturalD = rtfeldman$elm_css$Svg$Styled$Attributes$d('M-8 375c8 4 17 7 26 7s16 -3 24 -7l-2 -183l106 20h3c10 0 18 -7 18 -17l7 -570c-8 -4 -16 -7 -25 -7s-17 3 -25 7l3 183l-106 -20h-3c-10 0 -18 7 -18 17zM131 112l-92 -17l-3 -207l92 17z');
var author$project$Icons$Glyphs$natural = A2(
	rtfeldman$elm_css$Svg$Styled$svg,
	_List_fromArray(
		[
			rtfeldman$elm_css$Svg$Styled$Attributes$width('14px'),
			rtfeldman$elm_css$Svg$Styled$Attributes$viewBox('8 0 14 32')
		]),
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					author$project$Icons$Glyphs$naturalD,
					rtfeldman$elm_css$Svg$Styled$Attributes$fill(author$project$Icons$Glyphs$color),
					rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(10,16) scale(0.040, -0.040)')
				]),
			_List_Nil)
		]));
var author$project$Icons$Glyphs$none = A2(rtfeldman$elm_css$Svg$Styled$svg, _List_Nil, _List_Nil);
var author$project$Icons$Glyphs$sharpD = rtfeldman$elm_css$Svg$Styled$Attributes$d('M216 -312c0 -10 -8 -19 -18 -19s-19 9 -19 19v145l-83 -31v-158c0 -10 -9 -19 -19 -19s-18 9 -18 19v145l-32 -12c-2 -1 -5 -1 -7 -1c-11 0 -20 9 -20 20v60c0 8 5 16 13 19l46 16v160l-32 -11c-2 -1 -5 -1 -7 -1c-11 0 -20 9 -20 20v60c0 8 5 15 13 18l46 17v158 c0 10 8 19 18 19s19 -9 19 -19v-145l83 31v158c0 10 9 19 19 19s18 -9 18 -19v-145l32 12c2 1 5 1 7 1c11 0 20 -9 20 -20v-60c0 -8 -5 -16 -13 -19l-46 -16v-160l32 11c2 1 5 1 7 1c11 0 20 -9 20 -20v-60c0 -8 -5 -15 -13 -18l-46 -17v-158zM96 65v-160l83 30v160z');
var author$project$Icons$Glyphs$sharp = A2(
	rtfeldman$elm_css$Svg$Styled$svg,
	_List_fromArray(
		[
			rtfeldman$elm_css$Svg$Styled$Attributes$width('14px'),
			rtfeldman$elm_css$Svg$Styled$Attributes$viewBox('8 0 14 32')
		]),
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					author$project$Icons$Glyphs$sharpD,
					rtfeldman$elm_css$Svg$Styled$Attributes$fill(author$project$Icons$Glyphs$color),
					rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(10,16) scale(0.040, -0.040)')
				]),
			_List_Nil)
		]));
var author$project$Theory$TheoryEngine$accidentalToGlyph = function (acc) {
	switch (acc) {
		case 4:
			return author$project$Icons$Glyphs$sharp;
		case 5:
			return author$project$Icons$Glyphs$doubleSharp;
		case 1:
			return author$project$Icons$Glyphs$flat;
		case 0:
			return author$project$Icons$Glyphs$doubleFlat;
		case 2:
			return author$project$Icons$Glyphs$natural;
		default:
			return author$project$Icons$Glyphs$none;
	}
};
var rtfeldman$elm_css$Css$fill = rtfeldman$elm_css$Css$prop1('fill');
var author$project$Theory$TheoryEngine$noteToLabel = function (note) {
	return A2(
		rtfeldman$elm_css$Html$Styled$span,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$color(
						rtfeldman$elm_css$Css$hex('333')),
						rtfeldman$elm_css$Css$fill(
						rtfeldman$elm_css$Css$hex('333'))
					]))
			]),
		(note.dk === 3) ? _List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$text(
				author$project$Theory$TheoryEngine$noteNameToString(note.dT))
			]) : _List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$text(
				author$project$Theory$TheoryEngine$noteNameToString(note.dT)),
				A2(
				rtfeldman$elm_css$Html$Styled$sup,
				_List_Nil,
				_List_fromArray(
					[
						author$project$Theory$TheoryEngine$accidentalToGlyph(note.dk)
					]))
			]));
};
var author$project$Exercises$KeyNotes$view = function (model) {
	var result = A2(author$project$Exercises$KeyNotes$checkKeys, model.O.cJ, model.O.$7);
	var noteStr = author$project$Theory$TheoryEngine$noteNameToString(model.F.dU.dT);
	var deckLen = elm$core$List$length(model.ap);
	var progress = 100 * (model.aS / deckLen);
	var done = progress >= 100;
	return A2(
		author$project$UI$Elements$exerciseFrame,
		progress,
		_List_fromArray(
			[
				author$project$UI$Elements$description(
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('Find the given note on the keyboard')
					])),
				(!model.bg) ? A2(
				author$project$Theory$RenderEngine$renderNotes,
				_List_fromArray(
					[model.F.dU]),
				model.F.bK) : A2(
				rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$fontWeight(
								rtfeldman$elm_css$Css$int(800)),
								rtfeldman$elm_css$Css$fontSize(
								rtfeldman$elm_css$Css$px(36)),
								rtfeldman$elm_css$Css$height(
								rtfeldman$elm_css$Css$em(1.2)),
								rtfeldman$elm_css$Css$verticalAlign(rtfeldman$elm_css$Css$middle),
								rtfeldman$elm_css$Css$textTransform(rtfeldman$elm_css$Css$uppercase)
							]))
					]),
				_List_fromArray(
					[
						author$project$Theory$TheoryEngine$noteToLabel(model.F.dU)
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$map,
				author$project$Exercises$KeyNotes$KeyMsg,
				A3(author$project$Theory$Keyboard$view, model.O, model.T, !result)),
				done ? A2(
				author$project$UI$Elements$exerciseBtn,
				2,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$KeyNotes$Reset)
					])) : (model.T ? A2(
				author$project$UI$Elements$exerciseBtn,
				1,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$KeyNotes$NextNote)
					])) : A2(
				author$project$UI$Elements$exerciseBtn,
				0,
				_List_fromArray(
					[
						_Utils_eq(model.O.cJ, _List_Nil) ? rtfeldman$elm_css$Html$Styled$Attributes$disabled(true) : rtfeldman$elm_css$Html$Styled$Attributes$disabled(false),
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$KeyNotes$CheckNote)
					])))
			]));
};
var author$project$Exercises$KeySignatures$CheckKey = {$: 6};
var author$project$Exercises$KeySignatures$NextKey = {$: 2};
var author$project$Exercises$KeySignatures$Reset = {$: 5};
var author$project$Exercises$KeySignatures$SelectAccidental = function (a) {
	return {$: 4, a: a};
};
var author$project$Exercises$KeySignatures$accidentalBtn = F3(
	function (model, done, acc) {
		var click = _Utils_eq(model.r, acc) ? author$project$Exercises$KeySignatures$SelectAccidental(3) : author$project$Exercises$KeySignatures$SelectAccidental(acc);
		return A3(
			author$project$UI$Elements$noteBtn,
			(model.T && (_Utils_eq(model.r, acc) && (_Utils_eq(model.r, model.n.dk) && _Utils_eq(
				model.x,
				elm$core$Maybe$Just(model.n.dU))))) ? 3 : ((model.T && (_Utils_eq(model.r, acc) && (!_Utils_eq(model.r, model.n.dk)))) ? 2 : (_Utils_eq(model.r, acc) ? 1 : 0)),
			_List_fromArray(
				[
					rtfeldman$elm_css$Html$Styled$Events$onClick(click),
					rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.T || done)
				]),
			_List_fromArray(
				[
					author$project$Theory$TheoryEngine$accidentalToGlyph(acc)
				]));
	});
var author$project$Exercises$KeySignatures$SelectNote = function (a) {
	return {$: 3, a: a};
};
var author$project$Exercises$KeySignatures$noteBtn = F3(
	function (model, done, note) {
		return A3(
			author$project$UI$Elements$noteBtn,
			(model.T && (_Utils_eq(
				model.x,
				elm$core$Maybe$Just(note)) && (_Utils_eq(
				model.x,
				elm$core$Maybe$Just(model.n.dU)) && _Utils_eq(model.r, model.n.dk)))) ? 3 : ((model.T && _Utils_eq(
				model.x,
				elm$core$Maybe$Just(note))) ? 2 : (_Utils_eq(
				elm$core$Maybe$Just(note),
				model.x) ? 1 : 0)),
			_List_fromArray(
				[
					rtfeldman$elm_css$Html$Styled$Events$onClick(
					author$project$Exercises$KeySignatures$SelectNote(
						elm$core$Maybe$Just(note))),
					rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.T || done)
				]),
			_List_fromArray(
				[
					rtfeldman$elm_css$Html$Styled$text(
					author$project$Theory$TheoryEngine$noteNameToString(note))
				]));
	});
var author$project$Theory$RenderEngine$drawKey = F2(
	function (num, accidental) {
		var sharpOffsets = _List_fromArray(
			[-2, -0.5, -2.5, -1, 0.5, -1.5, 0]);
		var flatOffsets = _List_fromArray(
			[0, -1.5, 0.5, -1, 1, -0.5, 1.5]);
		var accidentals = elm$core$List$indexedMap(
			F2(
				function (x, y) {
					return A3(author$project$Theory$RenderEngine$drawAccidental, y, accidental, x);
				}));
		switch (accidental) {
			case 4:
				return elm$core$Maybe$Just(
					accidentals(
						A2(elm$core$List$take, num, sharpOffsets)));
			case 1:
				return elm$core$Maybe$Just(
					accidentals(
						A2(elm$core$List$take, num, flatOffsets)));
			default:
				return elm$core$Maybe$Nothing;
		}
	});
var author$project$Theory$TheoryEngine$computeMajorKey = F2(
	function (note, accidental) {
		switch (note) {
			case 0:
				switch (accidental) {
					case 4:
						return elm$core$Maybe$Just(
							_Utils_Tuple2(7, 4));
					case 3:
						return elm$core$Maybe$Just(
							_Utils_Tuple2(0, 3));
					case 1:
						return elm$core$Maybe$Just(
							_Utils_Tuple2(7, 1));
					default:
						return elm$core$Maybe$Nothing;
				}
			case 1:
				switch (accidental) {
					case 1:
						return elm$core$Maybe$Just(
							_Utils_Tuple2(5, 1));
					case 3:
						return elm$core$Maybe$Just(
							_Utils_Tuple2(2, 4));
					default:
						return elm$core$Maybe$Nothing;
				}
			case 2:
				switch (accidental) {
					case 1:
						return elm$core$Maybe$Just(
							_Utils_Tuple2(3, 1));
					case 3:
						return elm$core$Maybe$Just(
							_Utils_Tuple2(4, 4));
					default:
						return elm$core$Maybe$Nothing;
				}
			case 3:
				switch (accidental) {
					case 4:
						return elm$core$Maybe$Just(
							_Utils_Tuple2(6, 4));
					case 3:
						return elm$core$Maybe$Just(
							_Utils_Tuple2(1, 1));
					default:
						return elm$core$Maybe$Nothing;
				}
			case 4:
				switch (accidental) {
					case 1:
						return elm$core$Maybe$Just(
							_Utils_Tuple2(6, 1));
					case 3:
						return elm$core$Maybe$Just(
							_Utils_Tuple2(1, 4));
					default:
						return elm$core$Maybe$Nothing;
				}
			case 5:
				switch (accidental) {
					case 1:
						return elm$core$Maybe$Just(
							_Utils_Tuple2(4, 1));
					case 3:
						return elm$core$Maybe$Just(
							_Utils_Tuple2(3, 4));
					default:
						return elm$core$Maybe$Nothing;
				}
			case 6:
				switch (accidental) {
					case 1:
						return elm$core$Maybe$Just(
							_Utils_Tuple2(2, 1));
					case 3:
						return elm$core$Maybe$Just(
							_Utils_Tuple2(5, 4));
					default:
						return elm$core$Maybe$Nothing;
				}
			default:
				return elm$core$Maybe$Nothing;
		}
	});
var author$project$Theory$TheoryEngine$computeMinorKey = F2(
	function (note, accidental) {
		var relativeMajor = A3(
			author$project$Theory$TheoryEngine$transposeNote,
			A2(author$project$Theory$TheoryEngine$IntervalName, 1, 3),
			0,
			A3(author$project$Theory$TheoryEngine$Note, note, 1, accidental));
		var noteName = relativeMajor.dT;
		var acc = relativeMajor.dk;
		return A2(author$project$Theory$TheoryEngine$computeMajorKey, noteName, acc);
	});
var author$project$Theory$TheoryEngine$computeKey = function (key) {
	var _n0 = key.d1;
	switch (_n0) {
		case 2:
			return A2(author$project$Theory$TheoryEngine$computeMajorKey, key.dU, key.dk);
		case 1:
			return A2(author$project$Theory$TheoryEngine$computeMinorKey, key.dU, key.dk);
		default:
			return elm$core$Maybe$Nothing;
	}
};
var author$project$Theory$RenderEngine$drawKeySignature = F2(
	function (keySignature, clef) {
		var clefOffset = function () {
			switch (clef) {
				case 0:
					return 0;
				case 1:
					return 1;
				case 3:
					return -0.5;
				default:
					return 0.5;
			}
		}();
		var _n0 = A2(
			elm$core$Maybe$withDefault,
			_Utils_Tuple2(0, 3),
			author$project$Theory$TheoryEngine$computeKey(keySignature));
		var num = _n0.a;
		var acc = _n0.b;
		return _List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Svg$Styled$g,
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$Attributes$id('lines'),
						rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(0,2)')
					]),
				A2(
					elm$core$List$map,
					author$project$Theory$RenderEngine$staffLine,
					A2(elm$core$List$range, 1, 5))),
				A2(
				rtfeldman$elm_css$Svg$Styled$g,
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$Attributes$id('clef'),
						rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(0,2)')
					]),
				_List_fromArray(
					[
						author$project$Theory$RenderEngine$drawClef(clef)
					])),
				A2(
				rtfeldman$elm_css$Svg$Styled$g,
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$Attributes$id('keySignature'),
						rtfeldman$elm_css$Svg$Styled$Attributes$transform(
						'translate(7,' + (elm$core$String$fromFloat(clefOffset) + ')'))
					]),
				A2(
					elm$core$Maybe$withDefault,
					_List_Nil,
					A2(author$project$Theory$RenderEngine$drawKey, num, acc)))
			]);
	});
var author$project$Theory$RenderEngine$renderKeySignatureSize = F3(
	function (size, key, clef) {
		return A2(
			rtfeldman$elm_css$Svg$Styled$svg,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$width(size),
					rtfeldman$elm_css$Svg$Styled$Attributes$viewBox('8 0 12 9'),
					rtfeldman$elm_css$Svg$Styled$Attributes$style('font-family: sans-serif; font-size: 11px; max-width: 100%;')
				]),
			_List_fromArray(
				[
					A2(
					rtfeldman$elm_css$Svg$Styled$g,
					_List_Nil,
					A2(author$project$Theory$RenderEngine$drawKeySignature, key, clef))
				]));
	});
var author$project$Theory$RenderEngine$renderKeySignature = F2(
	function (key, clef) {
		return A3(author$project$Theory$RenderEngine$renderKeySignatureSize, '90mm', key, clef);
	});
var author$project$Exercises$KeySignatures$view = function (model) {
	var total = elm$core$List$length(model.bm);
	var progress = 100 * (model.aS / total);
	var done = progress >= 100;
	return A2(
		author$project$UI$Elements$exerciseFrame,
		progress,
		_List_fromArray(
			[
				author$project$UI$Elements$description(
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('Identify the key signature')
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$marginRight(rtfeldman$elm_css$Css$auto),
								rtfeldman$elm_css$Css$marginLeft(rtfeldman$elm_css$Css$auto),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$em(45)),
								rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center)
							]))
					]),
				_List_fromArray(
					[
						function () {
						var _n0 = model.bK;
						if (!_n0.$) {
							var clef = _n0.a;
							return A2(author$project$Theory$RenderEngine$renderKeySignature, model.n, clef);
						} else {
							return author$project$Theory$RenderEngine$renderLines;
						}
					}()
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$display(rtfeldman$elm_css$Css$inlineBlock)
									]))
							]),
						A2(
							elm$core$List$map,
							function (x) {
								return A3(author$project$Exercises$KeySignatures$noteBtn, model, done, x);
							},
							_List_fromArray(
								[0, 1, 2, 3]))),
						A2(
						rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$display(rtfeldman$elm_css$Css$inlineBlock)
									]))
							]),
						A2(
							elm$core$List$map,
							function (x) {
								return A3(author$project$Exercises$KeySignatures$noteBtn, model, done, x);
							},
							_List_fromArray(
								[4, 5, 6])))
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$em(1))
							]))
					]),
				A2(
					elm$core$List$map,
					function (x) {
						return A3(author$project$Exercises$KeySignatures$accidentalBtn, model, done, x);
					},
					_List_fromArray(
						[4, 1]))),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$em(0.5)),
								rtfeldman$elm_css$Css$fontWeight(
								rtfeldman$elm_css$Css$int(800)),
								rtfeldman$elm_css$Css$fontSize(
								rtfeldman$elm_css$Css$em(1.2))
							]))
					]),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text(
						author$project$Theory$TheoryEngine$qualityToString(model.n.d1))
					])),
				done ? A2(
				author$project$UI$Elements$exerciseBtn,
				2,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$KeySignatures$Reset)
					])) : (model.T ? A2(
				author$project$UI$Elements$exerciseBtn,
				1,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$KeySignatures$NextKey)
					])) : A2(
				author$project$UI$Elements$exerciseBtn,
				0,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(
						_Utils_eq(model.x, elm$core$Maybe$Nothing)),
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$KeySignatures$CheckKey)
					])))
			]));
};
var author$project$Exercises$Notes$CheckNote = {$: 1};
var author$project$Exercises$Notes$NextNote = {$: 0};
var author$project$Exercises$Notes$Reset = {$: 3};
var author$project$Exercises$Notes$SelectNote = function (a) {
	return {$: 2, a: a};
};
var author$project$Exercises$Notes$view = function (model) {
	var noteStr = author$project$Theory$TheoryEngine$noteNameToString(model.F.dU.dT);
	var deckLen = elm$core$List$length(model.ap);
	var progress = 100 * (model.aS / deckLen);
	var done = progress >= 100;
	var btnWrapper = function (x) {
		return A3(
			author$project$UI$Elements$noteBtn,
			(_Utils_eq(x, model.x) && (model.T && _Utils_eq(x, noteStr))) ? 3 : ((_Utils_eq(x, model.x) && (model.T && (!_Utils_eq(x, noteStr)))) ? 2 : (((!_Utils_eq(x, model.x)) && (model.T && _Utils_eq(x, noteStr))) ? 3 : (_Utils_eq(x, model.x) ? 1 : 0))),
			_List_fromArray(
				[
					rtfeldman$elm_css$Html$Styled$Events$onClick(
					author$project$Exercises$Notes$SelectNote(x)),
					rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.T || done)
				]),
			_List_fromArray(
				[
					rtfeldman$elm_css$Html$Styled$text(x)
				]));
	};
	return A2(
		author$project$UI$Elements$exerciseFrame,
		progress,
		_List_fromArray(
			[
				author$project$UI$Elements$description(
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('Identify the given note')
					])),
				A2(
				author$project$Theory$RenderEngine$renderNotes,
				_List_fromArray(
					[model.F.dU]),
				model.F.bK),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$display(rtfeldman$elm_css$Css$inlineBlock)
									]))
							]),
						A2(
							elm$core$List$map,
							btnWrapper,
							_List_fromArray(
								['C', 'D', 'E', 'F']))),
						A2(
						rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$display(rtfeldman$elm_css$Css$inlineBlock)
									]))
							]),
						A2(
							elm$core$List$map,
							btnWrapper,
							_List_fromArray(
								['G', 'A', 'B'])))
					])),
				done ? A2(
				author$project$UI$Elements$exerciseBtn,
				2,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$Notes$Reset)
					])) : (model.T ? A2(
				author$project$UI$Elements$exerciseBtn,
				1,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$Notes$NextNote)
					])) : A2(
				author$project$UI$Elements$exerciseBtn,
				0,
				_List_fromArray(
					[
						(model.x === '') ? rtfeldman$elm_css$Html$Styled$Attributes$disabled(true) : rtfeldman$elm_css$Html$Styled$Attributes$disabled(false),
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$Notes$CheckNote)
					])))
			]));
};
var author$project$Exercises$Sevenths$CheckTriad = {$: 8};
var author$project$Exercises$Sevenths$GetRandomClef = {$: 0};
var author$project$Exercises$Sevenths$Reset = {$: 7};
var author$project$Theory$TheoryEngine$getTriad = F2(
	function (root, quality) {
		var getNote = F2(
			function (q, i) {
				return A2(
					author$project$Theory$TheoryEngine$transposeNoteUp,
					A2(author$project$Theory$TheoryEngine$IntervalName, q, i),
					root);
			});
		switch (quality) {
			case 0:
				return _List_fromArray(
					[
						root,
						A2(getNote, 1, 3),
						A2(getNote, 0, 5)
					]);
			case 1:
				return _List_fromArray(
					[
						root,
						A2(getNote, 1, 3),
						A2(getNote, 4, 5)
					]);
			case 2:
				return _List_fromArray(
					[
						root,
						A2(getNote, 2, 3),
						A2(getNote, 4, 5)
					]);
			case 3:
				return _List_fromArray(
					[
						root,
						A2(getNote, 2, 3),
						A2(getNote, 3, 5)
					]);
			default:
				return _List_Nil;
		}
	});
var author$project$Theory$TheoryEngine$getSeventhChord = F2(
	function (root, quality) {
		var getNote = F2(
			function (q, i) {
				return A2(
					author$project$Theory$TheoryEngine$transposeNoteUp,
					A2(author$project$Theory$TheoryEngine$IntervalName, q, i),
					root);
			});
		switch (quality) {
			case 0:
				return _Utils_ap(
					A2(author$project$Theory$TheoryEngine$getTriad, root, 0),
					_List_fromArray(
						[
							A2(getNote, 0, 7)
						]));
			case 1:
				return _Utils_ap(
					A2(author$project$Theory$TheoryEngine$getTriad, root, 0),
					_List_fromArray(
						[
							A2(getNote, 1, 7)
						]));
			case 5:
				return _Utils_ap(
					A2(author$project$Theory$TheoryEngine$getTriad, root, 1),
					_List_fromArray(
						[
							A2(getNote, 1, 7)
						]));
			case 3:
				return _Utils_ap(
					A2(author$project$Theory$TheoryEngine$getTriad, root, 1),
					_List_fromArray(
						[
							A2(getNote, 2, 7)
						]));
			case 4:
				return _Utils_ap(
					A2(author$project$Theory$TheoryEngine$getTriad, root, 2),
					_List_fromArray(
						[
							A2(getNote, 2, 7)
						]));
			default:
				return _Utils_ap(
					A2(author$project$Theory$TheoryEngine$getTriad, root, 2),
					_List_fromArray(
						[
							A2(getNote, 1, 7)
						]));
		}
	});
var author$project$Theory$TheoryEngine$getSeventhInversion = F3(
	function (root, quality, inversion) {
		var rootChord = A2(author$project$Theory$TheoryEngine$getSeventhChord, root, quality);
		var chordArray = elm$core$Array$fromList(rootChord);
		var fifth = A2(
			elm$core$Maybe$withDefault,
			A3(author$project$Theory$TheoryEngine$Note, 7, 1, 3),
			A2(elm$core$Array$get, 2, chordArray));
		var seventh = A2(
			elm$core$Maybe$withDefault,
			A3(author$project$Theory$TheoryEngine$Note, 7, 1, 3),
			A2(elm$core$Array$get, 3, chordArray));
		var third = A2(
			elm$core$Maybe$withDefault,
			A3(author$project$Theory$TheoryEngine$Note, 7, 1, 3),
			A2(elm$core$Array$get, 1, chordArray));
		switch (inversion) {
			case 0:
				return rootChord;
			case 1:
				return _List_fromArray(
					[
						root,
						_Utils_update(
						third,
						{dW: third.dW - 1}),
						_Utils_update(
						fifth,
						{dW: fifth.dW - 1}),
						_Utils_update(
						seventh,
						{dW: seventh.dW - 1})
					]);
			case 2:
				return _List_fromArray(
					[
						_Utils_update(
						fifth,
						{dW: fifth.dW - 1}),
						_Utils_update(
						seventh,
						{dW: seventh.dW - 1}),
						root,
						third
					]);
			default:
				return _List_fromArray(
					[
						_Utils_update(
						seventh,
						{dW: seventh.dW - 1}),
						root,
						third,
						fifth
					]);
		}
	});
var author$project$Exercises$Sevenths$getTriadInversion = F3(
	function (mNote, mQuality, mInversion) {
		if (mNote.$ === 1) {
			return _List_Nil;
		} else {
			var note = mNote.a;
			if (mQuality.$ === 1) {
				return _List_Nil;
			} else {
				var quality = mQuality.a;
				if (mInversion.$ === 1) {
					return _List_Nil;
				} else {
					var inversion = mInversion.a;
					return A3(author$project$Theory$TheoryEngine$getSeventhInversion, note, quality, inversion);
				}
			}
		}
	});
var author$project$Exercises$Sevenths$modelToNotes = function (model) {
	var _n0 = model.a2;
	if (!_n0.$) {
		var note = _n0.a;
		var _n1 = model._;
		if (!_n1.$) {
			var inversion = _n1.a;
			var _n2 = model.d1;
			if (!_n2.$) {
				var quality = _n2.a;
				return A3(author$project$Theory$TheoryEngine$getSeventhInversion, note, quality, inversion);
			} else {
				return _List_Nil;
			}
		} else {
			return _List_Nil;
		}
	} else {
		return _List_Nil;
	}
};
var author$project$Exercises$Sevenths$SelectQuality = function (a) {
	return {$: 6, a: a};
};
var author$project$Theory$TheoryEngine$quality7ToString = function (quality) {
	switch (quality) {
		case 0:
			return 'Diminished';
		case 1:
			return 'Half-Diminished';
		case 2:
			return 'Dominant';
		case 3:
			return 'Minor Major';
		case 4:
			return 'Major';
		default:
			return 'Minor';
	}
};
var author$project$Exercises$Sevenths$qualityBtn = F3(
	function (model, done, quality) {
		var userQuality = model.aF;
		var toString = function (x) {
			if (!x.$) {
				var q = x.a;
				return rtfeldman$elm_css$Svg$Styled$text(
					author$project$Theory$TheoryEngine$quality7ToString(q));
			} else {
				return rtfeldman$elm_css$Svg$Styled$text('');
			}
		};
		var mq = model.d1;
		if (!userQuality.$) {
			var si = userQuality.a;
			return A3(
				author$project$UI$Elements$noteBtn,
				(model.T && _Utils_eq(quality, mq)) ? 3 : ((_Utils_eq(
					quality,
					elm$core$Maybe$Just(si)) && (model.T && (!_Utils_eq(quality, mq)))) ? 2 : (_Utils_eq(
					quality,
					elm$core$Maybe$Just(si)) ? 1 : 0)),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(
						author$project$Exercises$Sevenths$SelectQuality(quality)),
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.T || done)
					]),
				_List_fromArray(
					[
						toString(quality)
					]));
		} else {
			return A3(
				author$project$UI$Elements$noteBtn,
				0,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(
						author$project$Exercises$Sevenths$SelectQuality(quality)),
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.T || done)
					]),
				_List_fromArray(
					[
						toString(quality)
					]));
		}
	});
var author$project$Exercises$Sevenths$SelectRoot = function (a) {
	return {$: 5, a: a};
};
var author$project$Exercises$Sevenths$rootBtn = F3(
	function (model, note, done) {
		var userRoot = model.aG;
		var mr = model.a2;
		if (!userRoot.$) {
			var sr = userRoot.a;
			return A3(
				author$project$UI$Elements$noteBtn,
				(model.T && _Utils_eq(
					elm$core$Maybe$Just(note),
					mr)) ? 3 : ((_Utils_eq(note, sr) && (model.T && (!_Utils_eq(
					elm$core$Maybe$Just(note),
					mr)))) ? 2 : (_Utils_eq(note, sr) ? 1 : 0)),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(
						author$project$Exercises$Sevenths$SelectRoot(
							elm$core$Maybe$Just(note))),
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.T || done)
					]),
				_List_fromArray(
					[
						author$project$Theory$TheoryEngine$noteToLabel(note)
					]));
		} else {
			return A3(
				author$project$UI$Elements$noteBtn,
				0,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(
						author$project$Exercises$Sevenths$SelectRoot(
							elm$core$Maybe$Just(note))),
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.T || done)
					]),
				_List_fromArray(
					[
						author$project$Theory$TheoryEngine$noteToLabel(note)
					]));
		}
	});
var author$project$Exercises$Sevenths$view = function (model) {
	var progress = 100 * (elm$core$List$length(model.aw) / model.aW);
	var done = progress >= 100;
	return A2(
		author$project$UI$Elements$exerciseFrame,
		progress,
		_List_fromArray(
			[
				author$project$UI$Elements$description(
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$text('Select the Root and Quality of the Triad')
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$marginRight(rtfeldman$elm_css$Css$auto),
								rtfeldman$elm_css$Css$marginLeft(rtfeldman$elm_css$Css$auto),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$em(45)),
								rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center)
							]))
					]),
				function () {
					var _n0 = model.bK;
					if (!_n0.$) {
						var clef = _n0.a;
						return _List_fromArray(
							[
								A2(
								author$project$Theory$RenderEngine$renderNotes,
								author$project$Exercises$Sevenths$modelToNotes(model),
								clef)
							]);
					} else {
						return _List_fromArray(
							[author$project$Theory$RenderEngine$renderLines]);
					}
				}()),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				A2(
					elm$core$List$map,
					function (x) {
						return A3(author$project$Exercises$Sevenths$rootBtn, model, x, done);
					},
					A3(author$project$Exercises$Sevenths$getTriadInversion, model.a2, model.d1, model._))),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$em(1))
							]))
					]),
				A2(
					elm$core$List$map,
					function (x) {
						return A3(
							author$project$Exercises$Sevenths$qualityBtn,
							model,
							done,
							elm$core$Maybe$Just(x));
					},
					model.bw)),
				done ? A2(
				author$project$UI$Elements$exerciseBtn,
				2,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$Sevenths$Reset)
					])) : (model.T ? A2(
				author$project$UI$Elements$exerciseBtn,
				1,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$Sevenths$GetRandomClef)
					])) : A2(
				author$project$UI$Elements$exerciseBtn,
				0,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(
						_Utils_eq(model.aG, elm$core$Maybe$Nothing) || _Utils_eq(model.aF, elm$core$Maybe$Nothing)),
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$Sevenths$CheckTriad)
					])))
			]));
};
var author$project$Exercises$Triads$CheckTriad = {$: 8};
var author$project$Exercises$Triads$GetRandomClef = {$: 0};
var author$project$Exercises$Triads$Reset = {$: 7};
var author$project$Theory$TheoryEngine$getTriadInversion = F3(
	function (root, quality, inversion) {
		var rootChord = A2(author$project$Theory$TheoryEngine$getTriad, root, quality);
		var chordArray = elm$core$Array$fromList(rootChord);
		var fifth = A2(
			elm$core$Maybe$withDefault,
			A3(author$project$Theory$TheoryEngine$Note, 7, 1, 3),
			A2(elm$core$Array$get, 2, chordArray));
		var third = A2(
			elm$core$Maybe$withDefault,
			A3(author$project$Theory$TheoryEngine$Note, 7, 1, 3),
			A2(elm$core$Array$get, 1, chordArray));
		switch (inversion) {
			case 0:
				return rootChord;
			case 1:
				return _List_fromArray(
					[
						_Utils_update(
						third,
						{dW: third.dW - 1}),
						_Utils_update(
						fifth,
						{dW: fifth.dW - 1}),
						root
					]);
			case 2:
				return _List_fromArray(
					[
						_Utils_update(
						fifth,
						{dW: fifth.dW - 1}),
						root,
						third
					]);
			default:
				return _List_Nil;
		}
	});
var author$project$Exercises$Triads$getTriadInversion = F3(
	function (mNote, mQuality, mInversion) {
		if (mNote.$ === 1) {
			return _List_Nil;
		} else {
			var note = mNote.a;
			if (mQuality.$ === 1) {
				return _List_Nil;
			} else {
				var quality = mQuality.a;
				if (mInversion.$ === 1) {
					return _List_Nil;
				} else {
					var inversion = mInversion.a;
					return A3(author$project$Theory$TheoryEngine$getTriadInversion, note, quality, inversion);
				}
			}
		}
	});
var author$project$Exercises$Triads$modelToNotes = function (model) {
	var _n0 = model.a2;
	if (!_n0.$) {
		var note = _n0.a;
		var _n1 = model._;
		if (!_n1.$) {
			var inversion = _n1.a;
			var _n2 = model.d1;
			if (!_n2.$) {
				var quality = _n2.a;
				return A3(author$project$Theory$TheoryEngine$getTriadInversion, note, quality, inversion);
			} else {
				return _List_Nil;
			}
		} else {
			return _List_Nil;
		}
	} else {
		return _List_Nil;
	}
};
var author$project$Exercises$Triads$SelectQuality = function (a) {
	return {$: 6, a: a};
};
var author$project$Exercises$Triads$qualityBtn = F3(
	function (model, done, quality) {
		var userQuality = model.aF;
		var toString = function (x) {
			if (!x.$) {
				var q = x.a;
				return rtfeldman$elm_css$Svg$Styled$text(
					author$project$Theory$TheoryEngine$qualityToString(q));
			} else {
				return rtfeldman$elm_css$Svg$Styled$text('');
			}
		};
		var mq = model.d1;
		if (!userQuality.$) {
			var si = userQuality.a;
			return A3(
				author$project$UI$Elements$noteBtn,
				(model.T && _Utils_eq(quality, mq)) ? 3 : ((_Utils_eq(
					quality,
					elm$core$Maybe$Just(si)) && (model.T && (!_Utils_eq(quality, mq)))) ? 2 : (_Utils_eq(
					quality,
					elm$core$Maybe$Just(si)) ? 1 : 0)),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(
						author$project$Exercises$Triads$SelectQuality(quality)),
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.T || done)
					]),
				_List_fromArray(
					[
						toString(quality)
					]));
		} else {
			return A3(
				author$project$UI$Elements$noteBtn,
				0,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(
						author$project$Exercises$Triads$SelectQuality(quality)),
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.T || done)
					]),
				_List_fromArray(
					[
						toString(quality)
					]));
		}
	});
var author$project$Exercises$Triads$SelectRoot = function (a) {
	return {$: 5, a: a};
};
var author$project$Exercises$Triads$rootBtn = F3(
	function (model, note, done) {
		var userRoot = model.aG;
		var mr = model.a2;
		if (!userRoot.$) {
			var sr = userRoot.a;
			return A3(
				author$project$UI$Elements$noteBtn,
				(model.T && _Utils_eq(
					elm$core$Maybe$Just(note),
					mr)) ? 3 : ((_Utils_eq(note, sr) && (model.T && (!_Utils_eq(
					elm$core$Maybe$Just(note),
					mr)))) ? 2 : (_Utils_eq(note, sr) ? 1 : 0)),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(
						author$project$Exercises$Triads$SelectRoot(
							elm$core$Maybe$Just(note))),
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.T || done)
					]),
				_List_fromArray(
					[
						author$project$Theory$TheoryEngine$noteToLabel(note)
					]));
		} else {
			return A3(
				author$project$UI$Elements$noteBtn,
				0,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(
						author$project$Exercises$Triads$SelectRoot(
							elm$core$Maybe$Just(note))),
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(model.T || done)
					]),
				_List_fromArray(
					[
						author$project$Theory$TheoryEngine$noteToLabel(note)
					]));
		}
	});
var author$project$Exercises$Triads$view = function (model) {
	var progress = 100 * (elm$core$List$length(model.aw) / model.aW);
	var done = progress >= 100;
	return A2(
		author$project$UI$Elements$exerciseFrame,
		progress,
		_List_fromArray(
			[
				author$project$UI$Elements$description(
				_List_fromArray(
					[
						rtfeldman$elm_css$Svg$Styled$text('Select the Root and Quality of the Triad')
					])),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$marginRight(rtfeldman$elm_css$Css$auto),
								rtfeldman$elm_css$Css$marginLeft(rtfeldman$elm_css$Css$auto),
								rtfeldman$elm_css$Css$maxWidth(
								rtfeldman$elm_css$Css$em(45)),
								rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center)
							]))
					]),
				function () {
					var _n0 = model.bK;
					if (!_n0.$) {
						var clef = _n0.a;
						return _List_fromArray(
							[
								A2(
								author$project$Theory$RenderEngine$renderNotes,
								author$project$Exercises$Triads$modelToNotes(model),
								clef)
							]);
					} else {
						return _List_fromArray(
							[author$project$Theory$RenderEngine$renderLines]);
					}
				}()),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				A2(
					elm$core$List$map,
					function (x) {
						return A3(author$project$Exercises$Triads$rootBtn, model, x, done);
					},
					A3(author$project$Exercises$Triads$getTriadInversion, model.a2, model.d1, model._))),
				A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$marginTop(
								rtfeldman$elm_css$Css$em(1))
							]))
					]),
				A2(
					elm$core$List$map,
					function (x) {
						return A3(
							author$project$Exercises$Triads$qualityBtn,
							model,
							done,
							elm$core$Maybe$Just(x));
					},
					model.bw)),
				done ? A2(
				author$project$UI$Elements$exerciseBtn,
				2,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$Triads$Reset)
					])) : (model.T ? A2(
				author$project$UI$Elements$exerciseBtn,
				1,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$Triads$GetRandomClef)
					])) : A2(
				author$project$UI$Elements$exerciseBtn,
				0,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$disabled(
						_Utils_eq(model.aG, elm$core$Maybe$Nothing) || _Utils_eq(model.aF, elm$core$Maybe$Nothing)),
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Exercises$Triads$CheckTriad)
					])))
			]));
};
var rtfeldman$elm_css$Css$hidden = {u: 0, aB: 0, I: 'hidden', ba: 0};
var rtfeldman$elm_css$Css$marginBottom = rtfeldman$elm_css$Css$prop1('margin-bottom');
var rtfeldman$elm_css$Css$overflow = rtfeldman$elm_css$Css$prop1('overflow');
var rtfeldman$elm_css$Css$paddingBottom = rtfeldman$elm_css$Css$prop1('padding-bottom');
var rtfeldman$elm_css$Html$Styled$iframe = rtfeldman$elm_css$Html$Styled$node('iframe');
var rtfeldman$elm_css$Html$Styled$Attributes$attribute = rtfeldman$elm_css$VirtualDom$Styled$attribute;
var rtfeldman$elm_css$Html$Styled$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			rtfeldman$elm_css$VirtualDom$Styled$property,
			key,
			elm$json$Json$Encode$string(string));
	});
var rtfeldman$elm_css$Html$Styled$Attributes$src = function (url) {
	return A2(rtfeldman$elm_css$Html$Styled$Attributes$stringProperty, 'src', url);
};
var author$project$Page$Content$displayVideo = function (code) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$relative),
						rtfeldman$elm_css$Css$height(
						rtfeldman$elm_css$Css$px(0)),
						rtfeldman$elm_css$Css$overflow(rtfeldman$elm_css$Css$hidden),
						rtfeldman$elm_css$Css$paddingBottom(
						rtfeldman$elm_css$Css$pct(56.25)),
						rtfeldman$elm_css$Css$marginBottom(
						rtfeldman$elm_css$Css$em(1)),
						rtfeldman$elm_css$Css$backgroundColor(
						A4(rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0.1))
					]))
			]),
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Html$Styled$iframe,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$src('https://player.vimeo.com/video/' + code),
						A2(rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'frameborder', '0'),
						A2(rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'),
						A2(rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'allowfullscreen', 'true'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$height(
								rtfeldman$elm_css$Css$pct(100)),
								rtfeldman$elm_css$Css$width(
								rtfeldman$elm_css$Css$pct(100)),
								rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$absolute),
								rtfeldman$elm_css$Css$top(
								rtfeldman$elm_css$Css$px(0)),
								rtfeldman$elm_css$Css$left(
								rtfeldman$elm_css$Css$px(0)),
								rtfeldman$elm_css$Css$border(
								rtfeldman$elm_css$Css$px(0))
							]))
					]),
				_List_Nil)
			]));
};
var rtfeldman$elm_css$Css$paddingTop = rtfeldman$elm_css$Css$prop1('padding-top');
var author$project$Page$Content$displayContent = function (contentModel) {
	switch (contentModel.$) {
		case 0:
			var m = contentModel.a;
			return A2(
				rtfeldman$elm_css$Html$Styled$map,
				author$project$Page$Content$NoteMsg,
				author$project$Exercises$Notes$view(m));
		case 1:
			var m = contentModel.a;
			return A2(
				rtfeldman$elm_css$Html$Styled$map,
				author$project$Page$Content$KeyNoteMsg,
				author$project$Exercises$KeyNotes$view(m));
		case 2:
			var m = contentModel.a;
			return A2(
				rtfeldman$elm_css$Html$Styled$map,
				author$project$Page$Content$BasicIntervalsMsg,
				author$project$Exercises$BasicIntervals$view(m));
		case 3:
			var m = contentModel.a;
			return A2(
				rtfeldman$elm_css$Html$Styled$map,
				author$project$Page$Content$KeyBasicIntervalsMsg,
				author$project$Exercises$KeyBasicIntervals$view(m));
		case 4:
			var m = contentModel.a;
			return A2(
				rtfeldman$elm_css$Html$Styled$map,
				author$project$Page$Content$IntervalsMsg,
				author$project$Exercises$Intervals$view(m));
		case 5:
			var m = contentModel.a;
			return A2(
				rtfeldman$elm_css$Html$Styled$map,
				author$project$Page$Content$KeyIntervalsMsg,
				author$project$Exercises$KeyIntervals$view(m));
		case 6:
			var m = contentModel.a;
			return A2(
				rtfeldman$elm_css$Html$Styled$map,
				author$project$Page$Content$TriadsMsg,
				author$project$Exercises$Triads$view(m));
		case 7:
			var m = contentModel.a;
			return A2(
				rtfeldman$elm_css$Html$Styled$map,
				author$project$Page$Content$SeventhsMsg,
				author$project$Exercises$Sevenths$view(m));
		case 8:
			var m = contentModel.a;
			return A2(
				rtfeldman$elm_css$Html$Styled$map,
				author$project$Page$Content$KeySigMsg,
				author$project$Exercises$KeySignatures$view(m));
		case 9:
			var code = contentModel.a;
			return author$project$Page$Content$displayVideo(code);
		default:
			return A2(
				rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(
								rtfeldman$elm_css$Css$pct(100)),
								rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center),
								rtfeldman$elm_css$Css$paddingTop(
								rtfeldman$elm_css$Css$em(3))
							]))
					]),
				_List_fromArray(
					[
						A2(
						rtfeldman$elm_css$Html$Styled$h2,
						_List_Nil,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$text('Sorry. Content Not Found.')
							]))
					]));
	}
};
var author$project$Icons$FeatherIcons$list = A2(
	author$project$Icons$FeatherIcons$makeBuilder,
	'list',
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$line,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$x1('8'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y1('6'),
					rtfeldman$elm_css$Svg$Styled$Attributes$x2('21'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y2('6')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$line,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$x1('8'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y1('12'),
					rtfeldman$elm_css$Svg$Styled$Attributes$x2('21'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y2('12')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$line,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$x1('8'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y1('18'),
					rtfeldman$elm_css$Svg$Styled$Attributes$x2('21'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y2('18')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$line,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$x1('3'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y1('6'),
					rtfeldman$elm_css$Svg$Styled$Attributes$x2('3'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y2('6')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$line,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$x1('3'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y1('12'),
					rtfeldman$elm_css$Svg$Styled$Attributes$x2('3'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y2('12')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$line,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$x1('3'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y1('18'),
					rtfeldman$elm_css$Svg$Styled$Attributes$x2('3'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y2('18')
				]),
			_List_Nil)
		]));
var rtfeldman$elm_css$Css$alignItems = function (fn) {
	return A3(
		rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'alignItems',
		'align-items',
		fn(rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var rtfeldman$elm_css$Css$displayFlex = A2(rtfeldman$elm_css$Css$property, 'display', 'flex');
var rtfeldman$elm_css$Css$lastChild = rtfeldman$elm_css$Css$pseudoClass('last-child');
var rtfeldman$elm_css$Css$notAllowed = {a: 0, I: 'not-allowed'};
var author$project$UI$Elements$simpleIconBtn = A2(
	rtfeldman$elm_css$Html$Styled$styled,
	rtfeldman$elm_css$Html$Styled$button,
	_List_fromArray(
		[
			rtfeldman$elm_css$Css$margin(
			rtfeldman$elm_css$Css$px(0)),
			rtfeldman$elm_css$Css$cursor(rtfeldman$elm_css$Css$pointer),
			rtfeldman$elm_css$Css$border(
			rtfeldman$elm_css$Css$px(0)),
			rtfeldman$elm_css$Css$height(
			rtfeldman$elm_css$Css$px(46)),
			rtfeldman$elm_css$Css$color(author$project$UI$Branding$colors.bL),
			rtfeldman$elm_css$Css$fontSize(
			rtfeldman$elm_css$Css$em(1.2)),
			rtfeldman$elm_css$Css$displayFlex,
			rtfeldman$elm_css$Css$alignItems(rtfeldman$elm_css$Css$center),
			rtfeldman$elm_css$Css$backgroundColor(
			A4(rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0)),
			A2(
			rtfeldman$elm_css$Css$padding2,
			rtfeldman$elm_css$Css$px(4),
			rtfeldman$elm_css$Css$px(14)),
			rtfeldman$elm_css$Css$lastChild(
			_List_fromArray(
				[
					rtfeldman$elm_css$Css$marginRight(
					rtfeldman$elm_css$Css$px(0))
				])),
			rtfeldman$elm_css$Css$disabled(
			_List_fromArray(
				[
					rtfeldman$elm_css$Css$cursor(rtfeldman$elm_css$Css$notAllowed),
					rtfeldman$elm_css$Css$color(
					rtfeldman$elm_css$Css$hex('777'))
				]))
		]));
var author$project$UI$Screen$largePx = 1000;
var rtfeldman$elm_css$Css$Media$feature = F2(
	function (key, _n0) {
		var value = _n0.I;
		return {
			cr: key,
			I: elm$core$Maybe$Just(value)
		};
	});
var rtfeldman$elm_css$Css$Media$minWidth = function (value) {
	return A2(rtfeldman$elm_css$Css$Media$feature, 'min-width', value);
};
var rtfeldman$elm_css$Css$Structure$OnlyQuery = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var rtfeldman$elm_css$Css$Media$only = rtfeldman$elm_css$Css$Structure$OnlyQuery;
var rtfeldman$elm_css$Css$Structure$Screen = 1;
var rtfeldman$elm_css$Css$Media$screen = 1;
var rtfeldman$elm_css$Css$Preprocess$WithMedia = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var rtfeldman$elm_css$Css$Media$withMedia = rtfeldman$elm_css$Css$Preprocess$WithMedia;
var author$project$UI$Screen$largeScreen = rtfeldman$elm_css$Css$Media$withMedia(
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Css$Media$only,
			rtfeldman$elm_css$Css$Media$screen,
			_List_fromArray(
				[
					rtfeldman$elm_css$Css$Media$minWidth(
					rtfeldman$elm_css$Css$px(author$project$UI$Screen$largePx))
				]))
		]));
var rtfeldman$elm_css$Css$flexEnd = rtfeldman$elm_css$Css$prop1('flex-end');
var rtfeldman$elm_css$Css$justifyContent = function (fn) {
	return A3(
		rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'justifyContent',
		'justify-content',
		fn(rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var rtfeldman$elm_css$Css$zIndex = rtfeldman$elm_css$Css$prop1('z-index');
var author$project$Page$Content$listMenuBtn = function (model) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$width(
						rtfeldman$elm_css$Css$pct(100)),
						rtfeldman$elm_css$Css$displayFlex,
						rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$flexEnd),
						author$project$UI$Screen$largeScreen(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$display(rtfeldman$elm_css$Css$none)
							])),
						rtfeldman$elm_css$Css$zIndex(
						rtfeldman$elm_css$Css$int(3))
					]))
			]),
		_List_fromArray(
			[
				A2(
				author$project$UI$Elements$simpleIconBtn,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Page$Content$ToggleList)
					]),
				_List_fromArray(
					[
						A2(
						author$project$Icons$FeatherIcons$toHtml,
						_List_Nil,
						A2(
							author$project$Icons$FeatherIcons$withSizeUnit,
							'em',
							A2(author$project$Icons$FeatherIcons$withSize, 1.5, author$project$Icons$FeatherIcons$list))),
						A2(
						rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										rtfeldman$elm_css$Css$marginLeft(
										rtfeldman$elm_css$Css$px(4))
									]))
							]),
						_List_fromArray(
							[
								rtfeldman$elm_css$Html$Styled$text('Exercises')
							]))
					]))
			]));
};
var author$project$Icons$FeatherIcons$arrowLeft = A2(
	author$project$Icons$FeatherIcons$makeBuilder,
	'arrow-left',
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$line,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$x1('19'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y1('12'),
					rtfeldman$elm_css$Svg$Styled$Attributes$x2('5'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y2('12')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$polyline,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$points('12 19 5 12 12 5')
				]),
			_List_Nil)
		]));
var author$project$Icons$FeatherIcons$arrowRight = A2(
	author$project$Icons$FeatherIcons$makeBuilder,
	'arrow-right',
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$line,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$x1('5'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y1('12'),
					rtfeldman$elm_css$Svg$Styled$Attributes$x2('19'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y2('12')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$polyline,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$points('12 5 19 12 12 19')
				]),
			_List_Nil)
		]));
var rtfeldman$elm_css$Css$block = {bg: 0, I: 'block'};
var rtfeldman$elm_css$Css$lineHeight = rtfeldman$elm_css$Css$prop1('line-height');
var rtfeldman$elm_css$Css$textDecoration = rtfeldman$elm_css$Css$prop1('text-decoration');
var rtfeldman$elm_css$Html$Styled$a = rtfeldman$elm_css$Html$Styled$node('a');
var author$project$UI$Elements$linkBtn = A2(
	rtfeldman$elm_css$Html$Styled$styled,
	rtfeldman$elm_css$Html$Styled$a,
	_List_fromArray(
		[
			rtfeldman$elm_css$Css$margin(
			rtfeldman$elm_css$Css$em(0)),
			rtfeldman$elm_css$Css$marginBottom(
			rtfeldman$elm_css$Css$px(4)),
			A2(
			rtfeldman$elm_css$Css$padding2,
			rtfeldman$elm_css$Css$px(0),
			rtfeldman$elm_css$Css$em(0.8)),
			rtfeldman$elm_css$Css$height(
			rtfeldman$elm_css$Css$em(3)),
			rtfeldman$elm_css$Css$borderRadius(
			rtfeldman$elm_css$Css$px(4)),
			rtfeldman$elm_css$Css$cursor(rtfeldman$elm_css$Css$pointer),
			A2(rtfeldman$elm_css$Css$property, 'transition', '0.2s'),
			rtfeldman$elm_css$Css$textDecoration(rtfeldman$elm_css$Css$none),
			rtfeldman$elm_css$Css$display(rtfeldman$elm_css$Css$block),
			rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center),
			rtfeldman$elm_css$Css$lineHeight(
			rtfeldman$elm_css$Css$em(3.5)),
			rtfeldman$elm_css$Css$fontWeight(
			rtfeldman$elm_css$Css$int(800)),
			rtfeldman$elm_css$Css$fontSize(
			rtfeldman$elm_css$Css$em(1.2)),
			rtfeldman$elm_css$Css$textTransform(rtfeldman$elm_css$Css$uppercase),
			rtfeldman$elm_css$Css$color(author$project$UI$Branding$colors.cM),
			rtfeldman$elm_css$Css$border(
			rtfeldman$elm_css$Css$px(0)),
			A5(
			rtfeldman$elm_css$Css$boxShadow5,
			rtfeldman$elm_css$Css$inset,
			rtfeldman$elm_css$Css$em(0),
			rtfeldman$elm_css$Css$rem(-0.3),
			rtfeldman$elm_css$Css$em(0),
			A4(rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0.1)),
			rtfeldman$elm_css$Css$active(
			_List_fromArray(
				[
					rtfeldman$elm_css$Css$transform(
					rtfeldman$elm_css$Css$scale(0.9))
				]))
		]));
var rtfeldman$elm_css$Css$spaceBetween = rtfeldman$elm_css$Css$prop1('space-between');
var rtfeldman$elm_css$Html$Styled$Attributes$href = function (url) {
	return A2(rtfeldman$elm_css$Html$Styled$Attributes$stringProperty, 'href', url);
};
var author$project$Page$Content$prevNextBtns = function (model) {
	return A2(
		rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						rtfeldman$elm_css$Css$displayFlex,
						rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$spaceBetween),
						A2(
						rtfeldman$elm_css$Css$margin2,
						rtfeldman$elm_css$Css$px(0),
						rtfeldman$elm_css$Css$em(1)),
						author$project$UI$Screen$largeScreen(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$display(rtfeldman$elm_css$Css$none)
							]))
					]))
			]),
		_Utils_ap(
			((model.ao - 1) >= 0) ? _List_fromArray(
				[
					A2(
					author$project$UI$Elements$linkBtn,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$backgroundColor(author$project$UI$Branding$colors.cx),
									rtfeldman$elm_css$Css$color(author$project$UI$Branding$colors.bL)
								])),
							rtfeldman$elm_css$Html$Styled$Attributes$href(
							'/course/' + (elm$core$String$fromInt(model.be) + ('/' + elm$core$String$fromInt(model.ao - 1))))
						]),
					_List_fromArray(
						[
							A2(
							author$project$Icons$FeatherIcons$toHtml,
							_List_Nil,
							A2(
								author$project$Icons$FeatherIcons$withSizeUnit,
								'em',
								A2(author$project$Icons$FeatherIcons$withSize, 1.5, author$project$Icons$FeatherIcons$arrowLeft)))
						]))
				]) : _List_fromArray(
				[
					A2(rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)
				]),
			(_Utils_cmp(
				model.ao + 1,
				elm$core$List$length(model.aX.dt)) < 0) ? _List_fromArray(
				[
					A2(
					author$project$UI$Elements$linkBtn,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$backgroundColor(author$project$UI$Branding$colors.cx),
									rtfeldman$elm_css$Css$color(author$project$UI$Branding$colors.bL)
								])),
							rtfeldman$elm_css$Html$Styled$Attributes$href(
							'/course/' + (elm$core$String$fromInt(model.be) + ('/' + elm$core$String$fromInt(model.ao + 1))))
						]),
					_List_fromArray(
						[
							A2(
							author$project$Icons$FeatherIcons$toHtml,
							_List_Nil,
							A2(
								author$project$Icons$FeatherIcons$withSizeUnit,
								'em',
								A2(author$project$Icons$FeatherIcons$withSize, 1.5, author$project$Icons$FeatherIcons$arrowRight)))
						]))
				]) : _List_fromArray(
				[
					A2(rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil)
				])));
};
var rtfeldman$elm_css$Svg$Styled$circle = rtfeldman$elm_css$Svg$Styled$node('circle');
var rtfeldman$elm_css$Svg$Styled$polygon = rtfeldman$elm_css$Svg$Styled$node('polygon');
var rtfeldman$elm_css$Svg$Styled$Attributes$cx = rtfeldman$elm_css$VirtualDom$Styled$attribute('cx');
var rtfeldman$elm_css$Svg$Styled$Attributes$cy = rtfeldman$elm_css$VirtualDom$Styled$attribute('cy');
var rtfeldman$elm_css$Svg$Styled$Attributes$r = rtfeldman$elm_css$VirtualDom$Styled$attribute('r');
var author$project$Icons$FeatherIcons$playCircle = A2(
	author$project$Icons$FeatherIcons$makeBuilder,
	'play-circle',
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$circle,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$cx('12'),
					rtfeldman$elm_css$Svg$Styled$Attributes$cy('12'),
					rtfeldman$elm_css$Svg$Styled$Attributes$r('10')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$polygon,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$points('10 8 16 12 10 16 10 8')
				]),
			_List_Nil)
		]));
var author$project$Icons$Music$chord = A2(
	author$project$Icons$FeatherIcons$makeBuilder,
	'chord',
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$d('m15 19.017h-4a2 2 0 0 0-2 2 2 2 0 0 0 2 2h2a2 2 0 0 0 2-2z')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$d('m15 12.508h-4a2 2 0 0 0-2 2 2 2 0 0 0 2 2h2a2 2 0 0 0 2-2z')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$d('m15 6h-4a2 2 0 0 0-2 2 2 2 0 0 0 2 2h2a2 2 0 0 0 2-2z')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$polyline,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$transform('translate(0 0.20339)'),
					rtfeldman$elm_css$Svg$Styled$Attributes$points('15 19 15 2')
				]),
			_List_Nil)
		]));
var author$project$Icons$Music$interval = A2(
	author$project$Icons$FeatherIcons$makeBuilder,
	'music',
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$d('M9 17H5a2 2 0 0 0-2 2 2 2 0 0 0 2 2h2a2 2 0 0 0 2-2zm12-2h-4a2 2 0 0 0-2 2 2 2 0 0 0 2 2h2a2 2 0 0 0 2-2z')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$polyline,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$points('9 17 9 5 21 3 21 15')
				]),
			_List_Nil)
		]));
var author$project$Icons$Music$keyboard = A2(
	author$project$Icons$FeatherIcons$makeBuilder,
	'keyboard',
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$rect,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$height('15'),
					rtfeldman$elm_css$Svg$Styled$Attributes$width('18'),
					rtfeldman$elm_css$Svg$Styled$Attributes$x('3'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y('4')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$rect,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$height('9'),
					rtfeldman$elm_css$Svg$Styled$Attributes$width('3'),
					rtfeldman$elm_css$Svg$Styled$Attributes$x('7'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y('4')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$rect,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$height('9'),
					rtfeldman$elm_css$Svg$Styled$Attributes$width('3'),
					rtfeldman$elm_css$Svg$Styled$Attributes$x('14'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y('4')
				]),
			_List_Nil)
		]));
var author$project$Icons$Music$note = A2(
	author$project$Icons$FeatherIcons$makeBuilder,
	'note',
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$d('m 15.0001,15.881356 h -4 a 2,2 0 0 0 -2.0000006,2 2,2 0 0 0 2.0000006,2 h 2 a 2,2 0 0 0 2,-2 z')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$polyline,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$points('15 17 15 5')
				]),
			_List_Nil)
		]));
var author$project$Icons$Music$alto = A2(
	author$project$Icons$FeatherIcons$makeBuilder,
	'treble',
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$d('M 6,1.5 V 21.5')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$d('M 9,1.5 V 21.5')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$d('m 13.042893,18.436883 c 0,0 0.165569,1.65391 2.634218,1.65391 3.83684,0 4.11298,-7.474025 0,-7.423425 -1.942402,0.0239 -2.415162,2.586707 -2.634218,1.481739 -0.196024,-0.98879 -1.477712,-2.627699 -1.477712,-2.627699 0,0 1.13787,-1.6389083 1.333894,-2.6276992 0.219055,-1.1049674 0.691816,1.4578422 2.634218,1.4817392 4.11298,0.0506 3.83684,-7.4234256 0,-7.4234256 -2.367039,0 -2.634218,1.6539107 -2.634218,1.6539107')
				]),
			_List_Nil)
		]));
var rtfeldman$elm_css$Svg$Styled$Attributes$fillOpacity = rtfeldman$elm_css$VirtualDom$Styled$attribute('fill-opacity');
var rtfeldman$elm_css$Svg$Styled$Attributes$opacity = rtfeldman$elm_css$VirtualDom$Styled$attribute('opacity');
var author$project$Icons$Music$bass = A2(
	author$project$Icons$FeatherIcons$makeBuilder,
	'bass',
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$d('M 6.6388216,6.8079793 C 8.029771,1.9119122 15.074933,2.2829264 15.14666,8.3427473 15.198351,12.709768 10.584579,17.626848 6.6649937,20.401806')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$circle,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$r('1.3474576'),
					rtfeldman$elm_css$Svg$Styled$Attributes$cy('10.144068'),
					rtfeldman$elm_css$Svg$Styled$Attributes$cx('19.347456'),
					rtfeldman$elm_css$Svg$Styled$Attributes$opacity('1'),
					rtfeldman$elm_css$Svg$Styled$Attributes$fillOpacity('1'),
					rtfeldman$elm_css$Svg$Styled$Attributes$stroke('none'),
					rtfeldman$elm_css$Svg$Styled$Attributes$fill('#333')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$circle,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$r('1.3474576'),
					rtfeldman$elm_css$Svg$Styled$Attributes$cx('19.347456'),
					rtfeldman$elm_css$Svg$Styled$Attributes$cy('6.0762706'),
					rtfeldman$elm_css$Svg$Styled$Attributes$opacity('1'),
					rtfeldman$elm_css$Svg$Styled$Attributes$fillOpacity('1'),
					rtfeldman$elm_css$Svg$Styled$Attributes$stroke('none'),
					rtfeldman$elm_css$Svg$Styled$Attributes$fill('#333')
				]),
			_List_Nil)
		]));
var author$project$Icons$Music$tenor = author$project$Icons$Music$alto;
var author$project$Icons$Music$treble = A2(
	author$project$Icons$FeatherIcons$makeBuilder,
	'treble',
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$d('m 11.650046,13.602443 c 0.930921,-1.632866 3.972543,-1.285817 4.670517,0.09867 0.819028,1.624599 -0.419866,3.348172 -2.271962,4.112944 -5.5974542,2.311314 -8.9534821,-5.628825 -1.322034,-10.1694904 3.464854,-2.0615672 2.971161,-6.3165102 0.762713,-5.898305 -0.971316,0.1839345 -1.489912,1.0850275 -1.689183,2.4376739 -0.532891,3.6172639 1.401546,10.3477375 1.401546,15.6953025 0.01887,2.227865 -1.215524,2.561713 -2.065181,2.117706')
				]),
			_List_Nil)
		]));
var author$project$Theory$RenderEngine$clefToIcon = function (clef) {
	switch (clef) {
		case 0:
			return author$project$Icons$Music$treble;
		case 1:
			return author$project$Icons$Music$bass;
		case 3:
			return author$project$Icons$Music$tenor;
		default:
			return author$project$Icons$Music$alto;
	}
};
var rtfeldman$elm_css$Css$borderLeft3 = rtfeldman$elm_css$Css$prop3('border-left');
var rtfeldman$elm_css$Css$borderTop = rtfeldman$elm_css$Css$prop1('border-top');
var rtfeldman$elm_css$Css$borderTop3 = rtfeldman$elm_css$Css$prop3('border-top');
var rtfeldman$elm_css$Css$firstChild = rtfeldman$elm_css$Css$pseudoClass('first-child');
var rtfeldman$elm_css$Css$flex = rtfeldman$elm_css$Css$prop1('flex');
var rtfeldman$elm_css$Css$listStyleType = rtfeldman$elm_css$Css$prop1('list-style-type');
var rtfeldman$elm_css$Css$UnitlessFloat = 0;
var rtfeldman$elm_css$Css$num = function (val) {
	return {
		ab: 0,
		G: 0,
		dV: 0,
		bp: 0,
		S: val,
		aM: '',
		a9: 0,
		I: elm$core$String$fromFloat(val)
	};
};
var rtfeldman$elm_css$Css$padding = rtfeldman$elm_css$Css$prop1('padding');
var rtfeldman$elm_css$Html$Styled$li = rtfeldman$elm_css$Html$Styled$node('li');
var rtfeldman$elm_css$Html$Styled$ul = rtfeldman$elm_css$Html$Styled$node('ul');
var author$project$Page$Course$listCourseContent = F3(
	function (courseNum, activeContent, info) {
		var icons = function (x) {
			switch (x.$) {
				case 2:
					return _List_Nil;
				case 0:
					return _List_Nil;
				default:
					var eType = x.a;
					switch (eType.$) {
						case 0:
							var config = eType.a;
							return _Utils_ap(
								_List_fromArray(
									[author$project$Icons$Music$note]),
								A2(
									elm$core$List$map,
									function (y) {
										return author$project$Theory$RenderEngine$clefToIcon(y.bK);
									},
									config.aV));
						case 1:
							var display = eType.a;
							var config = eType.b;
							return (!display) ? _Utils_ap(
								_List_fromArray(
									[author$project$Icons$Music$note, author$project$Icons$Music$keyboard]),
								A2(
									elm$core$List$map,
									function (y) {
										return author$project$Theory$RenderEngine$clefToIcon(y.bK);
									},
									config.aV)) : _List_fromArray(
								[author$project$Icons$Music$note, author$project$Icons$Music$keyboard]);
						case 2:
							var config = eType.a;
							return _Utils_ap(
								_List_fromArray(
									[author$project$Icons$Music$chord]),
								A2(
									elm$core$List$map,
									function (y) {
										return author$project$Theory$RenderEngine$clefToIcon(y);
									},
									config.aV));
						case 3:
							var config = eType.a;
							return _Utils_ap(
								_List_fromArray(
									[author$project$Icons$Music$chord]),
								A2(
									elm$core$List$map,
									function (y) {
										return author$project$Theory$RenderEngine$clefToIcon(y);
									},
									config.aV));
						case 4:
							return _List_fromArray(
								[author$project$Icons$Music$interval]);
						case 5:
							return _List_fromArray(
								[author$project$Icons$Music$keyboard, author$project$Icons$Music$interval]);
						case 6:
							var config = eType.a;
							return _Utils_ap(
								_List_fromArray(
									[author$project$Icons$Music$interval]),
								A2(
									elm$core$List$map,
									function (y) {
										return author$project$Theory$RenderEngine$clefToIcon(y);
									},
									config.aV));
						case 7:
							var config = eType.a;
							return _List_fromArray(
								[author$project$Icons$Music$interval, author$project$Icons$Music$keyboard]);
						case 9:
							var config = eType.a;
							return A2(
								elm$core$List$map,
								function (y) {
									return author$project$Theory$RenderEngine$clefToIcon(y);
								},
								config.aV);
						default:
							return _List_fromArray(
								[author$project$Icons$Music$chord]);
					}
			}
		};
		var content = info.dt;
		var active = function (x) {
			if (!activeContent.$) {
				var num = activeContent.a;
				return _Utils_eq(num, x) ? _List_fromArray(
					[
						rtfeldman$elm_css$Css$backgroundColor(
						A4(rtfeldman$elm_css$Css$rgba, 0, 0, 0, 4.0e-2)),
						A3(
						rtfeldman$elm_css$Css$borderLeft3,
						rtfeldman$elm_css$Css$px(6),
						rtfeldman$elm_css$Css$solid,
						author$project$UI$Branding$colors.di),
						rtfeldman$elm_css$Css$borderTop(
						rtfeldman$elm_css$Css$px(0))
					]) : (_Utils_eq(num, x - 1) ? _List_fromArray(
					[
						rtfeldman$elm_css$Css$borderTop(
						rtfeldman$elm_css$Css$px(0))
					]) : _List_Nil);
			} else {
				return _List_Nil;
			}
		};
		return A2(
			rtfeldman$elm_css$Html$Styled$ul,
			_List_fromArray(
				[
					rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							rtfeldman$elm_css$Css$listStyleType(rtfeldman$elm_css$Css$none),
							rtfeldman$elm_css$Css$margin(
							rtfeldman$elm_css$Css$px(0)),
							rtfeldman$elm_css$Css$padding(
							rtfeldman$elm_css$Css$px(0)),
							rtfeldman$elm_css$Css$backgroundColor(author$project$UI$Branding$colors.cM),
							A3(
							rtfeldman$elm_css$Css$border3,
							rtfeldman$elm_css$Css$px(1),
							rtfeldman$elm_css$Css$solid,
							rtfeldman$elm_css$Css$hex('EAEAEA'))
						]))
				]),
			A2(
				elm$core$List$indexedMap,
				F2(
					function (x, y) {
						return A2(
							rtfeldman$elm_css$Html$Styled$li,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$Attributes$css(
									_Utils_ap(
										_List_fromArray(
											[
												A2(rtfeldman$elm_css$Css$property, 'transition', '0.2s'),
												rtfeldman$elm_css$Css$hover(
												_List_fromArray(
													[
														rtfeldman$elm_css$Css$backgroundColor(
														rtfeldman$elm_css$Css$hex('EAEAEA'))
													])),
												A3(
												rtfeldman$elm_css$Css$borderTop3,
												rtfeldman$elm_css$Css$px(1),
												rtfeldman$elm_css$Css$solid,
												rtfeldman$elm_css$Css$hex('EAEAEA')),
												rtfeldman$elm_css$Css$firstChild(
												_List_fromArray(
													[
														rtfeldman$elm_css$Css$borderTop(
														rtfeldman$elm_css$Css$px(0))
													]))
											]),
										active(x)))
								]),
							_List_fromArray(
								[
									A2(
									rtfeldman$elm_css$Html$Styled$a,
									_List_fromArray(
										[
											rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													rtfeldman$elm_css$Css$textDecoration(rtfeldman$elm_css$Css$none),
													rtfeldman$elm_css$Css$color(author$project$UI$Branding$colors.bL),
													rtfeldman$elm_css$Css$displayFlex,
													A2(
													rtfeldman$elm_css$Css$padding2,
													rtfeldman$elm_css$Css$px(14),
													rtfeldman$elm_css$Css$px(0)),
													rtfeldman$elm_css$Css$alignItems(rtfeldman$elm_css$Css$center)
												])),
											rtfeldman$elm_css$Html$Styled$Attributes$href(
											'/course/' + (elm$core$String$fromInt(courseNum) + ('/' + elm$core$String$fromInt(x))))
										]),
									function () {
										var _n0 = y.du;
										if (!_n0.$) {
											return _List_fromArray(
												[
													A2(
													rtfeldman$elm_css$Html$Styled$span,
													_List_fromArray(
														[
															rtfeldman$elm_css$Html$Styled$Attributes$css(
															_List_fromArray(
																[
																	rtfeldman$elm_css$Css$width(
																	rtfeldman$elm_css$Css$em(4)),
																	rtfeldman$elm_css$Css$height(
																	rtfeldman$elm_css$Css$em(2)),
																	rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center)
																]))
														]),
													_List_fromArray(
														[
															A2(
															author$project$Icons$FeatherIcons$toHtml,
															_List_Nil,
															A2(
																author$project$Icons$FeatherIcons$withSizeUnit,
																'em',
																A2(author$project$Icons$FeatherIcons$withSize, 2, author$project$Icons$FeatherIcons$playCircle)))
														])),
													A2(
													rtfeldman$elm_css$Html$Styled$span,
													_List_fromArray(
														[
															rtfeldman$elm_css$Html$Styled$Attributes$css(
															_List_fromArray(
																[
																	rtfeldman$elm_css$Css$textDecoration(rtfeldman$elm_css$Css$none),
																	rtfeldman$elm_css$Css$color(author$project$UI$Branding$colors.bL)
																]))
														]),
													_List_fromArray(
														[
															rtfeldman$elm_css$Html$Styled$text(y.dT)
														]))
												]);
										} else {
											return _List_fromArray(
												[
													A2(
													rtfeldman$elm_css$Html$Styled$span,
													_List_fromArray(
														[
															rtfeldman$elm_css$Html$Styled$Attributes$css(
															_List_fromArray(
																[
																	rtfeldman$elm_css$Css$width(
																	rtfeldman$elm_css$Css$em(4)),
																	rtfeldman$elm_css$Css$color(author$project$UI$Branding$colors.cw),
																	rtfeldman$elm_css$Css$height(
																	rtfeldman$elm_css$Css$em(2)),
																	rtfeldman$elm_css$Css$paddingTop(
																	rtfeldman$elm_css$Css$em(0.7)),
																	rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center),
																	rtfeldman$elm_css$Css$verticalAlign(rtfeldman$elm_css$Css$middle)
																]))
														]),
													_List_Nil),
													A2(
													rtfeldman$elm_css$Html$Styled$div,
													_List_fromArray(
														[
															rtfeldman$elm_css$Html$Styled$Attributes$css(
															_List_fromArray(
																[
																	rtfeldman$elm_css$Css$flex(
																	rtfeldman$elm_css$Css$num(1))
																]))
														]),
													_List_fromArray(
														[
															A2(
															rtfeldman$elm_css$Html$Styled$div,
															_List_fromArray(
																[
																	rtfeldman$elm_css$Html$Styled$Attributes$css(
																	_List_fromArray(
																		[
																			rtfeldman$elm_css$Css$paddingBottom(
																			rtfeldman$elm_css$Css$em(0.5))
																		]))
																]),
															A2(
																elm$core$List$map,
																function (z) {
																	return A2(
																		author$project$Icons$FeatherIcons$toHtml,
																		_List_Nil,
																		A2(
																			author$project$Icons$FeatherIcons$withSizeUnit,
																			'em',
																			A2(
																				author$project$Icons$FeatherIcons$withSize,
																				1.3,
																				A2(author$project$Icons$FeatherIcons$withClass, 'pr1', z))));
																},
																icons(y.du))),
															A2(
															rtfeldman$elm_css$Html$Styled$div,
															_List_fromArray(
																[
																	rtfeldman$elm_css$Html$Styled$Attributes$css(
																	_List_fromArray(
																		[
																			rtfeldman$elm_css$Css$textDecoration(rtfeldman$elm_css$Css$none),
																			rtfeldman$elm_css$Css$color(author$project$UI$Branding$colors.bL)
																		]))
																]),
															_List_fromArray(
																[
																	rtfeldman$elm_css$Html$Styled$text(y.dT)
																]))
														]))
												]);
										}
									}())
								]));
					}),
				content));
	});
var author$project$Skeleton$footerHeight = function (hasPaid) {
	return hasPaid ? rtfeldman$elm_css$Css$px(0) : rtfeldman$elm_css$Css$px(40);
};
var rtfeldman$elm_css$Css$borderBottom3 = rtfeldman$elm_css$Css$prop3('border-bottom');
var rtfeldman$elm_css$Css$fixed = {aT: 0, a1: 0, bE: 0, I: 'fixed'};
var rtfeldman$elm_css$Css$overflowX = rtfeldman$elm_css$Css$prop1('overflow-x');
var rtfeldman$elm_css$Css$overflowY = rtfeldman$elm_css$Css$prop1('overflow-y');
var rtfeldman$elm_css$Css$right = rtfeldman$elm_css$Css$prop1('right');
var author$project$Page$Content$view = F2(
	function (hasPaid, model) {
		var title = model.ck.dT + (' | ' + model.aX.d7);
		return {
			p: _List_Nil,
			dN: _List_fromArray(
				[
					A2(
					rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$width(
									rtfeldman$elm_css$Css$pct(100)),
									rtfeldman$elm_css$Css$overflowY(rtfeldman$elm_css$Css$auto),
									rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$fixed),
									rtfeldman$elm_css$Css$top(
									rtfeldman$elm_css$Css$px(46)),
									rtfeldman$elm_css$Css$bottom(
									author$project$Skeleton$footerHeight(hasPaid)),
									author$project$UI$Screen$largeScreen(
									_List_fromArray(
										[
											rtfeldman$elm_css$Css$maxWidth(
											rtfeldman$elm_css$Css$pct(70))
										]))
								]))
						]),
					_List_fromArray(
						[
							author$project$Page$Content$listMenuBtn(model),
							author$project$Page$Content$displayContent(model.h),
							author$project$Page$Content$prevNextBtns(model)
						])),
					A2(
					rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$overflowY(rtfeldman$elm_css$Css$auto),
									rtfeldman$elm_css$Css$overflowX(rtfeldman$elm_css$Css$hidden),
									rtfeldman$elm_css$Css$margin(
									rtfeldman$elm_css$Css$px(0)),
									rtfeldman$elm_css$Css$padding(
									rtfeldman$elm_css$Css$px(0)),
									rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$fixed),
									rtfeldman$elm_css$Css$right(
									model.bB ? rtfeldman$elm_css$Css$px(0) : rtfeldman$elm_css$Css$px(-1000)),
									A2(rtfeldman$elm_css$Css$property, 'transition', '0.2s'),
									rtfeldman$elm_css$Css$top(
									rtfeldman$elm_css$Css$px(46)),
									rtfeldman$elm_css$Css$bottom(
									author$project$Skeleton$footerHeight(hasPaid)),
									author$project$UI$Screen$largeScreen(
									_List_fromArray(
										[
											rtfeldman$elm_css$Css$display(rtfeldman$elm_css$Css$block),
											rtfeldman$elm_css$Css$right(
											rtfeldman$elm_css$Css$px(0)),
											rtfeldman$elm_css$Css$width(
											rtfeldman$elm_css$Css$pct(30))
										]))
								]))
						]),
					_List_fromArray(
						[
							A2(
							rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											rtfeldman$elm_css$Css$width(
											rtfeldman$elm_css$Css$pct(100)),
											rtfeldman$elm_css$Css$backgroundColor(author$project$UI$Branding$colors.cM),
											A3(
											rtfeldman$elm_css$Css$borderLeft3,
											rtfeldman$elm_css$Css$px(1),
											rtfeldman$elm_css$Css$solid,
											rtfeldman$elm_css$Css$hex('EAEAEA')),
											A3(
											rtfeldman$elm_css$Css$borderBottom3,
											rtfeldman$elm_css$Css$px(1),
											rtfeldman$elm_css$Css$solid,
											rtfeldman$elm_css$Css$hex('EAEAEA')),
											author$project$UI$Screen$largeScreen(
											_List_fromArray(
												[
													rtfeldman$elm_css$Css$display(rtfeldman$elm_css$Css$none)
												]))
										]))
								]),
							_List_fromArray(
								[
									A2(
									author$project$UI$Elements$simpleIconBtn,
									_List_fromArray(
										[
											rtfeldman$elm_css$Html$Styled$Events$onClick(author$project$Page$Content$ToggleList)
										]),
									_List_fromArray(
										[
											A2(author$project$Icons$FeatherIcons$toHtml, _List_Nil, author$project$Icons$FeatherIcons$x),
											A2(
											rtfeldman$elm_css$Html$Styled$span,
											_List_fromArray(
												[
													rtfeldman$elm_css$Html$Styled$Attributes$css(_List_Nil)
												]),
											_List_fromArray(
												[
													rtfeldman$elm_css$Html$Styled$text('Close')
												]))
										]))
								])),
							A2(
							rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											rtfeldman$elm_css$Css$width(
											rtfeldman$elm_css$Css$pct(100)),
											rtfeldman$elm_css$Css$backgroundColor(author$project$UI$Branding$colors.cM),
											A3(
											rtfeldman$elm_css$Css$borderLeft3,
											rtfeldman$elm_css$Css$px(1),
											rtfeldman$elm_css$Css$solid,
											rtfeldman$elm_css$Css$hex('EAEAEA'))
										]))
								]),
							_List_fromArray(
								[
									A2(
									rtfeldman$elm_css$Html$Styled$h2,
									_List_fromArray(
										[
											rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center),
													rtfeldman$elm_css$Css$margin(
													rtfeldman$elm_css$Css$px(0)),
													rtfeldman$elm_css$Css$padding(
													rtfeldman$elm_css$Css$em(1)),
													rtfeldman$elm_css$Css$color(author$project$UI$Branding$colors.bL)
												]))
										]),
									_List_fromArray(
										[
											rtfeldman$elm_css$Html$Styled$text(model.aX.d7)
										]))
								])),
							A3(
							author$project$Page$Course$listCourseContent,
							model.be,
							elm$core$Maybe$Just(model.ao),
							model.aX)
						]))
				]),
			d7: title
		};
	});
var author$project$Skeleton$headerHeight = rtfeldman$elm_css$Css$px(46);
var rtfeldman$elm_css$Html$Styled$h1 = rtfeldman$elm_css$Html$Styled$node('h1');
var author$project$Page$Course$view = F2(
	function (hasPaid, model) {
		var courses = author$project$Config$Course$allCourses(hasPaid);
		var courseInfo = A2(
			elm$core$Maybe$withDefault,
			A3(author$project$Config$Types$CourseInfo, 'No Course Found', '', _List_Nil),
			A2(
				elm$core$Array$get,
				model.bX,
				elm$core$Array$fromList(courses)));
		return {
			p: _List_Nil,
			dN: _List_fromArray(
				[
					A2(
					rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$auto),
									rtfeldman$elm_css$Css$maxWidth(
									rtfeldman$elm_css$Css$em(40)),
									rtfeldman$elm_css$Css$marginTop(author$project$Skeleton$headerHeight)
								]))
						]),
					_List_fromArray(
						[
							A2(
							rtfeldman$elm_css$Html$Styled$h1,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center),
											rtfeldman$elm_css$Css$margin(
											rtfeldman$elm_css$Css$px(0)),
											rtfeldman$elm_css$Css$paddingTop(
											rtfeldman$elm_css$Css$em(0.5)),
											rtfeldman$elm_css$Css$paddingBottom(
											rtfeldman$elm_css$Css$em(1)),
											rtfeldman$elm_css$Css$color(author$project$UI$Branding$colors.bL)
										]))
								]),
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$text(courseInfo.d7)
								])),
							A3(author$project$Page$Course$listCourseContent, model.bX, elm$core$Maybe$Nothing, courseInfo)
						]))
				]),
			d7: courseInfo.d7
		};
	});
var author$project$Icons$FeatherIcons$edit = A2(
	author$project$Icons$FeatherIcons$makeBuilder,
	'edit',
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$d('M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$polygon,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$points('18 2 22 6 12 16 8 16 8 12 18 2')
				]),
			_List_Nil)
		]));
var rtfeldman$elm_css$Svg$Styled$Attributes$rx = rtfeldman$elm_css$VirtualDom$Styled$attribute('rx');
var rtfeldman$elm_css$Svg$Styled$Attributes$ry = rtfeldman$elm_css$VirtualDom$Styled$attribute('ry');
var author$project$Icons$FeatherIcons$video = A2(
	author$project$Icons$FeatherIcons$makeBuilder,
	'video',
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$polygon,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$points('23 7 16 12 23 17 23 7')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$rect,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$x('1'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y('5'),
					rtfeldman$elm_css$Svg$Styled$Attributes$width('15'),
					rtfeldman$elm_css$Svg$Styled$Attributes$height('14'),
					rtfeldman$elm_css$Svg$Styled$Attributes$rx('2'),
					rtfeldman$elm_css$Svg$Styled$Attributes$ry('2')
				]),
			_List_Nil)
		]));
var elm$core$List$partition = F2(
	function (pred, list) {
		var step = F2(
			function (x, _n0) {
				var trues = _n0.a;
				var falses = _n0.b;
				return pred(x) ? _Utils_Tuple2(
					A2(elm$core$List$cons, x, trues),
					falses) : _Utils_Tuple2(
					trues,
					A2(elm$core$List$cons, x, falses));
			});
		return A3(
			elm$core$List$foldr,
			step,
			_Utils_Tuple2(_List_Nil, _List_Nil),
			list);
	});
var rtfeldman$elm_css$Css$backgroundImage = rtfeldman$elm_css$Css$prop1('background-image');
var rtfeldman$elm_css$Css$backgroundRepeat = rtfeldman$elm_css$Css$prop1('background-repeat');
var rtfeldman$elm_css$Css$Preprocess$WithPseudoElement = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var rtfeldman$elm_css$Css$Structure$PseudoElement = elm$core$Basics$identity;
var rtfeldman$elm_css$Css$pseudoElement = function (element) {
	return rtfeldman$elm_css$Css$Preprocess$WithPseudoElement(element);
};
var rtfeldman$elm_css$Css$before = rtfeldman$elm_css$Css$pseudoElement('before');
var rtfeldman$elm_css$Css$listStyle = rtfeldman$elm_css$Css$prop1('list-style');
var rtfeldman$elm_css$Css$repeat = {ak: 0, K: 0, I: 'repeat'};
var rtfeldman$elm_css$Css$textShadow = rtfeldman$elm_css$Css$prop1('text-shadow');
var rtfeldman$elm_css$Css$url = function (urlValue) {
	return {aj: 0, I: 'url(' + (urlValue + ')')};
};
var rtfeldman$elm_css$Html$Styled$Attributes$class = rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('className');
var author$project$Page$Home$viewCourse = F2(
	function (num, info) {
		var colorList = _List_fromArray(
			[author$project$UI$Branding$colors.cL, author$project$UI$Branding$colors.dh, author$project$UI$Branding$colors.cw, author$project$UI$Branding$colors.d_, author$project$UI$Branding$colors.di, author$project$UI$Branding$colors.dj]);
		var index = A2(
			elm$core$Basics$modBy,
			elm$core$List$length(colorList),
			num);
		var color = A2(
			elm$core$Maybe$withDefault,
			author$project$UI$Branding$colors.cL,
			A2(
				elm$core$Array$get,
				index,
				elm$core$Array$fromList(colorList)));
		var _n0 = A2(
			elm$core$List$partition,
			function (x) {
				var _n1 = x.du;
				switch (_n1.$) {
					case 0:
						return true;
					case 1:
						return false;
					default:
						return false;
				}
			},
			info.dt);
		var videos = _n0.a;
		var exercises = _n0.b;
		var numExercises = elm$core$List$length(exercises);
		var numVideos = elm$core$List$length(videos);
		return A2(
			rtfeldman$elm_css$Html$Styled$a,
			_List_fromArray(
				[
					rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							rtfeldman$elm_css$Css$backgroundColor(author$project$UI$Branding$colors.cM),
							rtfeldman$elm_css$Css$display(rtfeldman$elm_css$Css$block),
							rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$relative),
							rtfeldman$elm_css$Css$textShadow(rtfeldman$elm_css$Css$none),
							rtfeldman$elm_css$Css$textDecoration(rtfeldman$elm_css$Css$none),
							A5(
							rtfeldman$elm_css$Css$boxShadow5,
							rtfeldman$elm_css$Css$px(0),
							rtfeldman$elm_css$Css$px(4),
							rtfeldman$elm_css$Css$px(8),
							rtfeldman$elm_css$Css$px(0),
							A4(rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0.2)),
							rtfeldman$elm_css$Css$backgroundImage(rtfeldman$elm_css$Css$none),
							rtfeldman$elm_css$Css$color(author$project$UI$Branding$colors.bL),
							rtfeldman$elm_css$Css$width(
							rtfeldman$elm_css$Css$em(21)),
							rtfeldman$elm_css$Css$padding(
							rtfeldman$elm_css$Css$px(0)),
							rtfeldman$elm_css$Css$margin(
							rtfeldman$elm_css$Css$em(1)),
							rtfeldman$elm_css$Css$marginBottom(
							rtfeldman$elm_css$Css$em(2)),
							A2(rtfeldman$elm_css$Css$property, 'transition', '0.5s'),
							rtfeldman$elm_css$Css$hover(
							_List_fromArray(
								[
									A5(
									rtfeldman$elm_css$Css$boxShadow5,
									rtfeldman$elm_css$Css$px(0),
									rtfeldman$elm_css$Css$px(4),
									rtfeldman$elm_css$Css$px(8),
									rtfeldman$elm_css$Css$px(0),
									A4(rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0.5))
								])),
							rtfeldman$elm_css$Css$cursor(rtfeldman$elm_css$Css$pointer)
						])),
					rtfeldman$elm_css$Html$Styled$Attributes$href(
					'/course/' + elm$core$String$fromInt(num))
				]),
			_List_fromArray(
				[
					A2(
					rtfeldman$elm_css$Html$Styled$h2,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$fontWeight(
									rtfeldman$elm_css$Css$int(700)),
									rtfeldman$elm_css$Css$margin(
									rtfeldman$elm_css$Css$px(0)),
									rtfeldman$elm_css$Css$marginBottom(
									rtfeldman$elm_css$Css$em(0.5)),
									rtfeldman$elm_css$Css$fontSize(
									rtfeldman$elm_css$Css$rem(1.7)),
									A2(
									rtfeldman$elm_css$Css$padding2,
									rtfeldman$elm_css$Css$px(24),
									rtfeldman$elm_css$Css$px(18)),
									rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$relative),
									rtfeldman$elm_css$Css$before(
									_List_fromArray(
										[
											rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$absolute),
											rtfeldman$elm_css$Css$top(
											rtfeldman$elm_css$Css$px(0)),
											rtfeldman$elm_css$Css$left(
											rtfeldman$elm_css$Css$px(0)),
											rtfeldman$elm_css$Css$width(
											rtfeldman$elm_css$Css$pct(100)),
											rtfeldman$elm_css$Css$height(
											rtfeldman$elm_css$Css$pct(100)),
											rtfeldman$elm_css$Css$backgroundImage(
											rtfeldman$elm_css$Css$url('/music-party.svg')),
											rtfeldman$elm_css$Css$backgroundRepeat(rtfeldman$elm_css$Css$repeat),
											A2(rtfeldman$elm_css$Css$property, 'filter', 'opacity(15%)'),
											A2(rtfeldman$elm_css$Css$property, 'content', '\'\''),
											rtfeldman$elm_css$Css$backgroundColor(color)
										]))
								]))
						]),
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$text(
							elm$core$String$fromInt(num + 1) + '. '),
							rtfeldman$elm_css$Html$Styled$text(info.d7)
						])),
					A2(
					rtfeldman$elm_css$Html$Styled$ul,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$listStyle(rtfeldman$elm_css$Css$none),
									rtfeldman$elm_css$Css$displayFlex,
									rtfeldman$elm_css$Css$padding(
									rtfeldman$elm_css$Css$px(0)),
									rtfeldman$elm_css$Css$margin(
									rtfeldman$elm_css$Css$px(0)),
									rtfeldman$elm_css$Css$marginLeft(
									rtfeldman$elm_css$Css$em(2)),
									rtfeldman$elm_css$Css$marginTop(
									rtfeldman$elm_css$Css$em(1))
								]))
						]),
					(numVideos > 0) ? _List_fromArray(
						[
							A2(
							rtfeldman$elm_css$Html$Styled$li,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$Attributes$class('mr2')
								]),
							_List_fromArray(
								[
									A2(
									author$project$Icons$FeatherIcons$toHtml,
									_List_Nil,
									A2(
										author$project$Icons$FeatherIcons$withSizeUnit,
										'em',
										A2(
											author$project$Icons$FeatherIcons$withSize,
											0.9,
											A2(author$project$Icons$FeatherIcons$withClass, 'mr1', author$project$Icons$FeatherIcons$video)))),
									rtfeldman$elm_css$Html$Styled$text(
									_Utils_ap(
										elm$core$String$fromInt(numVideos),
										(numVideos === 1) ? ' Video' : ' Videos'))
								])),
							A2(
							rtfeldman$elm_css$Html$Styled$li,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									author$project$Icons$FeatherIcons$toHtml,
									_List_Nil,
									A2(
										author$project$Icons$FeatherIcons$withSizeUnit,
										'em',
										A2(
											author$project$Icons$FeatherIcons$withSize,
											0.9,
											A2(author$project$Icons$FeatherIcons$withClass, 'mr1', author$project$Icons$FeatherIcons$edit)))),
									rtfeldman$elm_css$Html$Styled$text(
									elm$core$String$fromInt(numExercises) + ' Exercises')
								]))
						]) : _List_fromArray(
						[
							A2(
							rtfeldman$elm_css$Html$Styled$li,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									author$project$Icons$FeatherIcons$toHtml,
									_List_Nil,
									A2(
										author$project$Icons$FeatherIcons$withSizeUnit,
										'em',
										A2(
											author$project$Icons$FeatherIcons$withSize,
											0.9,
											A2(author$project$Icons$FeatherIcons$withClass, 'mr1', author$project$Icons$FeatherIcons$edit)))),
									rtfeldman$elm_css$Html$Styled$text(
									elm$core$String$fromInt(numExercises) + ' Exercises')
								]))
						])),
					A2(
					rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$margin(
									rtfeldman$elm_css$Css$em(1)),
									rtfeldman$elm_css$Css$marginTop(
									rtfeldman$elm_css$Css$em(2))
								]))
						]),
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$text(info.dz)
						]))
				]));
	});
var rtfeldman$elm_css$Css$flexWrap = rtfeldman$elm_css$Css$prop1('flex-wrap');
var rtfeldman$elm_css$Css$wrap = {aY: 0, bj: 0, I: 'wrap'};
var rtfeldman$elm_css$Html$Styled$img = rtfeldman$elm_css$Html$Styled$node('img');
var rtfeldman$elm_css$Html$Styled$Attributes$alt = rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('alt');
var rtfeldman$elm_css$Html$Styled$Attributes$height = function (n) {
	return A2(
		rtfeldman$elm_css$VirtualDom$Styled$attribute,
		'height',
		elm$core$String$fromInt(n));
};
var rtfeldman$elm_css$Html$Styled$Attributes$target = rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('target');
var author$project$Page$Home$view = F2(
	function (hasPaid, model) {
		var courses = author$project$Config$Course$allCourses(hasPaid);
		return {
			p: _List_Nil,
			dN: _List_fromArray(
				[
					A2(
					rtfeldman$elm_css$Html$Styled$h1,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$marginTop(author$project$Skeleton$headerHeight),
									rtfeldman$elm_css$Css$paddingTop(
									rtfeldman$elm_css$Css$em(1)),
									rtfeldman$elm_css$Css$width(
									rtfeldman$elm_css$Css$pct(100)),
									rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center)
								]))
						]),
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$text('Know Your Theory')
						])),
					A2(
					rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$paddingTop(
									rtfeldman$elm_css$Css$px(0)),
									rtfeldman$elm_css$Css$width(
									rtfeldman$elm_css$Css$pct(100)),
									rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center)
								]))
						]),
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$text('Master your theory fundamentals.')
						])),
					A2(
					rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$paddingTop(
									rtfeldman$elm_css$Css$px(0)),
									rtfeldman$elm_css$Css$width(
									rtfeldman$elm_css$Css$pct(100)),
									rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center)
								]))
						]),
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$text('Crafted with love by Ryan Frazier. If you benefit from this app please consider a donation.')
						])),
					A2(
					rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$paddingTop(
									rtfeldman$elm_css$Css$px(0)),
									rtfeldman$elm_css$Css$width(
									rtfeldman$elm_css$Css$pct(100)),
									rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center)
								]))
						]),
					_List_fromArray(
						[
							A2(
							rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											rtfeldman$elm_css$Css$paddingTop(
											rtfeldman$elm_css$Css$px(0)),
											rtfeldman$elm_css$Css$width(
											rtfeldman$elm_css$Css$pct(100)),
											rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center)
										])),
									rtfeldman$elm_css$Html$Styled$Attributes$href('https://ko-fi.com/H2H81MK2A'),
									rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
								]),
							_List_fromArray(
								[
									A2(
									rtfeldman$elm_css$Html$Styled$img,
									_List_fromArray(
										[
											rtfeldman$elm_css$Html$Styled$Attributes$height(36),
											rtfeldman$elm_css$Html$Styled$Attributes$src('https://cdn.ko-fi.com/cdn/kofi2.png?v=2'),
											rtfeldman$elm_css$Html$Styled$Attributes$alt('Buy Me a Coffee at ko-fi.com')
										]),
									_List_Nil)
								]))
						])),
					A2(
					rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$paddingTop(
									rtfeldman$elm_css$Css$px(0)),
									rtfeldman$elm_css$Css$width(
									rtfeldman$elm_css$Css$pct(100)),
									rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center)
								]))
						]),
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$text('I have more music resources available at '),
							A2(
							rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$Attributes$href('https://frazierpianostudio.com')
								]),
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$text('frazierpianostudio.com')
								]))
						])),
					A2(
					rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									A2(
									rtfeldman$elm_css$Css$margin2,
									rtfeldman$elm_css$Css$em(1),
									rtfeldman$elm_css$Css$auto),
									rtfeldman$elm_css$Css$marginTop(
									rtfeldman$elm_css$Css$px(0)),
									rtfeldman$elm_css$Css$padding(
									rtfeldman$elm_css$Css$em(1)),
									rtfeldman$elm_css$Css$maxWidth(
									rtfeldman$elm_css$Css$em(80)),
									rtfeldman$elm_css$Css$displayFlex,
									rtfeldman$elm_css$Css$flexWrap(rtfeldman$elm_css$Css$wrap),
									rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$center)
								]))
						]),
					A2(elm$core$List$indexedMap, author$project$Page$Home$viewCourse, courses))
				]),
			d7: 'Know Your Theory'
		};
	});
var rtfeldman$elm_css$Html$Styled$footer = rtfeldman$elm_css$Html$Styled$node('footer');
var author$project$Skeleton$viewFooter = function (hasPaid) {
	return hasPaid ? A2(rtfeldman$elm_css$Html$Styled$footer, _List_Nil, _List_Nil) : A2(
		rtfeldman$elm_css$Html$Styled$footer,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$Attributes$href('/payment'),
						rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								rtfeldman$elm_css$Css$width(
								rtfeldman$elm_css$Css$pct(100)),
								rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center),
								rtfeldman$elm_css$Css$display(rtfeldman$elm_css$Css$block),
								rtfeldman$elm_css$Css$fontWeight(
								rtfeldman$elm_css$Css$int(700)),
								rtfeldman$elm_css$Css$fontSize(
								rtfeldman$elm_css$Css$em(1.2)),
								rtfeldman$elm_css$Css$height(
								author$project$Skeleton$footerHeight(hasPaid)),
								rtfeldman$elm_css$Css$lineHeight(
								author$project$Skeleton$footerHeight(hasPaid)),
								rtfeldman$elm_css$Css$textDecoration(rtfeldman$elm_css$Css$none),
								rtfeldman$elm_css$Css$color(author$project$UI$Branding$colors.bL),
								rtfeldman$elm_css$Css$backgroundColor(author$project$UI$Branding$colors.dO),
								rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$fixed),
								rtfeldman$elm_css$Css$bottom(
								rtfeldman$elm_css$Css$px(0))
							]))
					]),
				_List_fromArray(
					[
						rtfeldman$elm_css$Html$Styled$text('Pay to unlock')
					]))
			]));
};
var author$project$Icons$FeatherIcons$lock = A2(
	author$project$Icons$FeatherIcons$makeBuilder,
	'lock',
	_List_fromArray(
		[
			A2(
			rtfeldman$elm_css$Svg$Styled$rect,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$x('3'),
					rtfeldman$elm_css$Svg$Styled$Attributes$y('11'),
					rtfeldman$elm_css$Svg$Styled$Attributes$width('18'),
					rtfeldman$elm_css$Svg$Styled$Attributes$height('11'),
					rtfeldman$elm_css$Svg$Styled$Attributes$rx('2'),
					rtfeldman$elm_css$Svg$Styled$Attributes$ry('2')
				]),
			_List_Nil),
			A2(
			rtfeldman$elm_css$Svg$Styled$path,
			_List_fromArray(
				[
					rtfeldman$elm_css$Svg$Styled$Attributes$d('M7 11V7a5 5 0 0 1 10 0v4')
				]),
			_List_Nil)
		]));
var author$project$UI$Elements$menuLinkBtn = A2(
	rtfeldman$elm_css$Html$Styled$styled,
	rtfeldman$elm_css$Html$Styled$a,
	_List_fromArray(
		[
			rtfeldman$elm_css$Css$displayFlex,
			rtfeldman$elm_css$Css$alignItems(rtfeldman$elm_css$Css$center),
			rtfeldman$elm_css$Css$textDecoration(rtfeldman$elm_css$Css$none),
			rtfeldman$elm_css$Css$color(author$project$UI$Branding$colors.bL),
			rtfeldman$elm_css$Css$fontWeight(
			rtfeldman$elm_css$Css$int(600)),
			rtfeldman$elm_css$Css$fontSize(
			rtfeldman$elm_css$Css$em(1.2)),
			rtfeldman$elm_css$Css$marginLeft(
			rtfeldman$elm_css$Css$px(4))
		]));
var rtfeldman$elm_css$Css$flexDirection = rtfeldman$elm_css$Css$prop1('flex-direction');
var rtfeldman$elm_css$Css$rgb = F3(
	function (r, g, b) {
		return {
			aR: 1,
			dq: b,
			am: 0,
			cw: g,
			d2: r,
			I: A2(
				rtfeldman$elm_css$Css$cssFunction,
				'rgb',
				A2(
					elm$core$List$map,
					elm$core$String$fromInt,
					_List_fromArray(
						[r, g, b])))
		};
	});
var rtfeldman$elm_css$Css$row = {bO: 0, aY: 0, I: 'row'};
var rtfeldman$elm_css$Html$Styled$nav = rtfeldman$elm_css$Html$Styled$node('nav');
var author$project$Skeleton$viewHeader = F2(
	function (hasPaid, userProfile) {
		return A2(
			rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							rtfeldman$elm_css$Css$width(
							rtfeldman$elm_css$Css$pct(100)),
							rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center),
							A3(
							rtfeldman$elm_css$Css$borderBottom3,
							rtfeldman$elm_css$Css$px(1),
							rtfeldman$elm_css$Css$solid,
							A4(rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0.2)),
							rtfeldman$elm_css$Css$position(rtfeldman$elm_css$Css$fixed),
							rtfeldman$elm_css$Css$top(
							rtfeldman$elm_css$Css$px(0)),
							rtfeldman$elm_css$Css$left(
							rtfeldman$elm_css$Css$px(0)),
							rtfeldman$elm_css$Css$right(
							rtfeldman$elm_css$Css$px(0)),
							rtfeldman$elm_css$Css$backgroundColor(
							A3(rtfeldman$elm_css$Css$rgb, 255, 255, 255)),
							rtfeldman$elm_css$Css$zIndex(
							rtfeldman$elm_css$Css$int(1)),
							rtfeldman$elm_css$Css$height(author$project$Skeleton$headerHeight)
						]))
				]),
			_List_fromArray(
				[
					A2(
					rtfeldman$elm_css$Html$Styled$nav,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$displayFlex,
									rtfeldman$elm_css$Css$justifyContent(rtfeldman$elm_css$Css$spaceBetween)
								]))
						]),
					_List_fromArray(
						[
							A2(
							rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											rtfeldman$elm_css$Css$displayFlex,
											rtfeldman$elm_css$Css$flexDirection(rtfeldman$elm_css$Css$row)
										]))
								]),
							_List_fromArray(
								[
									A2(
									author$project$UI$Elements$menuLinkBtn,
									_List_fromArray(
										[
											rtfeldman$elm_css$Html$Styled$Attributes$href('/')
										]),
									_List_fromArray(
										[
											A2(author$project$Icons$FeatherIcons$toHtml, _List_Nil, author$project$Icons$FeatherIcons$home),
											A2(
											rtfeldman$elm_css$Html$Styled$span,
											_List_fromArray(
												[
													rtfeldman$elm_css$Html$Styled$Attributes$css(
													_List_fromArray(
														[
															rtfeldman$elm_css$Css$padding(
															rtfeldman$elm_css$Css$px(8)),
															rtfeldman$elm_css$Css$marginLeft(
															rtfeldman$elm_css$Css$px(4))
														]))
												]),
											_List_fromArray(
												[
													rtfeldman$elm_css$Html$Styled$text('Home')
												]))
										]))
								])),
							A2(
							rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											rtfeldman$elm_css$Css$displayFlex,
											rtfeldman$elm_css$Css$flexDirection(rtfeldman$elm_css$Css$row)
										]))
								]),
							_Utils_ap(
								hasPaid ? _List_Nil : _List_fromArray(
									[
										A2(
										author$project$UI$Elements$menuLinkBtn,
										_List_fromArray(
											[
												rtfeldman$elm_css$Html$Styled$Attributes$href('/payment'),
												rtfeldman$elm_css$Html$Styled$Attributes$css(
												_List_fromArray(
													[
														rtfeldman$elm_css$Css$marginRight(
														rtfeldman$elm_css$Css$px(4))
													]))
											]),
										_List_fromArray(
											[
												A2(author$project$Icons$FeatherIcons$toHtml, _List_Nil, author$project$Icons$FeatherIcons$lock),
												A2(
												rtfeldman$elm_css$Html$Styled$span,
												_List_fromArray(
													[
														rtfeldman$elm_css$Html$Styled$Attributes$css(
														_List_fromArray(
															[
																rtfeldman$elm_css$Css$marginLeft(
																rtfeldman$elm_css$Css$px(4))
															]))
													]),
												_List_fromArray(
													[
														rtfeldman$elm_css$Html$Styled$text('Unlock')
													]))
											]))
									]),
								_List_Nil))
						]))
				]));
	});
var author$project$Skeleton$mainWrapper = F3(
	function (hasPaid, userProfile, body) {
		return A2(
			rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(author$project$Skeleton$viewHeader, hasPaid, userProfile),
					A2(
					rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									rtfeldman$elm_css$Css$marginBottom(
									author$project$Skeleton$footerHeight(hasPaid))
								]))
						]),
					body),
					author$project$Skeleton$viewFooter(hasPaid)
				]));
	});
var elm$html$Html$map = elm$virtual_dom$VirtualDom$map;
var elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var elm$virtual_dom$VirtualDom$keyedNodeNS = F2(
	function (namespace, tag) {
		return A2(
			_VirtualDom_keyedNodeNS,
			namespace,
			_VirtualDom_noScript(tag));
	});
var elm$virtual_dom$VirtualDom$nodeNS = function (tag) {
	return _VirtualDom_nodeNS(
		_VirtualDom_noScript(tag));
};
var rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles = F2(
	function (_n0, styles) {
		var newStyles = _n0.b;
		var classname = _n0.c;
		return elm$core$List$isEmpty(newStyles) ? styles : A3(elm$core$Dict$insert, classname, newStyles, styles);
	});
var rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute = function (_n0) {
	var val = _n0.a;
	return val;
};
var rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml = F2(
	function (_n6, _n7) {
		var key = _n6.a;
		var html = _n6.b;
		var pairs = _n7.a;
		var styles = _n7.b;
		switch (html.$) {
			case 4:
				var vdom = html.a;
				return _Utils_Tuple2(
					A2(
						elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3(elm$core$List$foldl, rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _n9 = A3(
					elm$core$List$foldl,
					rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _n9.a;
				var finalStyles = _n9.b;
				var vdom = A3(
					elm$virtual_dom$VirtualDom$node,
					elemType,
					A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3(elm$core$List$foldl, rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _n10 = A3(
					elm$core$List$foldl,
					rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _n10.a;
				var finalStyles = _n10.b;
				var vdom = A4(
					elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3(elm$core$List$foldl, rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _n11 = A3(
					elm$core$List$foldl,
					rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _n11.a;
				var finalStyles = _n11.b;
				var vdom = A3(
					elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3(elm$core$List$foldl, rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _n12 = A3(
					elm$core$List$foldl,
					rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _n12.a;
				var finalStyles = _n12.b;
				var vdom = A4(
					elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
		}
	});
var rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml = F2(
	function (html, _n0) {
		var nodes = _n0.a;
		var styles = _n0.b;
		switch (html.$) {
			case 4:
				var vdomNode = html.a;
				return _Utils_Tuple2(
					A2(elm$core$List$cons, vdomNode, nodes),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3(elm$core$List$foldl, rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _n2 = A3(
					elm$core$List$foldl,
					rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _n2.a;
				var finalStyles = _n2.b;
				var vdomNode = A3(
					elm$virtual_dom$VirtualDom$node,
					elemType,
					A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3(elm$core$List$foldl, rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _n3 = A3(
					elm$core$List$foldl,
					rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _n3.a;
				var finalStyles = _n3.b;
				var vdomNode = A4(
					elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3(elm$core$List$foldl, rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _n4 = A3(
					elm$core$List$foldl,
					rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _n4.a;
				var finalStyles = _n4.b;
				var vdomNode = A3(
					elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3(elm$core$List$foldl, rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _n5 = A3(
					elm$core$List$foldl,
					rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _n5.a;
				var finalStyles = _n5.b;
				var vdomNode = A4(
					elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(elm$core$List$cons, vdomNode, nodes),
					finalStyles);
		}
	});
var elm$core$Dict$singleton = F2(
	function (key, value) {
		return A5(elm$core$Dict$RBNode_elm_builtin, 1, key, value, elm$core$Dict$RBEmpty_elm_builtin, elm$core$Dict$RBEmpty_elm_builtin);
	});
var rtfeldman$elm_css$VirtualDom$Styled$stylesFromPropertiesHelp = F2(
	function (candidate, properties) {
		stylesFromPropertiesHelp:
		while (true) {
			if (!properties.b) {
				return candidate;
			} else {
				var _n1 = properties.a;
				var styles = _n1.b;
				var classname = _n1.c;
				var rest = properties.b;
				if (elm$core$String$isEmpty(classname)) {
					var $temp$candidate = candidate,
						$temp$properties = rest;
					candidate = $temp$candidate;
					properties = $temp$properties;
					continue stylesFromPropertiesHelp;
				} else {
					var $temp$candidate = elm$core$Maybe$Just(
						_Utils_Tuple2(classname, styles)),
						$temp$properties = rest;
					candidate = $temp$candidate;
					properties = $temp$properties;
					continue stylesFromPropertiesHelp;
				}
			}
		}
	});
var rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties = function (properties) {
	var _n0 = A2(rtfeldman$elm_css$VirtualDom$Styled$stylesFromPropertiesHelp, elm$core$Maybe$Nothing, properties);
	if (_n0.$ === 1) {
		return elm$core$Dict$empty;
	} else {
		var _n1 = _n0.a;
		var classname = _n1.a;
		var styles = _n1.b;
		return A2(elm$core$Dict$singleton, classname, styles);
	}
};
var rtfeldman$elm_css$Css$Structure$ClassSelector = function (a) {
	return {$: 0, a: a};
};
var rtfeldman$elm_css$VirtualDom$Styled$snippetFromPair = function (_n0) {
	var classname = _n0.a;
	var styles = _n0.b;
	return A2(
		rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
		styles,
		rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
			_List_fromArray(
				[
					rtfeldman$elm_css$Css$Structure$ClassSelector(classname)
				])));
};
var rtfeldman$elm_css$VirtualDom$Styled$toDeclaration = function (dict) {
	return rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
		elm$core$List$singleton(
			rtfeldman$elm_css$Css$Preprocess$stylesheet(
				A2(
					elm$core$List$map,
					rtfeldman$elm_css$VirtualDom$Styled$snippetFromPair,
					elm$core$Dict$toList(dict)))));
};
var rtfeldman$elm_css$VirtualDom$Styled$toStyleNode = function (styles) {
	return A3(
		elm$virtual_dom$VirtualDom$node,
		'style',
		_List_Nil,
		elm$core$List$singleton(
			elm$virtual_dom$VirtualDom$text(
				rtfeldman$elm_css$VirtualDom$Styled$toDeclaration(styles))));
};
var rtfeldman$elm_css$VirtualDom$Styled$unstyle = F3(
	function (elemType, properties, children) {
		var unstyledProperties = A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _n0 = A3(
			elm$core$List$foldl,
			rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _n0.a;
		var styles = _n0.b;
		var styleNode = rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(styles);
		return A3(
			elm$virtual_dom$VirtualDom$node,
			elemType,
			unstyledProperties,
			A2(
				elm$core$List$cons,
				styleNode,
				elm$core$List$reverse(childNodes)));
	});
var rtfeldman$elm_css$VirtualDom$Styled$containsKey = F2(
	function (key, pairs) {
		containsKey:
		while (true) {
			if (!pairs.b) {
				return false;
			} else {
				var _n1 = pairs.a;
				var str = _n1.a;
				var rest = pairs.b;
				if (_Utils_eq(key, str)) {
					return true;
				} else {
					var $temp$key = key,
						$temp$pairs = rest;
					key = $temp$key;
					pairs = $temp$pairs;
					continue containsKey;
				}
			}
		}
	});
var rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey = F2(
	function (_default, pairs) {
		getUnusedKey:
		while (true) {
			if (!pairs.b) {
				return _default;
			} else {
				var _n1 = pairs.a;
				var firstKey = _n1.a;
				var rest = pairs.b;
				var newKey = '_' + firstKey;
				if (A2(rtfeldman$elm_css$VirtualDom$Styled$containsKey, newKey, rest)) {
					var $temp$default = newKey,
						$temp$pairs = rest;
					_default = $temp$default;
					pairs = $temp$pairs;
					continue getUnusedKey;
				} else {
					return newKey;
				}
			}
		}
	});
var rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode = F2(
	function (allStyles, keyedChildNodes) {
		var styleNodeKey = A2(rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey, '_', keyedChildNodes);
		var finalNode = rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(allStyles);
		return _Utils_Tuple2(styleNodeKey, finalNode);
	});
var rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed = F3(
	function (elemType, properties, keyedChildren) {
		var unstyledProperties = A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _n0 = A3(
			elm$core$List$foldl,
			rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _n0.a;
		var styles = _n0.b;
		var keyedStyleNode = A2(rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode, styles, keyedChildNodes);
		return A3(
			elm$virtual_dom$VirtualDom$keyedNode,
			elemType,
			unstyledProperties,
			A2(
				elm$core$List$cons,
				keyedStyleNode,
				elm$core$List$reverse(keyedChildNodes)));
	});
var rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS = F4(
	function (ns, elemType, properties, keyedChildren) {
		var unstyledProperties = A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _n0 = A3(
			elm$core$List$foldl,
			rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _n0.a;
		var styles = _n0.b;
		var keyedStyleNode = A2(rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode, styles, keyedChildNodes);
		return A4(
			elm$virtual_dom$VirtualDom$keyedNodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				elm$core$List$cons,
				keyedStyleNode,
				elm$core$List$reverse(keyedChildNodes)));
	});
var rtfeldman$elm_css$VirtualDom$Styled$unstyleNS = F4(
	function (ns, elemType, properties, children) {
		var unstyledProperties = A2(elm$core$List$map, rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _n0 = A3(
			elm$core$List$foldl,
			rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _n0.a;
		var styles = _n0.b;
		var styleNode = rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(styles);
		return A4(
			elm$virtual_dom$VirtualDom$nodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				elm$core$List$cons,
				styleNode,
				elm$core$List$reverse(childNodes)));
	});
var rtfeldman$elm_css$VirtualDom$Styled$toUnstyled = function (vdom) {
	switch (vdom.$) {
		case 4:
			var plainNode = vdom.a;
			return plainNode;
		case 0:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A3(rtfeldman$elm_css$VirtualDom$Styled$unstyle, elemType, properties, children);
		case 1:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A4(rtfeldman$elm_css$VirtualDom$Styled$unstyleNS, ns, elemType, properties, children);
		case 2:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A3(rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed, elemType, properties, children);
		default:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A4(rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS, ns, elemType, properties, children);
	}
};
var rtfeldman$elm_css$Html$Styled$toUnstyled = rtfeldman$elm_css$VirtualDom$Styled$toUnstyled;
var author$project$Skeleton$view = F4(
	function (hasPaid, userProfile, toMsg, details) {
		var body = A2(rtfeldman$elm_css$Html$Styled$div, details.p, details.dN);
		return {
			dr: _List_fromArray(
				[
					A2(
					elm$html$Html$map,
					toMsg,
					rtfeldman$elm_css$Html$Styled$toUnstyled(
						A3(
							author$project$Skeleton$mainWrapper,
							hasPaid,
							userProfile,
							_List_fromArray(
								[body]))))
				]),
			d7: details.d7
		};
	});
var author$project$Main$view = function (model) {
	var _n0 = model.C;
	switch (_n0.$) {
		case 2:
			return A4(
				author$project$Skeleton$view,
				model.A,
				model.aN,
				elm$core$Basics$never,
				{
					p: _List_Nil,
					dN: _List_fromArray(
						[
							A2(
							rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											rtfeldman$elm_css$Css$maxWidth(
											rtfeldman$elm_css$Css$em(40)),
											rtfeldman$elm_css$Css$margin(rtfeldman$elm_css$Css$auto),
											rtfeldman$elm_css$Css$textAlign(rtfeldman$elm_css$Css$center),
											rtfeldman$elm_css$Css$marginTop(author$project$Skeleton$headerHeight)
										]))
								]),
							_List_fromArray(
								[
									A2(
									rtfeldman$elm_css$Html$Styled$h1,
									_List_fromArray(
										[
											rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													rtfeldman$elm_css$Css$fontSize(
													rtfeldman$elm_css$Css$em(3))
												]))
										]),
									_List_fromArray(
										[
											rtfeldman$elm_css$Html$Styled$text('404 Not Found')
										])),
									A2(
									rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											rtfeldman$elm_css$Html$Styled$text('Sorry we couldn\'t find what you are looking for.')
										])),
									A2(
									author$project$UI$Elements$linkBtn,
									_List_fromArray(
										[
											rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													rtfeldman$elm_css$Css$backgroundColor(author$project$UI$Branding$colors.ed),
													A2(
													rtfeldman$elm_css$Css$margin2,
													rtfeldman$elm_css$Css$px(0),
													rtfeldman$elm_css$Css$em(1))
												])),
											rtfeldman$elm_css$Html$Styled$Attributes$href('/')
										]),
									_List_fromArray(
										[
											A2(
											author$project$Icons$FeatherIcons$toHtml,
											_List_Nil,
											A2(
												author$project$Icons$FeatherIcons$withSizeUnit,
												'em',
												A2(author$project$Icons$FeatherIcons$withSize, 1.5, author$project$Icons$FeatherIcons$home)))
										]))
								]))
						]),
					d7: 'Not Found'
				});
		case 0:
			var course = _n0.a;
			return A4(
				author$project$Skeleton$view,
				model.A,
				model.aN,
				author$project$Main$CourseMsg,
				A2(author$project$Page$Course$view, model.A, course));
		case 1:
			var content = _n0.a;
			return A4(
				author$project$Skeleton$view,
				model.A,
				model.aN,
				author$project$Main$ContentMsg,
				A2(author$project$Page$Content$view, model.A, content));
		default:
			var home = _n0.a;
			return A4(
				author$project$Skeleton$view,
				model.A,
				model.aN,
				author$project$Main$HomeMsg,
				A2(author$project$Page$Home$view, model.A, home));
	}
};
var elm$browser$Browser$application = _Browser_application;
var author$project$Main$main = elm$browser$Browser$application(
	{dJ: author$project$Main$init, dX: author$project$Main$UrlChanged, dY: author$project$Main$LinkClicked, d6: author$project$Main$subscriptions, d8: author$project$Main$update, eb: author$project$Main$view});
_Platform_export({'Main':{'init':author$project$Main$main(
	elm$json$Json$Decode$succeed(0))(0)}});}(this));